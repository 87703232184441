<template>

  <view-content :back="{ name:'Settings' }">

    <template v-slot:header>
      User Roles
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="permits['settings.roles.add']" @click="addRole()">
        Add Role
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <tr v-for="role in orderedRoles" :key="'r' + role.id">
        <td>
          <span>{{ role.name }}</span><br>
          <small v-if="!roleEditable(role)" class="text-muted">This is a default role and edits can't be saved</small>
        </td>
        <td class="tools t2">
          <button @click="editRole(role)" class="btn btn-info btn-round mr-1" v-if="permits['settings.roles.edit']">
            <fa-icon :icon="['fal', 'pencil']" />
          </button>
          <button @click="promptDeleteRole(role)" class="btn btn-danger btn-round" v-if="permits['settings.roles.delete'] && roleEditable(role)">
            <fa-icon :icon="['fal', 'trash']" />
          </button>
        </td>
      </tr>
    </table>

    <portal to="modals">
      <modal :header="'User Role'" :active.sync="roleFormActive" @submit="submitRole()" :validated="roleValid">
        <input-text :label="'Name'" v-model="role.name" />
        <div class="field">
          <label class="label">Permissions</label>
          <treeselect
            v-model="role.permissions"
            :multiple="true"
            :options="sortedPermissions"
            :append-to-body="true"
            :flat="true"
            :autoDeselectDescendants="true"
            :autoSelectAncestors="true"
            :clearable="false"
            :limit="5"
            :defaultExpandLevel="3">
          </treeselect>
          <!--<checkboxtree :options="permissions" v-model="role.permissions"></checkboxtree>-->
        </div>
      </modal>

      <delete
        :submitting="submitting"
        :header="'Delete Role'"
        :active.sync="activePromptDeleteRole"
        :message="'Are you sure you wish to delete '+role.role+' as a Role?\n All users with this role will be given the Publisher role.'"
        @submit="confirmDeleteRole()"
      ></delete>
    </portal>

  </view-content>

</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { findIndex, orderBy } from 'lodash'
import { i18n } from '@/i18n'
import { get, put, post, destroy } from '@/composables/serverInterface'
import { assign, merge, push, forget } from '@/composables/dataInterface'

const role = ref({
  id: null,
  name: null,
  permissions: []
})
const roleFormActive = ref(false)
const activePromptDeleteRole = ref(false)
const submitting = ref(false)

const sid = computed(() => store.state.core.sid)
const permits = computed(() => store.state.core.permits)
const roles = computed(() => store.state.roles)
const permissions = computed(() => store.state.permissions)

const roleValid = computed(() => {
  if (role.value.permissions.length === 0) return false
  if (role.value.name.length === 0) return false
  return true
})
const orderedRoles = computed(() => {
  return orderBy(roles.value, ['name'])
})
const sortedPermissions = computed(() => {
  const permArr = []
  if (!permissions.value) return permissions.value
  permissions.value.forEach(p => {
    const obj = {
      id: p.id,
      target: p.name,
      label: i18n.t('permissions.' + p.name.replaceAll('.', '+'))
    }
    const dotPos = p.name.split('.')
    dotPos.pop()
    if (dotPos.length === 1) {
      const target = dotPos.pop()
      const index = findIndex(permArr, np => {
        return np.target === target
      })
      if (index >= 0) {
        // eslint-disable-next-line no-prototype-builtins
        if (!permArr[index].hasOwnProperty('children')) permArr[index].children = []
        permArr[index].children.push(obj)
      }
    } else if (dotPos.length === 2) {
      const secondTarget = dotPos.join('.')
      dotPos.pop()
      const firstTarget = dotPos.join('.')
      const firstIndex = findIndex(permArr, np => {
        return np.target === firstTarget
      })
      const secondIndex = findIndex(permArr[firstIndex].children, np => {
        return np.target === secondTarget
      })
      if (firstIndex >= 0 && secondIndex >= 0) {
        // eslint-disable-next-line no-prototype-builtins
        if (!permArr[firstIndex].children[secondIndex].hasOwnProperty('children')) permArr[firstIndex].children[secondIndex].children = []
        permArr[firstIndex].children[secondIndex].children.push(obj)
      }
    } else {
      permArr.push(obj)
    }
  })
  return permArr
})

onMounted(() => {
  get('roles').then(res => {
    assign('roles', res)
  })
  get('permissions').then(res => {
    assign('permissions', res)
  })
})

const roleEditable = (r) => {
  const protect = [1, 2, 3, 11, 12, 65, 66, 83, 84, 99, 271]
  if (protect.indexOf(r.id) >= 0) {
    return parseInt(sid.value) === 1
  }
  return true
}
const addRole = () => {
  role.value.id = 0
  role.value.name = null
  role.value.permissions = []
  roleFormActive.value = true
}
const editRole = (r) => {
  role.value.id = r.id
  role.value.permissions = r.permissions
  role.value.name = r.name
  roleFormActive.value = true
}
const submitRole = () => {
  if (roleEditable(role.value)) {
    const uri = ['roles']
    if (role.value.id) {
      uri.push(role.value.id)
      put(uri, role.value).then(res => {
        merge('roles', res.data.data, 'id')
      })
    } else {
      post(uri, role.value).then(res => {
        push('roles', res)
      })
    }
    roleFormActive.value = false
  }
}
const promptDeleteRole = (r) => {
  role.value = r
  activePromptDeleteRole.value = true
}
const confirmDeleteRole = () => {
  destroy(['roles', role.value.id]).then(() => {
    forget('roles', role.value)
    activePromptDeleteRole.value = false
  })
}

</script>

<style>
.last-name {
  text-transform: uppercase;
}
</style>
