<template>
  <div>
    <modal :header="$t('labels.congregation.meeting_settings')" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default="{ error }">
      <div v-if="!congregation.active">
        <input-text :name="'name'" :label="'Name'" v-model="resource.name" :error="error" />
      </div>
      <div v-if="!congregation.active || cong.id === congregation.id">
        <input-text :name="'suburb'" :label="'Suburb'" v-model="resource.suburb" :error="error" />
        <input-text :name="'post_code'" :label="'Zip/Post Code'" v-model="resource.postcode" :error="error" />
        <input-text :name="'address'" :label="'Address'" v-model="resource.address" :error="error" />
        <input-text :name="'circuit'" :label="'Circuit'" v-model="resource.circuit" :error="error" />
      </div>
      <input-slider :name="'public_day'" :label="$t('fields.public_meeting_day.label')" v-model="resource.public_day" :options="days" :error="error" />
      <input-time :name="'public_start_time'" class="pt-3" :label="$t('fields.public_meeting_start_time.label')" :minute-step="5" v-model="resource.public_start_time" :error="error" />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { post } from '@/composables/serverInterface.js'
import { store } from '@/store.js'

const props = defineProps(['congregation', 'active'])
const emit = defineEmits(['close'])

const cong = store.state.settings.cong

const initialResource = {
  id: null,
  name: null,
  country: null,
  region: null,
  suburb: null,
  postcode: null,
  address: null,
  circuit: null,
  ptc: null,
  public_day: null,
  public_start_time: null
}

const resource = ref({ ...initialResource })

const days = ref({
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
})

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    Object.keys(resource.value).forEach(key => {
      if (props.congregation[key]) resource.value[key] = props.congregation[key]
    })
  }
})

const submit = () => {
  resource.value.congregation = props.congregation.id
  post(['congregation', props.congregation.id], resource.value).then(res => {
    emit('saveResponse', res)
  })
}

</script>
