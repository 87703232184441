<template>

  <v-tour name="attendants-schedule-tour" :steps="steps" :options="options" :callbacks="callbacks"></v-tour>

</template>

<script setup>
import { computed, ref } from 'vue'
import { store } from '@/store'
import { i18n } from '@/i18n'

const res = computed(() => store.state.attendants.responsibilities)

const options = computed(() => {
  return {
    labels: {
      buttonSkip: i18n.t('actions.ar', { a: i18n.t('actions.stop'), r: i18n.tc('resources.tour', 1) }),
      buttonPrevious: i18n.t('actions.previous'),
      buttonNext: i18n.t('actions.next'),
      buttonStop: i18n.t('actions.ar', { a: i18n.t('actions.finish'), r: i18n.tc('resources.tour', 1) })
    }
  }
})

const steps = computed(() => [
  {
    content: i18n.t('tours.attendant.step01'),
    params: {
      type: 'sticky'
    }
  },
  {
    content: i18n.t('tours.attendant.step02'),
    params: {
      type: 'sticky',
      stop: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step03'),
    params: {
      type: 'sticky',
      stop: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step04'),
    params: {
      type: 'sticky'
    }
  },
  {
    target: '#add-responsibility',
    content: i18n.t('tours.attendant.step05'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step06'),
    params: {
      type: 'sticky',
      prev: 'false'
    }
  },
  {
  },
  {
    target: '#first-responsibility',
    content: i18n.t('tours.attendant.step07'),
    params: {
      prev: 'false',
      stop: 'false'
    }
  },
  {
    target: '#first-responsibility-order',
    content: i18n.t('tours.attendant.step08'),
    params: {
      prev: 'false',
      stop: 'false'
    }
  },
  {
    target: '#nav-schedules',
    content: i18n.t('tours.attendant.step09'),
    params: {
      next: 'false',
      stop: 'false'
    }
  },
  {
    target: '#nav-attendants',
    content: i18n.t('tours.attendant.step10'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step11'),
    params: {
      type: 'sticky',
      prev: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step12'),
    params: {
      type: 'sticky',
      stop: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step13'),
    params: {
      type: 'sticky',
      stop: 'false'
    }
  },
  {
    target: '#first-meeting',
    content: i18n.t('tours.attendant.step14'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step15'),
    params: {
      type: 'sticky',
      stop: 'false',
      prev: 'false'
    }
  },
  {
    target: '#nav-settings',
    content: i18n.t('tours.attendant.step16'),
    params: {
      nav: 'false'
    }
  },
  {
    target: '#automatic-messaging',
    content: i18n.t('tours.attendant.step17'),
    params: {
      nav: 'false'
    }
  },
  {
    target: '#attendant-toggle',
    content: i18n.t('tours.attendant.step18'),
    params: {
      prev: 'false'
    }
  },
  {
    target: '#nav-settings',
    content: i18n.t('tours.attendant.step19'),
    params: {
      nav: 'false'
    }
  },
  {
    target: '#print-attendants-schedule',
    content: i18n.t('tours.attendant.step20'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step21'),
    params: {
      type: 'sticky',
      prev: 'false',
      stop: 'false'
    }
  },
  {
    content: i18n.t('tours.attendant.step22'),
    params: {
      type: 'sticky',
      prev: 'false'
    }
  }
])

const nextStepCallback = (currentStep) => {
  if (currentStep === 3 && res.value.length > 0) {
    this.$tours['attendants-schedule-tour'].currentStep = 7
  }
  if (currentStep === 15) {
    this.$store.commit('CLOSE_ATTENDANTS_FORM')
  }
}

const callbacks = ref({
  onNextStep: nextStepCallback
})

</script>
