<template>

  <view-content :back="{name:'Topics'}">

    <template v-slot:header>
      Discussion
    </template>

    <div class="mb-2 pb-5"></div>
    <div class="max-w rounded overflow-hidden shadow-md bg-white mb-4 font-sans" v-if="forumTopic">
      <div class="p-2">
        <avatar :user="forumTopic.author" :small="true" />
        <div class="text-left pt-2">
          <h5 class="text-base font-semibold mb-1">{{ forumTopic.label }}</h5>
          <div class="font-normal" v-html="forumTopic.content"></div>
        </div>
      </div>
    </div>
    <div class="max-w rounded overflow-hidden shadow-md bg-white mb-4" v-for="r in orderedComments" :key="'t' + r.id">
      <div class="p-2 font-sans text-left">
        <avatar :user="r.author" :small="true" />
        <div class="pt-2 mb-1" v-html="r.content"></div>
        <small class="text-muted">{{ ago(r.created_at) }}</small>
      </div>
      <footer class="flex flex-row border-t divide-x font-sans" v-if="r.can_edit">
        <div @click="update(r)" class="flex-auto cursor-pointer py-2">
          <small class="text-muted pl-1 pt-1"><fa-icon :icon="['fal', 'pencil']" class="text-primary" /> edit</small>
        </div>
        <div @click="promptDestroy(r)" class="flex-auto cursor-pointer py-2">
          <small class="text-muted pl-1 pt-1"><fa-icon :icon="['fal', 'trash']" class="text-primary" /> delete</small>
        </div>
      </footer>
    </div>

    <button class="btn btn-warning mb-4" @click="create">Add Comment</button>

    <portal to="modals">
      <modal :submitting="submitting" :header="'Comment'" :active.sync="createTopicComments" @submit="submit" :error="axiosError">
        <input-message :label="'Comment'" :type="'text'" v-model="resource.content" :error="axiosError && axiosError.errors && axiosError.errors.content ? axiosError.errors.content[0] : null" />
      </modal>

      <delete
        :submitting="submitting"
        :header="'Delete Comment'"
        :active.sync="destroyTopicComments"
        :message="'Are you sure you wish to delete this comment?'"
        @submit="destruction"
      ></delete>
    </portal>

  </view-content>

</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { get, put, post, destroy } from '@/composables/serverInterface'
import { assign, merge, push, forget } from '@/composables/dataInterface'
import { fillObject } from '@/helpers'
import { find, orderBy } from 'lodash'
import { store } from '@/store'
import { Sec } from '@/mixins/sec'

const props = defineProps(['topic'])
const activeID = ref(null)
const submitting = ref(false)
const createTopicComments = ref(false)
const destroyTopicComments = ref(false)
const initialResource = {
  id: null,
  content: ''
}
const resource = ref({ ...initialResource })
const resources = computed(() => store.state.topicComments)
const axiosError = computed(() => store.state.core.axiosError)
const forumTopics = computed(() => store.state.forumTopics)
const forumTopic = computed(() => {
  return find(forumTopics.value, t => t.id === parseInt(props.topic))
})
const orderedComments = computed(() => {
  return orderBy(resources.value, ['created_at', 'desc'])
})

onMounted(() => {
  if (forumTopics.value.length) index()
  else {
    get(['forums', 1, 'topics']).then(res => {
      assign('forumTopics', res)
      index()
    })
  }
})

const index = () => {
  get(['topics', props.topic, 'comments']).then(res => {
    assign('topicComments', res)
  })
}
const create = () => {
  resource.value = { ...initialResource }
  createTopicComments.value = true
}
const update = (c) => {
  resource.value = { ...initialResource }
  fillObject(resource.value, c)
  createTopicComments.value = true
}
const promptDestroy = (c) => {
  activeID.value = c.id
  destroyTopicComments.value = true
}
const submit = () => {
  if (resource.value.id) {
    put(['topics', props.topic, 'comments', resource.value.id], resource.value).then(res => {
      merge('topicComments', res.data.data)
      createTopicComments.value = false
    })
  } else {
    post(['topics', props.topic, 'comments'], resource.value).then(res => {
      push('topicComments', res)
      createTopicComments.value = false
    })
  }
}
const destruction = () => {
  destroy(['topics', props.topic, 'comments', activeID.value]).then(() => {
    forget('topicComments', activeID.value)
    destroyTopicComments.value = false
  })
}
const ago = (time) => {
  return (new Sec(time)).ago()
}

</script>
