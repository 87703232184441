<template>

  <div class="notification is-warning" v-if="showInstallBanner" @click="install" style="cursor:pointer">
    <div class="is-clearfix"><b style="line-height:32px;">{{ $t('notifications.install.message') }}</b>
      <button class="btn btn-info is-pulled-right" style="cursor:pointer">{{ $t('notifications.install.button') }}</button>
    </div>
  </div>

</template>

<script>

export default {
  name: 'installPrompt',
  data () {
    return {
      showInstallBanner: false,
      installEvent: null
    }
  },
  created () {
    window.addEventListener('beforeinstallprompt', e => {
      e.preventDefault()
      this.installEvent = e
      console.log(this.installEvent)
      this.showInstallBanner = true
    })
  },
  methods: {
    install () {
      this.showInstallBanner = false
      this.installEvent.prompt()
      this.installEvent.userChoice.then((result) => {
        if (result.outcome === 'accepted') {
          console.log('User accepted the A2HS prompt')
        } else {
          console.log('User dismissed the A2HS prompt')
        }
        this.installEvent = null
      })
    }
  }
}
</script>
