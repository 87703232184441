var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.public_meeting && _vm.public_meeting.event && _vm.public_meeting.event.public_theme && _vm.publicTalkActive)?_c('div',{ref:"publicTalkPrompt",staticClass:"notification is-event",class:_vm.collapse ? 'collapsing' : ''},[_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.dismissAlert('publicTalkActive')}}}),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('notifications.special_event', {
              day: (new _vm.Sec(_vm.public_meeting.local_date)).format('d').toUpperCase(),
              theme: _vm.public_meeting.event.public_theme,
          }))}})]):(_vm.modules && _vm.modules.meeting_schedule.on && _vm.public_meeting && _vm.public_meeting.parts.talk.talk.notes && _vm.publicTalkActive)?_c('div',{ref:"publicTalkPrompt",staticClass:"notification public-talk",class:_vm.collapse ? 'collapsing' : ''},[_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.dismissAlert('publicTalkActive')}}}),_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('notifications.public_talk', {
              day: (new _vm.Sec(_vm.public_meeting.local_date)).format('d').toUpperCase(),
              theme: _vm.public_meeting.parts.talk.talk.notes,
          }))}})]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }