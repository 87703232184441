<template>
  <div>
    <table class="bg-white w-100 mt-4">
      <tr>
        <td class="align-middle p-2"><strong>{{ $t('labels.attendance.label') }}:</strong> {{ attendance }}</td>
        <td class="tools t1 p-2">
          <button class="btn btn-info btn-round" @click="editAttendance">
            <fa-icon :icon="['fal', 'pencil']" />
          </button>
        </td>
      </tr>
    </table>
    <modal :submitting="submitting" :header="$t('labels.attendance.label')" :active.sync="attendanceFormActive" @submit="submitAttendance">
      <input-text :label="$t('fields.attendance.label')" :type="'number'" v-model="form.attendance"></input-text>
    </modal>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { post } from '@/composables/serverInterface'
import { merge } from '@/composables/dataInterface'

const props = defineProps(['attendance', 'meeting'])

const form = ref({
  meeting_id: null,
  attendance: null
})
const submitting = ref(false)
const attendanceFormActive = ref(false)

const editAttendance = () => {
  attendanceFormActive.value = true
  form.value.meeting_id = props.meeting
  form.value.attendance = props.attendance
}

const submitAttendance = () => {
  submitting.value = true
  post(['meeting-schedule', props.meeting, 'meeting-attendance'], form.value)
    .then(res => {
      submitting.value = false
      merge('meetings', res.data.data)
      attendanceFormActive.value = false
    })
}

</script>
