<template>
  <div>
    <modal :header="'Booking Talk for ' + speaker.full_name"
           :active="active"
           @submit="submit"
           @close="emit('close')"
           :validated="ptcValid"
    >
      <template v-slot:default="{ error }">
        <warning-box class="mb-3" v-if="!speakerIsAvailable">
          <template #header>Speaker Unavailable</template>
          {{ speaker.full_name }} is not available to give a talk on that date.
        </warning-box>
        <tuxedo-select
          name="meeting_id"
          :label="$t('fields.date.label')"
          v-model="resource.meeting_id"
          :error="error"
          :options="availableDates"
          insistLabel="local_date"
          inputClass="bg-gray-100"
          insistReturn="id"
        >
          <template #label="{ option }">
            {{ new Sec(option.local_date).format('jS M Y') }}
          </template>
        </tuxedo-select>
        <tuxedo-select
          name="public_talk_id"
          :label="'Talk'"
          v-model="resource.public_talk_id"
          :error="error"
          :options="sortedTalks"
          insistLabel="title"
          inputClass="bg-gray-100"
          insistReturn="id"
        >
          <template #label="{ option }">
            {{ option.number }}.&nbsp;{{ $t('talks.'+option.number) }}
          </template>
          <template #content="{ option }">
            <div v-if="option.promote" class="text-teal">{{ speaker.full_name }} gives this talk</div>
            <div>Last Date Given: {{ lastTimeTalkWasGiven(option) }}</div>
          </template>
        </tuxedo-select>
        <input-toggle :label="'Request Confirmation'" v-model="resource.confirmation" :error="error"
                     :help="'This will send an email to the PTC of this congregation asking for them to confirm this assignment'" />

        <div class="text-red-700 bg-red-100 border border-red-200 rounded p-2 shadow" v-if="!ptcValid">
          <div class="flex flex-col justify-content-between">
            <div class="font-bold">IMPORTANT!</div>
            <div>
              You won't be able to complete a speaker booking until your congregation assigns a valid Public Talk
              Coordinator. You can assign a PTC by going to Settings > Meeting Settings and selecting a publisher with
              a valid email address.
            </div>
          </div>
        </div>

      </template>
    </modal>
  </div>
</template>

<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { filter, find, forEach, map, orderBy } from 'lodash'
import { store } from '@/store'
import { Sec } from '@/mixins/sec'
// eslint-disable-next-line import/no-webpack-loader-syntax
import AvailableMeetingDatesWorker from 'worker-loader!@/workers/availableMeetingDates.js'
import { get, post } from '@/composables/serverInterface.js'
import { assign, merge } from '@/composables/dataInterface.js'

const props = defineProps(['congregation', 'speaker', 'active', 'defaultMeeting'])
const emit = defineEmits(['close'])

const meetings = store.state.publicMeetings
const availableDates = ref([])
const talks = store.state.publicTalkTitles
const events = store.state.events
const ptcId = store.state.settings.cong.modules.meeting_schedule.ptc
const pubs = store.state.users

const initialResource = {
  speaker_id: null,
  meeting_id: null,
  public_talk_id: null,
  confirmation: true
}

const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    // This is a bit hair brained and I will need to improve it. But we wait for a second because
    // the speaker prop hasn't yet been filled when the active prop is fired. This is because
    // the speaker prop take about a second to finish populating. What would dbe the ideal
    // solution for this? When working with multi level forms, it would probably be best
    // to have a little loading screen in between to ensure all data is correctly loaded.
    setTimeout(() => {
      resource.value = {
        ...initialResource,
        meeting_id: props.defaultMeeting ?? null,
        speaker_id: props.speaker.id
      }
    }, 1000)
    if (props.defaultMeeting) await updateAvailableDates()
  }
})

onMounted(() => {
  get(['public-meeting-schedule']).then(res => {
    assign('publicMeetings', res)
    meetings.value = store.state.publicMeetings
    updateAvailableDates()
  })
})

const ptc = computed(() => {
  return find(pubs, p => parseInt(p.id) === parseInt(ptcId))
})

const ptcValid = computed(() => {
  return !!(ptc.value && ptc.value.email)
})

const speakerOutlines = computed(() => {
  return map(props.speaker.outlines, 'id')
})

const promotedTalks = computed(() => {
  return forEach(talks, function (t) { t.promote = speakerOutlines.value.includes(t.id) })
})

// eslint-disable-next-line no-unused-vars
const sortedTalks = computed(() => {
  return orderBy(promotedTalks.value, ['promote', 'number'], ['desc', 'asc'])
})

const speakerIsAvailable = computed(() => {
  if (!props.speaker.away_dates) return true
  if (props.speaker.away_dates.length < 1) return true
  if (!resource.value.meeting_id) return true

  const current = find(availableDates.value, d => d.id === resource.value.meeting_id)
  if (!current) return true

  const date = new Sec(current.local_date)
  let available = true

  props.speaker.away_dates.forEach(a => {
    const startDate = new Sec(a.start_date)
    const endDate = new Sec(a.end_date)
    if (date.isBetween(startDate, endDate)) available = false
  })

  return available
})

const lastTimeTalkWasGiven = (talk) => {
  const theNote = talk.number + '. ' + talk.title
  const meetingsWithTalk = filter(meetings.value, m => {
    const talkPart = find(m.parts, p => {
      return p.meeting === 'talk' && p.part === 'talk'
    })
    if (talkPart && talkPart.assignments && talkPart.assignments.length > 0) {
      return talkPart.assignments[0].notes === theNote
    }
  })
  if (meetingsWithTalk.length > 0) {
    return new Sec(orderBy(meetingsWithTalk, 'local_date')[0].local_date).format('jS F Y')
  }
}

const updateAvailableDates = async () => {
  const worker = new AvailableMeetingDatesWorker()
  worker.postMessage({
    meetings: meetings.value,
    events: events,
    defaultMeeting: props.defaultMeeting
  })
  worker.addEventListener('message', (event) => {
    availableDates.value = event.data
  })
}

const submit = () => {
  if (ptcValid.value) {
    resource.value.congregation_id = props.congregation.id
    post(['public-speaker-assignment'], resource.value)
      .then(res => {
        emit('saveResponse', res)
        merge('publicMeetings', res)
        updateAvailableDates()
      })
  }
}

</script>
