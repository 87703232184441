<template>
  <div class="h-full">
    <div ref="header" class="text-gray-100 w-full flex justify-center fixed top-0 bg-teal-darkest z-20 rounded-b-lg transition-all overflow-hidden" :style="'height: ' + headerHeight + 'px'">
      <div class="px-3 py-4 w-full max-w-screen-md text-left">
        <div class="flourish flourish-1"></div>
        <div class="flourish flourish-2"></div>
        <div class="flourish flourish-3"></div>
        <div ref="headerContent">
          <h1 ref="headerH1" class="text-2xl font-sans font-semibold transition-all flex justify-between">
            <slot name="header" ref="headerSlot"></slot>
            <router-link :to="back" v-if="back && !singleView">
                <span ref="headerBack" class="bg-teal rounded-full w-8 h-8 flex justify-center items-center transition-all">
                  <fa-icon class="text-teal-darkest relative right-px" :icon="['fas', 'chevron-left']" />
                </span>
            </router-link>
          </h1>
          <div ref="subtext" class="transition-all">
            <slot name="subtext"></slot>
          </div>
          <div ref="actions" class="transition-all mt-2">
            <slot name="actions"></slot>
          </div>
        </div>
      </div>
    </div>
    <div ref="content" class="bg-gray-100 w-full flex justify-center relative" :style="'padding-top: ' + startingHeaderHeight +  'px'">
      <div class="w-full px-2 pt-4 pb-24" :class="contentClass">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watchEffect } from 'vue'
import { store } from '@/store'

defineProps({
  back: {
    type: Object,
    default: null
  },
  contentClass: {
    type: String,
    default: 'max-w-screen-md'
  }
})
const header = ref(null)
const headerContent = ref(null)
const subtext = ref(null)
const actions = ref(null)
const headerSlot = ref(null)
const headerH1 = ref(null)
const headerHeight = ref(71)
const startingHeaderHeight = ref(71)
const collapsedHeaderHeight = ref(46)
const singleView = store.state.core.singleView

onMounted(() => {
  watchEffect(() => calculateHeading())
  calculateHeading()
  document.body.onscroll = () => handleScroll()
})

const calculateHeading = () => {
  startingHeaderHeight.value = headerContent.value.offsetHeight + 32
  headerHeight.value = startingHeaderHeight.value
}

const handleScroll = () => {
  // Get current scroll position
  const currentScrollPosition = window.scrollX || document.documentElement.scrollTop
  // console.log(currentScrollPosition)
  if (currentScrollPosition > startingHeaderHeight.value - 20) {
    headerHeight.value = collapsedHeaderHeight.value
    subtext.value.classList.add('opacity-0')
    actions.value.classList.add('opacity-0')
    headerH1.value.classList.remove('text-2xl')
    headerH1.value.classList.add('text-xl')
    headerH1.value.classList.add('-my-2')
  } else {
    headerHeight.value = startingHeaderHeight.value
    subtext.value.classList.remove('opacity-0')
    actions.value.classList.remove('opacity-0')
    headerH1.value.classList.add('text-2xl')
    headerH1.value.classList.remove('text-xl')
    headerH1.value.classList.remove('-my-2')
  }
}
</script>

<style>
.flourish {
  height: 60px;
  width: 1500px;
  position: absolute;
  pointer-events: none;
  top: 300px;
  transform: rotate(-75deg);
}

.flourish-1 {
  left: -800px;
  background: rgba(255, 255, 255, 0.1);
}

.flourish-2 {
  left: -738px;
  background: rgba(255, 255, 255, 0.15);
}

.flourish-3 {
  height: 4px;
  left: auto;
  right: -655px;
  background: rgba(255, 255, 255, 0.1);
}
</style>
