import axios from 'axios'

export const GET_AWAY_TALKS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/away-talks')
      .then(function (response) {
        commit('UPDATE_AWAY_TALKS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_CONGREGATION_SPEAKERS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/congregation-speakers'
      , payload.cong)
      .then(function (response) {
        commit('UPDATE_PUBLIC_SPEAKERS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_CONGREGATION_PTC = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/congregation-ptc?api_token=' + payload.token, payload.cong)
      .then(function (response) {
        commit('UPDATE_PTC', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_CONGREGATION_MEETING = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/congregation-public-meeting', payload.cong)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_NEW_CONGREGATION = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/new-congregation', payload.cong)
      .then(function (response) {
        dispatch('GET_ALL_CONGREGATIONS', payload)
        dispatch('GET_CONGREGATION_SPEAKERS', payload)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_NEW_SPEAKER = ({ commit, dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/new-speaker', payload)
      .then(function (response) {
        dispatch('GET_CONGREGATION_SPEAKERS', { cong: payload.cong })
        resolve(response)
      })
      .catch(function (error) {
        commit('SUBMIT_ERROR', error.response.data)
        reject(error)
      })
  })
}

export const SUBMIT_PUBLIC_SPEAKER = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/public-speaker-assignment', payload.talk)
      .then(function (response) {
        commit('UPDATE_PUBLIC_SPEAKER_SCHEDULE', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_AWAY_SPEAKER = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/away-speaker-assignment', payload.talk)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const CLEAR_PUBLIC_SPEAKER = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/clear-speaker-assignment', payload.meeting)
      .then(function (response) {
        commit('UPDATE_PUBLIC_SPEAKER_SCHEDULE', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
