<template>
  <div class="mb-10">
    <label v-if="label" class="text-uppercase font-semibold">
      {{ label }}
    </label>
    <p class="" v-if="help">{{ help }}</p>
    <div class="-mx-3 mt-2">
      <ul class="flex justify-content-around m-0 list-none">
        <li class="flex-1 text-center relative m-0 input-slider" v-for="(option, key) in options" :key="'rs' + key">
          <span class="block m-0 w-full bg-gray-200 absolute top-1/2 connector"></span>
          <input class="sr-only" type="radio" :id="'r'+key+option.replace(/ /g,'')+'-'+label.replace(/ /g,'')" :name="label.replace(/ /g,'')" :value="key" @click="updateValue($event.target.value)" :checked="(value == key || (!value && key == 0))">
          <span class="bg-blue-400 rounded-full absolute inset-x-0 mx-auto active-marker transition"></span>
          <label
            class="absolute block inset-x-0 my-0 mx-auto rounded-full cursor-pointer transition-all"
            :for="'r'+key+option.replace(/ /g,'')+'-'+label.replace(/ /g,'')" />
          <span class="relative top-6">{{ option }}</span>
        </li>
      </ul>
    </div>
  </div>

</template>

<script setup>
defineProps(['label', 'help', 'value', 'options', 'required'])
const emit = defineEmits(['input'])

const updateValue = (value) => {
  emit('input', value)
}
</script>

<style lang="css">
.input-slider .connector {
  height: 3px;
}
.input-slider label {
  background-color: rgba(0, 0, 0, 0.1);
  border: 1px solid #d2d6dc;
  height: 26px;
  width: 26px;
  top: -3px;
}
.input-slider .active-marker {
  display: none;
  height: 0;
  width: 0;
  top: -3px;
  opacity: 0.4;
}
.input-slider input:checked ~ label {
  background-color: #4299e1;
  border: none;
  height: 18px;
  width: 18px;
  top: 1px;
}
.input-slider input:checked + .active-marker {
  display: block;
  height: 26px;
  width: 26px;
}
</style>
