<template>
  <div class="max-w rounded overflow-hidden shadow-md bg-white mb-4">
    <div class="p-2">
      <avatar :user="topic.author" />
      <div class="text-left font-sans pt-2">
        <h5 class="text-base font-semibold mb-1">{{ topic.label }}</h5>
        <div class="font-normal" v-html="topic.content"></div>
      </div>
      <div class="text-left my-2" @click="pushState(topic.id)">
        <badge v-if="topic.state === 'proposed'" class="bg-yellow-400">{{ $t('labels.' + topic.state) }}</badge>
        <badge v-else-if="topic.state === 'dev_queue'" class="bg-blue-400">{{ $t('labels.' + topic.state) }}</badge>
        <badge v-else class="bg-teal">{{ $t('labels.' + topic.state) }}</badge>
      </div>
    </div>
    <footer class="flex flex-row border-t divide-x font-sans">
      <div class="flex-auto cursor-pointer" @click="likeTopic(topic.id)">
        <div class="pt-2 h-full border-b-4" :class="topic.liked ? 'border-orange' : 'border-transparent'">
          <small class="font-semibold">{{ topic.likes }}</small>
          <small> likes</small>
        </div>
      </div>
      <router-link :to="'/forum/topics/' + topic.id" class="flex-auto pt-2 cursor-pointer">
          <span>
            <small class="font-semibold">{{ topic.comments }}</small>
            <small> comments</small>
          </span>
      </router-link>
      <div class="flex-auto cursor-pointer" @click="subscribeToTopic(topic.id)">
        <div class="pt-2 h-full border-b-4" :class="topic.subscribed ? 'border-orange' : 'border-transparent'">
          <small v-if="!topic.subscribed">subscribe</small>
          <small v-else>subscribed</small>
        </div>
      </div>
      <div class="flex-auto py-2 cursor-pointer" @click="popupMenu = true" :id="'popupMenu' + topic.id">
        <fa-icon :icon="['fal', 'ellipsis-h']" class="" />
      </div>
      <tuxedo-secondary-menu v-if="popupMenu" @close="popupMenu = false" :target="'popupMenu' + topic.id">
        <ul>
          <sm-item class="text-lg justify-content-center" v-if="topic.can_edit">
            <span @click="$emit('update')">{{ $t('actions.edit') }}</span>
          </sm-item>
          <sm-item class="text-lg justify-content-center" v-if="topic.can_delete">
            <span @click="$emit('destroy')">{{ $t('actions.delete') }}</span>
          </sm-item>
        </ul>
      </tuxedo-secondary-menu>
    </footer>
  </div>

</template>

<script setup>
import { ref } from 'vue'
import { put } from '@/composables/serverInterface'
import { merge } from '@/composables/dataInterface'
import { store } from '@/store'

defineProps(['topic'])

const popupMenu = ref(false)
const sid = store.state.core.sid

const subscribeToTopic = (id) => {
  put(['forums', 1, 'topics', id], { subscribe: true }).then(res => {
    merge('forumTopics', res.data.data)
  })
}
const likeTopic = (id) => {
  put(['forums', 1, 'topics', id], { like: true }).then(res => {
    merge('forumTopics', res.data.data)
  })
}
const pushState = (id) => {
  if (sid === 1) {
    put(['forums', 1, 'topics', id], { pushState: true }).then(res => {
      merge('forumTopics', res.data.data)
    })
  }
}
</script>
