export function assignKey () {
  let result = ''
  const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  const charLength = chars.length
  for (let i = 0; i < 16; i++) {
    result += chars.charAt(Math.floor(Math.random() *
                    charLength))
  }
  return result
}
