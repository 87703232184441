<template>
  <button class="w-9 base-timer" @click="$emit('click')">
    <svg class="base-timer__svg" viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
      <g class="base-timer__circle">
        <circle class="base-timer__path-elapsed text-gray-200 stroke-current" cx="50" cy="50" r="45"></circle>
        <path
          id="base-timer-path-remaining"
          :stroke-dasharray="dashArray"
          class="base-timer__path-remaining text-green-600 stroke-current"
          d="
          M 50, 50
          m -45, 0
          a 45,45 0 1,0 90,0
          a 45,45 0 1,0 -90,0
        "
        ></path>
      </g>
    </svg>
    <span class="absolute top-1/2 right-1/2 transform -translate-y-1/2 translate-x-1/2 font-sans font-semibold w-9 text-lg">
      <fa-icon :icon="['fas', 'times']" />
    </span>
  </button>
</template>

<script setup>
import { ref, onBeforeUnmount, onMounted } from 'vue'
const props = defineProps(['ms'])

const timerInterval = ref(null)
const msLeft = ref(0)
const msPassed = ref(0)
const dashArray = ref('283 283')
const updateDelay = ref(500)

onMounted(() => {
  stopTimer()
  startTimer()
})

onBeforeUnmount(() => {
  stopTimer()
})

const startTimer = () => {
  dashArray.value = '283 283'
  msLeft.value = props.ms
  msPassed.value = 0
  timerInterval.value = setInterval(() => {
    msPassed.value += updateDelay.value
    msLeft.value = props.ms - msPassed.value
    setCircleDasharray()

    if (msLeft.value <= 0) {
      stopTimer()
      startTimer()
    }
  }, updateDelay.value)
}

const stopTimer = () => {
  clearInterval(timerInterval.value)
}

const calculateTimeFraction = () => {
  const rawTimeFraction = msLeft.value / props.ms
  return rawTimeFraction - (updateDelay.value / props.ms) * ((updateDelay.value / 1000) - rawTimeFraction)
}

const setCircleDasharray = () => {
  dashArray.value = `${(calculateTimeFraction() * 283).toFixed(0)} 283`
}
</script>

<style>
/* Sets the containers height and width */
.base-timer {
  position: relative;
}

/* Removes SVG styling that would hide the time label */
.base-timer__circle {
  fill: none;
  stroke: none;
}

/* The SVG path that displays the timer's progress */
.base-timer__path-elapsed {
  stroke-width: 7px;
}

.base-timer__path-remaining {
  /* Just as thick as the original ring */
  stroke-width: 7px;

  /* Rounds the line endings to create a seamless circle */
  stroke-linecap: round;

  /* Makes sure the animation starts at the top of the circle */
  transform: rotate(90deg);
  transform-origin: center;

  /* One second aligns with the speed of the countdown timer */
  transition: 0.5s linear all;
}

.base-timer__svg {
  /* Flips the svg and makes the animation to move left-to-right */
  transform: scaleX(-1);
}
</style>
