export const state = {
  maps: [],
  mapsLastUpdated: null,
  suburbs: [],
  streets: [],
  map: null,
  street: {},
  foreign: null,
  dnc: {},
  types: {}
}
