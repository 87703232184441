<template>
  <div class="public absolute inset-x-0 top-0" v-if="startTime" :class="templateClass">

    <reset-schedule :schedule="schedule" @reload="$emit('reload')" />

    <meeting-part v-if="chairmanPart" :part="chairmanPart" :chairman="true" @assign="assign" />

    <div v-if="talkParts.length">
      <div class="heading mb-3 shadow-md bg-talk text-white">{{ $t('schedules.public.public_talk') }} <span class="clock">{{ startTime | time }}</span></div>
      <meeting-part v-for="part in talkParts" :key="'part' + part.id" :part="part" :edit="edit" />
    </div>

    <div v-if="watchtowerParts.length">
      <div class="heading mb-3 shadow-md bg-watchtower text-white">{{ $t('schedules.public.watchtower') }} <span class="clock">{{ watchtowerStart | time }}</span></div>
      <meeting-part v-for="part in watchtowerParts" :key="'part' + part.id" :part="part" :edit="edit" @assign="assign" />
    </div>

    <attendance-form class="mb-24" v-if="this.cong.modules.meeting_attendance.on && permits['settings.meetings.attendance']" :attendance="schedule.attendance" :meeting="schedule.id" />

    <meeting-assignment
      :part="part"
      :hall="assignmentHall"
      :assignment="assignment"
      :assignmentType="assignmentType"
      :active.sync="assignmentFormActive" />

  </div>
</template>

<script>
import ResetSchedule from './ResetSchedule'
import { mapState } from 'vuex'

export default {
  name: 'MeetingParts',
  components: {
    'reset-schedule': ResetSchedule
  },
  props: ['edit', 'active', 'loading', 'schedule', 'meeting', 'day'],
  data () {
    return {
      loadDirection: 'forward',
      part: {},
      assignmentHall: 1,
      assignment: {},
      assignmentType: 'main',
      assignmentFormActive: false
    }
  },
  computed: {
    ...mapState({
      schedules: state => state.meetings,
      resources: state => state.meetingParts,
      cong: state => state.settings.cong,
      permits: state => state.core.permits
    }),
    templateClass () {
      if (!this.loading && this.meeting === 'cl') return 'animate-forward-out'
      else if (this.loading) return 'animate-' + this.loadDirection + '-out'
      else if (this.startTime) return 'animate-' + this.loadDirection + '-in'
      else return 'hidden'
    },
    parts () {
      return this._.orderBy(this.resources, ['order'], ['asc'])
    },
    startTime () {
      if (this.schedule) {
        const date = new Date(this.schedule.start_date)
        const currentDay = date.getDay()
        let publicDay = this.cong.modules.meeting_schedule.public_day || 0
        publicDay = parseInt(publicDay) === 0 ? 7 : publicDay
        const distance = parseInt(publicDay) - currentDay
        date.setDate(date.getDate() + distance)
        const time = this.cong.modules.meeting_schedule.public_start_time.split(':')
        date.setHours(time[0], time[1], 0)
        return date
      }
      return null
    },
    watchtowerStart () {
      let minutes = 0
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.talkParts)) {
        minutes += parseInt(value.time)
      }
      return new Date(this.startTime.getTime() + (minutes * 60000))
    },
    talkParts () {
      const parts = this._.filter(this.parts, r => { return r.meeting === 'talk' })
      let minutes = 0
      parts.forEach(p => {
        p.startTime = new Date(this.startTime.getTime() + (minutes * 60000))
        minutes += parseInt(p.time)
      })
      return parts
    },
    watchtowerParts () {
      const parts = this._.filter(this.parts, r => { return r.meeting === 'watchtower' })
      let minutes = 0
      parts.forEach(p => {
        p.startTime = new Date(this.watchtowerStart.getTime() + (minutes * 60000))
        minutes += parseInt(p.time)
      })
      return parts
    },
    chairmanPart () {
      return this._.find(this.parts, r => { return r.meeting === 'talk' && r.part === 'song' })
    }
  },
  watch: {
    day: function (newVal, oldVal) {
      if (this.meeting === 'p') {
        if (parseInt(newVal) > parseInt(oldVal)) this.loadDirection = 'backward'
        else this.loadDirection = 'forward'
      }
    },
    meeting: function (newVal, oldVal) {
      if (oldVal === 'cl') this.loadDirection = 'backward'
      else this.loadDirection = 'forward'
    }
  },
  methods: {
    assign (part, hall, assignment, type) {
      console.log('trigger')
      this.part = part
      this.assignment = assignment
      this.assignmentType = type || 'main'
      this.assignmentHall = hall || 1
      this.assignmentFormActive = true
    }
  }
}

</script>
