import submit from '../router/submit'
import queue from '../router/queue'
import helpers from '../helpers'

function configureUri (uri) {
  if (Array.isArray(uri)) {
    let string = ''
    uri.forEach(p => { string += '/' + p })
    uri = string
  }
  if (uri.charAt(0) !== '/') uri = '/' + uri

  return uri
}

function resourceIsCached (store, resource) {
  return store.state.core.remoteCache[helpers.pluralize(resource)] !== undefined
}

function freshDataRequired (store, resource) {
  resource = helpers.pluralize(resource)
  if (store.state.core.remoteCache[resource] === null) return true
  return store.state.core.remoteCache[resource] !== store.state.core.localCache[resource]
}

function discoverResource (uri) {
  if (!Array.isArray(uri)) uri = uri.split('/')
  return helpers.kebabToSnake(helpers.singular(uri.pop()))
}

export const get = {
  methods: {
    get (uri, options) {
      uri = configureUri(uri)
      const resource = options && options.resource ? options.resource : discoverResource(uri)
      const ignoreCache = options && options.ignoreCache ? options.ignoreCache : false

      if (!ignoreCache && resourceIsCached(this.$store, resource)) {
        if (!freshDataRequired(this.$store, resource)) {
          return new Promise(resolve => {
            resolve()
          })
        }
      }

      const config = {
        data: {
          message: this.$t('actions.action_resource', {
            action: this.$t('actions.loading'),
            resource: this.$tc('resources.' + resource, 0)
          })
        }
      }

      if (options && options.params) {
        config.params = options.params
      }

      return new Promise(resolve => {
        queue.get('/api' + uri, config)
          .then(response => {
            if (resourceIsCached(this.$store, resource)) {
              const key = helpers.pluralize(resource)
              this.$store.commit('UPDATE_LOCAL_CACHE', {
                key: key,
                value: this.$store.state.core.remoteCache[key]
              })
            }
            resolve(response)
          })
      })
    }
  }
}

export const post = {
  methods: {
    post (uri, payload) {
      uri = configureUri(uri)

      return new Promise((resolve, reject) => {
        this.$store.commit('submitting', true)
        submit.post('/api' + uri, payload)
          .then(response => {
            this.$store.commit('submitting', false)
            resolve(response)
          })
          .catch(error => {
            this.$store.commit('submitting', false)
            reject(error)
          })
      })
    }
  }
}

export const put = {
  methods: {
    put (uri, payload) {
      uri = configureUri(uri)

      return new Promise(resolve => {
        this.$store.commit('submitting', true)
        submit.put('/api' + uri, payload)
          .then(response => {
            this.$store.commit('submitting', false)
            resolve(response)
          })
          .catch(() => { this.$store.commit('submitting', false) })
      })
    }
  }
}

export const download = {
  methods: {
    download (uri, payload, accept) {
      uri = configureUri(uri)

      return new Promise((resolve, reject) => {
        submit.post('/api' + uri, payload, {
          responseType: 'arraybuffer',
          headers: {
            Accept: accept
          }
        })
          .then((response) => {
            let filename = 'generated-file-download'
            if (response.headers['content-disposition']) {
              filename = response.headers['content-disposition'].split('=')
            } else if (response.headers['Content-Disposition']) {
              filename = response.headers['Content-Disposition'].split('=')
            }

            // It is necessary to create a new blob object with mime-type explicitly set
            // otherwise only Chrome works like it should
            const newBlob = new Blob([response.data], { type: payload.accept })

            // IE doesn't allow using a blob object directly as link href
            // instead it is necessary to use msSaveOrOpenBlob
            if (window.navigator && window.navigator.msSaveOrOpenBlob) {
              window.navigator.msSaveOrOpenBlob(newBlob)
              return
            }

            // For other browsers:
            // Create a link pointing to the ObjectURL containing the blob.
            const data = window.URL.createObjectURL(newBlob)
            const link = document.createElement('a')
            link.href = data
            link.download = filename[1]
            link.click()
            setTimeout(function () {
              // For Firefox it is necessary to delay revoking the ObjectURL
              window.URL.revokeObjectURL(data)
            }, 100)
            resolve(response)
          })
          .catch(function (error) {
            reject(error)
          })
      })
    }
  }
}

export const destroy = {
  methods: {
    destroy (uri, payload) {
      uri = configureUri(uri)

      return new Promise((resolve, reject) => {
        this.$store.commit('submitting', true)
        submit.delete('/api' + uri, payload)
          .then(response => {
            this.$store.commit('submitting', false)
            resolve(response)
          })
          .catch(error => {
            this.$store.commit('submitting', false)
            reject(error)
          })
      })
    }
  }
}
