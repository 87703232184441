<template>
  <div>
    <div class="font-bold mt-2 uppercase text-xs">
      {{ label }}
    </div>
    <div class="bg-gray-100 rounded -mx-1 p-1" style="min-height: 29px" @click="copy" v-tooltip="tooltip">
      <slot></slot>
    </div>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { i18n } from '@/i18n'

defineProps(['label'])

const tooltip = ref({
  content: i18n.t('labels.copy.tip'),
  trigger: 'manual',
  show: false
})

const copy = (event) => {
  tooltip.value.show = true
  const content = event.target.innerText
  const dummy = document.createElement('textarea')
  document.body.appendChild(dummy)
  dummy.style.position = 'absolute'
  dummy.style.left = '-9999px'
  dummy.value = content
  dummy.select()
  document.execCommand('copy')
  tooltip.value.content = i18n.t('labels.copy.active')
  document.body.removeChild(dummy)
  setTimeout(() => {
    tooltip.value.show = false
  }, 3000)
}

</script>
