import axios from 'axios'
import queue from '@/router/queue'
import submit from '@/router/submit'
import store from '@/store'
import { useProfileStore } from '@/stores/profile'

export default function axiosConfig () {
  axios.defaults.baseURL = process.env.VUE_APP_URL

  const profile = useProfileStore()

  axios.interceptors.request.use((config) => {
    if (profile.token) {
      config.headers.Authorization = `Bearer ${profile.token}`
    }
    config.headers.dataType = 'json'
    config.headers.contentType = 'application/json'
    return config
  })

  queue.interceptors.request.use((config) => {
    if (profile.token) config.headers.Authorization = `Bearer ${profile.token}`
    config.headers.dataType = 'json'
    config.headers.contentType = 'application/json'
    return config
  })

  submit.interceptors.request.use((config) => {
    if (profile.token) config.headers.Authorization = `Bearer ${profile.token}`
    config.headers.dataType = 'json'
    config.headers.contentType = 'application/json'
    return config
  })

  axios.interceptors.response.use(response => {
    return response
  }, error => {
    store.commit('AXIOS_ERROR', error)
    return Promise.reject(error)
  })
}
