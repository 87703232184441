export class Sec {
  constructor (time) {
    if (time !== undefined) {
      this.time = new Date(time)

      // if the 'time' that has been passed in couldn't be parsed by the browsers default behaviour then
      // we attempt it using the Date parse method
      if (!this.isValidDate()) this.time = new Date(Date.parse(time))
      if (!this.isValidDate()) this.time = this.parseFromLocalISO(time)
      if (!this.isValidDate()) {
        console.info('Sec error: Failed to parse ' + time.toString() + ' into a Date object')
        this.time = new Date()
      }
    } else this.time = new Date()

    return this
  }

  // Setters
  applyTimeString (time) {
    if (time) {
      const parts = time.split(':')
      this.time.setHours(parts.shift())
      this.time.setMinutes(parts.shift())
    } else {
      console.error('Sec error: No time string supplied')
    }
    return this
  }

  year (year) {
    if (year !== undefined) {
      this.time.setYear(year)
      return this
    } else return this.time.year
  }

  month (month) {
    if (month !== undefined) {
      this.time.setMonth(month)
      return this
    } else return this.time.month
  }

  hour (hour) {
    this.time.setHours(hour)
    return this
  }

  minute (minute) {
    this.time.setMinutes(minute)
    return this
  }

  // Attributes
  dayOfWeek (day) {
    if (day !== undefined) {
      const difference = day - this.time.getDay()
      this.time.setDate(this.time.getDate() + difference)
      return this
    }
    return this.time.getDay()
  }

  // Calculations
  startOf (denomination) {
    switch (denomination) {
      case 'week':
        this.dayOfWeek(0)
    }
    return this
  }

  add (count, denomination) {
    switch (denomination) {
      case 'm':
      case 'min':
      case 'mins':
      case 'minute':
      case 'minutes':
        this.addMinutes(count)
        return this
      case 'h':
      case 'hour':
      case 'hours':
        this.addHours(count)
        return this
      case 'd':
      case 'day':
      case 'days':
        this.addDays(count)
        return this
      case 'w':
      case 'week':
      case 'weeks':
        this.addWeeks(count)
        return this
      case 'month':
      case 'months':
        this.addMonths(count)
        return this
      case 'y':
      case 'year':
      case 'years':
        this.addYears(count)
        return this
    }
  }

  subtract (count, denomination) {
    switch (denomination) {
      case 'm':
      case 'min':
      case 'mins':
      case 'minute':
      case 'minutes':
        this.subtractMinutes(count)
        return this
      case 'h':
      case 'hour':
      case 'hours':
        this.subtractHours(count)
        return this
      case 'd':
      case 'day':
      case 'days':
        this.subtractDays(count)
        return this
      case 'w':
      case 'week':
      case 'weeks':
        this.subtractWeeks(count)
        return this
      case 'month':
      case 'months':
        this.subtractMonths(count)
        return this
      case 'y':
      case 'year':
      case 'years':
        this.subtractYears(count)
        return this
    }
  }

  subtractMinutes (minutes) {
    this.time = new Date(this.time.getTime() - minutes * 60000)
    return this
  }

  addMinutes (minutes) {
    this.time = new Date(this.time.getTime() + minutes * 60000)
    return this
  }

  subtractHours (hours) {
    this.time = new Date(this.time.getTime() - hours * 1000)
    return this
  }

  addHours (hours) {
    this.time = new Date(this.time.getTime() + hours * 1000)
    return this
  }

  subtractDays (days) {
    this.time = new Date(this.time.setDate(this.time.getDate() - days))
    return this
  }

  addDays (days) {
    this.time = new Date(this.time.setDate(this.time.getDate() + days))
    return this
  }

  subtractWeeks (weeks) {
    this.time = new Date(this.time.setDate(this.time.getDate() - (weeks * 7)))
    return this
  }

  addWeeks (weeks) {
    this.time = new Date(this.time.setDate(this.time.getDate() + (weeks * 7)))
    return this
  }

  subtractMonths (months) {
    this.time = new Date(this.time.setMonth(this.time.getMonth() - months))
    return this
  }

  addMonths (months) {
    this.time = new Date(this.time.setMonth(this.time.getMonth() + months))
    return this
  }

  subtractYears (years) {
    this.time = new Date(this.time.setYear(this.time.getFullYear() - years))
    return this
  }

  addYears (years) {
    this.time = new Date(this.time.setYear(this.time.getFullYear() + years))
    return this
  }

  diff (time, denomination) {
    const seconds = (this.time - time.time) / 1000
    switch (denomination) {
      case 'm':
      case 'min':
      case 'mins':
      case 'minute':
      case 'minutes':
        return Math.round(seconds / 60)
      case 'h':
      case 'hour':
      case 'hours':
        return Math.round(seconds / 60 / 60)
      case 'd':
      case 'day':
      case 'days':
        return Math.round(seconds / 60 / 60 / 24)
      case 'w':
      case 'week':
      case 'weeks':
        return Math.round(seconds / 60 / 60 / 24 / 7)
      case 'month':
      case 'months':
        return Math.round((seconds / 60 / 60 / 24 / 30))
      case 'y':
      case 'year':
      case 'years':
        return Math.round(seconds / 60 / 60 / 24 / 365)
    }
  }

  // Comparison methods
  isBefore (time) {
    return this.time < time.time
  }

  isAfter (time) {
    return this.time > time.time
  }

  isSame (time) {
    return this.time.getTime() === time.time.getTime()
  }

  isSameWeek (time) {
    return this.format('Y_W') === time.format('Y_W')
  }

  isBetween (start, end) {
    return this.time >= start.time && this.time <= end.time
  }

  // Helpers
  isValidDate () {
    return this.time instanceof Date && !isNaN(this.time)
  }

  ago () {
    const seconds = Math.floor((new Date() - this.time) / 1000)
    var interval = seconds / 31536000

    if (interval > 1.75) return Math.round(interval) + ' years ago'
    if (interval > 1) return Math.floor(interval) + ' year ago'
    interval = seconds / 2592000
    if (interval > 1.75) return Math.round(interval) + ' months ago'
    if (interval > 1) return Math.floor(interval) + ' month ago'
    interval = seconds / 86400
    if (interval > 1.75) return Math.round(interval) + ' days ago'
    if (interval > 1) return Math.floor(interval) + ' day ago'
    interval = seconds / 3600
    if (interval > 1.75) return Math.round(interval) + ' hours ago'
    if (interval > 1) return Math.floor(interval) + ' hour ago'
    interval = seconds / 60
    if (interval > 1.75) return Math.round(interval) + ' minutes ago'
    if (interval > 1) return Math.floor(interval) + ' minute ago'
    return 'a few seconds ago'
  }

  format (string) {
    if (string === 'locale') {
      return this.time.toLocaleDateString([], {
        weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour12: true, hour: 'numeric', minute: '2-digit'
      })
    }

    string = string.replaceAll('d', '[x1]') // Day of the month, 2 digits with leading zeros
    string = string.replaceAll('D', '[x2]') // A textual representation of a day, three letters
    string = string.replaceAll('j', '[x3]') // Day of the month without leading zeros
    string = string.replaceAll('l', '[x4]') // A full textual representation of the day of the week
    string = string.replaceAll('N', '[x5]') // ISO 8601 numeric representation of the day of the week
    string = string.replaceAll('S', '[x6]') // English ordinal suffix for the day of the month, 2 characters
    string = string.replaceAll('w', '[x7]') // Numeric representation of the day of the week
    string = string.replaceAll('z', '[x8]') // The day of the year (starting from 0)
    string = string.replaceAll('W', '[x9]') // ISO 8601 week number of year, weeks starting on Monday
    string = string.replaceAll('F', '[x10]') // A full textual representation of a month, such as January or March
    string = string.replaceAll('m', '[x11]') // Numeric representation of a month, with leading zeros
    string = string.replaceAll('M', '[x12]') // A short textual representation of a month, three letters
    string = string.replaceAll('n', '[x13]') // Numeric representation of a month, without leading zeros
    string = string.replaceAll('t', '[x14]') // Number of days in the given month
    string = string.replaceAll('L', '[x15]') // Whether it's a leap year
    string = string.replaceAll('o', '[x16]') // ISO 8601 week-numbering year. This has the same value as Y, except that if the ISO week number (W) belongs to the previous or next year, that year is used instead.
    string = string.replaceAll('Y', '[x17]') // A full numeric representation of a year, at least 4 digits, with - for years BCE.
    string = string.replaceAll('y', '[x18]') // A two digit representation of a year
    string = string.replaceAll('a', '[x19]') // Lowercase Ante meridiem and Post meridiem
    string = string.replaceAll('A', '[x20]') // Uppercase Ante meridiem and Post meridiem
    string = string.replaceAll('B', '[x21]') // Swatch Internet time
    string = string.replaceAll('g', '[x22]') // 12-hour format of an hour without leading zeros
    string = string.replaceAll('G', '[x23]') // 24-hour format of an hour without leading zeros
    string = string.replaceAll('h', '[x24]') // 12-hour format of an hour with leading zeros
    string = string.replaceAll('H', '[x25]') // 24-hour format of an hour with leading zeros
    string = string.replaceAll('i', '[x26]') // Minutes with leading zeros
    string = string.replaceAll('s', '[x27]') // Seconds with leading zeros
    string = string.replaceAll('u', '[x28]') // Microseconds.
    string = string.replaceAll('v', '[x29]') // Milliseconds.
    string = string.replaceAll('e', '[x30]') // Timezone identifier
    string = string.replaceAll('I', '[x31]') // Whether or not the date is in daylight saving time
    string = string.replaceAll('O', '[x32]') // Difference to Greenwich time (GMT) without colon between hours and minutes
    string = string.replaceAll('P', '[x33]') // Difference to Greenwich time (GMT) with colon between hours and minutes
    string = string.replaceAll('p', '[x34]') // The same as P, but returns Z instead of +00:00
    string = string.replaceAll('T', '[x35]') // Timezone abbreviation, if known; otherwise the GMT offset.
    string = string.replaceAll('Z', '[x36]') // Timezone offset in seconds.
    string = string.replaceAll('c', '[x37]') // ISO 8601 date
    string = string.replaceAll('r', '[x38]') // » RFC 2822/» RFC 5322 formatted date
    string = string.replaceAll('U', '[x39]') // Seconds since the Unix Epoch (January 1 1970 00:00:00 GMT)

    string = string.replaceAll('[x2]', this.time.toLocaleDateString([], { weekday: 'short' }))
    string = string.replaceAll('[x3]', this.time.toLocaleDateString([], { day: 'numeric' }))
    string = string.replaceAll('[x4]', this.time.toLocaleDateString([], { weekday: 'long' }))
    string = string.replaceAll('[x6]', this.ordinalText())
    string = string.replaceAll('[x9]', this.iso8601Week())
    string = string.replaceAll('[x10]', this.time.toLocaleDateString([], { month: 'long' }))
    string = string.replaceAll('[x12]', this.time.toLocaleDateString([], { month: 'short' }))
    string = string.replaceAll('[x17]', this.time.toLocaleDateString([], { year: 'numeric' }))
    let h = parseInt(this.time.toLocaleTimeString([], { hour: 'numeric' }))
    if (h > 12) h -= 12
    string = string.replaceAll('[x19]', this.time.toLocaleTimeString([], { hour12: true })
      .replace(/[0-9:]+/, '').replace(' ', '').toLowerCase())
    string = string.replaceAll('[x20]', this.time.toLocaleTimeString([], { hour12: true })
      .replace(/[0-9:]+/, '').replace(' ', ''))
    string = string.replaceAll('[x22]', h)
    string = string.replaceAll('[x23]', this.time.toLocaleTimeString([], { hour: 'numeric', hour12: false }))
    string = string.replaceAll('[x24]', h.toString().padStart(2, '0'))
    string = string.replaceAll('[x25]', this.time.toLocaleTimeString([], { hour: 'numeric', hour12: false })).padStart(2, '0')
    string = string.replaceAll('[x26]', this.time.toLocaleTimeString([], { minute: '2-digit' }).padStart(2, '0'))

    return string
  }

  ordinalText () {
    const day = parseInt(this.time.toLocaleDateString([], { day: 'numeric' }))
    if (day === 1 || day === 21 || day === 31) return 'st'
    if (day === 2 || day === 22) return 'nd'
    if (day === 3 || day === 23) return 'rd'
    return 'th'
  }

  // Determine the week of the year (local timezone) based on the ISO 8601 definition.
  iso8601Week () {
    // Create a copy of the current date, we don't want to mutate the original
    const date = new Date(this.time.getTime())

    // Find Thursday of this week starting on Monday
    date.setDate(date.getDate() + 4 - (date.getDay() || 7))
    const thursday = date.getTime()

    // Find January 1st
    date.setMonth(0) // January
    date.setDate(1) // 1st
    const jan1st = date.getTime()

    // Round the amount of days to compensate for daylight saving time
    const days = Math.round((thursday - jan1st) / 86400000) // 1 day = 86400000 ms
    return Math.floor(days / 7) + 1
  }

  parseFromLocalISO (string) {
    const dt = string.split(' ')
    let date = false
    let time = false

    if (dt[0] && dt[0].includes('-')) date = dt[0].split('-')
    if (dt[0] && dt[0].includes(':')) time = dt[0].split(':')
    if (dt[1] && dt[1].includes('-')) date = dt[1].split('-')
    if (dt[1] && dt[1].includes(':')) time = dt[1].split(':')

    if (date && time) return new Date(date[0], date[1] - 1, date[2], time[0], time[1], time[1])
    if (date) return new Date(date[0], date[1] - 1, date[2])
    if (time) return new Date(null, null, null, time[0], time[1], time[1])
  }
}
