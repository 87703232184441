<template>
  <div>
    <modal :header="$t('labels.speaker_directory.quick_outlines.title')" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default="{ error }">
        <input-text
          :label="$t('labels.speaker_directory.quick_outlines.label')"
          :help="$t('labels.speaker_directory.quick_outlines.tip')"
          :name="'outlines'" v-model="resource.outlines" :error="error"
        />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { post } from '@/composables/serverInterface.js'

const props = defineProps(['congregation', 'speaker', 'active'])
const emit = defineEmits(['close'])

const initialResource = {
  outlines: null
}

const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
  }
})

const submit = () => {
  post(['congregations', props.congregation.id, 'speakers', props.speaker, 'outlines'], resource.value)
    .then(res => {
      emit('saveResponse', res)
    })
}

</script>
