<template>

  <view-content :back="{ name:'Reports' }">

    <template v-slot:header>
      {{ 'Report for '+user.full_name }}
    </template>

    <div class="top-sliding-nav">
      <ul>
        <li v-for="year in serviceYears" :key="'year' + year">
          <button class="btn-clear" :class="showYear == year ? 'active' : ''" @click="showYear = year">{{ year }}</button>
        </li>
      </ul>
    </div>

    <div style="margin: 0 -16px">
      <table class="table is-striped is-fullwidth align-center">
        <thead>
        <tr v-if="showYear > 2023">
          <th></th>
          <th></th>
          <th class="text-center" v-if="parseInt(user.pioneer) || parseInt(user.aux)">Hrs.</th>
          <th class="text-center">Std.</th>
          <th class="text-center" v-if="parseInt(user.pioneer)">Credit</th>
          <th></th>
        </tr>
        <tr v-else>
          <th></th>
          <th>Hrs.</th>
          <th>Plc.</th>
          <th>Vid.</th>
          <th>Rvs.</th>
          <th>Std.</th>
          <th v-if="parseInt(user.pioneer)">Spe.</th>
          <th></th>
        </tr>
        </thead>
        <tbody v-if="showYear > 2023">
        <tr v-for="report in filteredReports" :key="'report' + report.id">
          <td class="text-left"><small>{{ report.month_year }}</small></td>
          <td class="text-left"><fa-icon v-if="report.active" :icon="['fal', 'check']" /></td>
          <td class="text-center" v-if="parseInt(user.pioneer) || parseInt(user.aux)">{{ report.hours ? report.hours : 0 }}</td>
          <td class="text-center">{{ report.studies ? report.studies : 0 }}</td>
          <td v-if="parseInt(user.pioneer)">{{ report.special_hours ? report.special_hours : 0 }}</td>
          <td>
            <fa-icon v-if="canDelete(report)" :icon="['fal', 'trash']" @click="promptDeleteReport(report)" />
          </td>
        </tr>
        <tr><td colspan="6"></td> </tr>
        </tbody>
        <tbody v-else>
        <tr v-for="report in filteredReports" :key="'report' + report.id">
          <td><small>{{ report.month_year }}</small></td>
          <td>{{ report.hours ? report.hours : 0 }}</td>
          <td>{{ report.placements ? report.placements : 0 }}</td>
          <td>{{ report.videos ? report.videos : 0 }}</td>
          <td>{{ report.return_visits ? report.return_visits : 0 }}</td>
          <td>{{ report.studies ? report.studies : 0 }}</td>
          <td v-if="parseInt(user.pioneer)">{{ report.special_hours ? report.special_hours : 0 }}</td>
          <td>
            <fa-icon v-if="canDelete(report)" :icon="['fal', 'trash']" @click="promptDeleteReport(report)" />
          </td>
        </tr>
        <tr><td colspan="6"></td> </tr>
        </tbody>
        <tfoot v-if="showYear > 2023">
        <tr>
          <th>Average</th>
          <th></th>
          <td v-if="parseInt(user.pioneer) || parseInt(user.aux)">{{ reportAvg.hours }}</td>
          <td>{{ reportAvg.studies }}</td>
          <td v-if="user.pioneer">{{ reportAvg.special_hours }}</td>
          <td></td>
        </tr>
        <tr>
          <th>Total</th>
          <th></th>
          <td v-if="parseInt(user.pioneer) || parseInt(user.aux)">{{ reportTot.hours }}</td>
          <td>{{ reportTot.max_studies }}</td>
          <td v-if="user.pioneer">{{ reportTot.special_hours }}</td>
          <td></td>
        </tr>
        </tfoot>
        <tfoot v-else>
        <tr>
          <th>Average</th>
          <td>{{ reportAvg.hours }}</td>
          <td>{{ reportAvg.placements }}</td>
          <td>{{ reportAvg.videos }}</td>
          <td>{{ reportAvg.return_visits }}</td>
          <td>{{ reportAvg.studies }}</td>
          <td v-if="user.pioneer">{{ reportAvg.special_hours }}</td>
          <td></td>
        </tr>
        <tr>
          <th>Total</th>
          <td>{{ reportTot.hours }}</td>
          <td>{{ reportTot.placements }}</td>
          <td>{{ reportTot.videos }}</td>
          <td>{{ reportTot.return_visits }}</td>
          <td>{{ reportTot.max_studies }}</td>
          <td v-if="user.pioneer">{{ reportTot.special_hours }}</td>
          <td></td>
        </tr>
        </tfoot>
      </table>
    </div>

    <portal to="modals">
      <delete
          :submitting="submitting"
          :header="'Delete Report'"
          :active.sync="activePromptDeleteReport"
          :message="'Are you sure you wish to delete this report?.'"
          @submit="confirmDeleteReport()"
      ></delete>
    </portal>

  </view-content>

</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import { store } from '@/store'
import { find, reverse, uniq } from 'lodash'
import { useRoute } from 'vue-router/composables'
import { get, destroy } from '@/composables/serverInterface'
import { Sec } from '@/mixins/sec'

const emit = defineEmits(['update:loading'])
const route = useRoute()
const userID = route.params.id

const report = ref(null)
const reports = ref([])
const submitting = ref(false)
const showYear = ref(null)
const activePromptDeleteReport = ref(false)

const canDelete = (report) => {
  const cutoff = (new Sec()).subtract(3, 'years')
  const date = new Sec(report.report_month)
  if (date.isBefore(cutoff)) {
    return false
  }
  return true
}

const promptDeleteReport = (r) => {
  report.value = r
  activePromptDeleteReport.value = true
}

const confirmDeleteReport = () => {
  destroy(['service-report', report.value.id])
    .then(response => {
      reports.value = response.data.data
      activePromptDeleteReport.value = false
    })
}

const users = computed(() => store.state.users)

const user = computed(() => {
  return find(users.value, (u) => { return parseInt(u.id) === parseInt(userID) })
})

const serviceYears = computed(() => {
  const years = []
  reports.value.forEach(function (report) {
    const d = report.report_month.split('-')
    if (d[1] >= 9) years.push(parseInt(d[0]) + 1)
    else years.push(parseInt(d[0]))
  })
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  return reverse(uniq(years))
})

const filteredReports = computed(() => {
  const arr = []
  reports.value.forEach(r => {
    const d = r.report_month.split('-')
    if (parseInt(d[0]) === showYear.value && d[1] < 9) arr.push(r)
    if (parseInt(d[0]) === (showYear.value - 1) && d[1] >= 9) arr.push(r)
  })
  // eslint-disable-next-line vue/no-side-effects-in-computed-properties
  return reverse(arr)
})

const reportTot = computed(() => {
  const totals = {
    hours: 0,
    placements: 0,
    videos: 0,
    return_visits: 0,
    studies: 0,
    max_studies: 0,
    special_hours: 0
  }
  filteredReports.value.forEach(function (report) {
    totals.hours += parseFloat(report.hours ? report.hours : 0)
    totals.placements += parseFloat(report.placements ? report.placements : 0)
    totals.videos += parseFloat(report.videos ? report.videos : 0)
    totals.return_visits += parseFloat(report.return_visits ? report.return_visits : 0)
    totals.studies += parseFloat(report.studies ? report.studies : 0)
    totals.max_studies = report.studies > totals.max_studies ? parseFloat(report.studies) : parseFloat(totals.max_studies)
    totals.special_hours += parseFloat(report.special_hours ? report.special_hours : 0)
  })
  return totals
})

const reportAvg = computed(() => {
  const averages = {
    hours: parseFloat(reportTot.value.hours / filteredReports.value.length).toFixed(1),
    placements: parseFloat(reportTot.value.placements / filteredReports.value.length).toFixed(1),
    videos: parseFloat(reportTot.value.videos / filteredReports.value.length).toFixed(1),
    return_visits: parseFloat(reportTot.value.return_visits / filteredReports.value.length).toFixed(1),
    studies: parseFloat(reportTot.value.studies / filteredReports.value.length).toFixed(1),
    special_hours: parseFloat(reportTot.value.special_hours / filteredReports.value.length).toFixed(1)
  }
  return averages
})

onMounted(() => {
  const d = new Date()
  if (d.getMonth() > 9) showYear.value = d.getFullYear() + 1
  else showYear.value = d.getFullYear()
  emit('update:loading', true)
  get(['service-report', userID])
    .then(response => {
      reports.value = response.data.data
      emit('update:loading', false)
    })
    .catch(e => {
      emit('update:error', e)
    })
})
</script>
