import axios from 'axios'

export const GET_ATTENDANT_SCHEDULE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/attendant-schedule')
      .then(function (response) {
        commit('UPDATE_ATTENDANT_SCHEDULE', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_ATTENDANT_SCHEDULE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/attendant-schedule', payload)
      .then(function (response) {
        commit('UPDATE_ATTENDANT_SCHEDULE', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_RESPONSIBILITY_ORDER = ({ commit }, payload) => {
  commit('UPDATE_ATTENDANT_RESPONSIBILITIES', payload.responsibilities)
  return new Promise((resolve, reject) => {
    axios.post('api/attendant-responsibility-order', payload)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
