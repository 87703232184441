<template>
  <div class="rounded-full aspect-square border flex justify-content-center align-items-center"
       style="background: #dee2e6; color: #6574cd"
       :class="border">
    <span class="text-sm uppercase">{{ initials }}</span>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const props = defineProps(['user'])

const initials = computed(() => {
  let matches = ''
  if (props.user.full_name) {
    matches = props.user.full_name.match(/\b(\w)/g)
  } else {
    matches = props.user.name.match(/\b(\w)/g)
  }
  return matches.slice(0, 2).join('')
})

const border = computed(() => {
  if (props.user.appointment === 'elder') return 'border-elder'
  else if (props.user.appointment === 'servant') return 'border-servant'
  return 'border-publisher'
})

</script>
