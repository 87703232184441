import axios from 'axios'
import _ from 'lodash'

export const GET_ACCOUNT_ACTIVITY = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/congregations/' + payload.congregation + '/accounts')
      .then(function (response) {
        commit('UPDATE_ACCOUNT_ACTIVITY', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_ACCOUNT_STATUS = ({ state, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/congregations/' + payload.congregation + '/accounts/' + payload.congregation)
      .then(function (response) {
        commit('MERGE_BALANCE', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_INVOICE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/download-invoice/' + payload.invoice + '?api_token=' + payload.token, { responseType: 'arraybuffer' })
      .then(function (response) {
        const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/pdf' }))
        const link = document.createElement('a')
        const filename = 'Service-App-Invoice-' + _.padStart(payload.invoice, 5, '0') + '.pdf'
        link.href = url
        link.setAttribute('download', filename) // or any other extension
        document.body.appendChild(link)
        link.click()
        resolve()
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

// export const POST_CREDITS_PURCHASE = ({ dispatch }, payload) => {
//     return new Promise((resolve, reject) => {
//         axios.post('https://srvc.app/post/confirm-purchase/?t=' + payload.token, JSON.stringify(payload.purchase))
//             .then(function (response) {
//                 dispatch('GET_CONG_SETTINGS', {token: payload.token});
//                 resolve(response);
//             })
//             .catch(function (error) {
//                 reject(error)
//             });
//     })
// };

export const CHARGE_CREDIT_CARD = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/charge-card?api_token=' + payload.token, payload.purchase)
      .then(function (response) {
        console.log(response)
        dispatch('GET_ACCOUNT_ACTIVITY', { token: payload.token })
        dispatch('GET_CONG_SETTINGS', { token: payload.token })
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
