<template>
  <div class="text-lg w-75 text-right">
    <div class="inline">
      <fa-icon class="female-pub text-orange-400 relative z-20" :class="femalePubClass" :icon="['fas', 'female']" />
      <fa-icon class="male-pub text-orange-dark relative z-10" :class="malePubClass" :icon="['fas', 'male']" />
    </div>
    <div v-if="active === 'nh'" class="inline">
      <fa-icon class="door-closed relative z-0" :class="doorNhClass" :icon="['fal', 'door-closed']" />
    </div>
    <div v-if="active === 'home'" class="inline-block door z-0 relative" style="width: 24px; height: 22px" :class="doorHomeClass">
      <fa-icon class="closed absolute" :class="doorHomeCloseClass" :icon="['fal', 'door-closed']" />
      <fa-icon class="open absolute" :class="doorHomeOpenClass" :icon="['fal', 'door-open']" />
    </div>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps(['active'])

const femalePubClass = computed(() => { return props.active ? 'female-animate' : '' })
const malePubClass = computed(() => { return props.active ? 'male-animate' : '' })
const doorHomeClass = computed(() => {
  if (props.active === 'home') return 'door-knock-animate'
  return null
})
const doorNhClass = computed(() => {
  if (props.active === 'nh') return 'door-closed-animate'
  return null
})
const doorHomeOpenClass = computed(() => {
  if (props.active === 'home') return 'door-open-animate'
  return null
})
const doorHomeCloseClass = computed(() => {
  if (props.active === 'home') return 'door-close-animate'
  return null
})

</script>

<style scoped>
.male-pub {
  opacity: 0;
  margin-right: 25px;
}
.male-animate {
  animation: maleIn 5s linear forwards;
}
.female-pub {
  opacity: 0;
  margin-right: 5px;
}
.female-animate {
  animation: femaleIn 4.8s linear 0.2s forwards;
}
.door-closed {
  color: #F59E0B;
}
.door {
  color: #059669;
}
.door .open {
  right: 0;
  top: 6px;
  opacity: 0;
}
.door .closed {
  right: 0;
  top: 6px;
}
.door-closed-animate {
  animation: doorClosed 3.8s linear 1.2s forwards;
}
.door-knock-animate {
  animation: doorOpenKnock 3.8s linear 1.2s forwards;
}
.door-open-animate {
  animation: doorOpen 5s linear forwards;
}
.door-close-animate {
  animation: doorClose 5s linear forwards;
}
@keyframes maleIn {
  0% {
    opacity: 0;
    margin-right: 25px;
  }
  15% {
    opacity: 1;
    margin-right: -8px;
  }
  75% {
    opacity: 1;
    margin-right: -8px;
  }
  100% {
    opacity: 0;
    margin-right: 25px;
  }
}
@keyframes femaleIn {
  0% {
    opacity: 0;
    margin-right: 5px;
  }
  15% {
    opacity: 1;
    margin-right: -2px;
  }
  75% {
    opacity: 1;
    margin-right: -2px;
  }
  100% {
    opacity: 0;
    margin-right: 5px;
  }
}
@keyframes doorClosed {
  0% { color: #D97706; }
  2% { color: #D97706; }
  3% { color: #F59E0B; }
  5% { color: #F59E0B; }
  6% { color: #D97706; }
  8% { color: #D97706; }
  9% { color: #F59E0B; }
  30% { color: #F59E0B; }
  31% { color: #D97706; }
  34% { color: #D97706; }
  35% { color: #F59E0B; }
  38% { color: #F59E0B; }
  39% { color: #D97706; }
  42% { color: #D97706; }
  43% { color: #F59E0B; }
}
@keyframes doorOpenKnock {
  0% { color: #047857; }
  3% { color: #047857; }
  4% { color: #059669; }
  7% { color: #059669; }
  8% { color: #047857; }
  11% { color: #047857; }
  12% { color: #059669; }
}
@keyframes doorOpen {
  49% { opacity: 0 }
  50% { opacity: 1 }
  74% { opacity: 1 }
  75% { opacity: 0 }
  100% { opacity: 0 }
}
@keyframes doorClose {
  49% { opacity: 1 }
  50% { opacity: 0 }
  74% { opacity: 0 }
  75% { opacity: 1 }
  100% { opacity: 1 }
}
</style>
