<template>

  <div class="field">
    <div class="control">
      <div class="copy">
        <div class="content">{{ value }}</div>
        <button class="button btn-sm" @click="copy" @mouseover="tooltip.show = true" @mouseout="tooltip.show = false" v-tooltip="tooltip">{{ $t('labels.copy.label')}}</button>
      </div>
      <p class="help" v-if="help">{{ help }}</p>
    </div>
  </div>

</template>

<script setup>
import { ref } from 'vue'
import { i18n } from '@/i18n'

const props = defineProps(['help', 'value'])

const tooltip = ref({
  content: i18n.t('labels.copy.tip'),
  trigger: 'manual',
  show: false
})

const copy = () => {
  tooltip.value.show = true
  const el = document.createElement('textarea')
  el.value = props.value
  el.setAttribute('readonly', '')
  el.style.position = 'absolute'
  el.style.left = '-9999px'
  document.body.appendChild(el)
  el.select()
  document.execCommand('copy')
  tooltip.value.content = i18n.t('labels.copy.active')
  document.body.removeChild(el)
  setTimeout(() => {
    tooltip.value.show = false
    tooltip.value.content = i18n.t('labels.copy.tip')
  }, 3000)
}
</script>
