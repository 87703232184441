<template>
  <div class="bg-white shadow rounded mt-2 font-sans text-left divide-y">
    <slot></slot>
  </div>
</template>

<script setup>
import { ref, provide } from 'vue'

const closeTrigger = ref(0)
const closeAll = () => {
  closeTrigger.value++
}

provide('accordion', {
  closeTrigger,
  closeAll
})
</script>
