<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      {{ $tc('resources.map', 0) }}
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="permits['settings.maps.add']" @click="addMap">
        {{ $t('actions.ar', { a: $t('actions.add'), r: $tc('resources.map', 1) }) }}
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <sort-list lockAxis="y" v-model="maps" :useDragHandle="true">
        <sort-item v-for="(map, id) in maps" :index="id" :key="id">
            <td class="tools t1" v-if="permits['settings.maps.edit']" v-handle>
              <button class="btn btn-warning btn-round">
                <fa-icon :icon="['fal', 'sort']" />
              </button>
            </td>
            <td>{{ map.number }} - {{ map.suburb }}</td>
            <td class="tools t3">
              <router-link class="btn btn-warning btn-round" :to="'/settings/maps/'+map.number">
                <fa-icon :icon="['fal', 'list-alt']" />
              </router-link>
              <button @click="editMap(map)" class="btn btn-info btn-round ml-1" v-if="permits['settings.maps.edit']">
                <fa-icon :icon="['fal', 'pencil']" />
              </button>
              <button @click="promptDeleteMap(map)" class="btn btn-danger btn-round ml-1" v-if="permits['settings.maps.delete']">
                <fa-icon :icon="['fal', 'trash']" />
              </button>
            </td>
        </sort-item>
      </sort-list>
    </table>

    <portal to="modals">
    <modal :submitting="submitting" :header="'Map Form'" :active.sync="mapFormActive" @submit="submitMap()" :validated="mapValid" :error="submitError">
      <tuxedo-select
        :label="'Map Category'"
        name="type_id"
        :options="categories"
        v-model="map.type_id"
        inputClass="bg-gray-100"
        insistReturn="id"
        insistLabel="type"
      >
        <template #label="{ option }">
          {{ $t('labels.map_types.' + option.type) }}
        </template>
      </tuxedo-select>

      <input-text :label="'Number'" :type="'text'" v-model="map.number" :error="submitError && submitError.errors.number ? submitError.errors.number[0] : null"></input-text>
      <input-text :label="map.type_id == 5 || map.type_id == 3 ? 'Name' : 'Suburb'" :type="'text'" v-model="map.suburb"></input-text>
      <datefield :label="'Last Complete'" v-model="map.complete" v-if="map.id === 0" :help="'The date of when this map was last completed, used to manage the initial way this map will be assigned and reported on. <b>This will be your only opportunity to add this date!</b>'"></datefield>
      <input-integer v-if="map.type_id != 5 && map.type_id != 3" :label="'Block Count'" :type="'number'" v-model="map.blocks" />
      <imageupload v-if="map.type_id != 5" :label="'Map Image'" v-model="map.asset.filename"></imageupload>
    </modal>

    <delete
      :submitting="submitting"
      :header="'Delete Map'"
      :active.sync="mapDeleteActive"
      :message="'Are you sure you wish to delete this map?'"
      @submit="confirmDeleteMap()"
    ></delete>
    </portal>
  </view-content>

</template>

<script>

import Delete from '../../general/confirm-delete.vue'
import { HandleDirective } from 'vue-slicksort'
import { mapState } from 'vuex'

export default {
  name: 'Maps',
  directives: { handle: HandleDirective },
  data () {
    return {
      map: {
        id: null,
        type_id: null,
        number: null,
        suburb: null,
        blocks: null,
        complete: null,
        congregation: null,
        asset: {
          location: null,
          filename: null
        }
      },
      mapFormActive: false,
      mapDeleteActive: false,
      submitting: false,
      loading: true,
      error: false
    }
  },
  computed: {
    ...mapState({
      permits: state => state.core.permits,
      categories: state => state.maps.types,
      submitError: state => state.core.submitError
    }),
    maps: {
      get: function () {
        return this._.orderBy(this.$store.state.maps.maps, ['order'])
      },
      set: function (value) {
        const maps = []
        this._.forEach(value, (map, key) => {
          map.order = key
          maps.push(map)
        })
        this.$store.dispatch('POST_MAP_ORDER', { token: this.$store.state.core.token, maps: maps })
      }
    },

    mapValid () {
      return true
    }
  },
  components: {
    Delete
  },
  created () {
    window.scrollTo(0, 0)
    this.$store.commit('BACKGROUND_LOADING', true)
    this.$store.dispatch('GET_MAP_TYPES').then(() => {
      this.$store.dispatch('GET_MAPS').then(() => {
        this.$store.commit('BACKGROUND_LOADING', false)
      })
    })
  },
  methods: {
    addMap () {
      this.$root.clearData(this.map)
      this.map.id = 0
      this.mapFormActive = true
    },
    editMap (map) {
      this.$root.copyData(map, this.map)
      this.mapFormActive = true
    },
    submitMap () {
      this.submitting = true
      this.$store.dispatch('POST_MAP', { token: this.$store.state.core.token, map: this.map })
        .then(() => {
          this.submitting = false
          this.mapFormActive = false
          this.$store.commit('SUBMIT_ERROR')
        })
        .catch(() => {
          this.submitting = false
        })
    },
    promptDeleteMap (map) {
      this.$root.copyData(map, this.map)
      this.mapDeleteActive = true
    },
    confirmDeleteMap () {
      this.submitting = true
      this.$store.dispatch('DELETE_MAP', { map: this.map })
        .then(() => {
          this.submitting = false
          this.mapDeleteActive = false
          const key = this.maps.findIndex(row => row.id === this.map.id)
          this.$delete(this.maps, key)
        })
    }
  }
}
</script>
