<template>
  <div ref="publicTalkPrompt" class="notification is-event" :class="collapse ? 'collapsing' : ''"  v-if="public_meeting && public_meeting.event && public_meeting.event.public_theme && publicTalkActive">
    <button class="delete" @click="dismissAlert('publicTalkActive')"></button>
    <div v-html="
            $t('notifications.special_event', {
                day: (new Sec(public_meeting.local_date)).format('d').toUpperCase(),
                theme: public_meeting.event.public_theme,
            })"></div>
  </div>
  <div ref="publicTalkPrompt" :class="collapse ? 'collapsing' : ''" class="notification public-talk" v-else-if="modules && modules.meeting_schedule.on && public_meeting && public_meeting.parts.talk.talk.notes && publicTalkActive">
    <button class="delete" @click="dismissAlert('publicTalkActive')"></button>
    <div v-html="
            $t('notifications.public_talk', {
                day: (new Sec(public_meeting.local_date)).format('d').toUpperCase(),
                theme: public_meeting.parts.talk.talk.notes,
            })"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PublicTalkPrompt',
  data () {
    return {
      collapse: false,
      height: null
    }
  },
  computed: {
    ...mapState({
      modules: state => state.settings.cong.modules,
      public_meeting: state => state.dashboard.public_meeting.public,
      publicTalkActive: state => state.dashboard.publicTalkActive
    })
  },
  mounted () {
    this.height = this.$refs.publicTalkPrompt ? this.$refs.publicTalkPrompt.clientHeight : 0
  },
  methods: {
    dismissAlert (alert) {
      this.collapse = true
      this.$refs.publicTalkPrompt.style.height = this.height + 'px'
      setTimeout(() => {
        this.$refs.publicTalkPrompt.style.height = 0
        this.$refs.publicTalkPrompt.style.padding = 0
        this.$refs.publicTalkPrompt.style.margin = 0
        setTimeout(() => {
          this.collapse = false
          this.$store.dispatch('HIDE_ALERT', { alert: alert })
        }, 300)
      }, 500)
    }
  }
}
</script>
