<template>
  <div ref="el" class="overflow-hidden first:rounded-t last:rounded-b">
    <div class="flex justify-content-between items-center py-2 px-3"
         @click="toggle()"
         :class="headerClass"
    >
      <div>
        <slot name="header"></slot>
      </div>
      <fa-icon class="text-lg" :icon="['fas', 'chevron-right']" />
    </div>
    <div class="transition-height duration-150 overflow-hidden"
         :style="'height: ' + contentForcedHeight + 'px'">
      <div ref="content">
        <div class="p-2">
          <slot>Here</slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch, onMounted, onBeforeUnmount, inject } from 'vue'

const props = defineProps(['headerClass', 'startOpen'])
const { closeTrigger, closeAll } = inject('accordion')
const open = ref(false)
const contentNaturalHeight = ref(0)
const el = ref(null)
const content = ref(null)
const observer = ref(null)

const contentForcedHeight = computed(() => {
  return open.value ? contentNaturalHeight.value : 0
})

const setNaturalHeight = () => {
  setTimeout(() => {
    contentNaturalHeight.value = content.value.offsetHeight
  }, 100)
}

watch(closeTrigger, () => close())

const close = () => {
  open.value = false
}
const toggle = () => {
  const o = !open.value
  closeAll()
  setTimeout(() => {
    open.value = o
  }, 50)
}

onMounted(() => {
  setNaturalHeight()
  if (props.startOpen) {
    open.value = true
  }

  const o = new MutationObserver(setNaturalHeight)
  o.observe(el.value, {
    childList: true,
    subtree: true
  })
  observer.value = o
})

onBeforeUnmount(() => {
  observer.value.disconnect()
})
</script>
