import { defineStore } from 'pinia'
import { useAPIStore } from './api'

import { orderBy, filter } from 'lodash'
import { singular, ucFirst } from '@/helpers'

export const useCollectionsStore = defineStore({
  id: 'collections',
  persist: true,
  state: () => ({
    cache: {},
    countries: [],
    countriesMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'name',
        dir: 'asc'
      }
    },
    meetings: [],
    meetingsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'start_date',
        dir: 'asc'
      }
    },
    regions: [],
    regionsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'name',
        dir: 'asc'
      }
    },
    sharedCarts: [],
    sharedCartsMeta: {
      show: 20,
      page: 1,
      total: 0,
      start: 1,
      end: 20,
      sort: {
        by: 'location',
        dir: 'asc'
      }
    },
    users: [],
    usersMeta: {
      show: 50,
      page: 1,
      total: 0,
      start: 1,
      end: 50,
      sort: {
        by: 'last_name',
        dir: 'asc'
      }
    }
  }),
  getters: {
  },
  actions: {
    all (collection, id = null, children = null) {
      let resources = children ? this[children] : this[collection]
      if (children) {
        const foreignKey = singular(collection) + '_id'
        resources = filter(resources, [foreignKey, parseInt(id)])
      }
      return orderBy(resources,
        [this[(children ?? collection) + 'Meta'].sort.by],
        [this[(children ?? collection) + 'Meta'].sort.dir])
    },
    get (payload) {
      return new Promise((resolve, reject) => {
        const [collection, meta, uri] = this.configureCollection(payload)
        if (this.collectionUpToDate(collection)) return resolve()
        const api = useAPIStore()
        let config = {}
        if (this[meta].live) {
          config = {
            params: {
              page: this[meta].page,
              sortBy: this[meta].sort.by,
              sortDir: this[meta].sort.dir,
              filters: this[meta].filters
            }
          }
        }
        api.get(uri, config)
          .then(res => {
            this.handleServerResponse(collection, res)

            resolve()
          })
          .catch(error => reject(error))
      })
    },
    find (collection, id) {
      return this[collection].find(i => i.id === parseInt(id))
    },
    collectionUpToDate (collection) {
      if (!(collection in this)) {
        console.error('collection \'' + collection + '\' does not exist')
        return false
      }
      if (this[collection].length === 0) {
        console.info('collection \'' + collection + '\' is empty')
        return false
      }
      const latest = this[collection].map(i => i.updated_at).sort().reverse()[0]
      const timestamp = Math.floor(new Date(latest).getTime() / 1000)
      const cachestamp = String(this[collection].length).padStart(10, '0') + '-' + timestamp
      console.log('checking \'' + collection + '\' cache')
      console.info('local: ' + cachestamp + ', remote: ' + this.cache[collection])
      return cachestamp === this.cache[collection]
    },
    configureCollection (collection) {
      if (Array.isArray(collection)) {
        if (collection.length === 1) return [collection[0], collection[0] + 'Meta', collection]
        if (collection.length === 3) {
          const ref = singular(collection[0]) + ucFirst(collection[2])
          return [ref, ref + 'Meta', collection]
        }
      }

      return [collection, collection + 'Meta', [collection]]
    },
    handleServerResponse (collection, response) {
      let data = response

      // Determine the actual data layer for the response
      while (typeof data === 'object' && 'data' in data) {
        if ('meta' in data) this.handleServerMeta(collection, data.meta)
        data = data.data
      }

      this[collection] = data
    },
    handleServerMeta (collection, meta) {
      console.log('server side pagination detected for \'' + collection + '\'')
      this[collection + 'Meta'].live = true
      this[collection + 'Meta'].show = meta.per_page
      this[collection + 'Meta'].page = meta.current_page
      this[collection + 'Meta'].total = meta.total
      this[collection + 'Meta'].start = meta.from
      this[collection + 'Meta'].end = meta.to
    }
  }
})
