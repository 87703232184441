<template>
  <div>
    <action :secondary="true" :small="true" v-if="permits['maps.streets.add']" @click="create">
      {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.street', 1) }) }}
    </action>
    <div class="w-100 mt-2">
      <div v-for="(r, key) in resources" :key="'s3' + key"
           class="flex align-items-center justify-content-between py-1 border-t">
          <street-label :street="r" />
        <div>
          <button class="btn bg-gray-200 text-teal-400 btn-round rounded-full"
                  v-if="permits['maps.streets.edit'] || permits['maps.streets.delete']"
                  @click="openMenu(r)" :id="'streetMenu'">
            <fa-icon :icon="['fas', 'ellipsis-v']" />
          </button>
        </div>
      </div>
    </div>
    <tuxedo-secondary-menu v-if="showMenu" @close="showMenu = false" :target="'streetMenu'">
      <ul class="text-lg font-sans pt-2">
        <sm-item v-if="permits['maps.streets.edit']" @click="edit" class="justify-content-center">
          {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.street', 1) }) }}
        </sm-item>
        <sm-item v-if="permits['maps.streets.delete']" :icon="['fal', 'trash']"
                 class="text-red-700 border-t mt-2 justify-content-center" @click="promptDestroy">
          {{ $t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.street', 1) }) }}
        </sm-item>
      </ul>
    </tuxedo-secondary-menu>

    <modal :header="$t('labels.street.edit')" :active.sync="editActive" @submit="submit">
      <template v-slot:default="{ error }">
        <tuxedo-select name="suburb_id" :label="$t('fields.suburb.label')" v-model="resource.suburb_id" :error="error"
                       :options="sortedSuburbs" insistReturn="id" insistLabel="suburb" inputClass="bg-gray-100" />
        <tuxedo-input name="name" :label="$t('fields.street.label')" v-model="resource.name" :error="error" inputClass="bg-gray-100" />
      </template>
    </modal>

    <delete
      :header="$t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.street', 1) })"
      :active.sync="deleteActive"
      :message="$t('prompts.delete', { resource: $tc('resources.street', 1) })"
      @submit="confirmDestroy"
    ></delete>

  </div>
</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { fillObject } from '@/helpers'
import { put, post, destroy } from '@/composables/serverInterface'
import { merge, push, forget } from '@/composables/dataInterface'
import { orderBy } from 'lodash'
import StreetLabel from '@/components/maps/StreetLabel'

const props = defineProps(['map', 'resources'])

const permits = computed(() => store.state.core.permits)
const suburbs = computed(() => store.state.suburbs)

const initialResource = {
  id: null,
  name: '',
  suburb_id: null
}
const resource = ref(null)
const activeID = ref(null)
const showMenu = ref(false)
const editActive = ref(false)
const deleteActive = ref(false)

const sortedSuburbs = computed(() => {
  return orderBy(suburbs.value, ['suburb'])
})

onMounted(() => {
  resource.value = { ...initialResource }
})

const openMenu = (s) => {
  fillObject(resource.value, s, 0)
  activeID.value = s.id
  showMenu.value = true
}
const create = () => {
  resource.value = { ...initialResource }
  activeID.value = null
  editActive.value = true
}
const edit = () => {
  showMenu.value = false
  editActive.value = true
}
const submit = () => {
  if (resource.value.id) {
    put(['streets', resource.value.id], resource.value)
      .then(res => {
        merge('streets', res)
        editActive.value = false
      })
  } else {
    post(['streets'], { ...resource.value, map_id: props.map })
      .then(res => {
        push('streets', res)
        editActive.value = false
      })
  }
}
const promptDestroy = () => {
  showMenu.value = false
  deleteActive.value = true
}
const confirmDestroy = () => {
  destroy(['streets', resource.value.id])
    .then(() => {
      forget('streets', resource.value)
      deleteActive.value = false
    })
}

</script>
