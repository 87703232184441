<template>
  <div class="mb-4">
    <label v-if="label" :for="name" class="text-uppercase mb-0 font-semibold">
      <span>{{ label }}</span>
      <small v-if="sub">{{ sub }}</small>
    </label>
    <div class="text-base">
      <input type="number" step="1" class="bg-gray-100 w-full border-gray-300 border-b border-r rounded-md px-3 py-1.5 shadow-sm focus:outline-none focus:border-teal"
             :id="name" :placeholder="placeholder" v-model="inputValue" @keydown.enter.prevent>
    </div>
    <span class="text-xs text-red-600" v-if="error && error[name]">{{ error[name][0] }}</span>
    <small class="text-grey-500" v-else-if="help">{{ help }}</small>
  </div>
</template>

<script setup>
import { computed } from 'vue'
const emit = defineEmits(['input'])
const props = defineProps({
  label: String,
  placeholder: String,
  sub: String,
  name: String,
  help: String,
  value: [Number, String],
  error: Object
})

const inputValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emit('input', val)
  }
})

</script>
