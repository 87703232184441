<template>

    <section class="assignment" :id="meeting.start_date" :class="meeting.event ? 'assembly' : 'meeting'">

      <card :start="meeting.local_date" :date="true" :color="event ? 'event' : 'meeting'" class="mb-5"
            :actionSlot="true"
      >
        <template v-slot:header>
          <span v-if="event">
            <span v-if="event.type === 'custom'">{{ event.label }}</span>
            <span v-else>{{ $t('labels.appointments.' + event.type) }}</span>
          </span>
          <span v-else>Public Talk</span>
          <span class="ml-2">
            <span class="tag is-warning" v-if="publicTalk && (!publicTalk.confirmed || parseInt(publicTalk.confirmed) === 0)">Unconfirmed</span>
            <span class="tag is-danger" v-if="publicTalk && parseInt(publicTalk.confirmed) === 2">Denied</span>
            <span class="tag is-danger" v-if="event && event.cancels_meeting === 'public'">No Meeting</span>
            <br>
          </span>
        </template>
        <template v-slot:content>
          <span v-if="event && event.has_speaker" class="font-light">
            <fa-icon :icon="['fal','user-tie']" class="fa mr-2" />
            <span>{{ event.speaker }}</span><br>
            <fa-icon :icon="['fal','podium']" class="fa mr-2" /><span v-html="event.theme"></span><br>
          </span>
          <span v-if="!event || publicTalk" class="font-light">
            <fa-icon :icon="['fal','user-tie']" class="fa mr-2" />
            <span v-if="publicTalk">{{ publicTalk.speaker_name }} ({{ publicTalk.speaker_congregation }} Congregation)</span><br>
            <fa-icon :icon="['fal','podium']" class="fa mr-2" /><span v-html="publicTalk ? publicTalk.notes : ''"></span>
          </span>
        </template>
        <template v-slot:actions>
          <div>
            <action :secondary="true" :small="true" v-if="publicTalk" @click="$emit('moveSpeaker', meeting)" class="ml-2 mb-2">
              Move Date
            </action>
            <action :secondary="true" :small="true" v-if="!publicTalk" @click="book" class="ml-2 mb-2">
              Book Speaker
            </action>
            <action :secondary="true" :small="true" v-if="publicTalk" @click="edit" class="ml-2 mb-2">
              Change Outline
            </action>
            <action :primary="true" :small="true" v-if="publicTalk" @click="promptClearMeeting" class="ml-2 mb-2">
              Clear
            </action>
          </div>
        </template>
      </card>

      <portal to="modals" v-if="changeMeetingFormActive || clearMeetingActive">

        <delete
                :submitting="submitting"
                :header="'Clear Public Talk'"
                :active.sync="clearMeetingActive"
                :message="'Are you sure you wish to clear this public talk?'"
                @submit="confirmClearMeeting()"
        ></delete>

      </portal>

    </section>

</template>

<script setup>
import { ref, computed } from 'vue'
import { store } from '@/store.js'
import { post } from '@/composables/serverInterface'
import { merge } from '@/composables/dataInterface'

const props = defineProps(['meeting'])
const emits = defineEmits(['search', 'edit'])

const changeMeetingFormActive = ref(false)
const clearMeetingActive = ref(false)
const submitting = ref(false)

const allEvents = store.state.events

const publicTalk = computed(() => {
  let talk = null
  if (props.meeting && props.meeting.parts) {
    talk = props.meeting.parts.find(p => {
      return p.meeting === 'talk' && p.part === 'talk'
    })
  }
  if (talk && talk.assignments && talk.assignments.length > 0) {
    return talk.assignments[0]
  }
  return null
})

const event = computed(() => {
  return allEvents.find(r => {
    const week = new Date(r.week_date)
    if (props.meeting) {
      const start = new Date(props.meeting.start_date)
      return week.getFullYear() === start.getFullYear() &&
        week.getMonth() === start.getMonth() &&
        week.getDate() === start.getDate() &&
        (r.has_speaker || r.cancels_meeting === 'both' || r.cancels_meeting === 'public')
    }
  })
})

const promptClearMeeting = () => {
  clearMeetingActive.value = true
}

const confirmClearMeeting = () => {
  submitting.value = true
  post(['clear-speaker-assignment'], publicTalk.value)
    .then(res => {
      merge('publicMeetings', res)
      submitting.value = false
      clearMeetingActive.value = false
    })
}

const edit = () => {
  emits('edit', publicTalk.value)
}

const book = () => {
  emits('search')
}

</script>
