import axios from 'axios'

export const ADD_AWAY_DATES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/away', payload.away)
      .then(function (response) {
        commit('UPDATE_PUB', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_AWAY_DATES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/away/' + payload.away.id)
      .then(function (response) {
        commit('UPDATE_PUB', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
