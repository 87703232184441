export const UPDATE_CART_SCHEDULE = (state, data) => {
  state.cart = data
}

export const UPDATE_FIELD_MEETINGS = (state, data) => {
  state.field_meetings = data
}

export const OPEN_ATTENDANTS_FORM = (state, data) => {
  state.attendantsFormActive = true
}

export const CLOSE_ATTENDANTS_FORM = (state, data) => {
  state.attendantsFormActive = false
}

export const UPDATE_NOTICEBOARD_FILES = (state, data) => {
  state.noticeboardFiles = data
}
