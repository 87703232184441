<template>
  <div>
    <modal :header="'Field Service Report Form'" :active="active" @submit="submit" @close="emit('close')" :validated="resource.report_month !== null">
      <template v-slot:default="{ error }">
        <input-select
          :label="'Reporting Month'"
          name="report_month"
          :options="monthOptions"
          v-model="resource.report_month"
          insistLabel="month"
          inputClass="bg-gray-100"
          insistReturn="date"
        />
        <input-toggle class="mb-3"
          :label="'Did you share in any form of the ministry during the month?'"
          :name="'aux'"
          :ifTrue="'yes'"
          :ifFalse="'no'"
          v-model="resource.active"
        />
        <input-toggle class="mb-3"
          v-if="canAuxPioneer"
          :label="'Did you Auxiliary Pioneer during the month?'"
          :name="'aux'"
          :ifTrue="'yes'"
          :ifFalse="'no'"
          v-model="resource.aux"
        />
        <input-integer
          v-if="pioneering && resource.active"
          :label="'Number of full hours spent in the ministry'"
          :name="'hours'"
          v-model="resource.hours"
          :error="error"
        />
        <input-integer
          v-if="canHaveBibleStudies"
          :label="'Number of different Bible studies'"
          :name="'studies'"
          v-model="resource.studies"
          :error="error"
        />
        <input-integer
          v-if="canAddCreditHours"
          :label="'Hour Credits'"
          :name="'special_hours'"
          v-model="resource.special_hours"
          :error="error"
        />
        <input-message
          :label="'Comments'"
          :name="'comments'"
          v-model="resource.comments"
        />
      </template>
    </modal>

  </div>
</template>
<script setup>
import { ref, watch, computed } from 'vue'
import { post } from '@/composables/serverInterface.js'
import { attach } from '@/composables/dataInterface.js'
import axios from 'axios'
import { Sec } from '@/mixins/sec'
import { find } from 'lodash'

const props = defineProps(['active', 'user'])
const emit = defineEmits(['close'])

const initialResource = {
  id: 0,
  user_id: null,
  report_month: null,
  active: false,
  hours: null,
  studies: null,
  special_hours: null,
  aux: null,
  pioneer: null,
  special_pioneer: null,
  comments: null
}

const monthOptions = ref({})
const resource = ref({ ...initialResource })
const pioneer = ref(false)
const specialPioneer = ref(false)

const pioneering = computed(() => {
  return pioneer.value || specialPioneer.value || resource.value.aux
})

const canAuxPioneer = computed(() => {
  return !pioneer.value && !specialPioneer.value && resource.value.active
})

const canHaveBibleStudies = computed(() => {
  return resource.value.active
})

const canAddCreditHours = computed(() => {
  return resource.value.active && (pioneer.value || specialPioneer.value)
})

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    resource.value.user_id = props.user.id
    resource.value.pioneer = parseInt(props.user.pioneer)
    pioneer.value = parseInt(props.user.pioneer)
    resource.value.special_pioneer = parseInt(props.user.special_pioneer)
    specialPioneer.value = parseInt(props.user.special_pioneer)
    axios.post('api/available-report-months', { id: props.user.id })
      .then(response => {
        monthOptions.value = response.data.data
        const month = find(monthOptions.value, (m) => {
          return m.month === (new Sec()).subtract(1, 'month').format('F, Y')
        })
        setTimeout(() => {
          if (month) resource.value.report_month = month.date
        }, 500)
      })
  }
})

const submit = () => {
  post(['report'], resource.value).then(res => {
    attach(res).as('current_report').to('users')
    emit('close')
  })
}

</script>
