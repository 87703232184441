<template>

  <v-tour name="welcome-tour" :steps="steps" :options="options"></v-tour>

</template>

<script setup>
import { computed } from 'vue'
import { i18n } from '@/i18n'

const options = computed(() => {
  return {
    labels: {
      buttonSkip: i18n.t('actions.ar', { a: i18n.t('actions.stop'), r: i18n.tc('resources.tour', 1) }),
      buttonPrevious: i18n.t('actions.previous'),
      buttonNext: i18n.t('actions.next'),
      buttonStop: i18n.t('actions.ar', { a: i18n.t('actions.finish'), r: i18n.tc('resources.tour', 1) })
    }
  }
})
const steps = computed(() => [
  {
    content: i18n.t('tours.welcome.step01'),
    params: {
      type: 'sticky'
    }
  },
  {
    content: i18n.t('tours.welcome.step02'),
    params: {
      type: 'sticky'
    }
  },
  {
    target: '#nav-main',
    content: i18n.t('tours.welcome.step03')
  },
  {
    target: '#nav-maps',
    content: i18n.t('tours.welcome.step04'),
    params: {
      prev: 'false'
    }
  },
  {
    target: '#nav-assignments',
    content: i18n.t('tours.welcome.step05'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.welcome.step06'),
    params: {
      type: 'sticky',
      prev: 'false'
    }
  },
  {
    content: i18n.t('tours.welcome.step07'),
    params: {
      type: 'sticky'
    }
  },
  {
    content: i18n.t('tours.welcome.step08'),
    params: {
      type: 'sticky'
    }
  },
  {
    content: i18n.t('tours.welcome.step09'),
    params: {
      type: 'sticky'
    }
  },
  {
    content: i18n.t('tours.welcome.step10'),
    params: {
      type: 'sticky'
    }
  },
  {
    target: '#nav-schedules',
    content: i18n.t('tours.welcome.step11'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.welcome.step12'),
    params: {
      type: 'sticky',
      prev: 'false'
    }
  },
  {
    content: i18n.t('tours.welcome.step13'),
    params: {
      type: 'sticky'
    }
  },
  {
    target: '#nav-settings',
    content: i18n.t('tours.welcome.step14'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.welcome.step15'),
    params: {
      type: 'sticky',
      prev: 'false'
    }
  },
  {
    target: '#my-profile',
    content: i18n.t('tours.welcome.step16'),
    params: {
      nav: 'false'
    }
  },
  {
    content: i18n.t('tours.welcome.step17'),
    params: {
      type: 'sticky',
      prev: 'false'
    }
  },
  {
    target: '#my-profile',
    content: i18n.t('tours.welcome.step18')
  },
  {
    target: '#my-ical',
    content: i18n.t('tours.welcome.step19')
  },
  {
    content: i18n.t('tours.welcome.step20'),
    params: {
      type: 'sticky'
    }
  }
])

// const nextStepCallback = currentStep => {
//   console.log(currentStep)
// }

</script>
