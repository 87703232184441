<template>
  <div>
    <sm-item v-if="permits['settings.mail.clam_schedule'] && cong.modules.meeting_schedule.on">
      <a @click="mailClmScheduleActive = true">{{ $t('labels.clm_to_chairman') }}</a>
    </sm-item>

    <modal :header="'Mail CLM Schedule'" :active.sync="mailClmScheduleActive" @submit="mailClmSchedule" :cancel="$t('actions.close')" :save="saveButton" :validated="true">
      <template v-slot:default="{ error }">
        <tuxedo-date :label="'Select Week to Mail'" :name="'week'" :insistFormat="'isoDate'" v-model="resource.week" :error="error" :inputClass="'bg-gray-100'" />
      </template>
    </modal>

  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { store } from '@/store'
import { post } from '@/composables/serverInterface'
import { i18n } from '@/i18n'

const cong = computed(() => store.state.settings.cong)
const permits = computed(() => store.state.core.permits)

const mailClmScheduleActive = ref(false)
const resource = ref({
  week: null
})
let saveButton = i18n.t('actions.send')

const mailClmSchedule = () => {
  post([
    'mailable',
    'clm-schedule-to-chairman'
  ], resource.value).then(() => {
    saveButton = 'Sent!'
    setTimeout(() => {
      saveButton = i18n.t('actions.send')
    }, 4000)
  })
}

</script>
