<template>

  <view-content>

    <template v-slot:header>
      {{ $t('labels.assignments.title') }}
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="(!available_territory || Object.keys(available_territory).length === 0) && isOnline" @click="requestAddressesFormActive = true" class="mr-2 mb-2">
        {{ $t('labels.addresses.request') }}
      </action>
      <action :primary="true" v-if="isOnline && permits['assignments.request_map'] && (!maps || Object.keys(maps).length === 0)" @click="requestMap">
        {{ $t('labels.map.request') }}
      </action>
    </template>

    <div v-if="cart_reports && isOnline">
      <cart-report v-for="(booking, key) in cart_reports" :key="'cr' + key" :schedule="booking" />
    </div>

    <div class="card is-blue" v-if="available_territory && Object.keys(available_territory).length > 0" :style="mapBoxStyle">
      <div class="card-content" :class="affixMap ? 'affix-top' : ''" ref="mapBox">
        <div class="number-header">
          <div class="num">
            <span>{{ available_territory_map[0].number }}</span>
          </div>
          <div class="header">
            {{ available_territory_map[0].suburb }} {{ $t('labels.addresses.title') }}
          </div>
        </div>
        <div class="map-holder">
          <GmapMap
            v-if="liveMap"
            :center="{lat:avgLat, lng:avgLng}"
            :zoom="available_territory_map[0].type_id === 5 ? 14 : 16"
            map-type-id="roadmap"
            :options="{
                    styles: mapStyle,
                    mapTypeControl: false,
                    streetViewControl: false,
                    disableDefaultUI: false,
                }"
            style="width: 100%; height: 340px;margin-bottom:10px;"
          >
            <GmapMarker
              :key="index"
              v-for="(m, index) in markers"
              :position="m.position"
              :clickable="true"
              :draggable="false"
              @click="center=m.position"
              :label="m.label"
            ></GmapMarker>
          </GmapMap>
          <img v-if="!liveMap && available_territory_map[0].asset" :src="'https://srvc.app/storage/'+available_territory_map[0].asset.location+available_territory_map[0].asset.filename" />
        </div>
        <button @click="affixMapTop" class="button btn-default btn-sm">
          {{ $t('labels.addresses.affix') }}
        </button>
        <button @click="liveMap = !liveMap" class="button btn-sm" :class="liveMap ? 'btn-success' : 'btn-default'">
          {{ $t('labels.addresses.live') }}
        </button>
      </div>
      <table class="table is-fullwidth align-middle" v-for="(street, street_name) in available_territory" :key="'at' + street_name">
        <tr>
          <th class="address">
            <div class="label">
              <v-touch @swiperight="prepStreet(street_name)" @swipeleft="unprepStreet(street_name)" class="draggable"></v-touch>
              <span style="font-weight:bold;position:absolute;bottom:5px;">{{ street_name }} <small v-if="street.street.suburb">/ {{ street.street.suburb }}</small></span>
            </div>
          </th>
        </tr>
        <tr v-for="(address, key) in street.addresses" :key="'sa' + key" :id="'address-'+address.id" :class="'optionable street-'+street_name.replace(' ','-')">
          <td class="address">
            <div class="check">
              <fa-icon :icon="['fal', 'check']" />
            </div>
            <div class="label">
              <v-touch @swiperight="prepAddress(address.id)" @swipeleft="unprepAddress(address.id)" class="draggable"></v-touch>
              <address-manage :address="address" @addressUpdated="removeAddress" @addressDeleted="removeAddress" />
            </div>
          </td>
        </tr>
      </table>
      <div class="card-footer" v-if="isOnline">
        <button @click="!handInSubmitting ? handInAddresses() : null" class="btn btn-success">
          <fa-icon :icon="['fal', 'inbox-in']" v-if="!handInSubmitting" /><span v-if="!handInSubmitting"> {{ $t('labels.addresses.hand_in') }}</span>
          <fa-icon :icon="['fal', 'spinner-third']" spin v-if="handInSubmitting" />
        </button>
      </div>
    </div>

    <div class="card is-blue" v-for="(map, key) in maps" :key="'maps' + key">
      <div class="card-content">
        <div class="number-header">
          <router-link :to="'/map/'+map.number+'/'" class="num" style="color:#20292f">
            <span>{{ map.number }}</span>
          </router-link>
          <div class="header">
            {{ map.suburb }}
          </div>
          <div class="tag is-info is-light" v-if="map.personal">
            <small>Your Personal Map</small>
          </div>
        </div>
        <table class="table is-narrow is-fullwidth no-border">
          <tr>
            <th width="100px">{{ $t('labels.map.time_out') }}:</th>
            <td>{{ map.time_out }}</td>
          </tr>
          <tr>
            <th width="100px">{{ $t('labels.map.completed') }}:</th>
            <td>{{ map.completed }}</td>
          </tr>
          <tr v-if="map.address_count > 0">
            <th width="100px">{{ $t('labels.addresses.title') }}:</th>
            <td>{{ map.address_count }} <span>({{ $tc('messages.incl_phone_numbers', map.address_with_phone_count) }})</span></td>
          </tr>
          <tr v-if="map.campaign">
            <th>{{ $t('labels.map.campaign') }}:</th>
            <td>{{ map.campaign }}</td>
          </tr>
        </table>
      </div>
      <div class="card-footer" v-if="isOnline && !map.personal">
        <button @click="promptHandInMap(map)" class="btn btn-success">
          <fa-icon :icon="['fal', 'inbox-in']" /> {{ $t('labels.map.hand_in') }}
        </button>
        <button @click="reAssignment(map)" class="btn btn-info">
          <fa-icon :icon="['fal', 'inbox-out']" /> {{ $t('labels.map.hand_off') }}
        </button>
      </div>
    </div>

    <div v-if="appointments === null">
      <card :rendering="true" class="mb-4" />
      <card :rendering="true" class="mb-4" />
      <card :rendering="true" class="mb-4" />
      <card :rendering="true" class="mb-4" />
      <card :rendering="true" class="mb-4" />
    </div>

    <div v-else v-for="(assignment, id) in appointments" :key="id">
      <cart-assignment v-if="assignment && assignment.base_type === 'cart'" :assignment="assignment" />
      <convention-assignment v-else-if="assignment && assignment.base_type === 'convention'" :assignment="assignment" />
      <assignment v-else-if="assignment" :assignment="assignment" />
    </div>

    <portal to="modals">
    <modal :submitting="submitting" :header="$t('labels.map.hand_off_map')" :active.sync="reAssignFormActive" @submit="submitReAssignment()" :validated="assignmentValid">
      <tuxedo-select
        :label="$t('labels.map.hand_to')"
        name="overseer"
        :options="activeUsers"
        v-model="map.user"
        insistLabel="last_first"
        inputClass="bg-gray-100"
        insistReturn="id"
      />
    </modal>

    <modal :submitting="submitting" :header="$t('labels.assign_addresses.title')" :active.sync="assignAddressesFormActive" @submit="confirmAssignAddresses()" :validated="true">
      <tuxedo-select
        :label="$t('labels.assign_addresses.assign_to')"
        name="addressSelectionUser"
        :options="activeUsers"
        v-model="addressSelectionUser"
        insistLabel="last_first"
        inputClass="bg-gray-100"
        insistReturn="id"
      />
    </modal>

    <modal :submitting="submitting" :header="$t('labels.assign_addresses.sms')" :active.sync="smsAddressesFormActive" @submit="confirmSMSAddresses()" :validated="true">
      <tuxedo-select
        :label="$t('labels.assign_addresses.sms')"
        name="addressSelectionUser"
        :options="activeUsers"
        v-model="addressSelectionUser"
        insistLabel="last_first"
        inputClass="bg-gray-100"
        insistReturn="id"
      />
    </modal>

      <RequestAddressesForm
        :active="requestAddressesFormActive"
        @close="requestAddressesFormActive = false"
      />

    <modal :submitting="submitting" :loading="dataLoading" :header="$t('labels.map.request')" :active.sync="requestMapActive" @submit="confirmMap()" :validated="mapValid">
      <div class="field" v-if="mapOptions.length > 0">
        <div class="detail-radio">
          <transition v-for="map in orderedMapOptions" :key="'map'+map.id" name="collapse">
            <label class="card is-blue" v-show="map.id == mapChoice || !mapChoice">
              <input type="radio" @click="checkMap(map.id)" :value="map.id" :checked="mapChoice == map.id">
              <span class="card-content">
                <span class="number-header">
                    <span class="num">{{ map.number }}</span>
                    <span class="header">
                        {{ map.suburb }} <small v-if="map.type">({{ $t('labels.map_types.'+map.type.type) }})</small>
                    </span>
                </span>
                <table class="table is-fullwidth no-border">
                    <tbody>
                    <tr v-if="map.assignment">
                        <th width="100px">{{ $t('labels.map.partial_map') }}</th>
                        <td>{{ map.assignment.completed }} complete</td>
                    </tr>
                    <tr v-if="!map.assignment">
                        <th width="100px">{{ $t('labels.map.fresh_map') }}</th>
                    </tr>
                    </tbody>
                </table>
              </span>
            </label>
          </transition>
        </div>
      </div>
      <div v-if="mapOptions.length == 0" style="margin:10px;text-align:center">{{ $t('labels.map.unavailable') }}</div>
    </modal>

    <update
      :submitting="submitting"
      :header="$t('labels.map.hand_in')"
      :active.sync="handInActive"
      :message="$t('labels.map.hand_in_confirm')"
      @submit="confirmHandInMap()"
    ></update>

    <update
      :submitting="submitting"
      :header="$t('notifications.address_contact.remove')"
      :active.sync="addressContactActive"
      :message="$t('notifications.address_contact.remove_confirm')"
      @submit="confirmClearAddressContact"
    ></update>

    <div class="btn-group" v-if="addressSelection.length > 0">
      <a href="#" id="smsUser" class="btn btn-column btn-success" @click="smsAddressesFormActive = true">{{ $t('labels.assign_addresses.sms') }}</a>
      <button class="btn btn-column btn-success" @click="assignAddressesFormActive = true">{{ $t('labels.assign_addresses.assign') }}</button>
    </div>
    </portal>

  </view-content>

</template>

<script>
import axios from 'axios'
import Update from '../general/confirm-update.vue'
import { mapState } from 'vuex'
import CartAssignment from './cart'
import ConventionAssignment from './convention'
import CartReport from './cart-report'
import { get } from '@/mixins/api'
import { assign } from '@/mixins/storeMutations'
import { Sec } from '@/mixins/sec'
import AddressManage from '@/components/maps/AddressManage'
import RequestAddressesForm from '@/components/forms/RequestAddressesForm.vue'

export default {
  name: 'Assignments',
  mixins: [get, assign],
  data () {
    return {
      affixMap: false,
      liveMap: false,
      mapBoxStyle: '',
      map: {
        id: null,
        user: null,
        campaign: 0,
        assignment: null
      },
      mapAddressChoice: {
        map: null,
        count: 10,
        type: 'any'
      },
      mapChoice: null,
      lazyRender: true,

      requestAddressesFormActive: false,
      requestMapActive: false,
      reAssignFormActive: false,
      handInActive: false,
      addressContactActive: false,
      assignAddressesFormActive: false,
      smsAddressesFormActive: false,

      addressSubmit: [],
      addressSelection: [],
      addressId: null,

      addressSelectionUser: null,
      submitting: null,
      dataLoading: false,
      handInSubmitting: null,
      loading: false,
      error: false
    }
  },
  computed: {
    ...mapState({
      isOnline: state => state.core.isOnline,
      permits: state => state.core.permits,
      maps: state => state.assignments.maps,
      pubs: state => state.users,
      cart_reports: state => state.assignments.cart_reports,
      available_territory: state => state.assignments.available_territory,
      available_territory_map: state => state.assignments.available_territory_map,
      carts: state => state.assignments.carts,
      special_groups: state => state.assignments.special_groups,
      attendants: state => state.assignments.attendants,
      mapOptions: state => state.assignments.mapOptions,
      meetingParts: state => state.assignments.meeting_parts,
      events: state => state.events,
      mapStyle: state => state.core.mapStyle
    }),
    orderedMapOptions () {
      return this._.orderBy(this.mapOptions, ['assignment'])
    },
    currentEvents () {
      return this.events.filter(e => {
        const end = new Sec(e.end_date)
        return end.isAfter(new Sec().subtract(1, 'day'))
      })
    },
    appointments () {
      if (this.lazyRender) return null
      let apps = []
      apps = apps.concat(this.carts).concat(this.meetingParts).concat(this.special_groups).concat(this.currentEvents)
        .concat(this.attendants)
      apps = this._.sortBy(apps, [function (app) {
        if (app) return app.base_date
      }])
      return apps
    },
    activeUsers: function () {
      if (this.$store.state.users) {
        const pubs = this.$store.state.users.filter(pub => pub.active === 1 && ['guest', 'unbaptised', 'publisher', 'servant', 'elder'].includes(pub.appointment))
        return this._.orderBy(pubs, ['last_first'], ['asc'])
      }
      return []
    },
    assignmentValid () {
      if (this.map.user) { return true } else { return false }
    },
    mapValid () {
      if (this.mapChoice) { return true } else { return false }
    },
    markers () {
      const markers = []
      this._.forEach(this.available_territory, (s) => {
        this._.forEach(s.addresses, (a) => {
          let label = a.house.toString()
          if (a.unit) label += '/' + a.unit
          markers.push({ position: { lat: a.lat, lng: a.lng }, label: label })
        })
      })
      return markers
    },
    avgLng () {
      let sum = 0
      const tot = this.markers.length
      this.markers.forEach((m) => {
        sum += m.position.lng
      })
      return sum / tot
    },
    avgLat () {
      let sum = 0
      const tot = this.markers.length
      this.markers.forEach((m) => {
        sum += m.position.lat
      })
      return sum / tot
    }
  },
  components: {
    RequestAddressesForm,
    CartAssignment,
    ConventionAssignment,
    CartReport,
    Update,
    AddressManage
  },
  created () {
    this.lazyRender = true
    if (this.isOnline) {
      this.$store.dispatch('GET_ASSIGNMENTS')
      this.$store.dispatch('GET_ADDRESS_OPTIONS')
      this.get('events').then(res => {
        this.assign('events', res)
      })
    }
  },
  mounted () {
    setTimeout(() => {
      this.lazyRender = false
    }, 100)
  },
  methods: {
    prepAddress (e) {
      document.getElementById('address-' + e).classList.add('prep')
      this.addressSelection.push(e)
    },
    unprepAddress (e) {
      document.getElementById('address-' + e).classList.remove('prep')
      const key = this.addressSelection.indexOf(e)
      this.$delete(this.addressSelection, key)
    },
    prepStreet (e) {
      const selection = this.addressSelection
      const elems = document.getElementsByClassName('street-' + e.replace(' ', '-'));
      [].forEach.call(elems, function (el) {
        el.classList.add('prep')
        const id = el.id.split('-')
        selection.push(id[1])
      })
    },
    unprepStreet (e) {
      const _this = this
      const selection = this.addressSelection
      const elems = document.getElementsByClassName('street-' + e.replace(' ', '-'));
      [].forEach.call(elems, function (el) {
        el.classList.remove('prep')
        const id = el.id.split('-')
        const key = selection.indexOf(id[1])
        _this.$delete(selection, key)
      })
    },
    removeAddress (address) {
      for (const [street, v] of Object.entries(this.available_territory)) {
        const key = this._.findKey(v.addresses, a => a.id === address)
        if (key) {
          this.$delete(this.available_territory[street].addresses, key)
        }
      }
    },
    clearAddressContact (address) {
      this.addressContactActive = true
      this.address = address
    },
    confirmClearAddressContact () {
      this.submitting = true
      this.$store.dispatch('CLEAR_ADDRESS_CONTACT', { address: this.address }).then(() => {
        this.submitting = false
        this.addressContactActive = false
      })
    },
    handInAddresses () {
      this.handInSubmitting = true
      this.$store.dispatch('HAND_IN_ADDRESSES', { token: this.$store.state.core.token }).then(() => {
        this.handInSubmitting = false
      })
    },
    requestMap () {
      this.dataLoading = true
      this.$store.dispatch('GET_MAP_OPTIONS', { token: this.$store.state.core.token }).then(() => {
        this.dataLoading = false
      })
      this.requestMapActive = true
    },
    confirmMap () {
      this.submitting = true
      this.$store.dispatch('REQUEST_MAP', { map: this.mapChoice }).then(() => {
        this.submitting = false
        this.requestMapActive = false
        this.$store.dispatch('GET_ASSIGNMENTS')
      })
        .catch(e => {
          this.$emit('update:error', e)
        })
    },
    reAssignment (map) {
      console.log(map)
      this.map.id = map.id
      this.map.user = map.user
      this.reAssignFormActive = true
    },
    submitReAssignment () {
      this.submitting = true
      this.$store.dispatch('REASSIGN_MAP', { map: this.map }).then(() => {
        const key = this.maps.findIndex(row => row.id === this.map.id)
        this.$delete(this.maps, key)
        this.submitting = false
        this.reAssignFormActive = false
      })
    },
    promptHandInMap (map) {
      this.map.id = map.id
      this.handInActive = true
    },
    confirmHandInMap () {
      this.submitting = true
      axios.post('api/map/hand-in', this.map)
        .then(response => {
          const key = this.maps.findIndex(row => row.id === this.map.id)
          this.$delete(this.maps, key)
          this.submitting = false
          this.handInActive = false
        })
        .catch(e => {
          this.$emit('update:error', e)
        })
    },
    checkMap (value) {
      if (this.mapChoice === value) { this.mapChoice = null } else { this.mapChoice = value }
    },
    confirmAssignAddresses () {
      this.submitting = true
      this.$store.dispatch('ASSIGN_ADDRESSES', {
        addressSelectionUser: this.addressSelectionUser,
        addressSelection: this.addressSelection
      }).then(() => {
        this.submitting = false
        this.assignAddressesFormActive = false
        this.addressSelection = []
        const elems = document.getElementsByClassName('optionable');
        [].forEach.call(elems, function (el) {
          el.classList.remove('prep')
        })
      })
    },
    confirmSMSAddresses () {
      this.submitting = true
      axios.post('/api/helpers/sms-addresses', { addresses: this.addressSelection })
        .then(response => {
          document.getElementById('smsUser').href = 'sms:' + this.addressSelectionUser + ';?&body=' + response.data
          document.getElementById('smsUser').click()
          this.submitting = false
          this.smsAddressesFormActive = false
          this.addressSelection = []
          const elems = document.getElementsByClassName('optionable');
          [].forEach.call(elems, function (el) {
            el.classList.remove('prep')
          })
        })
        .catch(e => {
          this.$emit('update:error', e)
        })
    },
    affixMapTop () {
      const height = this.$refs.mapBox.clientHeight + 'px'
      this.affixMap = !this.affixMap
      if (this.affixMap) this.mapBoxStyle = 'padding-top: ' + height
      else this.mapBoxStyle = ''
    }
  }
}
</script>

<style lang="less">

  .table {
    margin-bottom: 0;
  }

  .btn-group {
    position: fixed;
    bottom: 56px;
    left: 0;
    right: 0;
    width: 100%;
    height: 50px;
    z-index: 500;
    display: flex;

    a {
      line-height: 37px;
    }
  }

  .card-content.affix-top {
    position: fixed;
    background: white;
    top: 0;
    left: 0;
    right: 0;
    z-index: 100;
  }

  .map-holder {

    img {
      width: 100%;
    }
  }

</style>
