import axios from 'axios'

export const GET_MAPS = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/maps')
      .then(function (response) {
        commit('UPDATE_MAPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_SLIM_MAPS = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/slim-maps')
      .then(function (response) {
        commit('UPDATE_MAPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MAP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/map/' + payload.map)
      .then(function (response) {
        commit('UPDATE_MAP', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MAP_SUBURBS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/maps/' + payload.map + '/suburbs')
      .then(function (response) {
        commit('UPDATE_MAP_SUBURBS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MAP_STREETS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/street-data/' + payload.map)
      .then(function (response) {
        commit('UPDATE_MAP_STREETS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MAP_PRELOADED_ADRESSES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/preloaded-territory/' + payload.map)
      .then(function (response) {
        commit('ADD_TO_PRELOADED_TERRITORY', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MAP_FOREIGN_ADRESSES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/foreign-territory/' + payload.map)
      .then(function (response) {
        commit('UPDATE_MAP_FOREIGN_TERRITORY', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MAP_TYPES = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/map-types')
      .then(function (response) {
        commit('UPDATE_MAP_TYPES', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_MAP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/map', payload.map)
      .then(function (response) {
        commit('UPDATE_MAPS', response.data.data)
        resolve(response)
      })
      .catch((error) => {
        commit('SUBMIT_ERROR', error.response.data)
        reject(error)
      })
  })
}

export const POST_MAP_ORDER = ({ commit }, payload) => {
  commit('UPDATE_MAPS', payload.maps)
  return new Promise((resolve, reject) => {
    axios.post('api/map-order', payload)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_MAP = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/map/' + payload.map.id)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_SUBURB = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/maps/' + payload.suburb.map_id + '/suburbs', payload.suburb)
      .then(function (response) {
        commit('UPDATE_MAP_SUBURBS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_STREET = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/street', payload.street)
      .then(function (response) {
        commit('UPDATE_MAP_STREETS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_PRELOADED_TERRITORY = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/preloaded-territory', payload.address)
      .then(function (response) {
        if (payload.address.type === 'preloaded') {
          commit('ADD_TO_PRELOADED_TERRITORY', response.data.data)
        } else {
          commit('ADD_TO_FOREIGN_TERRITORY', response.data.data)
        }
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const PUT_PRELOADED_ADDRESS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.put('api/preloaded-territory/' + payload.id, payload)
      .then(function (response) {
        commit('UPDATE_PRELOADED_TERRITORY', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_PRELOADED_TERRITORY = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/preloaded-territory', { data: payload.address })
      .then(function (response) {
        if (payload.address.type === 'preloaded') {
          commit('DELETE_FROM_PRELOADED_TERRITORY', payload.address)
        } else {
          commit('DELETE_FROM_FOREIGN_ADDRESSES', payload.address)
        }
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_SUBURB = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/suburb', { data: payload.suburb })
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_STREET = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/street', { data: payload.street })
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
