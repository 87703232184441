<template>
  <modal :submitting="submitting"
         :header="'Print Life & Ministry Schedule'"
         :active="active"
         @close="emit('close')"
         @submit="submit"
         :save="'Generate PDF'"
         :validated="true">
    <tuxedo-select
      :label="'Size'"
      name="CLMPrintSize"
      :options="[{id:'0',label:'2-Up'},{id:'1',label:'4-Up'}]"
      v-model="resource.size"
      inputClass="bg-gray-100"
      insistReturn="id"
    />
  </modal>
</template>
<script setup>
import { ref } from 'vue'
import { download } from '@/composables/serverInterface'

defineProps(['active'])
const emit = defineEmits(['close'])

const submitting = ref(false)
const resource = ref({
  size: null
})

const submit = () => {
  submitting.value = true
  download(['generate-meeting-schedule'], resource.value)
    .then(() => {
      submitting.value = false
      emit('close')
    })
    .catch(() => {
      submitting.value = false
    })
}

</script>
