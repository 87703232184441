import axios from 'axios'
import store from '../store'

// It's bad practice to include interceptors on every axios request, so we create an
// additional instance we can use when we want to invoke our interceptors
const submit = axios.create()
submit.defaults.baseURL = process.env.VUE_APP_URL

submit.interceptors.request.use(request => {
  store.commit('axiosError', null)
  return request
})

submit.interceptors.response.use(response => {
  return response
}, error => {
  store.commit('axiosError', error)
  return Promise.reject(error)
})

export default submit
