// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueRouter from 'vue-router'
import VueTouch from 'vue-touch'
import VTooltip from 'v-tooltip'
import Hammer from 'hammerjs'
import helpers from './helpers'
import App from './App'
import * as VueGoogleMaps from 'vue2-google-maps'
import VueTour from './packages/vue-tour/main'
import AnimatedNumber from 'animated-number-vue'
import axiosConfig from './router/axios'
import VueLodash from 'vue-lodash'
import lodash from 'lodash'
import PortalVue from 'portal-vue'
import { i18n } from './i18n'
import './index.css'
import 'animate.css'
import pinia from './stores/pinia'

import store from './store'

import axios from 'axios'

import Authentication from './components/general/authentication.vue'
import Denied from './components/general/denied.vue'
import Dashboard from './components/dashboard/index.vue'
import Map from './components/maps/index.vue'
import Assignments from './components/assignments/index.vue'
import Assignment from './components/assignments/assignment.vue'
import Schedules from './components/schedules/index.vue'
import Cart from './components/schedules/cart.vue'
import Service from './components/schedules/service.vue'
import Meetings from './components/schedules/meetings-2/index.vue'
import Attendants from './components/schedules/attendants.vue'
import Tours from './components/tours/index'

import Settings from './components/settings/index.vue'
import Profile from './components/settings/profile/index.vue'
import TranslationsView from '@/views/settings/TranslationsView.vue'
import TranslationView from '@/views/settings/TranslationView.vue'
import TranslationCategoryView from '@/views/settings/TranslationCategoryView.vue'
import Events from './components/settings/events/index.vue'
import Maps from './components/settings/maps/index.vue'
import Users from './components/settings/users.vue'
import PublisherView from '@/views/settings/PublisherView.vue'
import Qualifications from './components/settings/qualifications/index.vue'
import Qualification from './components/settings/qualifications/qualification.vue'
import UserRoles from './components/settings/user-roles.vue'
import Reports from './components/settings/reports.vue'
import Report from './components/settings/report.vue'
import ReportTotals from './components/settings/report-totals/index'
import Groups from './components/settings/groups/index.vue'
import Group from './components/settings/groups/group.vue'
import PrintDNCs from './components/settings/print-dncs.vue'
import AccountCredits from './components/settings/credits/index.vue'
import Attendance from './components/settings/attendance/index.vue'

import CartLocations from './components/settings/carts.vue'
import CartRotations from './components/settings/cart-rotations/index.vue'
import CartBookings from './components/settings/cart-bookings/index.vue'

import FieldServiceLocations from './components/settings/locations/index'
import SpecialServiceGroups from './components/settings/special-service-groups/index'
import Messaging from './components/settings/messaging.vue'
import AutomaticMessaging from './components/settings/messaging/settings.vue'
import MapBuilder from './components/settings/map-builder/MapBuilder.vue'
import Assign from './components/settings/assign-maps/index.vue'
import RotationGroups from './components/settings/rotation-groups/index.vue'
import AwayDates from './components/settings/away/index.vue'
import PublicScheduler from './components/settings/public/index.vue'
import SpeakerDirectory from './components/settings/speaker-directory/index.vue'
import CongregationProfile from './components/settings/speaker-directory/CongregationProfile.vue'
import AttendantsScheduler from './components/settings/attendants/index'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import Modal from './components/general/form-modal.vue'
import Wizard from './components/general/wizard.vue'
import WizardStep from './components/general/wizard-step.vue'
import CheckboxField from './components/general/form-fields/checkbox.vue'
import DateTimeField from './components/general/form-fields/datetime.vue'
import CopyField from './components/general/form-fields/copy.vue'
import TopSlidingNav from './components/general/top-sliding-nav.vue'

import MeetingPart from './components/schedules/meetings/part.vue'

import ForumTopics from './components/forum/topics'
import Discussion from './components/forum/discussion'

import { useProfileStore } from '@/stores/profile'

require('./icons')
require('./components')
require('./filters')

// eslint-disable-next-line no-extend-native
Date.prototype.getFirstDayOfWeek = function () {
  return (new Date(this.setDate(this.getDate() - this.getDay() + (this.getDay() === 0 ? -6 : 1))))
}

// eslint-disable-next-line no-extend-native
Date.prototype.getLastDayOfWeek = function () {
  return (new Date(this.setDate(this.getDate() - this.getDay() + 7)))
}

Vue.use(VueRouter)
Vue.use(VueTouch, { name: 'v-touch' })
Vue.use(PortalVue)
Vue.use(VTooltip)
Vue.use(VueTour)
Vue.use(VueLodash, { lodash: lodash })
Vue.mixin({ methods: helpers })

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyDblYEe6yme4xwfWLG1Lf3093CnmWE9LfU'
    // libraries: 'places', // This is required if you use the Autocomplete plugin
    // OR: libraries: 'places,drawing'
    // OR: libraries: 'places,drawing,visualization'
    // (as you require)

    /// / If you want to set the version, you can do so:
    // v: '3.26',
  }
})

Vue.directive('pan', {
  bind: function (el, binding) {
    if (typeof binding.value === 'function') {
      const mc = new Hammer(el)
      mc.get('pan').set({ direction: Hammer.DIRECTION_ALL })
      mc.on('pan', binding.value)
    }
  }
})

const routes = [
  { path: '/auth/', name: 'Authenticate', component: Authentication },
  { path: '/denied/', name: 'Denied', component: Denied },

  { path: '/', name: 'Dashboard', component: Dashboard },
  { path: '/map/:id', component: Map, props: true },

  { path: '/assignments/', name: 'Assignments', component: Assignments },

  { path: '/schedules/', name: 'Schedules', component: Schedules },
  { path: '/schedules/cart/:id/', component: Cart },
  { path: '/schedules/service/', component: Service },
  { path: '/schedules/meetings/', component: Meetings },
  { path: '/schedules/attendants/', component: Attendants },

  { path: '/settings/', name: 'Settings', component: Settings },
  { path: '/settings/credits/', name: 'AccountCredits', component: AccountCredits },
  { path: '/settings/my-profile/', name: 'Profile', component: Profile },
  { path: '/settings/translations/', name: 'Translations', component: TranslationsView },
  { path: '/settings/translations/:iso', component: TranslationView },
  { path: '/settings/translations/:iso/:category', component: TranslationCategoryView },
  { path: '/settings/events/', component: Events },
  { path: '/settings/map-builder/', name: 'MapBuilder', component: MapBuilder },
  { path: '/settings/maps/', name: 'Maps', component: Maps },
  { path: '/settings/maps/:id', name: 'MapConfig', component: Map, props: true },
  { path: '/settings/users/', component: Users, name: 'Publishers' },
  { path: '/settings/users/:id', component: PublisherView, props: true },
  { path: '/settings/user-roles/', component: UserRoles },
  { path: '/settings/reports/', name: 'Reports', component: Reports },
  { path: '/settings/report-totals/', name: 'ReportTotals', component: ReportTotals },
  { path: '/settings/reports/publisher/:id', component: Report },
  { path: '/settings/groups/', name: 'Groups', component: Groups },
  { path: '/settings/group/:id', component: Group },

  { path: '/settings/carts/', component: CartLocations },
  { path: '/settings/cart-rotations/', component: CartRotations },
  { path: '/settings/cart-bookings/', component: CartBookings },

  { path: '/settings/print-dncs/', component: PrintDNCs },
  { path: '/settings/field-service-locations/', component: FieldServiceLocations },
  { path: '/settings/special-service-groups/', component: SpecialServiceGroups },
  { path: '/settings/messaging/', component: Messaging },
  { path: '/settings/messaging-settings/', component: AutomaticMessaging },
  { path: '/settings/assign/', component: Assign },
  { path: '/settings/rotation-groups', component: RotationGroups },

  { path: '/settings/qualifications', name: 'Qualifications', component: Qualifications },
  { path: '/settings/qualifications/:id', component: Qualification, props: true },

  { path: '/settings/away', component: AwayDates },

  { path: '/settings/public-scheduler/', component: PublicScheduler },
  { path: '/settings/speaker-directory/', name: 'SpeakerDirectory', component: SpeakerDirectory },
  { path: '/settings/speaker-directory/:congregation', component: CongregationProfile },

  { path: '/settings/attendants-scheduler/', component: AttendantsScheduler },
  { path: '/settings/meeting-attendance/', component: Attendance },

  { path: '/forum/topics/', name: 'Topics', component: ForumTopics, meta: { preserveScroll: true } },
  { path: '/forum/topics/:topic', component: Discussion, props: true }
]

const router = new VueRouter({
  routes, // short for `routes: routes`
  mode: 'history'
})

let scrollWatcher = null

require('./assets/sass/main.scss')

Vue.config.productionTip = false

Vue.mixin({ methods: helpers })

/* eslint-disable no-new */
const app = new Vue({
  router,
  pinia,
  i18n,
  render: h => h(App),
  el: '#app',
  components: {
    App,
    CheckboxField,
    DateTimeField,
    CopyField,
    Modal,
    Wizard,
    WizardStep,
    Treeselect,
    TopSlidingNav,
    MeetingPart,
    Assignment,
    Tours,
    AnimatedNumber
  },
  template: '<App/>',
  store,
  methods: {
    updateStatus: function () {
      if (typeof window.navigator.onLine === 'undefined') {
        // If the browser doesn't support connection status reports
        // assume that we are online because most apps' only react
        // when they now that the connection has been interrupted
        this.$store.state.core.isOnline = true
      } else {
        this.$store.state.core.isOnline = window.navigator.onLine
      }
    },
    clearData (data) {
      for (const key in data) {
        if (data[key] !== null && typeof (data[key]) === 'object') {
          this.clearData(data[key])
        } else {
          data[key] = null
        }
      }
    },
    clearEmptyData (data) {
      for (const key in data) {
        if (data[key] !== null && typeof (data[key]) === 'object') {
          this.clearEmptyData(data[key])
        } else if (data[key] === null) delete data[key]
        else if (typeof data[key] === 'object' && data[key].length === 0) {
          console.log(key)
          console.log(data[key])
          delete data[key]
        }
      }
      console.log(data)
    },
    copyData (from, to) {
      this.clearData(to)
      for (const key in to) {
        if (to[key] !== null && typeof (to[key]) === 'object' && from[key] !== null) {
          this.copyData(from[key], to[key])
        } else if (from[key] !== null) {
          to[key] = from[key]
        }
      }
    },
    arrayCopy (from, to) {
      for (const key in from) {
        if (from[key] !== null) {
          to[key] = from[key]
        }
      }
    },
    refId (objectArray, link, id) {
      for (let i = 0; i < objectArray.length; i++) {
        if (objectArray[i][link] === id) {
          return i
        }
      }
    },
    thisMonth () {
      const date = new Date()
      const month = date.getMonth()
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[month]
    },
    lastMonth () {
      const date = new Date()
      const month = date.getMonth() === 0 ? 11 : date.getMonth() - 1
      const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
      return months[month]
    },
    subscribeUser () {
      const key = this.urlB64ToUint8Array('BKV87uUCUM1Dyf_9AmT2m-D0dHF8G3qJy8ig3Yd0raqFXKEk4k47hvAxSb6lWzhfhAGQRduaqHR-EmFjOb2GIL0')
      if ('serviceWorker' in navigator) {
        navigator.serviceWorker.ready.then(reg => {
          reg.pushManager.subscribe({
            userVisibleOnly: true,
            applicationServerKey: key
          }).then(sub => {
            console.log('Endpoint URL: ', sub.endpoint)
            console.log(JSON.stringify(sub))

            const key = sub.getKey('p256dh')
            const token = sub.getKey('auth')
            const contentEncoding = (PushManager.supportedContentEncodings || ['aesgcm'])[0]

            const data = {
              endpoint: sub.endpoint,
              publicKey: key ? btoa(String.fromCharCode.apply(null, new Uint8Array(key))) : null,
              authToken: token ? btoa(String.fromCharCode.apply(null, new Uint8Array(token))) : null,
              contentEncoding
            }

            axios.post('api/notify-endpoint', data)
              .then(response => {
                this.$store.state.core.push_notifications = true
                console.log(response)
              })
          }).catch(e => {
            this.$store.state.core.push_notifications = true
            if (Notification.permission === 'denied') {
              console.warn('Permission for notifications was denied')
            } else {
              console.error('Unable to subscribe to push', e)
            }
          })
        })
      }
    },
    urlB64ToUint8Array (base64String) {
      const padding = '='.repeat((4 - base64String.length % 4) % 4)
      const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/')

      const rawData = window.atob(base64)
      const outputArray = new Uint8Array(rawData.length)

      for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i)
      }
      return outputArray
    }
  }
})

axiosConfig()

const profile = useProfileStore()

/*
Before we move the user along to a new view we check to see when they were last authenticated,
if it was more than eight hours ago then we'll push them through our authentication method again.
 */
router.beforeEach((to, from, next) => {
  document.removeEventListener('scroll', scrollWatcher)
  store.commit('BACKGROUND_LOADING', false)
  store.commit('SUBMIT_ERROR')
  window.scrollTo(0, 0)
  // console.log(to);
  if (to.query.t) store.commit('UPDATE_TOKEN', to.query.t)
  if (to.query.src) store.commit('UPDATE_SOURCE', to.query.src)
  if (to.name === 'Assignments') {
    store.commit('AUTO_REFRESH', true)
  } else {
    store.commit('AUTO_REFRESH', false)
  }
  if (store.state.core.singleView && to.name !== 'Authenticate' && to.name !== 'Denied') {
    const allowed = '/' + store.state.core.singleView
    console.log(allowed)
    if (allowed !== to.path) {
      next(allowed)
    }
  }
  if (Object.keys(store.state.core.permits).length === 0 && to.name !== 'Authenticate' && to.name !== 'Denied') {
    store.commit('TEMP_TO', to)
    next('/auth/?t=' + store.state.core.token)
  }
  if (['Assignments', 'Schedules', 'Settings'].includes(to.name)) {
    store.commit('UPDATE_SOURCE', null)
    const authdt = profile.lastAuthenticated
    const dt = new Date()
    dt.setHours(dt.getHours() - 8)
    if (authdt < dt) {
      store.commit('TEMP_TO', to)
      next('/auth/')
    } else {
      next()
    }
  } else {
    next()
  }
})

router.afterEach((to, from) => {
  if (to.meta.preserveScroll) {
    console.log('preserve')
    console.log(store.state.scrollPosition)
    setTimeout(() => {
      window.scrollTo(0, store.state.scrollPosition[to.name])
    }, 100)
    document.addEventListener('scroll', scrollWatcher = () => {
      Vue.set(store.state.scrollPosition, to.name, window.scrollY)
    })
  }
})

// Initial setup
app.updateStatus()

// Setup the listeners
window.addEventListener('online', app.updateStatus)
window.addEventListener('offline', app.updateStatus)

// module.exports = main
