<template>
  <div ref="subscribePrompt" class="notification is-event" :class="collapse ? 'collapsing' : ''" v-if="on">
    <button class="delete" @click="dismissAlert('subscribePromptActive')"></button>
    <strong class="uppercase">{{ $t('notifications.subscribe.label') }}</strong>
    <br>
    <div>{{ $t('notifications.subscribe.description') }}</div>
    <div class="mt-3 flex justify-content-end gap-4">
      <action :default="true" @click="dismissAlert('subscribePromptActive')">
        {{ $t('notifications.subscribe.decline') }}
      </action>
      <action :secondary="true" @click="subscribe">
        <fa-icon :icon="['fal', 'spinner-third']" spin v-if="submitting" />
        <span v-else>{{ $t('notifications.subscribe.accept') }}</span>
      </action>
    </div>

  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'
import { post } from '@/composables/serverInterface'

export default {
  data () {
    return {
      submitting: false,
      collapse: false,
      height: null
    }
  },
  mounted () {
    this.height = this.$refs.subscribePrompt ? this.$refs.subscribePrompt.clientHeight : 0
  },
  computed: {
    ...mapState({
      cong: state => state.settings.cong,
      subscribePromptActive: state => state.dashboard.subscribePromptActive
    }),
    publicMeeting () {
      if (this.cong.modules && this.cong.modules.meeting_schedule) {
        if ((new Sec()).dayOfWeek() === parseInt(this.cong.modules.meeting_schedule.public_day)) return true
      }
      return false
    },
    clam () {
      if (this.cong.modules && this.cong.modules.meeting_schedule) {
        if ((new Sec()).dayOfWeek() === parseInt(this.cong.modules.meeting_schedule.clam_day)) return true
      }
      return false
    },
    url () {
      if (this.publicMeeting) return this.cong.modules.meeting_schedule.public_url
      if (this.clam) return this.cong.modules.meeting_schedule.clam_url
      return ''
    },
    on () {
      return this.subscribePromptActive
    }
  },
  methods: {
    dismissAlert (alert) {
      this.collapse = true
      this.$refs.subscribePrompt.style.height = this.height + 'px'
      setTimeout(() => {
        this.$refs.subscribePrompt.style.height = 0
        this.$refs.subscribePrompt.style.padding = 0
        this.$refs.subscribePrompt.style.margin = 0
        setTimeout(() => {
          this.collapse = false
          this.$store.dispatch('HIDE_ALERT', { alert: alert })
        }, 300)
      }, 500)
    },
    subscribe () {
      this.submitting = true
      post('profile-subscribed', { subscribed: true }).then(res => {
        this.submitting = false
        this.$emit('subscribed', res.data)
      })
    }
  }
}
</script>
