<template>
  <div>
    <action :secondary="true" :small="true" v-if="permits['maps.preloaded.add']" @click="create">
      {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.address', 1) }) }}
    </action>
    <div class="w-100 mt-2">
      <div v-for="(s, key) in preloadedStreets" :key="'ss2' + key">
        <div class="flex align-items-center justify-content-between py-1 border-t">
          <div>
            <street-label :street="s" />
            <div>
              <small>
                {{ $t('resources.count', {count: preloadedInStreet(s).length, resource: $tc('resources.address', preloadedInStreet(s).length) }) }}
              </small>
            </div>
          </div>
          <div>
            <action :default="true" :small="true" @click="$emit('expand', s.id)">
              <span v-if="expandedStreet === s.id">{{ $t('actions.collapse') }}</span>
              <span v-else>{{ $t('actions.expand') }}</span>
            </action>
          </div>
        </div>
        <div v-for="(address, akey) in preloadedInStreet(s)" :key="'pa' + akey">
          <div v-if="expandedStreet === s.id" class="flex align-items-center justify-content-between py-1 border-t">
            <AddressLabel :address="address" />
            <div v-if="addressDeleteArray.includes(address.id)" class="flex">
              <undo-timer :ms="3500" @click="cancel(address.id)" />
            </div>
            <div v-else>
              <button v-if="permits['maps.preloaded.edit']" @click="editAddress(address)" class="btn btn-info btn-round mr-1">
                <fa-icon :icon="['fal', 'phone-plus']" />
              </button>
              <button v-if="permits['maps.preloaded.delete']" @click="deleteAddress(address)" class="btn btn-danger btn-round" :disabled="addressDeleteArray.includes(address.id)">
                <fa-icon :icon="['fal', 'trash']" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <modal :header="resource.id ? labels.edit : labels.create" :active.sync="editActive" @submit="submit">
      <template v-slot:default="{ error }">
        <tuxedo-select name="street" :label="$t('fields.street.label')" v-model="resource.street" :error="error"
                       :options="sortedStreets" insistLabel="name" inputClass="bg-gray-100" insistReturn="id" />
        <input-text v-if="map.type_id === 2" :label="'Business Name'" :type="'text'" v-model="resource.name" />
        <input-text :label="$t('labels.house_number.label')" :charReplace="'[^0-9-+,]'"
                    :error="error && error.house ? error.house[0] : null"
                    v-model="resource.house"
                    :help="'You can add multiple numbers at once by separating them with a comma, you can also add ranges by connecting a range with a hyphen or a plus. eg. 10-13 creates addresses 10, 11, 12, 13 but 10+14 creates addresses 10, 12, 14. Letters aren\'t able to be used in this field.'" />
        <input-text v-if="suffixOn" :label="'Street Number Suffix'" v-model="resource.suffix"
                    :help="'You can include a letter suffix, or the end of a number range to the house number.'" />
        <input-text :label="'Unit Number'" :type="'text'" :maxlength="25" v-model="resource.unit"
                    :help="'You can add multiple units at once by separating them with a comma, you can also add ranges of units by connecting a range with a hyphen. eg. 1,4,10-15,20-25'" />
        <input-text v-if="map.type_id === 3"
                    :label="'Latitude'"
                    :error="error && error.lat ? error.lat[0] : null"
                    :type="'text'"
                    v-model="resource.lat"></input-text>
        <input-text v-if="map.type_id === 3"
                    :label="'Longitude'"
                    :error="error && error.lng ? error.lng[0] : null"
                    :type="'text'"
                    v-model="resource.lng"
                    :help="'If coordinates are not provided the Service App will do its best to find them for you based on the address, but it may not be possible with some rural addresses. For accurate location you may manually enter the latitude and longitude (as floating point numbers) from Google Maps yourself.'"></input-text>
      </template>
    </modal>

    <modal :header="labels.edit" :active.sync="numberAssignActive" @submit="submitAddress">
      <template v-slot:default="{ error }">
        <input-text :label="'Phone Number'" :type="'text'" v-model="number.number" :error="error" />
      </template>
    </modal>

  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { store } from '@/store'
import { put, post, destroy } from '@/composables/serverInterface'
import { merge, push, forget } from '@/composables/dataInterface'
import AddressLabel from '@/components/maps/AddressLabel'
import StreetLabel from '@/components/maps/StreetLabel'
import { orderBy, find, filter } from 'lodash'
import { useLabels } from '@/composables/labels'

const props = defineProps(['map', 'resources', 'expandedStreet'])

const labels = useLabels('preloadedAddresses')

const permits = computed(() => store.state.core.permits)
const streets = computed(() => store.state.streets)

const initialResource = {
  id: null,
  map_id: null,
  name: null,
  block: null,
  street: null,
  house: null,
  unit: '',
  date: null,
  note: '',
  type: 'preloaded'
}
const number = ref({
  id: null,
  number: null
})
const resource = ref({
  house: null
})
const addressDeleteArray = ref([])
const activeID = ref(null)
const editActive = ref(false)
const numberAssignActive = ref(false)

const sortedStreets = computed(() => {
  return orderBy(streets.value, ['name'])
})
const preloadedStreets = computed(() => {
  return filter(sortedStreets.value, s => {
    return find(props.resources, a => a.street === s.id)
  })
})
const suffixOn = computed(() => {
  if (!resource.value.house) return true
  const chars = [',', '-', '+']
  return !chars.some(char => resource.value.house.includes(char))
})

const preloadedInStreet = (s) => {
  const filtered = filter(props.resources, r => r.street === s.id)
  return orderBy(filtered, ['house', 'suffix', 'unit'])
}
const editAddress = (a) => {
  numberAssignActive.value = true
  number.value.id = a.id
  number.value.number = a.number
}
const submitAddress = () => {
  put(['preloaded-territory', number.value.id], number.value)
    .then(res => {
      merge('preloadedAddresses', res)
      numberAssignActive.value = false
    })
}
const create = () => {
  resource.value = { ...initialResource }
  activeID.value = null
  editActive.value = true
}
const submit = () => {
  post(['preloaded-territory'], { ...resource.value, map: props.map.id })
    .then(res => {
      push('preloadedAddresses', res)
    })
}
const deleteAddress = (a) => {
  addressDeleteArray.value.push(a.id)
  setTimeout(() => {
    if (addressDeleteArray.value.includes(a.id)) {
      destroy(['preloaded-territory'], { data: a })
        .then(() => {
          const key = addressDeleteArray.value.indexOf(a.id)
          addressDeleteArray.value.splice(key, 1)
          forget('preloadedAddresses', a)
        })
    }
  }, 3000)
}
const cancel = (a) => {
  const key = addressDeleteArray.value.indexOf(a.id)
  addressDeleteArray.value.splice(key, 1)
}

</script>
