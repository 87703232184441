<template>
  <button :class="cl" class="relative rounded font-sans shadow-sm cursor-pointer inline-block" @click.prevent="click" :disabled="disabled">
    <fa-icon class="mr-1" v-if="icon" :icon="icon" />
    <slot></slot>
    <span v-if="toggle" class="absolute bottom-1/2 transform translate-y-1/2 left-2.5 h-4 w-0.5 inline-block"
    :class="toggle === 'on' ? 'action-glow' : 'bg-teal-darkest'"></span>
  </button>
</template>

<script setup>
import { computed, defineProps } from 'vue'
import { useRouter } from 'vue-router/composables'

const emit = defineEmits(['click'])
const props = defineProps({
  primary: Boolean,
  secondary: Boolean,
  danger: Boolean,
  default: Boolean,
  toggle: String,
  icon: Array,
  small: Boolean,
  disabled: Boolean,
  to: String
})
const router = useRouter()

const cl = computed(() => {
  let cl = 'font-medium'
  if (props.disabled) cl += ' bg-white text-dark ring-2 ring-inset ring-gray-300'
  if (props.primary) cl += ' bg-gradient-to-r from-orange-dark to-orange text-white'
  if (props.secondary || props.toggle) cl += ' bg-gradient-to-r from-teal-400 to-teal-300 text-white'
  if (props.danger) cl += ' bg-gradient-to-r from-red-700 to-red-600 text-white'
  if (props.default) cl += ' bg-gradient-to-r from-gray-300 to-gray-200 text-dark'
  if (props.icon) cl += ' pl-3 pr-4'
  else if (props.toggle) cl += ' pl-6 pr-4'
  else if (!props.small) cl += ' px-4'
  if (props.small) cl += ' text-sm py-1 px-3'
  else cl += ' text-base py-1.5'
  return cl
})

const click = () => {
  if (!props.disabled) {
    if (props.to) {
      router.push(props.to)
    } else {
      emit('click')
    }
  }
}
</script>

<style>
.action-glow {
  box-shadow: 5px 0 10px #21ced9, 0 0 5px 1px #ffffff;
  background: #ffffff;
}
</style>
