import Vue from 'vue'
import _ from 'lodash'

export const UPDATE_PUB = (state, data) => {
  const id = _.findIndex(state.users, (pub) => {
    return pub.id === data.id
  })
  Vue.set(state.users, id, data)
}

export const UPDATE_CARTS = (state, data) => {
  state.cartLocations = data
}

export const UPDATE_USERS = (state, data) => {
  state.users = data
}

export const UPDATE_PUB_PART_DATES = (state, data) => {
  // Clear old data
  _.forIn(state.users, (pub, id) => {
    Vue.set(state.users[id], 'lastParts', [])
    Vue.set(state.users[id], 'lastOfKind', null)
    Vue.set(state.users[id], 'lastAsHelper', null)
    Vue.set(state.users[id], 'partsInMonth', null)
    Vue.set(state.users[id], 'utilisation', null)
  })
  _.forIn(data.last_part, (value, id) => {
    const key = state.users.findIndex(user => parseInt(user.id) === parseInt(id))
    Vue.set(state.users[key], 'lastParts', value)
  })
  _.forIn(data.of_kind, (value, id) => {
    const key = state.users.findIndex(user => parseInt(user.id) === parseInt(id))
    Vue.set(state.users[key], 'lastOfKind', value)
  })
  _.forIn(data.helper, (value, id) => {
    const key = state.users.findIndex(user => parseInt(user.id) === parseInt(id))
    Vue.set(state.users[key], 'lastAsHelper', value)
  })
  _.forIn(data.count, (value, id) => {
    const key = state.users.findIndex(user => parseInt(user.id) === parseInt(id))
    Vue.set(state.users[key], 'partsInMonth', value)
  })
  _.forIn(data.away, (value, id) => {
    const key = state.users.findIndex(user => parseInt(user.id) === parseInt(id))
    Vue.set(state.users[key], 'away', value)
  })
  _.forIn(data.utilisation, (value, id) => {
    const key = state.users.findIndex(user => parseInt(user.id) === parseInt(id))
    Vue.set(state.users[key], 'utilisation', value)
  })
}

export const updateQueue = (state, payload) => {
  state.axiosQueue.push({
    url: payload.url,
    message: payload.message,
    icon: payload.icon
  })
}

export const recordResponseTime = (state, payload) => {
  if (!state.axiosTiming[payload.url]) {
    Vue.set(state.axiosTiming, payload.url, [])
  }
  Vue.set(state.axiosTiming[payload.url], state.axiosTiming[payload.url].length, payload.seconds)
  if (state.axiosTiming[payload.url].length > 5) state.axiosTiming[payload.url].shift()
}

export const removeFromQueue = (state, payload) => {
  if (payload) {
    const key = state.axiosQueue.findIndex(q => q.url === payload.url)
    Vue.delete(state.axiosQueue, key)
  } else {
    const filterQueue = state.axiosQueue.filter(q => q.error && q.error.length > 0)
    Vue.set(state, 'axiosQueue', filterQueue)
  }
}
