<template>
  <tbody>
    <slot />
  </tbody>
</template>

<script>
import { ContainerMixin } from 'vue-slicksort'

export default {
  name: 'SortList',
  mixins: [ContainerMixin]
}
</script>
