import axios from 'axios'

export const ASSIGN_MAP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/map/assign', payload)
      .then(function (response) {
        commit('UPDATE_MAPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const REASSIGN_MAP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/map/reassign', payload.map)
      .then(response => {
        resolve(response)
      })
      .catch(e => {
        reject(e)
      })
  })
}

export const POST_TOGGLE_LOCK_MAP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/lock-map', payload)
      .then(function (response) {
        commit('UPDATE_MAPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_TOGGLE_NUMBER_SEARCH = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/map/number-lookup', payload)
      .then(function (response) {
        commit('UPDATE_MAPS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
