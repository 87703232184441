<template>

    <view-content :back="{name:'Settings'}">

      <template v-slot:header>
        Do Not Calls
      </template>

      <table v-for="map in sortedMaps" :key="'map' + map.id" class="w-100 mb-2">
        <tbody>
        <tr>
          <th colspan="2"><h2><b>Map No.{{ map.number }} - {{ map.suburb }}</b></h2></th>
        </tr>
        <tr v-for="street in map.streets" :key="'street' + street.id" v-show="street.dncs.length > 0">
          <td style="width: 150px">{{ street.name }}</td>
          <td>
            <span v-for="(dnc, key) in street.dncs" :key="'dnc' + dnc.id">
                <span v-if="dnc.unit">Unit {{ dnc.unit }}</span>
                <span>{{ dnc.number }}</span><span v-if="key !== street.dncs.length - 1">, </span>
            </span>
          </td>
        </tr>
        </tbody>
      </table>

    </view-content>

</template>

<script setup>
import { computed, onMounted } from 'vue'
import { get } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'
import { store } from '@/store'
import { sortBy, filter } from 'lodash'

const dncs = computed(() => store.state.dncs)
const streets = computed(() => store.state.streets)
const maps = computed(() => store.state.maps.maps)

const sortedMaps = computed(() => {
  const ms = maps.value
  ms.forEach(m => {
    const sts = mapStreets(m)
    sts.forEach(s => {
      s.dncs = streetDNCs(s)
    })
    m.streets = sts
  })
  return sortBy(ms, 'order')
})

onMounted(() => {
  get('dnc').then(res => {
    assign('dncs', res)
  })
})

const mapStreets = (map) => {
  const sts = filter(streets.value, s => s.map_id === map.id)
  return sortBy(sts, 'name')
}
const streetDNCs = (street) => {
  const dns = filter(dncs.value, d => d.street_id === street.id)
  return sortBy(dns, 'house')
}
</script>

<style>

    .map {
        margin-bottom: 15px;
    }

    h2 {
        font-weight: bold;
        text-align: left;
    }

</style>
