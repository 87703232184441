<template>
  <div>
    <div>
      <label class="file-label">
        <input
          multiple
          class="file-input"
          type="file"
          @change="uploadImages($event.target.files)"
          :accept="accept"
        >
        <span class="file-cta">
        <span class="file-icon">
          <fa-icon :icon="['fal', 'upload']" />
        </span>
        <span class="file-label">
            Choose files&hellip;
        </span>
      </span>
      </label>
    </div>
    <div class="mt-2">
      <strong>Files to Upload</strong>
      <ul class="mt-1">
        <li v-for="name in fileNames" :key="name">{{ name }} <small class="cursor-pointer text-underline" @click="removeFile(name)">(remove)</small></li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      files: [],
      fileNames: [],
      formFiles: null
    }
  },
  props: ['accept', 'label', 'type', 'help', 'required'],
  methods: {
    uploadImages (files) {
      this.files = files
      if (this.files) {
        // let formFiles = [];
        const formData = new FormData()
        if (this.formFiles) {
          for (const pair of this.formFiles.entries()) {
            formData.append('files[]', pair[1], pair[1].name)
          }
        }
        for (const file of this.files) {
          // this trims a files name so there are no spaces in it
          const trimmedAddress = file.name.split(' ').join('')
          const filename = `${trimmedAddress}`
          this.fileNames.push(filename)

          formData.append('files[]', file, filename)
        }
        this.formFiles = formData
        this.$emit('changeFiles', this.formFiles)
      }
    },
    removeFile (name) {
      const formData = new FormData()
      this.fileNames = []
      if (this.formFiles) {
        for (const pair of this.formFiles.entries()) {
          if (pair[1].name !== name) {
            formData.append('files[]', pair[1], pair[1].name)
            this.fileNames.push(pair[1].name)
          }
        }
        this.formFiles = formData
      }
    }
  }
}
</script>
