<template>

    <div id="new-spinner-background">

        <div class="container">

            <div class="top">
                <div class="tip" :class="[$parent.welcomeClass, head]">
                    <p>{{ tip }}</p>
                </div>
            </div>

            <div class="bottom">
                <div class="logo flex justify-center">
                    <img src="https://srvc.app/logo2x.png" />
                </div>

                <div class="text-animation">
                    <div class="welcome" :class="$parent.welcomeClass">
                        <p v-html="$t('labels.welcome', { name: $store.state.core.name })"></p>
                    </div>
                </div>

                <div class="spinner">
                    <fa-icon :icon="['fal', 'spinner-third']" spin />
                    <p><animated-number :value="percent" :formatValue="formatToPercent" :duration="800" /></p>
                </div>
            </div>
        </div>

    </div>

</template>

<script setup>
import { computed } from 'vue'
import { store } from '@/store'
import { i18n } from '@/i18n'
import AnimatedNumber from 'animated-number-vue'

const totalRequests = computed(() => store.state.core.init_load_requests)
const completeRequests = computed(() => store.state.core.complete_load_requests)

const percent = computed(() => {
  return parseInt((100 / totalRequests.value) * completeRequests.value)
})

const tip = computed(() => {
  const min = 1
  const max = Math.floor(9)
  const select = Math.floor(Math.random() * (max - min + 1)) + min
  return i18n.t('tips.tip' + select)
})

const head = computed(() => {
  const heads = ['sister', 'brother']
  const min = 0
  const max = 1
  const select = Math.floor(Math.random() * (max - min + 1)) + min
  return heads[select]
})

const formatToPercent = (value) => {
  return i18n.t('labels.loading', { percent: Math.min(value.toFixed(0), 100) })
}

</script>
