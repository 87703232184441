<template>
  <tr>
    <slot />
  </tr>
</template>

<script>
import { ElementMixin } from 'vue-slicksort'

export default {
  name: 'SortItem',
  mixins: [ElementMixin]
}
</script>
