<template>
  <div>
    <modal
      :header="$t('labels.congregation.ptc')"
      :active="active"
      :save="$t('actions.send')"
      @submit="submit"
      @close="emit('close')"
    >
      <template v-slot:default="{ error }">
        <input-message :name="'message'"
                       :label="$t('fields.message.label')"
                       :help="'This sends a list of your public traveling speakers to this congregation'"
                       :type="'text'"
                       v-model="resource.message"
                       :error="error" />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { post } from '@/composables/serverInterface.js'
import { i18n } from '@/i18n'

const props = defineProps(['congregation', 'ptc', 'active'])
const emit = defineEmits(['close'])

const initialResource = {
  message: i18n.t('notifications.public_speakers.email_message')
}
const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    if (props.ptc) {
      Object.keys(resource.value).forEach(key => {
        if (props.ptc[key]) resource.value[key] = props.ptc[key]
      })
    }
  }
})

const submit = () => {
  resource.value.congregation = props.congregation.id
  post(['congregation', props.congregation.id, 'send-speakers'], resource.value).then(() => {
    emit('close')
  })
}

</script>
