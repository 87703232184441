<template>
  <div>
    <modal :header="$t('labels.addresses.request')" :active="active" @submit="submit" @close="emit('close')" :validated="addressesValid">
      <template v-slot:default="{ error }">
        <div class="field">
          <label class="label">{{ $t('fields.suburb.label') }}</label>
          <div class="detail-radio">
            <transition v-for="map in mapAddressOptions" :key="'add'+map.id" name="collapse">
              <label class="card is-blue" v-show="map.id === resource.map || !resource.map">
                <input type="radio" @click="checkAddresses(map.id)" :value="map.id" :checked="resource.map === map.id">
                <span class="card-content">
                <span class="number-header">
                    <span class="num">{{ map.number }}</span>
                    <span class="header">
                        {{ map.suburb }} <small v-if="map.type">({{ $t('labels.map_types.'+map.type.type) }})</small>
                    </span>
                </span>
                <table class="table is-fullwidth no-border">
                    <tbody>
                    <tr>
                        <th width="100px">{{ $t('labels.map.age') }}:</th>
                        <td>{{ map.assignment.time_out }}</td>
                    </tr>
                    <tr v-if="map.address_count > 0">
                        <th width="100px">{{ $t('labels.addresses.title') }}:</th>
                      <td>{{ map.unassigned_address_count }} <span class="inline-block lowercase">({{ $tc('messages.incl_phone_numbers', map.address_with_phone_count) }})</span></td>
                    </tr>
                    <tr>
                        <th width="100px">{{ $t('labels.map.completed') }}:</th>
                        <td>{{ map.assignment.completed }}</td>
                    </tr>
                    </tbody>
                </table>
            </span>
              </label>
            </transition>
          </div>
        </div>
        <tuxedo-radio
          v-show="resource.map"
          :label="$t('fields.address_type.label')"
          :name="'type'"
          v-model="resource.type"
          :options="addressChoices"
          :help="$t('fields.address_type.tip')"
          :error="error"
        />
        <tuxedo-select
          v-show="resource.map"
          :label="$t('fields.count.label')"
          :help="$t('fields.count.tip')"
          name="count"
          :options="countChoices"
          v-model="resource.count"
          inputClass="bg-gray-100"
          :insistReturn="'id'"
        />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { store } from '@/store'
import { i18n } from '@/i18n'
import { post } from '@/composables/serverInterface.js'

const props = defineProps(['active'])
const emit = defineEmits(['close'])

const addressChoices = {
  any: i18n.t('fields.address_type.any'),
  phone: i18n.t('fields.address_type.phone'),
  non_phone: i18n.t('fields.address_type.non_phone')
}

const countChoices = ref([
  {
    id: 10,
    label: i18n.t('fields.count.10')
  },
  {
    id: 20,
    label: i18n.t('fields.count.20')
  },
  {
    id: 50,
    label: i18n.t('fields.count.50')
  },
  {
    id: 10000,
    label: i18n.t('fields.count.all')
  }
])

const mapAddressOptions = computed(() => store.state.assignments.mapAddressOptions)

const addressesValid = computed(() => {
  return !!resource.value.map
})

const initialResource = {
  map: null,
  count: 10,
  type: 'any'
}
const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
  }
})

const checkAddresses = (v) => {
  if (resource.value.map === v) resource.value.map = null
  else resource.value.map = v
}

const submit = () => {
  post(['request-territory'], resource.value).then(() => {
    // overwrite out submitting value because we still need to run an old dispatch
    store.commit('submitting', true)
    store.dispatch('GET_ASSIGNMENTS').then(() => {
      store.commit('submitting', false)
      emit('close')
    })
  })
}

</script>
