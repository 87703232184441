import axios from 'axios'

export const GET_FIELD_SERVICE_LOCATIONS = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/service-group-locations')
      .then(function (response) {
        commit('SET_FIELD_SERVICE_LOCATIONS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const POST_FIELD_SERVICE_LOCATION = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/service-group-location', payload)
      .then(function (response) {
        commit('SET_FIELD_SERVICE_LOCATIONS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
