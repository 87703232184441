<template>
  <modal :submitting="submitting" :header="$t('labels.congregation.meeting_settings')" :active="active" @close="emit('close')" @submit="submit" :validated="true">
    <input-toggle class="mb-3" :label="$t('fields.meeting_scheduling.label')" v-model="meetingSchedule.on" :help="$t('fields.meeting_scheduling.tip')" />
    <tuxedo-select :label="$t('fields.ptc.label')" :name="'ptc'" v-model="meetingSchedule.ptc"
                   :options="publishersWithEmail" :insistLabel="'last_first'" :insistReturn="'id'" :inputClass="'bg-gray-100'">
      <template #label="{ option }">
        {{ option.last_first }}
      </template>
      <template #content="{ option }">
        {{ option.email }}
      </template>
    </tuxedo-select>
    <input-slider v-if="meetingSchedule.on" :label="$t('labels.clam_meeting_day.label')" v-model="meetingSchedule.clam_day" :options="days" />
    <input-time v-if="meetingSchedule.on" :minute-step="5" :label="$t('labels.clam_meeting_start_time.label')" :name="'clam_start_time'" v-model="meetingSchedule.clam_start_time" />
    <input-text v-if="meetingSchedule.on" :label="$t('labels.clam_meeting_url.label')" v-model="meetingSchedule.clam_url" :help="$t('labels.clam_meeting_url.tip')"></input-text>
    <input-slider v-if="meetingSchedule.on" :label="$t('labels.public_meeting_day.label')" v-model="meetingSchedule.public_day" :options="days" />
    <input-time v-if="meetingSchedule.on" :label="$t('labels.public_meeting_start_time.label')" :minute-step="5" :name="'public_start_time'" v-model="meetingSchedule.public_start_time"></input-time>
    <input-text v-if="meetingSchedule.on" :label="$t('labels.public_meeting_url.label')" v-model="meetingSchedule.public_url" :help="$t('labels.public_meeting_url.tip')"></input-text>
    <input-slider v-if="meetingSchedule.on" :label="$t('labels.schedule_history.label')" v-model="meetingSchedule.history" :help="$t('labels.schedule_history.tip')" :options="history" />
    <input-toggle class="mb-3" :label="$t('labels.attendance.label')" v-model="meetingAttendance.on" :help="$t('labels.attendance.tip')" />
    <input-toggle :label="$t('labels.attendants.label')" v-model="attendantsSchedule.on" :help="$t('labels.attendants.tip')" />
  </modal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { post } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'
import { store } from '@/store'
import { orderBy } from 'lodash'

const props = defineProps(['active'])
const emit = defineEmits(['close'])

const settings = computed(() => store.state.settings)
const users = computed(() => store.state.users)

const publishersWithEmail = computed(() => {
  let pubs = []
  if (users.value) {
    pubs = users.value.filter(pub => pub.email && pub.active)
  }
  return orderBy(pubs, ['last_first'])
})

const days = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
}
const history = {
  simple: 'Simple',
  detailed: 'Detailed'
}

const submitting = ref(false)
const meetingSchedule = ref({
  on: false,
  ptc: null,
  clam_day: null,
  clam_start_time: null,
  clam_url: null,
  public_day: null,
  public_start_time: null,
  public_url: null,
  history: null
})
const meetingAttendance = ref({
  on: false
})
const attendantsSchedule = ref({
  on: false
})

watch(() => props.active, async (active) => {
  if (active) {
    let module = settings.value.cong.modules.meeting_schedule
    Object.keys(meetingSchedule.value).forEach(key => {
      if (module[key]) meetingSchedule.value[key] = (key === 'on' ? Boolean(module[key]) : module[key])
    })
    module = settings.value.cong.modules.meeting_attendance
    Object.keys(meetingAttendance.value).forEach(key => {
      if (module[key]) meetingAttendance.value[key] = (key === 'on' ? Boolean(module[key]) : module[key])
    })
    module = settings.value.cong.modules.attendants_schedule
    Object.keys(attendantsSchedule.value).forEach(key => {
      if (module[key]) attendantsSchedule.value[key] = (key === 'on' ? Boolean(module[key]) : module[key])
    })
  }
})

const submit = () => {
  submitting.value = true
  post(['congregation'], {
    modules: {
      meeting_schedule: meetingSchedule.value,
      meeting_attendance: meetingAttendance.value,
      attendants_schedule: attendantsSchedule.value
    }
  })
    .then(res => {
      const obj = { ...settings.value }
      obj.cong = res.data.data
      assign('settings', obj)
      submitting.value = false
      emit('close')
    })
    .catch(() => {
      submitting.value = false
    })
}

</script>
