import Vue from 'vue'

export const assign = {
  data () {
    return {
      componentState: {
        page: 1,
        lastPage: 1,
        total: 0
      }
    }
  },
  methods: {
    assign (collection, data) {
      if (!data) return

      // Determine the actual data layer for the response
      while ('data' in data) {
        // Determine if this response contains a state layer
        if (data.meta) {
          this.componentState.page = data.meta.current_page
          this.componentState.lastPage = data.meta.last_page
          this.componentState.total = data.meta.total
        }

        data = data.data
      }

      // Determine if this collection uses an internal data property which is common for API collections
      // const internalData = Object.hasOwn(this.$store.state[collection], 'data')
      const internalData = false

      // Just overwrite the store collection with data received from the API
      if (internalData) Vue.set(this.$store.state[collection], 'data', data)
      else Vue.set(this.$store.state, collection, data)
    }
  }
}

export const push = {
  methods: {
    push (collection, obj) {
      // Determine the actual data layer for the response
      while ('data' in obj) obj = obj.data

      // Determine if this collection uses an internal data property which is common for API collections
      const internalData = Object.hasOwn(this.$store.state[collection], 'data')

      // Now append the data as an object to the end of the collection
      if (internalData) {
        const data = this.$store.state[collection].data
        data.push(obj)
        Vue.set(this.$store.state[collection], 'data', data)
      } else {
        let data = this.$store.state[collection]
        if (Array.isArray(obj)) data = data.concat(obj)
        else data.push(obj)
        Vue.set(this.$store.state, collection, data)
      }
    }
  }
}

export const merge = {
  methods: {
    merge (collection, object, targetUsing) {
      // Determine which property we'll use to match an object in the collection with the object being passed in
      targetUsing = targetUsing ?? 'id'

      // Determine the actual data layer for the response
      while ('data' in object) object = object.data

      // Determine if this collection uses an internal data property which is common for API collections
      const internalData = Object.hasOwn(this.$store.state[collection], 'data')

      let collectionKey = -1
      if (internalData) collectionKey = this.$store.state[collection].data.findIndex(o => o[targetUsing] === object[targetUsing])
      else collectionKey = this.$store.state[collection].findIndex(o => o[targetUsing] === object[targetUsing])

      // The merge doesn't overwrite the entire object in the collection, it only merges or overwrites the properties of
      // the object being passed in, so we iterate over the object properties and set them individually
      if (collectionKey >= 0) {
        Object.keys(object).forEach(key => {
          if (internalData) Vue.set(this.$store.state[collection][collectionKey].data, key, object[key])
          else Vue.set(this.$store.state[collection][collectionKey], key, object[key])
        })
      }
    }
  }
}

export const forget = {
  methods: {
    forget (collection, object, targetUsing) {
      // Determine which property we'll use to match an object in the collection with the object being passed in
      targetUsing = targetUsing ?? 'id'

      // Determine if this collection uses an internal data property which is common for API collections
      const internalData = Object.hasOwn(this.$store.state[collection], 'data')

      let collectionKey = -1
      if (internalData) {
        collectionKey = this.$store.state[collection].data.findIndex(o =>
          o[targetUsing] === (typeof object === 'object' ? object[targetUsing] : object)
        )
      } else {
        collectionKey = this.$store.state[collection].findIndex(o =>
          o[targetUsing] === (typeof object === 'object' ? object[targetUsing] : object)
        )
      }

      if (internalData) Vue.delete(this.$store.state[collection].data, collectionKey)
      else Vue.delete(this.$store.state[collection], collectionKey)
    }
  }
}

export const forgetAttribute = {
  methods: {
    forgetAttribute (collection, object, attribute, targetUsing) {
      // Determine which property we'll use to match an object in the collection with the object being passed in
      targetUsing = targetUsing ?? 'id'

      // Determine if this collection uses an internal data property which is common for API collections
      const internalData = Object.hasOwn(this.$store.state[collection], 'data')

      let collectionKey = -1
      if (internalData) {
        collectionKey = this.$store.state[collection].data.findIndex(o =>
          o[targetUsing] === (typeof object === 'object' ? object[targetUsing] : object)
        )
      } else {
        collectionKey = this.$store.state[collection].findIndex(o =>
          o[targetUsing] === (typeof object === 'object' ? object[targetUsing] : object)
        )
      }

      console.log(this.$store.state[collection][collectionKey])

      if (internalData) Vue.delete(this.$store.state[collection].data[collectionKey], attribute)
      else Vue.delete(this.$store.state[collection][collectionKey], attribute)
    }
  }
}

export const manageScrollPosition = {
  data () {
    return {
      scrollWatcher: null
    }
  },
  methods: {
    watchScrollPos (key) {
      if (this.$store.state.scrollPosition[key]) {
        window.scrollTo(0, this.$store.state.scrollPosition[key])
      }
      document.addEventListener('scroll', this.scrollWatcher = () => {
        Vue.set(this.$store.state.scrollPosition, key, window.scrollY)
      })
    }
  },
  beforeDestroy () {
    document.removeEventListener('scroll', this.scrollWatcher)
  }
}
