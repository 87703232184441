import axios from 'axios'

export const GET_PART = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/get-part/' + payload.part)
      .then(function (response) {
        commit('SET_PART', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const REMOVE_QUALIFICATION = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/user-part-qualification', { data: payload.qualification })
      .then(function (response) {
        commit('UPDATE_PUB', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
