<template>
  <div ref="onlineMeetingPrompt" class="notification public-talk" :class="collapse ? 'collapsing' : ''" v-if="on">
    <button class="delete" @click="dismissAlert('onlineMeetingActive')"></button>
    <strong v-if="publicMeeting">{{ $t('notifications.meeting_conference.public') }}</strong>
    <strong v-else>{{ $t('notifications.meeting_conference.public') }}</strong>
    <br>
    <div>{{ $t('notifications.meeting_conference.message') }}<br>
      <span>{{ url }}</span>
    </div>
    <div class="mt-3 display-flex justify-content-end" style="margin-right:-25px">
      <a :href="url" target="_blank" class="button btn-sm btn-success">{{ $t('labels.button.join') }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'

export default {
  name: 'OnlineMeetingPrompt',
  data () {
    return {
      collapse: false,
      height: null
    }
  },
  mounted () {
    this.height = this.$refs.onlineMeetingPrompt ? this.$refs.onlineMeetingPrompt.clientHeight : 0
  },
  computed: {
    ...mapState({
      cong: state => state.settings.cong,
      onlineMeetingActive: state => state.dashboard.onlineMeetingActive
    }),
    publicMeeting () {
      if (this.cong.modules && this.cong.modules.meeting_schedule) {
        if ((new Sec()).dayOfWeek() === parseInt(this.cong.modules.meeting_schedule.public_day)) return true
      }
      return false
    },
    clam () {
      if (this.cong.modules && this.cong.modules.meeting_schedule) {
        if ((new Sec()).dayOfWeek() === parseInt(this.cong.modules.meeting_schedule.clam_day)) return true
      }
      return false
    },
    url () {
      if (this.publicMeeting) return this.cong.modules.meeting_schedule.public_url
      if (this.clam) return this.cong.modules.meeting_schedule.clam_url
      return ''
    },
    on () {
      return this.onlineMeetingActive && this.cong.modules && this.cong.modules.meeting_schedule && this.cong.modules.meeting_schedule.on && (this.publicMeeting || this.clam)
    }
  },
  methods: {
    dismissAlert (alert) {
      this.collapse = true
      this.$refs.onlineMeetingPrompt.style.height = this.height + 'px'
      setTimeout(() => {
        this.$refs.onlineMeetingPrompt.style.height = 0
        this.$refs.onlineMeetingPrompt.style.padding = 0
        this.$refs.onlineMeetingPrompt.style.margin = 0
        setTimeout(() => {
          this.collapse = false
          this.$store.dispatch('HIDE_ALERT', { alert: alert })
        }, 300)
      }, 500)
    }
  }
}
</script>
