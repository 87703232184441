<template>
  <div>
    <action :secondary="true" :small="true" v-if="permits['maps.suburbs.add']" @click="create">
      {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.suburb', 1) }) }}
    </action>
    <div class="w-100 mt-2">
      <div v-for="(r, key) in resources" :key="'s3' + key"
           class="flex align-items-center justify-content-between py-1 border-t">
        <div class="font-medium">{{ r.suburb }} {{ find(regions, 'id', r.region_id, 'code').toUpperCase() }}, {{ r.code }}</div>
        <div>
          <button class="btn bg-gray-200 text-teal-400 btn-round rounded-full"
                  v-if="permits['maps.suburbs.edit'] || permits['maps.suburbs.delete']"
                  @click="openMenu(r)" :id="'suburbMenu'">
            <fa-icon :icon="['fas', 'ellipsis-v']" />
          </button>
        </div>
      </div>
    </div>
    <tuxedo-secondary-menu v-if="showMenu" @close="showMenu = false" :target="'suburbMenu'">
      <ul class="text-lg font-sans pt-2">
        <sm-item v-if="permits['maps.suburbs.edit']" @click="edit" class="justify-content-center">
          {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.suburb', 1) }) }}
        </sm-item>
        <sm-item v-if="permits['maps.suburbs.delete']" :icon="['fal', 'trash']"
                 class="text-red-700 border-t mt-2 justify-content-center" @click="promptDestroy">
          {{ $t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.suburb', 1) }) }}
        </sm-item>
      </ul>
    </tuxedo-secondary-menu>

    <modal :header="$t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.suburb', 1) })" :active.sync="editActive" @submit="submit">
      <template v-slot:default="{ error }">
        <tuxedo-select name="region_id" :label="$t('fields.region.label')" :options="filteredRegions" :error="error"
                       v-model="resource.region_id" insistReturn="id" insistLabel="name" inputClass="bg-gray-100" />
        <tuxedo-input name="suburb" :label="$t('fields.suburb.label')" v-model="resource.suburb" :error="error" inputClass="bg-gray-100" />
        <tuxedo-input name="code" :label="$t('fields.code.label')" v-model="resource.code" :error="error" inputClass="bg-gray-100" />
      </template>
    </modal>

    <delete
      :header="$t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.suburb', 1) })"
      :active.sync="deleteActive"
      :message="$t('prompts.delete', { resource: $tc('resources.suburb', 1) })"
      @submit="confirmDestroy"
    ></delete>

  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { store } from '@/store'
import { filter } from 'lodash'
import { fillObject, pluck } from '@/helpers'
import { put, post, destroy } from '@/composables/serverInterface'
import { merge, push, forget } from '@/composables/dataInterface'

const newStore = useCollectionsStore()

const props = defineProps(['map', 'resources'])

const cong = computed(() => store.state.settings.cong)
const regions = computed(() => newStore.all('regions'))
const permits = computed(() => store.state.core.permits)

const filteredRegions = computed(() => {
  if (cong.value.country_id) {
    return filter(regions.value, r => r.country_id === parseInt(cong.value.country_id))
  }
  return regions.value
})

const initialResource = ref({
  id: null,
  suburb: '',
  code: null,
  region_id: null
})

const resource = ref({ ...initialResource })

const activeID = ref(null)
const showMenu = ref(false)
const editActive = ref(false)
const deleteActive = ref(false)

const openMenu = (suburb) => {
  activeID.value = suburb.id
  fillObject(resource.value, suburb)
  showMenu.value = true
}
const create = () => {
  activeID.value = null
  resource.value = { ...initialResource }
  editActive.value = true
}
const edit = () => {
  resource.value = { ...pluck(props.resources).using(activeID.value).obj() }
  showMenu.value = false
  editActive.value = true
}
const submit = () => {
  if (resource.value.id) {
    put(['suburbs', resource.value.id], resource.value)
      .then(res => {
        merge('suburbs', res)
        editActive.value = false
      })
  } else {
    post(['suburbs'], { ...resource.value, map_id: props.map })
      .then(res => {
        push('suburbs', res)
        editActive.value = false
      })
  }
}
const promptDestroy = () => {
  showMenu.value = false
  deleteActive.value = true
}
const confirmDestroy = () => {
  destroy(['suburbs', activeID.value])
    .then(() => {
      forget('suburbs', activeID.value)
      deleteActive.value = false
    })
}

</script>
