<template>
  <div>
    <modal :header="$t('labels.congregation.meeting_settings')" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default="{ error }">
        <input-text :name="'name'" :label="'Name'" v-model="resource.name" :error="error" />
        <tuxedo-select
          :inputClass="'bg-gray-100'"
          :label="$t('fields.country.label')"
          :name="'country_id'"
          :options="countries"
          v-model="resource.country_id"
          :insistReturn="'id'"
          :insistLabel="'name'"
          :error="error"
        />
        <tuxedo-select
          :inputClass="'bg-gray-100'"
          :label="$t('fields.region.label')"
          :name="'region_id'"
          v-model="resource.region_id"
          :options="filteredRegions"
          :insistLabel="'name'"
          :insistReturn="'id'"
          :error="error"
        />
        <input-text :name="'suburb'" :label="'Suburb'" v-model="resource.suburb" :error="error" />
        <input-text :name="'post_code'" :label="'Zip/Post Code'" v-model="resource.postcode" :error="error" />
        <input-text :name="'address'" :label="'Address'" v-model="resource.address" :error="error" />
        <input-text :name="'circuit'" :label="'Circuit'" v-model="resource.circuit" :error="error" />
        <input-slider :name="'public_day'" :label="$t('fields.public_meeting_day.label')" v-model="resource.public_day" :options="days" :error="error" />
        <input-time :name="'public_start_time'" class="pt-3" :label="$t('fields.public_meeting_start_time.label')" :minute-step="5" v-model="resource.public_start_time" :error="error" />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { useRouter } from 'vue-router/composables'
import { post } from '@/composables/serverInterface.js'
import { filter } from 'lodash'

const props = defineProps(['active'])
const emit = defineEmits(['close'])
const router = useRouter()
const store = useCollectionsStore()

const initialResource = {
  name: null,
  country_id: null,
  region_id: null,
  suburb: null,
  postcode: null,
  address: null,
  circuit: null,
  ptc: null,
  public_day: 0,
  public_start_time: '10:00'
}

const resource = ref({ ...initialResource })

const days = ref({
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
})

const countries = store.all('countries')
const regions = store.all('regions')

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
  }
})

const filteredRegions = computed(() => {
  if (resource.value.country_id) {
    return filter(regions, r => r.country_id === parseInt(resource.value.country_id))
  }
  return regions
})

const submit = () => {
  post(['congregations'], resource.value).then(res => {
    router.push('/settings/speaker-directory/' + res.data.data.id)
    emit('saveResponse', res)
  })
}

</script>
