<template>
  <view-content :back="{path:'/settings/translations/'+iso}">

    <template v-slot:header>
      {{ $t('languages.'+iso) }} -> {{ category }}
    </template>

    <sub-menu class="mt-3">
      <sm-item v-for="(data, label) in $t(category)" :key="label" @click="editLanguageLine(label)">
        {{ label }}
        <template #description>{{ $t(category+'.'+label, iso) }}</template>
      </sm-item>
    </sub-menu>

    <EditLanguageLineForm
      :category="category"
      :line="languageLine"
      :iso="iso"
      :active="editLanguageLineFormActive"
      @close="editLanguageLineFormActive = false"
    />
  </view-content>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useRoute } from 'vue-router/composables'
import EditLanguageLineForm from '@/components/forms/EditLanguageLineForm.vue'

const route = useRoute()

const editLanguageLineFormActive = ref(null)
const languageLine = ref(null)

const iso = computed(() => route.params.iso.toLowerCase())
const category = computed(() => route.params.category)

const editLanguageLine = (label) => {
  languageLine.value = label
  editLanguageLineFormActive.value = true
}

</script>
