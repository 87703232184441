import { mergeState } from 'tuxedo-api-interface/src/module/mutations'

export const UPDATE_CONG = (state, data) => {
  state.cong = data
}

export const MERGE_BALANCE = (state, data) => {
  mergeState(state, {
    object: 'cong',
    mergeAs: 'balance',
    data: data.balance
  })
}

export const UPDATE_TIMEZONES = (state, data) => {
  state.timezones = data
}
