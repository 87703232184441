import axios from 'axios'

export const GET_MESSAGING_STATS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/messaging-stats?api_token=' + payload.token)
      .then(function (response) {
        commit('UPDATE_MESSAGING_STATS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
