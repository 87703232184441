import Vue from 'vue'
import _ from 'lodash'

export const SET_ATTENDANT_RESPONSIBILITIES = (state, data) => {
  state.responsibilities = data
}

export const UPDATE_ATTENDANT_SCHEDULE = (state, data) => {
  if (!data.id) state.schedule = data
  else {
    const key = state.schedule.findIndex(row => row.id === data.id)
    if (key >= 0) Vue.set(state.schedule, key, data)
    else state.schedule.unshift(data)
  }
}

export const UPDATE_ATTENDANT_RESPONSIBILITIES = (state, data) => {
  if (!data.id) state.responsibilities = data
  else {
    const key = state.responsibilities.findIndex(row => row.id === data.id)
    if (key >= 0) Vue.set(state.responsibilities, key, data)
    else state.responsibilities.unshift(data)
  }
}

export const DELETE_ATTENDANT_RESPONSIBILITIES = (state, data) => {
  state.responsibilities = _.remove(state.responsibilities, (r) => { return r.id !== data.id })
}
