<template>
  <div>
    <action :secondary="true" :small="true" v-if="permits['maps.dnc.add']" @click="create">
      {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.dnc', 1) }) }}
    </action>
    <div class="w-100 mt-2">
      <div v-for="(s, key) in dncStreets" :key="'ss2' + key">
        <div class="flex align-items-center justify-content-between py-1 border-t">
          <div>
            <street-label :street="s" />
            <div>
              <small>
                {{ $t('resources.count', {count: dncInStreet(s).length, resource: $tc('resources.address', dncInStreet(s).length) }) }}
              </small>
            </div>
          </div>
          <div>
            <action :default="true" :small="true" @click="$emit('expand', s.id)">
              <span v-if="expandedStreet === s.id">{{ $t('actions.collapse') }}</span>
              <span v-else>{{ $t('actions.expand') }}</span>
            </action>
          </div>
        </div>
        <div v-for="(dnc, dkey) in dncInStreet(s)" :key="'sd' + dkey">
          <div v-if="expandedStreet === s.id" class="flex align-items-center justify-content-between py-1 border-t">
            <div>
              <span v-if="dnc.unit && dnc.unit !== 0">Unit {{ dnc.unit }} / </span>
              <span>{{ dnc.number }}</span><!--
           --><span v-if="dnc.suffix && isNumber(dnc.suffix)">-{{ dnc.suffix }}</span><!--
           --><span v-else-if="dnc.suffix && isString(dnc.suffix)">{{ dnc.suffix }}</span><br>
              <small><date-only :date="dnc.date"/> <span v-if="dnc.note">({{ dnc.note }})</span></small>
            </div>
            <div>
              <button class="btn bg-gray-200 text-teal-400 btn-round rounded-full"
                      v-if="permits['maps.dnc.update'] || permits['maps.dnc.delete']"
                      @click="openMenu(dnc)" :id="'dncMenu'">
                <fa-icon :icon="['fas', 'ellipsis-v']" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <tuxedo-secondary-menu v-if="showMenu" @close="showMenu = false" :target="'dncMenu'">
      <ul class="text-lg font-sans pt-2">
        <sm-item class="justify-content-center" v-if="permits['maps.dnc.update']" @click="confirm">
          {{ $t('actions.action_resource', { action: $t('actions.confirm'), resource: $tc('resources.dnc', 1) }) }}<br>
          <template #description>{{ $t('prompts.confirm_dnc') }}</template>
        </sm-item>
        <sm-item v-if="permits['maps.dnc.delete']" :icon="['fal', 'trash']"
                 class="justify-content-center text-red-700 border-t mt-2" @click="promptDestroy">
          {{ $t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.dnc', 1) }) }}
        </sm-item>
      </ul>
    </tuxedo-secondary-menu>

    <modal :header="$t('labels.dnc.add')" :active.sync="editActive" @submit="submit">
      <template v-slot:default="{ error }">
        <tuxedo-select name="street_id" :label="$t('fields.street.label')" v-model="resource.street_id" :error="error"
                       :options="sortedStreets" insistLabel="name" inputClass="bg-gray-100" insistReturn="id" />
        <tuxedo-input name="number" :label="$t('labels.house_number.label')" type="number" v-model="resource.number"
                      inputClass="bg-gray-100" :error="error" />
        <tuxedo-input name="suffix" :label="$t('labels.house_suffix.label')" :error="error" v-model="resource.suffix"
                      :help="$t('labels.house_suffix.tip', { number: resource.house || 10, range: parseInt(resource.house || 10) + 2 })"
                      inputClass="bg-gray-100" />
        <tuxedo-input name="unit" :label="$t('labels.house_unit.label')" v-model="resource.unit" :error="error"
                      inputClass="bg-gray-100" :help="$t('labels.house_unit.tip', { number: resource.house || 10 })" />
        <tuxedo-date name="date" :label="$t('fields.date.label')" v-model="resource.date" inputClass="bg-gray-100" />
        <input-text :label="$t('fields.comment.label')" :type="'text'" v-model="resource.note" />
      </template>
    </modal>

    <delete
      :header="$t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.dnc', 1) })"
      :active.sync="deleteActive"
      :message="$t('prompts.delete', { resource: $tc('resources.dnc', 1) })"
      @submit="confirmDestroy"
    ></delete>

  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { store } from '@/store'
import { orderBy, filter, find, isString } from 'lodash'
import { put, post, destroy } from '@/composables/serverInterface'
import { merge, push, forget } from '@/composables/dataInterface'
import StreetLabel from '@/components/maps/StreetLabel'

const props = defineProps(['map', 'resources', 'expandedStreet'])

const permits = computed(() => store.state.core.permits)
const streets = computed(() => store.state.streets)

const sortedStreets = computed(() => {
  return orderBy(streets.value, ['name'])
})
const dncStreets = computed(() => {
  return filter(sortedStreets.value, s => {
    return find(props.resources, a => a.street_id === s.id)
  })
})

const initialResource = {
  id: null,
  map_id: null,
  block: null,
  street_id: null,
  number: '',
  unit: '',
  suffix: null,
  date: null,
  note: ''
}

const resource = ref(null)
const address = ref({})
const activeID = ref(null)
const showMenu = ref(false)
const editActive = ref(false)
const deleteActive = ref(false)

const dncInStreet = (s) => {
  const filtered = filter(props.resources, r => r.street_id === s.id)
  return orderBy(filtered, ['number', 'unit'])
}
const openMenu = (a) => {
  activeID.value = a.id
  address.value = a
  showMenu.value = true
}
const create = () => {
  activeID.value = null
  resource.value = { ...initialResource }
  editActive.value = true
}
const confirm = () => {
  showMenu.value = false
  put(['dnc', activeID.value])
    .then(res => merge('doNotCalls', res))
}
const submit = () => {
  post(['dnc'], { ...resource.value, map_id: props.map })
    .then(res => {
      push('doNotCalls', res)
      editActive.value = false
    })
}
const promptDestroy = () => {
  showMenu.value = false
  deleteActive.value = true
}
const confirmDestroy = () => {
  destroy(['dnc', activeID.value])
    .then(() => {
      forget('doNotCalls', address.value)
      deleteActive.value = false
    })
}
const isNumber = (n) => {
  const reg = new RegExp(/^\d+$/)
  return reg.test(n)
}
</script>
