<template>

  <div>
    <WelcomeTour />
    <AttendantsScheduleTour />
  </div>

</template>

<script setup>
import WelcomeTour from './welcome-tour'
import AttendantsScheduleTour from './attendants-schedule-tour'
</script>
