<template>

  <view-content :back="{ name: 'Schedules' }">

    <template v-slot:header>
      {{ $tc('resources.attendant', 0) }}
    </template>

    <div class="card is-blue" v-for="(meeting, key) in schedule" :key="'sched' + key">
      <div class="card-content">
        <div class="date-header">
          <div class="date">
            <span>{{ showMonth(meeting.local_date) }}</span>
            <span>{{ showDay(meeting.local_date) }}</span>
          </div>
          <div class="header">
            {{ $t('labels.type.'+meeting.type) }}
          </div>
          <div class="float-right" v-if="permits['schedules.attendants.edit']">
            <button class="btn-info btn-round" @click="editAttendants(meeting)" :id="key === 0 ? 'first-meeting' : ''">
              <fa-icon :icon="['fal', 'pencil']" />
            </button>
          </div>
        </div>
        <table class="table is-narrow is-fullwidth no-border">
          <tr v-for="attendants in orderedAttendants(meeting.attendants)" :key="'att' + attendants.id">
            <th width="100px">{{ attendants.responsibility }}</th>
            <td v-if="attendants.user">{{ publisherName(attendants.user) }}</td>
          </tr>
        </table>
      </div>
    </div>

    <portal to="modals">
    <modal :submitting="submitting" :header="'Edit Attendants'" :active.sync="attendantsFormActive" @submit="submitAttendants">
      <input-toggle class="mb-3" :label="'Show All Publishers'" v-model="showAll" :help="'Choose from all publishers in your congregation'" />
      <div v-for="res in orderedResponsibilities" :key="res.id">
        <tuxedo-select
          :name="'attendants_' + res.id"
          :label="res.label"
          :options="optionalPubs1(res)"
          v-model="meeting.attendants[res.id]"
          insistLabel="last_first"
          inputClass="bg-gray-100"
          insistReturn="id"
        />
      </div>

    </modal>
    </portal>

  </view-content>

</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { get } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'
import { Sec } from '@/mixins/sec'
import { forEach, find, orderBy } from 'lodash'

const showAll = ref(false)
const meeting = ref({
  id: null,
  attendants: {}
})
const submitting = ref(false)

const permits = computed(() => store.state.core.permits)
const schedule = computed(() => store.state.attendants.schedule)
const users = computed(() => store.state.users)
const responsibilities = computed(() => store.state.attendantResponsibilities)

const attendantsFormActive = computed({
  get () {
    return store.state.schedules.attendantsFormActive
  },
  set () {
    store.commit('CLOSE_ATTENDANTS_FORM')
  }
})
const orderedResponsibilities = computed(() => {
  return orderBy(responsibilities.value, ['order'])
})

onMounted(() => {
  get('attendant-responsibilities').then(res => {
    assign('attendantResponsibilities', res)
  })
  store.commit('BACKGROUND_LOADING', true)
  store.dispatch('GET_ATTENDANT_SCHEDULE').then(() => {
    store.commit('BACKGROUND_LOADING', false)
  })
})

const optionalPubs1 = (r) => {
  let collection = orderBy(users.value, ['last_first'])
  collection = collection.filter(user => {
    if (showAll.value) return user
    return r.attendants.includes(user.id)
  })
  return collection
}
const orderedAttendants = (collection) => {
  return orderBy(collection, ['order'])
}
const publisherName = (id) => {
  const user = find(users.value, { id: id })
  if (user) return user.full_name
  else return null
}
const editAttendants = (m) => {
  meeting.value.id = m.id
  meeting.value.attendants = {}
  forEach(m.attendants, (a) => {
    meeting.value.attendants[a.id] = a.user
  })
  store.commit('OPEN_ATTENDANTS_FORM')
}
const submitAttendants = () => {
  submitting.value = true
  store.dispatch('SUBMIT_ATTENDANT_SCHEDULE', meeting.value).then(() => {
    submitting.value = false
    store.commit('CLOSE_ATTENDANTS_FORM')
  })
}
const showMonth = (time) => {
  return (new Sec(time)).format('M')
}
const showDay = (time) => {
  return (new Sec(time)).format('jS')
}

</script>
