<template>

  <view-content v-if="forum" :back="{ name:'Settings' }">

    <template v-slot:header>
      Feature Requests
    </template>

    <template v-slot:subtext>
      <p class="mb-2">A forum to propose new Service App features.</p>
      <p class="mb-2">Proposed features helpful to many congregations will enter the Development Queue, those that are liked the most will be given development priority.</p>
      <p>Subscribe to be notified of new comments and feature requests.</p>
    </template>

    <template v-slot:actions>
      <action :primary="true" @click="create" class="mr-2 mb-2">
        Request Feature
      </action>
      <action :secondary="true" @click="subscribeToForum(forum.id, !forum.subscribed)" class="mr-2" :icon="['fal', 'bell']">
        <span v-if="!forum.subscribed">Subscribe</span>
        <span v-else>Unsubscribe</span>
      </action>
      <action :secondary="true" @click="searchForumTopics = true" class="mr-2" :icon="['fal', 'search']">
        <span>Search</span>
      </action>
    </template>

    <div v-for="r in sortedTopics" :key="'t' + r.id">
      <topic-card :topic="r" @update="update(r)" @destroy="promptDestroy(r)" />
    </div>

    <portal to="modals">
    <modal :header="'Search'" :active.sync="searchForumTopics" @submit="searchForumTopics = false" :error="null">
      <input-text :label="'Search Phrase'" :type="'text'" v-model="searchPhrase" />
    </modal>

    <modal :header="'New Feature'" :active.sync="createForumTopics" @submit="submit" :error="axiosError">
      <input-text :label="'Label'" :type="'text'" v-model="resource.label" :error="axiosError && axiosError.errors && axiosError.errors.label ? axiosError.errors.label[0] : null" />
      <input-message :label="'Description'" :type="'text'" v-model="resource.content" :error="axiosError && axiosError.errors && axiosError.errors.content ? axiosError.errors.content[0] : null" />
    </modal>

    <delete
      :header="'Delete Topic'"
      :active.sync="destroyForumTopics"
      :message="'Are you sure you wish to delete this topic?'"
      @submit="destruction"
    ></delete>
    </portal>

  </view-content>

</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { get, put, post, destroy } from '@/composables/serverInterface'
import { assign, merge, push, forget } from '@/composables/dataInterface'
import { fillObject } from '@/helpers'
import { filter, orderBy } from 'lodash'

const activeID = ref(null)
const createForumTopics = ref(false)
const destroyForumTopics = ref(false)
const searchForumTopics = ref(false)
const initialResource = {
  id: null,
  label: '',
  content: ''
}
const resource = ref({ ...initialResource })
const searchPhrase = ref(null)

const forum = computed(() => store.state.forum)
const resources = computed(() => store.state.forumTopics)
const axiosError = computed(() => store.state.core.axiosError)

const sortedTopics = computed(() => {
  let arr = resources.value
  if (searchPhrase.value) {
    arr = filter(arr, (r) => JSON.stringify(r).toLowerCase().includes(searchPhrase.value.toLowerCase()))
  }
  return orderBy(arr, 'likes', 'desc')
})

onMounted(() => {
  searchPhrase.value = null
  show()
  // this.watchScrollPos('topics')
})

const create = () => {
  resource.value = { ...initialResource }
  createForumTopics.value = true
}

const update = (u) => {
  resource.value = { ...initialResource }
  fillObject(resource.value, u)
  createForumTopics.value = true
}

const promptDestroy = (u) => {
  activeID.value = u.id
  destroyForumTopics.value = true
}

const submit = () => {
  if (resource.value.id) {
    put(['forums', 1, 'topics', resource.value.id], resource.value).then(res => {
      merge('forumTopics', res.data.data)
      createForumTopics.value = false
    })
  } else {
    post(['forums', 1, 'topics'], resource.value).then(res => {
      push('forumTopics', res)
      createForumTopics.value = false
    })
  }
}

const destruction = () => {
  destroy(['forums', 1, 'topics', activeID.value]).then(() => {
    forget('forumTopics', activeID.value)
    destroyForumTopics.value = false
  })
}

const subscribeToForum = (id, subscribe) => {
  put(['forums', 1], { subscribe: subscribe })
    .then(() => { this.show() })
}

const show = () => {
  get(['forums', 1]).then(res => {
    assign('forum', res)
  })
  get(['forums', 1, 'topics']).then(res => {
    assign('forumTopics', res)
  })
}

</script>
