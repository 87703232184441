<template>
  <div ref="topicsPrompt" class="notification is-info" :class="collapse ? 'collapsing' : ''" v-if="on">
    <button class="delete" @click="dismissAlert('subscribedTopicsActive')"></button>
    <strong>UPDATES TO TOPICS YOU'RE SUBSCRIBED TO</strong><br>
    <div v-for="topic in alerts.topics" :key="'topic' + topic.id" class="pt-1">
      <router-link :to="'/forum/topics/' + topic.id" class="text-decoration-none">
        <strong class="text-primary">{{ topic.label }}</strong>
        <small class="text-muted pl-1 pt-1">{{ topic.comments }} comments</small>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SubscribedTopicPrompt',
  data () {
    return {
      collapse: false,
      height: null
    }
  },
  mounted () {
    this.height = this.$refs.topicsPrompt ? this.$refs.topicsPrompt.clientHeight : 0
  },
  computed: {
    ...mapState({
      subscribedTopicsActive: state => state.dashboard.subscribedTopicsActive,
      cong: state => state.settings.cong,
      alerts: state => state.dashboard.alerts
    }),
    on () {
      return this.subscribedTopicsActive && this.alerts.topics
    }
  },
  methods: {
    dismissAlert (alert) {
      this.collapse = true
      this.$refs.topicsPrompt.style.height = this.height + 'px'
      setTimeout(() => {
        this.$refs.topicsPrompt.style.height = 0
        this.$refs.topicsPrompt.style.padding = 0
        this.$refs.topicsPrompt.style.margin = 0
        setTimeout(() => {
          this.collapse = false
          this.$store.dispatch('HIDE_ALERT', { alert: alert })
        }, 300)
      }, 500)
    }
  }
}
</script>
