<template>

    <view-content :back="{ name:'Settings' }">

      <template v-slot:header>
        Congregation Account
      </template>

      <template v-slot:subtext>
        <p class="mb-2">
          <strong class="text-gray-100">{{ cong.name }} Congregation</strong>
          <br>
          Current Balance: <span v-html="cong.balance"></span>
        </p>
        <p class="mb-2">You can pay the owing balance here using a credit card, alternatively payment can be made via Direct Debit. Direct Debit details are listed on each invoice.</p>
        <p class="mb-2">Credit Card transaction incur a small processing fee.</p>
        <p>Any payment made over the due amount will put the account into credit and will be used for the next invoice.</p>
      </template>

      <template v-slot:actions>
        <action class="mr-2 mb-2" :primary="true" @click="purchaseFormActive = true">
          Pay Account
        </action>
        <action v-if="false" :secondary="true" @click="prepayFormActive = true">
          Pre-Pay
        </action>
      </template>

        <div class="notification is-success" v-if="result === 'succeeded'">
            <button class="delete" @click="result = null"></button>
            <strong>Payment Successful!</strong><br>
            The payment will be reflected on the account balance soon!
        </div>

        <div class="notification is-warning" v-if="result === 'cancelled'">
            <button class="delete" @click="result = null"></button>
            <strong>Payment Cancelled!</strong><br>
        </div>

        <div class="box info-box" v-for="act in activity" :key="'act' + act.id">

            <div v-if="act.type === 'invoice'">
                <div class="heading">
                    <p>
                    <span class="icon">
                        <fa-icon :icon="['fal', 'file-invoice']" class="fa-lg" />
                    </span>
                        Invoice #{{ formatId(act.id) }}
                    </p>
                </div>
                <div class="content">
                    <p>
                        <span>Issued: {{ date(act.issued_at).format('locale') }}</span><br>
                        <span v-if="act.state === 'paid'">Paid: {{ date(act.paid_at).format('locale') }}</span>
                    <br v-if="act.state != 'open'">
                    Total: ${{ act.total / 100 }}
                    </p>
                </div>
                <div class="actions a1">
                    <span class="icon is-medium" v-if="['issued','paid','overdue'].includes(act.state)" @click="downloadInvoice(act.id)">
                        <fa-icon :icon="['fal', 'cloud-download']" class="fa-lg" />
                    </span>
                </div>
                <div class="footer">
                    <span class="tag is-capitalized" :class="formatState(act.state)">
                        {{ act.state }}
                    </span>
                </div>
            </div>

            <article v-else-if="act.type === 'payment'">
                <div class="heading">
                    <p>
                        <span class="icon">
                            <fa-icon :icon="['fal', 'money-check-edit-alt']" class="fa-lg" />
                        </span>
                        Payment (${{ act.total / 100 }})
                    </p>
                </div>
                <div class="content">
                    <p>
                        Processed: {{ date(act.paid_at).format('locale') }}<br>
                    </p>
                </div>
            </article>

            <article v-else>
                <div class="heading">
                    <p>
                        <span class="icon">
                            <fa-icon :icon="['fal', 'receipt']" class="fa-lg" />
                        </span>
                        Cost (${{ act.total / 100 }})
                    </p>
                </div>
                <div class="content">
                    <p>
                      <span>Processed: {{ date(act.paid_at).format('locale') }}</span><br>
                      <span>Description: {{ act.description }}</span>
                    </p>
                </div>
            </article>

        </div>

      <portal to="modals">
        <modal :submitting="submitting" :header="'Create Invoice'" :active.sync="prepayFormActive" @submit="createInvoice" :save="'Submit'">
          <p>Enter in the amount you wish to Pre Pay. This will generate an invoice for your congregation for this amount.</p>
          <input-text :label="'Amount in AUD'" v-model="resource.amount" type="number"></input-text>
        </modal>

        <modal :submitting="submitting" :header="'Purchase Credits'" :active.sync="purchaseFormActive" @submit="confirm" :validated="complete" :save="'Charge Card '+dollars" :icon="'lock-alt'">
          <tuxedo-select
            :label="'Payment'"
            name="type"
            :options="[{id:'owing',label:'Pay Owing Amount'},{id:'other',label:'Pay Other Amount'}]"
            v-model="type"
            inputClass="bg-gray-100"
          />
          <input-text :label="'Amount in AUD'" v-model="amount" type="number" :help="'Processing Fee: '+feeInDollars" />

            <input-text v-if="false" :label="'Email Address'" v-model="email" :help="'The email address to which a receipt will be sent'" />

            <div class="secure">
                <div class='credit-card-inputs' :class='{ complete }'>
                    <div class="field">
                        <label class="label"><fa-icon :icon="['fal', 'lock-alt']" />Credit Card Number</label>
                        <div class="control">
                            <card-number class='stripe-element card-number'
                                         ref='cardNumber'
                                         :stripe='stripe'
                                         :options='options'
                                         @change='number = $event.complete'
                            ></card-number>
                        </div>
                    </div>
                    <div class="field">
                        <label class="label"><fa-icon :icon="['fal', 'lock-alt']" />Credit Card Expiry/CSV</label>
                        <div class="control field-flex">
                            <card-expiry class='stripe-element card-expiry'
                                         ref='cardExpiry'
                                         :stripe='stripe'
                                         :options='options'
                                         @change='expiry = $event.complete'
                            ></card-expiry>
                            <card-cvc class='stripe-element card-cvc'
                                      ref='cardCvc'
                                      :stripe='stripe'
                                      :options='options'
                                      @change='cvc = $event.complete'
                            ></card-cvc>
                        </div>
                        <p class="help">Credit card transactions are handled by Stripe using serverless tokenisation, card details are encrypted and sent via a Secure Socket Layer. No credit card details are saved.</p>
                    </div>
                </div>
            </div>

            <br>
            <div class="notification is-danger" v-if="error" >
                <button class="delete" @click="error = null"></button>
                <strong>Error!</strong><br>
                {{ error }}
            </div>
        </modal>
      </portal>

    </view-content>

</template>

<script>
import { CardNumber, CardExpiry, CardCvc, createToken } from 'vue-stripe-elements-plus'
import { mapState } from 'vuex'
import { Sec } from '@/mixins/sec'

export default {
  name: 'AccountActivity',
  data () {
    return {
      type: null,
      amount: null,
      email: null,
      result: null,
      options: {
        // see https://stripe.com/docs/stripe.js#element-options for details
      },
      purchaseFormActive: false,
      prepayFormActive: false,
      submitting: false,
      number: false,
      expiry: false,
      cvc: false,
      stripe: 'pk_live_E4CMFuWfdaJqqsDTHFncuFS900rmSm7GIa',
      error: null,
      resource: {
        amount: null
      }
    }
  },
  computed: {
    ...mapState({
      cong: state => state.settings.cong,
      activity: state => state.account.activity
    }),
    fee () {
      return (parseInt(((this.amount * 100) * 1.03) + 33) - parseInt(this.amount * 100))
    },
    feeInDollars () {
      return `$${(this.fee / 100).toFixed(2)}AUD`
    },
    cents () {
      return parseInt(this.amount * 100) + parseInt(this.fee)
    },
    dollars () {
      return `$${(this.cents / 100).toFixed(2)}AUD`
    },
    complete () {
      return this.number && this.expiry && this.cvc && parseInt(this.amount) > 0
    }

  },
  components: {
    CardNumber, CardExpiry, CardCvc
  },
  created () {
    this.email = this.$store.state.email
    this.$emit('update:loading', true)
    this.$store.dispatch('GET_ACCOUNT_STATUS', { congregation: this.cong.id })
    this.$store.dispatch('GET_CONG_SETTINGS', { token: this.$store.state.core.token })
    this.$store.dispatch('GET_ACCOUNT_ACTIVITY', { congregation: this.cong.id }).then(() => {
      this.$emit('update:loading', false)
    })
  },
  methods: {
    date (time) {
      return new Sec(time)
    },
    downloadInvoice (id) {
      this.$store.dispatch('GET_INVOICE', { token: this.$store.state.core.token, invoice: id }).then(response => {

      })
    },
    update () {
      // field completed, find field to focus next
      if (this.number) {
        if (!this.expiry) {
          this.$refs.cardExpiry.focus()
        } else if (!this.cvc) {
          this.$refs.cardCvc.focus()
        }
      } else if (this.expiry) {
        if (!this.cvc) {
          this.$refs.cardCvc.focus()
        } else if (!this.number) {
          this.$refs.cardNumber.focus()
        }
      }
      // no focus magic for the CVC field as it gets complete with three
      // numbers, but can also have four
    },
    createInvoice () {
      this.submitting = true
      this.$store.dispatch('store', {
        object: 'congregations',
        id: this.cong.id,
        tag: 'accounts',
        collection: this.$options.name,
        resource: this.resource
      }).then(() => {
      })
    },
    confirm () {
      this.submitting = true
      // createToken returns a Promise which resolves in a result object with
      // either a token or an error key.
      // See https://stripe.com/docs/api#tokens for the token object.
      // See https://stripe.com/docs/api#errors for the error object.
      // More general https://stripe.com/docs/stripe.js#stripe-create-token.
      createToken().then(data => {
        console.log(data)
        if (data.error) {
          this.error = data.error.message
        } else if (data.token) {
          const purchase = {
            email: this.email,
            amount: parseInt(this.amount * 100),
            fee: parseInt(this.fee),
            token: data.token.id,
            credits: this.credits
          }
          this.$store.dispatch('CHARGE_CREDIT_CARD', { token: this.$store.state.core.token, purchase: purchase }).then((response) => {
            console.log(response)
            this.submitting = false
            if (response.data.status && response.data.status === 'succeeded') {
              this.result = response.data.status
              this.purchaseFormActive = false
              this.error = null
            } else {
              this.result = null
              this.error = 'There has been an issue processing payment on this card.'
            }
          })
        }
      })
    },
    load (load) {
      this.$emit('update:loading', load)
    },
    formatId (id) {
      return this._.padStart(id, 5, '0')
    },
    formatState (state) {
      if (state === 'open') return 'is-info'
      if (state === 'paid') return 'is-success'
      if (state === 'issued') return 'is-warning'
      if (state === 'overdue') return 'is-danger'
      return false
    }
  },
  watch: {
    number () { this.update() },
    expiry () { this.update() },
    cvc () { this.update() },
    type: function (newVal, oldVal) {
      if (newVal === 'owing') this.amount = this.cong.raw_balance.total
    }
  }
}
</script>

<style>
    .secure {
        padding: 15px;
        background: #fff8cf;
    }
    .StripeElement {
        box-shadow: none;
        background: #ffffff;
        border: 0;
        width: 100%;
        border-radius: 3px;
        color: #363636;
        padding: 6px;
        font-family: 'Open Sans', sans-serif;
    }
    .StripeElement--focus {
        box-shadow: 0 0 16px #415566;
    }
    .stripe-card.complete {
        border-color: green;
    }
</style>
