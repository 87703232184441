<template>

    <div>

        <button class="button btn-info btn-major" @click="openMeetingForm">Add Away Talk</button>

      <Accordion>
        <AccordionItem :headerClass="'bg-teal-800 text-white'">
          <template v-slot:header>
            <b class="uppercase">Order & Filter</b><br>
            <small>
              {{ $t('resources.count', {count: sortedSpeakers.length, resource: $tc('resources.speaker', sortedSpeakers.length) }) }}
            </small>
          </template>
          <div>
            <button v-for="speaker in sortedSpeakers" :key="'speaker' + speaker.id" class="button mr-1 mb-1" :class="{'is-info':filters.findIndex(row => row.value === speaker.id) >= 0 }" @click="setFilter('speaker',speaker.id)">
              <fa-icon :icon="['fal', 'filter']" />
              <span class="ml-2">{{ speaker.full_name }}</span>
            </button>
          </div>
        </AccordionItem>
      </Accordion>

      <div class="card is-blue mt-3" v-for="(talk, id) in sortedAssignedTalks" :key="'sat' + id">
            <div class="card-content">
                <div class="date-header">
                    <div class="date">
                        <span>{{ date(talk.local_date).format('jS') }}</span>
                        <span>{{ date(talk.local_date).format('M') }}</span>
                    </div>
                    <div class="header">
                        {{ talk.congregation }} Congregation
                    </div>
                </div>
                <table class="table is-narrow is-fullwidth no-border">
                    <tr>
                        <th width="100px">Speaker:</th>
                        <td>
                          <span v-if="talk.parts.talk.talk.assignee">
                            {{ talk.parts.talk.talk.assignee[1].name }}
                          </span>
                        </td>
                    </tr>
                    <tr>
                        <th width="100px">Theme:</th>
                        <td>{{ talk.parts.talk.talk.notes }}</td>
                    </tr>
                    <tr>
                        <th width="100px">Time:</th>
                        <td>{{ date(talk.local_date +' '+talk.start_time).format('h:ia, M jS Y') }}</td>
                    </tr>
                </table>
            </div>
            <div class="card-footer">
                <button class="btn btn-info" @click="promptChangeMeeting(talk)">
                    Edit
                </button>
                <button class="btn btn-success" v-if="false && !talk.parts.talk.talk.assignee[1].confirmed">
                    Confirm
                </button>
                <button class="btn btn-danger" v-if="false && !talk.parts.talk.talk.assignee[1].confirmed">
                    Deny
                </button>
                <button class="btn btn-warning" @click="promptDeleteMeeting(talk)">
                    Cancel
                </button>
            </div>
        </div>

      <portal to="modals">
        <wizard :steps="5" :step.sync="step" :active.sync="changeMeetingFormActive">

            <wizard-step v-if="step === 0"
            :title="'Assignment Date'"
            :submitting.sync="submitting"
            :active.sync="changeMeetingFormActive"
            @next="step += 1"
            :nextValid="stepValid"
            @prev="step -= 1"
            :prevValid="false">
                <datefield :label="'Date'" v-model="assignment.date"></datefield>
            </wizard-step>

            <wizard-step v-if="step === 1"
                  :title="'Find Congregation'"
                  :submitting.sync="submitting"
                  :active.sync="changeMeetingFormActive"
                  @next="nextStep"
                  :nextValid="stepValid"
                  @prev="step -= 1"
                  :prevValid="true">
              <tuxedo-select
                :label="'Congregation Country'"
                name="country"
                :options="countries"
                v-model="assignment.country"
                insistLabel="name"
                inputClass="bg-gray-100"
                insistReturn="id"
              />

              <tuxedo-select
                :label="'Congregation Region'"
                name="region"
                :options="filteredRegions"
                v-model="assignment.region"
                insistLabel="name"
                inputClass="bg-gray-100"
                insistReturn="id"
              />

              <tuxedo-select
                :label="'Congregation'"
                name="cong"
                :options="sortedCongs"
                v-model="assignment.cong"
                insistLabel="name"
                inputClass="bg-gray-100"
                insistReturn="id"
              />

            </wizard-step>

            <wizard-step v-if="step === 2"
                  :title="'Add Congregation'"
                  :submitting.sync="submitting"
                  :active.sync="changeMeetingFormActive"
                  @next="nextStep"
                  :nextValid="stepValid"
                  @prev="step = 1"
                  :prevValid="true">
                <input-text :label="'Congregation Name'" v-model="assignment.cong_name" />
                <input-text :label="'Kingdom Hall Suburb'" v-model="assignment.suburb" />
                <input-text :label="'Kingdom Hall Zip/Post Code'" v-model="assignment.postcode" />
                <input-text :label="'Kingdom Hall Address'" v-model="assignment.address" />
                <input-email :label="'PTC Email Address'" v-model="assignment.ptc" />
                <input-slider :label="'Public Meeting Day'" v-model="assignment.public_day" :options="days" />
                <input-time :label="'Public Meeting Start Time'" :minute-step="5" :name="'public_start_time'" v-model="assignment.public_start_time" />
            </wizard-step>

            <wizard-step v-if="step === 3"
                  :title="'Select Speaker'"
                  :submitting.sync="submitting"
                  :active.sync="changeMeetingFormActive"
                  @next="nextStep"
                  :nextValid="stepValid"
                  @prev="step = 1"
                  :prevValid="true">

              <input-select
                v-if="!help"
                :label="'Speaker'"
                name="speaker"
                :options="sortedSpeakers"
                v-model="assignment.speaker"
                insistLabel="last_first"
                inputClass="bg-gray-100"
                insistReturn="id"
              />

              <p v-if="help">{{ help }}</p>
            </wizard-step>

            <wizard-step v-if="step === 4"
                  :title="'Select Talk'"
                  :submitting.sync="submitting"
                  :active.sync="changeMeetingFormActive"
                  @next="nextStep"
                  :nextValid="stepValid"
                  @prev="step = 3"
                  :prevValid="true">

              <tuxedo-select
                :label="'Talk'"
                name="talk"
                :options="sortedTalks"
                v-model="assignment.talk"
                insistLabel="title"
                inputClass="bg-gray-100"
                insistReturn="id"
              >
                <template #label="{ option }">
                  {{ option.number }}. {{ $t('talks.' + option.number) }}
                </template>
              </tuxedo-select>

            </wizard-step>

            <wizard-step v-if="step === 5"
                  :title="'Confirm Assignment'"
                  :submitting.sync="submitting"
                  :active.sync="changeMeetingFormActive"
                  @next="nextStep"
                  :nextValid="true"
                  @prev="step = 4"
                  :prevValid="true">

                <table class="table">
                    <tbody>
                    <tr>
                        <th>Congregation</th>
                        <td>{{ selectedCong ? selectedCong.name+' '+selectedCong.region+', '+selectedCong.country : '' }}</td>
                    </tr>
                    <tr>
                        <th>Speaker</th>
                        <td>{{ selectedSpeaker ? selectedSpeaker.full_name : '' }}</td>
                    </tr>
                    <tr>
                        <th>Talk</th>
                        <td v-html="selectedTalk ? selectedTalk.number+'. '+selectedTalk.title : ''"></td>
                    </tr>
                    </tbody>
                </table>
                <input-toggle class="mb-3" :label="'Request Confirmation'" v-model="assignment.confirmation" :help="'This will send an email to the speaker asking them to confirm this assignment'" />
            </wizard-step>

        </wizard>

        <delete
                :submitting="submitting"
                :header="'Clear Public Talk'"
                :active.sync="deleteMeetingFormActive"
                :message="'Are you sure you wish to delete this talk?'"
                @submit="confirmDeleteMeeting()"
        ></delete>
      </portal>

    </div>

</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { store } from '@/store'
import { filter, find, findIndex, orderBy } from 'lodash'
import { clearData, validateEmail } from '@/helpers'
import Accordion from '@/components/elements/Accordion'
import AccordionItem from '@/components/elements/AccordionItem'
import { Sec } from '@/mixins/sec'
import { get } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'

const newStore = useCollectionsStore()

const assignment = ref({
  country: null,
  region: null,
  id: null,
  cong_name: null,
  suburb: null,
  postcode: null,
  address: null,
  public_day: null,
  public_start_time: null,
  date: null,
  meeting_id: null,
  new_meeting_id: null,
  cong: null,
  speaker: null,
  talk: null,
  ptc: null
})
const filters = ref([])
const step = ref(0)
const help = ref(null)
const changeMeetingFormActive = ref(false)
const deleteMeetingFormActive = ref(false)
// const loading = ref(false)
const submitting = ref(false)
// const emailValid = ref(true)
const days = {
  0: 'Sun',
  1: 'Mon',
  2: 'Tue',
  3: 'Wed',
  4: 'Thu',
  5: 'Fri',
  6: 'Sat'
}

onMounted(() => {
  get(['world', 'congregations'])
  get('away-talks').then(res => assign('awayTalks', res))
})

const congs = computed(() => store.state.congregations)
const pubs = computed(() => store.state.users)
const talks = computed(() => store.state.publicTalkTitles)
const cong = computed(() => store.state.settings.cong)
const countries = computed(() => newStore.all('countries'))
const regions = computed(() => newStore.all('regions'))
const assignedTalks = computed(() => store.state.awayTalks)

const filteredRegions = computed(() => {
  if (assignment.value.country) {
    return filter(regions.value, r => r.country_id === parseInt(assignment.value.country))
  }
  return regions.value
})

const stepValid = computed(() => {
  if (step.value === 0 && assignment.value.date) return true
  if (step.value === 1) {
    if (assignment.value.country && assignment.value.region && assignment.value.cong) return true
  }
  if (step.value === 2) {
    if (assignment.value.cong_name &&
      assignment.value.region &&
      assignment.value.postcode &&
      assignment.value.address &&
      validateEmail(assignment.value.ptc) &&
      assignment.value.public_start_time) return true
  }
  if (step.value === 3 && assignment.value.speaker) return true

  if (step.value === 4 && assignment.value.talk) return true
  return false
})

// const speakerValid = computed(() => {
//   if (assignment.value.date &&
//     assignment.value.cong &&
//     assignment.value.speaker &&
//     assignment.value.talk) return true
//   return false
// })

const sortedCongs = computed(() => {
  return congs.value.filter(cong => {
    return parseInt(cong.country_id) === parseInt(assignment.value.country) && parseInt(cong.region_id) === parseInt(assignment.value.region)
  })
})

const selectedCong = computed(() => {
  return find(congs.value, (cong) => {
    return parseInt(cong.id) === parseInt(assignment.value.cong)
  })
})

const selectedSpeaker = computed(() => {
  return find(pubs.value, (speaker) => {
    return parseInt(speaker.id) === parseInt(assignment.value.speaker)
  })
})

const selectedTalk = computed(() => {
  return find(talks.value, (talk) => {
    return parseInt(talk.id) === parseInt(assignment.value.talk)
  })
})

const sortedSpeakers = computed(() => {
  const speakers = []
  pubs.value.forEach((speaker) => {
    speaker.qualified_parts.forEach((part) => {
      if (part.id === 15) {
        if (!find(speakers, ['id', speaker.id])) {
          speakers.push(speaker)
        }
      }
    })
  })
  return speakers
})

const sortedTalks = computed(() => {
  return orderBy(talks.value, ['number'])
})

const sortedAssignedTalks = computed(() => {
  return filter(assignedTalks.value, talk => {
    if (filters.value.length > 0) {
      return filters.value.findIndex(row => row.value === talk.parts.talk.talk.assignee[1].id) >= 0
    } else {
      return talk
    }
  })
})

const date = (time) => {
  return new Sec(time)
}

const nextStep = () => {
  submitting.value = true
  if (step.value === 1 && parseInt(assignment.value.cong) !== -1) {
    loadCongMeeting()
    submitting.value = false
    step.value = 3
  } else if (step.value === 1 && parseInt(assignment.value.cong) === -1) {
    assignment.value.cong = null
    step.value = 2
    submitting.value = false
  } else if (step.value === 2) {
    store.dispatch('SUBMIT_NEW_CONGREGATION', {
      token: store.state.core.token,
      cong: assignment.value
    }).then((res) => {
      assignment.value.cong = res.data.id
      loadCongMeeting()
      submitting.value = false
      step.value += 1
    })
  } else if (step.value === 3 && assignment.value.speaker) {
    store.dispatch('GET_PUBLIC_TALK_TITLES', {
      token: store.state.core.token
    }).then(() => {
      submitting.value = false
      step.value = 4
    })
  } else if (step.value === 4 && assignment.value.talk) {
    store.dispatch('GET_CONGREGATION_PTC', {
      token: store.state.core.token,
      cong: assignment.value
    }).then(() => {
      assignment.value.ptc = null
      submitting.value = false
      step.value = 5
    })
  } else if (step.value === 5) {
    store.dispatch('SUBMIT_AWAY_SPEAKER', { token: store.state.core.token, talk: assignment.value }).then(() => {
      store.dispatch('GET_AWAY_TALKS', { token: store.state.core.token })
      submitting.value = false
      changeMeetingFormActive.value = false
    })
  }
}

const openMeetingForm = () => {
  clearData(assignment.value)
  assignment.value.country = cong.value.country_id
  assignment.value.region = cong.value.region_id
  step.value = 0
  changeMeetingFormActive.value = true
}

const promptChangeMeeting = (talk) => {
  step.value = 0
  assignment.value.id = talk.parts.talk.talk.assignee[1].assignment
  assignment.value.date = talk.local_date
  assignment.value.meeting_id = talk.id
  assignment.value.country = cong.value.country_id
  assignment.value.region = cong.value.region_id
  assignment.value.cong = talk.parts.talk.talk.assignee[1].cong
  assignment.value.speaker = talk.parts.talk.talk.assignee[1].id
  const id = findIndex(sortedTalks.value, (t) => {
    return t.title === talk.parts.talk.talk.notes.replace(/[0-9]+.\s/g, '')
  })
  if (id !== -1) {
    assignment.value.talk = sortedTalks.value[id].id
  }
  changeMeetingFormActive.value = true
}

const promptDeleteMeeting = (talk) => {
  assignment.value.id = talk.parts.talk.talk.assignee[1].assignment
  deleteMeetingFormActive.value = true
}

const loadCongMeeting = () => {
  store.dispatch('GET_CONGREGATION_MEETING', { cong: assignment.value }).then((response) => {
    if (response.data) assignment.value.new_meeting_id = response.data.data.id
    else assignment.value.new_meeting_id.value = null
    if (response.data.data &&
      response.data.data.parts.talk.talk.assignee &&
      response.data.data.parts.talk.talk.assignee[1].assignee_cong_id !== cong.value.id
    ) {
      help.value = "This congregation already has a public speaker for that date, you can't add a new one."
    } else help.value = null
  })
}

const confirmDeleteMeeting = () => {
  submitting.value = true
  store.dispatch('CLEAR_PUBLIC_SPEAKER', { meeting: { id: assignment.value.id } }).then(() => {
    get('away-talks').then(res => assign('awayTalks', res))
    submitting.value = false
    deleteMeetingFormActive.value = false
  })
}

const setFilter = (name, value) => {
  const key = filters.value.findIndex(row => row.value === value)
  if (key >= 0) {
    filters.value.splice(key, 1)
  } else {
    filters.value.unshift({ name: name, value: value })
  }
}

</script>
