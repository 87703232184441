<template>
  <span>{{ display }}</span>
</template>

<script setup>
import { computed } from 'vue'
import { Sec } from '@/mixins/sec'

const props = defineProps(['date'])

const display = computed(() => {
  const time = new Sec(props.date)
  return time.format('locale')
})

</script>
