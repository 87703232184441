<template>
  <div>
    <modal :header="'Edit Language Line'"
           :active="active"
           @submit="submit"
           @close="emit('close')"
    >
      <template v-slot:default="{ error }">
        <div v-for="(field, label) in resource" :key="label">
          <template v-if="typeof resource[label] !== 'object'">
            <input-message :label="label" :error="error" v-model="resource[label]" :help="$t(category + '.' + label, 'en')" />
          </template>
          <template v-else>
            <div v-for="(field2, label2) in resource[label]" :key="label + label2">
              <template v-if="typeof resource[label][label2] !== 'object'">
                <input-message v-model="resource[label][label2]" :label="label + ' ' + label2" :error="error" :help="$t(category + '.' + label + '.' + label2, 'en')" />
              </template>
              <template v-else>
                <div v-for="(field3, label3) in resource[label][label2]" :key="label + label2 + label3">
                  <input-message v-model="resource[label][label2][label3]" :label="label + ' ' + label2 + ' ' + label3" :error="error" :help="$t(category + '.' + line + '.' + label2 + '.' + label3, 'en')" />
                </div>
              </template>
            </div>
          </template>
        </div>
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { post } from '@/composables/serverInterface.js'
import { i18n, requestLanguage } from '@/i18n'

const props = defineProps(['active', 'iso', 'category', 'line'])
const emit = defineEmits(['close'])

const resource = ref({})

watch(() => props.active, async (active) => {
  resource.value = {}
  if (active === true) {
    const target = i18n.t(props.category + '.' + props.line, props.iso)
    if (typeof target === 'object') {
      resource.value[props.line] = { ...target }
    } else {
      resource.value[props.line] = target
    }
  }
})

const submit = () => {
  post(['lang', props.iso, props.category], resource.value)
    .then(() => {
      requestLanguage(props.iso, false)
    })
}

</script>
