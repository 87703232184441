<template>

  <div class="field">
    <label class="label">{{ label }}</label>
    <div class="control">
      <flat-pickr class="input" @input="updateValue(inputTime)" :config="config" v-model="inputTime"></flat-pickr>
      <p class="help" v-if="help">{{ help }}</p>
    </div>
  </div>

</template>

<script setup>
import flatPickr from 'vue-flatpickr-component'
import 'flatpickr/dist/flatpickr.css'
import { ref, watch } from 'vue'

const emit = defineEmits(['input'])
const props = defineProps(['label', 'help', 'value'])

const inputTime = ref(null)
const config = ref({
  dateFormat: 'Y-m-d H:i:S',
  enableTime: true
})

const updateValue = (value) => {
  emit('input', value)
}

watch(() => props.value, (newVal) => {
  inputTime.value = newVal
})

</script>
