export const mapsRemoteUpdatedAt = (state) => {
  return state.cong.maps_updated_at
}

export const pubsRemoteUpdatedAt = (state) => {
  return state.cong.pubs_updated_at
}

export const eventsRemoteUpdatedAt = (state) => {
  return state.cong.events_updated_at
}
