import { i18n } from '@/i18n.js'
import { camelToSnake, singular } from '../helpers'

export function useLabels (resource) {
  resource = camelToSnake(resource)
  return {
    name: i18n.tc('resources.' + camelToSnake(singular(resource)), 0),
    create: i18n.t('actions.action_resource', { action: i18n.t('actions.create'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    edit: i18n.t('actions.action_resource', { action: i18n.t('actions.edit'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    delete: i18n.t('actions.action_resource', { action: i18n.t('actions.delete'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    send: i18n.t('actions.action_resource', { action: i18n.t('actions.send'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    view: i18n.t('actions.action_resource', { action: i18n.t('actions.view'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    assign: i18n.t('actions.action_resource', { action: i18n.t('actions.assign'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    download: i18n.t('actions.action_resource', { action: i18n.t('actions.download'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    upload: i18n.t('actions.action_resource', { action: i18n.t('actions.upload'), resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) })
  }
}

export function useMessages (resource) {
  resource = camelToSnake(resource)
  return {
    delete: i18n.t('messages.delete_resource', { resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 1) }),
    loading: i18n.t('messages.simple_loading', { resource: i18n.tc('resources.' + camelToSnake(singular(resource)), 0) })
  }
}
