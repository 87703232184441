<template>
  <div ref="fieldGroupPrompt" class="notification is-group" :class="collapse ? 'collapsing' : ''" v-if="alerts.group && alerts.group.location && nextGroupActive">
    <button class="delete" @click="dismissAlert('nextGroupActive')"></button>
    <div v-if="!alerts.group.online" v-html="
            $t('notifications.next_group', {
                time: alerts.group.meeting_time,
                label: alerts.group.label,
                location: alerts.group.location
            })"></div>
    <div v-if="alerts.group.online" v-html="
            $t('notifications.next_group_online', {
                time: alerts.group.meeting_time,
                label: alerts.group.label,
                url: alerts.group.location
            })"></div>
    <div class="mt-3 display-flex justify-content-end" style="margin-right:-25px">
      <a :href="alerts.group.location" target="_blank" class="button btn-sm btn-success">{{ $t('labels.button.join') }}</a>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'FieldGroupPrompt',
  data () {
    return {
      collapse: false,
      height: null
    }
  },
  mounted () {
    this.height = this.$refs.fieldGroupPrompt ? this.$refs.fieldGroupPrompt.clientHeight : 0
  },
  computed: {
    ...mapState({
      alerts: state => state.dashboard.alerts,
      nextGroupActive: state => state.dashboard.nextGroupActive
    })
  },
  methods: {
    dismissAlert (alert) {
      this.collapse = true
      this.$refs.fieldGroupPrompt.style.height = this.height + 'px'
      setTimeout(() => {
        this.$refs.fieldGroupPrompt.style.height = 0
        this.$refs.fieldGroupPrompt.style.padding = 0
        this.$refs.fieldGroupPrompt.style.margin = 0
        setTimeout(() => {
          this.collapse = false
          this.$store.dispatch('HIDE_ALERT', { alert: alert })
        }, 300)
      }, 500)
    }
  }
}
</script>
