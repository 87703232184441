<template>
    <div class="modal" :class="{ 'is-active': active }">
        <div class="modal-background"></div>
        <div class="modal-card">
            <progress class="progress is-info" :value="progress" max="100"></progress>
            <div class="card overflow-y-auto">
                <slot></slot>
            </div>
        </div>
    </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps(['step', 'steps', 'active'])

const progress = computed(() => {
  return (props.step / props.steps) * 100
})

</script>

<style lang="less">

    .spinner {
        font-size: 30px;
        width: 100%;
        text-align: center;

        p {
            font-size: 12px;
        }
    }

</style>
