<template>
  <router-link to="/settings/reports/" class="notification is-link text-center" v-if="on">
    <strong>{{ $t('notifications.report', { month: new Sec().subtractMonths(1).format('M').toUpperCase() }) }}</strong>
  </router-link>
</template>

<script setup>
import { computed } from 'vue'
import { store } from '@/store'
import { useProfileStore } from '@/stores/profile'
import { Sec } from '@/mixins/sec'

const profile = useProfileStore()

const modules = computed(() => store.state.settings.cong.modules)
const alerts = computed(() => store.state.dashboard.alerts)

const on = computed(() => {
  return modules.value &&
    modules.value.field_service_reports &&
    modules.value.field_service_reports.on &&
    !alerts.value.report &&
    profile.user.appointment !== 'guest' &&
    profile.user.appointment !== 'student'
})

</script>
