<template>

    <div class="top-sliding-nav">
        <ul v-if="!loading" v-pan="slide" ref="list">
            <li v-for="(option, key) in options" :key="'opt' + key" :ref="'opt' + key">
                <button class="btn-clear" :class="active(key)" @click="updateValue(key)">{{ option }}</button>
            </li>
        </ul>
        <progress class="progress is-info" :value="loadPercent" max="100" v-if="loading"></progress>
    </div>

</template>

<script setup>
import { computed, ref, watch, onMounted } from 'vue'
import { TweenMax, Elastic } from 'gsap'

const props = defineProps(['value', 'options', 'loading'])
const emit = defineEmits(['input'])

const loadPercent = ref(0)
const currentOffset = ref(0)
const list = ref(null)

const overflowRatio = computed(() => {
  return list.value.scrollWidth / list.value.offsetWidth
})

const itemWidth = computed(() => {
  if (list.value) {
    return list.value.scrollWidth / Object.keys(props.options).length
  } return 100
})

const count = computed(() => {
  return Object.keys(props.options).length
})

watch(() => props.loading, val => {
  loadPercent.value = 0
  load()
})

onMounted(() => {
  load()
})

const updateValue = (value) => {
  emit('input', value)
}

const load = () => {
  const timer = setInterval(() => {
    if (loadPercent.value < 100) {
      loadPercent.value++
    } else if (loadPercent.value === 100) {
      slide()
      clearInterval(timer)
      loadPercent.value++
    }
  }, 30)
}

const active = (key) => {
  if (props.value === undefined || key === undefined) return ''
  if ((props.value || props.value === 0) && props.value.toString() === key.toString()) return 'active'
  return ''
}

const slide = (e) => {
  if (!e) {
    e = list.value
    if (e) {
      const selected = e.getElementsByClassName('active')[0]
      if (selected) {
        if (selected.parentElement.offsetLeft < (e.offsetWidth - 100)) return
        e.deltaX = -selected.parentElement.offsetLeft
        e.isFinal = true
      }
    }
  }
  if (!e || !e.deltaX) return
  const dragOffset = 100 / itemWidth.value * e.deltaX / count.value * overflowRatio.value
  // console.log(this.count);
  // console.log(this.overflowRatio);
  // console.log(dragOffset);
  const transform = currentOffset.value + dragOffset
  list.value.style.setProperty('--x', transform)
  if (e.isFinal) {
    currentOffset.value = transform
    const maxScroll = 100 - overflowRatio.value * 100
    let finalOffset = currentOffset.value

    // scrolled to last item
    if (currentOffset.value <= maxScroll) {
      finalOffset = maxScroll
    } else if (currentOffset.value >= 0) {
      // scroll to first item
      finalOffset = 0
    } else {
      // animate to next item according to pan direction
      const index = currentOffset.value / overflowRatio.value / 100 * count.value
      const nextIndex = e.deltaX <= 0 ? Math.floor(index) : Math.ceil(index)
      finalOffset = 100 * overflowRatio.value / count.value * nextIndex
    }

    // bounce back animation
    TweenMax.fromTo(
      list.value,
      0.4,
      { '--x': currentOffset.value },
      {
        '--x': finalOffset,
        ease: Elastic.easeOut.config(1, 0.8),
        onComplete: () => {
          currentOffset.value = finalOffset
        }
      }
    )
  }
}

</script>
