<template>
  <div>
    <modal :header="$t('labels.speaker_directory.speaker')" :active="active" @submit="submit" @close="emit('close')">
      <template v-slot:default="{ error }">
        <input-text :label="$t('fields.name.label')" :name="'full_name'" v-model="resource.full_name" :error="error" />
        <input-email :label="$t('fields.email.label')" :name="'email'" v-model="resource.email" :error="error" />
        <input-text :label="$t('fields.mobile.label')" :name="'phone'" v-model="resource.phone" :error="error" />
        <input-slider :label="''" :name="'appointment'" v-model="resource.appointment" :options="{'elder': 'Elder', 'servant': 'Ministerial Servant'}" :error="error" />
      </template>
    </modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { put, post } from '@/composables/serverInterface.js'

const props = defineProps(['congregation', 'speaker', 'active'])
const emit = defineEmits(['close'])

const initialResource = {
  id: null,
  congregation_id: null,
  full_name: null,
  email: null,
  phone: null,
  appointment: null
}

const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    if (props.speaker) {
      Object.keys(resource.value).forEach(key => {
        if (props.speaker[key]) resource.value[key] = props.speaker[key]
      })
    }
  }
})

const submit = () => {
  resource.value.congregation_id = props.congregation.id
  if (resource.value.id) {
    put(['congregations', props.congregation.id, 'speakers', resource.value.id], resource.value)
      .then(res => {
        emit('saveResponse', res)
      })
  } else {
    post(['congregations', props.congregation.id, 'speakers'], resource.value)
      .then(res => {
        emit('saveResponse', res)
      })
  }
}

</script>
