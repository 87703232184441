import Vue from 'vue'
import { store } from '@/store.js'
import { singular } from '@/helpers'

export function merge (collection, object, targetUsing) {
  // Determine which property we'll use to match an object in the collection with the object being passed in
  targetUsing = targetUsing ?? 'id'

  // Determine the actual data layer for the response
  while ('data' in object) object = object.data

  // Determine if this collection uses an internal data property which is common for API collections
  const internalData = Object.hasOwn(store.state[collection], 'data')

  let collectionKey = -1
  if (internalData) collectionKey = store.state[collection].data.findIndex(o => o[targetUsing] === object[targetUsing])
  else collectionKey = store.state[collection].findIndex(o => o[targetUsing] === object[targetUsing])

  // The merge doesn't overwrite the entire object in the collection, it only merges or overwrites the properties of
  // the object being passed in, so we iterate over the object properties and set them individually
  if (collectionKey >= 0) {
    Object.keys(object).forEach(key => {
      if (internalData) Vue.set(store.state[collection][collectionKey].data, key, object[key])
      else Vue.set(store.state[collection][collectionKey], key, object[key])
    })
  }
}

export function assign (collection, data) {
  if (!data) return

  // Determine the actual data layer for the response
  while ('data' in data) {
    data = data.data
  }

  // Just overwrite the store collection with data received from the API
  Vue.set(store.state, collection, data)
}

/**
 * Attaches data to objects in a collection based on a foreign key. Where a collection would have nested objects.
 *
 * For instance: attach(res).as('current_report').to('users') Would attach a current_report object to a user in the
 * collection based upon 'res'.'user_id' matching 'user'.'id'
 *
 * @param {Array|Object} data - The data to be attached.
 * @return {Object} - An object with a chainable API to specify the attribute and collection to attach the data to.
 */
export function attach (data) {
  // Determine the actual data layer for the response
  while ('data' in data) {
    data = data.data
  }

  // If we were given a single object, then we place it in an array so the process will work
  if (!Array.isArray(data)) data = [data]

  const context = {
    data,
    attribute: null,
    collection: null
  }

  return {
    as: (attribute) => {
      context.attribute = attribute
      return {
        to: (collection) => {
          const foreignKey = singular(collection) + '_id'
          context.data.forEach(r => {
            // Find the object in the store based on the provided foreign key
            const obj = store.state[collection].find(o => o.id === r[foreignKey])

            // If the object was found, then attach the attribute to it
            if (obj) Vue.set(obj, attribute, r)
          })
        }
      }
    }
  }
}

export function push (collection, obj) {
  // Determine the actual data layer for the response
  while ('data' in obj) obj = obj.data

  let data = store.state[collection]
  if (Array.isArray(obj)) data = data.concat(obj)
  else data.push(obj)
  Vue.set(store.state, collection, data)
}

export function forget (collection, object, targetUsing) {
  // Determine which property we'll use to match an object in the collection with the object being passed in
  targetUsing = targetUsing ?? 'id'

  let collectionKey = -1
  collectionKey = store.state[collection].findIndex(o =>
    o[targetUsing] === (typeof object === 'object' ? object[targetUsing] : object)
  )

  Vue.delete(store.state[collection], collectionKey)
}

export function forgetAttribute (collection, object, attribute, targetUsing) {
  // Determine which property we'll use to match an object in the collection with the object being passed in
  targetUsing = targetUsing ?? 'id'

  // Find the index key of the object in the collection
  let collectionKey = -1
  collectionKey = store.state[collection].findIndex(o =>
    o[targetUsing] === (typeof object === 'object' ? object[targetUsing] : object)
  )

  // If the object was found in the collection, delete the attribute
  Vue.delete(store.state[collection][collectionKey], attribute)
}

export function clearCache () {
  for (const key in store.state.core.localCache) {
    if (Object.hasOwnProperty.call(store.state.core.localCache, key)) {
      delete store.state.core.localCache[key]
    }
  }
}

export function downloadXLSX (response) {
  const filename = response.headers['content-disposition'].split('=')

  // It is necessary to create a new blob object with mime-type explicitly set
  // otherwise only Chrome works like it should
  const newBlob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })

  // IE doesn't allow using a blob object directly as link href
  // instead it is necessary to use msSaveOrOpenBlob
  if (window.navigator && window.navigator.msSaveOrOpenBlob) {
    window.navigator.msSaveOrOpenBlob(newBlob)
    return
  }

  // For other browsers:
  // Create a link pointing to the ObjectURL containing the blob.
  const data = window.URL.createObjectURL(newBlob)
  const link = document.createElement('a')
  link.href = data
  link.download = filename[1].replace(/"/g, '')
  link.click()
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data)
  }, 100)
}
