export const state = {
  alerts: {
    report: true
  },
  public_meeting: {},
  nextGroupActive: true,
  nextCartActive: true,
  publicTalkActive: true,
  bannerActive: true,
  onlineMeetingActive: true,
  subscribedTopicsActive: true,
  subscribePromptActive: true
}
