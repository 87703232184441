<template>
  <div class="flex align-items-center justify-content-between py-1 border-t relative">
    <AddressLabel :address="address" />
    <home-animation v-if="addressSubmit.includes('d' + address.id)" class="absolute right-10" :active="'home'" />
    <home-animation v-if="addressSubmit.includes('i' + address.id)" class="absolute right-10" :active="'nh'" />
    <div v-if="addressSubmit.includes('i' + address.id) || addressSubmit.includes('d' + address.id)" class="flex">
      <undo-timer :ms="5700" @click="cancel(address.id)" />
    </div>
    <div v-else>
      <button @click="deleteAddress(address.id)" class="btn btn-success btn-round mr-1">
        <fa-icon :icon="['fal', 'thumbs-up']" />
      </button>
      <button @click="updateAddress(address.id)" class="btn btn-warning btn-round">
        <fa-icon :icon="['fal', 'thumbs-down']" />
      </button>
    </div>
  </div>

</template>

<script setup>
import { ref } from 'vue'
import { put, destroy } from '@/composables/serverInterface'
import { forget, merge } from '@/composables/dataInterface'
import AddressLabel from '@/components/maps/AddressLabel'
import HomeAnimation from '@/components/animations/HomeAnimation'

defineProps(['address'])
const emit = defineEmits(['addressDeleted', 'addressUpdated'])

const addressSubmit = ref([])

const deleteAddress = (a) => {
  addressSubmit.value.push('d' + a)
  setTimeout(() => {
    if (addressSubmit.value.includes('d' + a)) {
      destroy(['available-territory', a])
        .then(() => {
          const key = addressSubmit.value.indexOf('d' + a)
          addressSubmit.value.splice(key, 1)
          forget('availableAddresses', a)
          emit('addressDeleted', a)
        })
    }
  }, 5000)
}

const updateAddress = (a) => {
  addressSubmit.value.push('i' + a)
  setTimeout(() => {
    if (addressSubmit.value.includes('i' + a)) {
      put(['available-territory', 'increment'], { id: a })
        .then(res => {
          const key = addressSubmit.value.indexOf('i' + a)
          addressSubmit.value.splice(key, 1)
          merge('availableAddresses', res)
          emit('addressUpdated', a)
        })
    }
  }, 5000)
}

const cancel = (a) => {
  addressSubmit.value.splice(addressSubmit.value.indexOf('d' + a), 1)
  addressSubmit.value.splice(addressSubmit.value.indexOf('i' + a), 1)
}

</script>
