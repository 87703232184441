import axios from 'axios'

export const GET_MEETING_ATTENDANCE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/meeting-schedule/' + payload.year + '/attendance')
      .then(function (response) {
        commit('UPDATE_ATTENDANCE', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_MEETING_WEEK_ATTENDANCE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/meeting-schedule/week-attendance', payload)
      .then(function (response) {
        commit('UPDATE_ATTENDANCE', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
