<template>

  <div>
    <card :icon="['fal', 'user-tie']" :color="part.meeting" class="mb-3" :time="chairman ? '' : 'range'"
          :start="part.startTime" :end="endTime" :minutes="chairman ? '' : part.time"
          :congregation="partCongregation"
          :actionSlot="edit"
          :reference="part.references || part.notes" :loading="timeThroughPart">
      <template v-slot:header><span v-html="partTitle"></span></template>
      <template v-slot:badges>
        <div class="flex align-items-center">
          <div style="width: 85px" v-if="partCanHaveSecondSchool && halls > 1">Main Class</div>
          <assignee v-if="partCanBeAssigned" :part="part" :hall="hall" @assign="assign" />
          <helper v-if="partRequiresHelper" :part="part" :hall="hall" @assign="assign" />
        </div>
        <div class="flex mt-1" v-if="partCanHaveSecondSchool && halls > 1">
          <div style="width: 85px">Aux Class 1</div>
          <assignee v-if="partCanBeAssigned" :part="part" :hall="2" @assign="assign" />
          <helper v-if="partRequiresHelper" :part="part" :hall="2" @assign="assign" />
        </div>
        <div class="flex mt-1" v-if="partCanHaveSecondSchool && halls > 2">
          <div style="width: 85px">Aux Class 2</div>
          <assignee v-if="partCanBeAssigned" :part="part" :hall="3" @assign="assign" />
          <helper v-if="partRequiresHelper" :part="part" :hall="3" @assign="assign" />
        </div>
      </template>
      <template v-slot:actions>
        <div v-if="edit">
          <action :primary="true" :small="true" @click="popupMenu = !popupMenu" :id="'editPart' + part.id">{{ $t('actions.edit') }}</action>
        </div>
      </template>

    </card>

    <tuxedo-secondary-menu v-if="popupMenu" @close="popupMenu = false" :target="'editPart' + part.id">
      <ul class="text-lg font-sans p-2 text-left">
        <sm-item class="justify-content-center" @click="insertPart(part)">
          <span>
            {{ $t('actions.action_resource', { action: $t('actions.insert'), resource: $tc('resources.meeting_part', 1) }) }}
          </span>
        </sm-item>
        <sm-item class="justify-content-center" @click="editPart">
          <span>
            {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.meeting_part', 1) }) }}
          </span>
        </sm-item>
        <sm-item @click="destroyPart" :icon="['fal', 'trash']" class="justify-content-center text-red-700 border-t mt-2">
          {{ labels.delete }}
        </sm-item>
      </ul>
    </tuxedo-secondary-menu>

    <modal v-if="edit" :submitting="submitting" :header="'Edit Meeting Part'" :active.sync="editPartActive" @submit="submitPart">
      <template v-slot:default="{ error }">
        <tuxedo-input :inputClass="'bg-gray-100'" :label="$t('labels.meetings.title')" :name="'title'" v-model="resource.title" :error="error" />
        <tuxedo-input :inputClass="'bg-gray-100'" :label="$t('labels.minutes.label')" :help="$t('labels.minutes.tip')" :type="'number'" :name="'time'" v-model="resource.time" :error="error" />
        <tuxedo-input :inputClass="'bg-gray-100'" :label="$t('labels.references.label')" :help="$t('labels.references.tip')" :name="'references'" v-model="resource.references" :error="error" />
        <tuxedo-select :inputClass="'bg-gray-100'" :label="$t('labels.part_type.label')" :name="'user_meeting_part_id'"
                       v-model="resource.user_meeting_part_id" :options="parts" :insistReturn="'id'" :insistLabel="'part'"
                       :help="$t('labels.part_type.tip')"
                       :insistDisplay="resource.user_meeting_part_id ? $t('labels.meetings.' + find(parts, 'id', resource.user_meeting_part_id, 'part')) : ''"
                       :error="error"
        >
          <template v-slot:label="{ option }">
            {{ $t('labels.meetings.' + option.part) }}
          </template>
        </tuxedo-select>
        <tuxedo-select :inputClass="'bg-gray-100'" :label="$t('labels.helper.label')" :name="'helper_type'"
                       v-model="resource.helper_type" :help="$t('labels.helper.tip')" :options="helperTypes"
                       :insistReturn="'type'"
                       :insistDisplay="resource.helper_type ? $t('labels.meetings.' + resource.helper_type) : ''"
                       :error="error"
        >
          <template v-slot:label="{ option }">
            {{ $t('labels.meetings.' + option.type) }}
          </template>
        </tuxedo-select>
        <tuxedo-select v-if="resource.helper_type !== 'none'" :inputClass="'bg-gray-100'" :name="'helper_meeting_part_id'"
                       :label="$t('labels.helper_type.label')" v-model="resource.helper_meeting_part_id" :options="parts"
                       :insistReturn="'id'" :insistLabel="'part'" :help="$t('labels.helper_type.tip')"
                       :insistDisplay="resource.helper_meeting_part_id ? $t('labels.meetings.' + find(parts, 'id', resource.helper_meeting_part_id, 'part')) : ''"
                       :error="error"
        >
          <template v-slot:label="{ option }">
            {{ $t('labels.meetings.' + option.part) }}
          </template>
        </tuxedo-select>
      </template>
    </modal>

    <delete
      :submitting="submitting"
      :header="labels.delete"
      :active.sync="deletePartActive"
      :message="$t('messages.delete_resource', { resource: $tc('resources.meeting_part', 1) })"
      @submit="destruction"
    ></delete>

  </div>

</template>

<script>
import { mapState } from 'vuex'
import { i18n } from '@/i18n'
import { resetData, fillObject } from '../../../mixins/helpers'
import { put, post, destroy } from '../../../mixins/api'
import { merge, assign, forget } from '../../../mixins/storeMutations'

export default {
  name: 'Part',
  props: {
    part: {
      required: false
    },
    chairman: {
      required: false
    },
    hall: {
      required: false,
      default () {
        return 1
      }
    },
    edit: {
      required: false
    },
    halls: {
      required: false
    }
  },
  mixins: [put, post, merge, assign, destroy, forget, resetData, fillObject],
  data () {
    return {
      resource: {
        id: null,
        title: null,
        time: null,
        references: null,
        meeting: null,
        order: null,
        user_meeting_part_id: null,
        helper_meeting_part_id: null,
        helper_type: 'none'
      },
      partResource: {
        id: null,
        user_id: null,
        hall: 1,
        confirmed: true
      },
      helperTypes: [
        { type: 'none' },
        { type: 'householder' },
        { type: 'reader' },
        { type: 'student' }
      ],
      popupMenu: false,
      editPartActive: false,
      deletePartActive: false,
      now: new Date(),
      labels: {
        delete: this.$t('actions.action_resource', { action: this.$t('actions.delete'), resource: this.$tc('resources.meeting_part', 1) })
      }
    }
  },
  created () {
    setInterval(() => {
      this.now = new Date()
    }, 1000)
  },
  computed: {
    ...mapState({
      users: state => state.core.pubs,
      parts: state => state.parts,
      submitting: state => state.tuxedo.submitting
    }),
    partCanBeAssigned () {
      if (this.part.meeting === 'prelude' && this.part.part === 'comments' && !this.chairman) return false
      if (this.part.meeting === 'talk' && this.part.part === 'song' && !this.chairman) return false
      if (this.part.meeting === 'living' && this.part.part === 'mid-song') return false
      if (this.part.meeting === 'living' && this.part.part === 'review') return false
      if (this.part.meeting === 'watchtower' && this.part.part === 'mid-song') return false
      return true
    },
    partCanHaveSecondSchool () {
      if (this.part.meeting === 'treasures' && this.part.part === 'reading') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'first' && !this.part.title.includes('Discussion')) return true
      if (this.part.meeting === 'ministry' && this.part.part === 'second') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'third') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'fourth') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'study') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'talk') return true
      return false
    },
    partRequiresHelper () {
      if (this.part.meeting === 'ministry' && this.part.time > 5) return false
      if (this.part.meeting === 'ministry' && this.part.references && this.part.references.includes('Talk.')) return false
      if (this.part.meeting === 'ministry' && this.part.title === 'Talk') return false
      if (this.part.meeting === 'ministry' && this.part.part === 'first' && !this.part.title.includes('Discussion')) return true
      if (this.part.meeting === 'ministry' && this.part.part === 'second') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'third') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'fourth') return true
      if (this.part.meeting === 'ministry' && this.part.part === 'study') return true
      if (this.part.meeting === 'living' && this.part.part === 'study') return true
      if (this.part.meeting === 'watchtower' && this.part.part === 'discussion') return true
      return false
    },
    partHasHelper () {
      return this.part && this.part.assignments && this.part.assignments[0] && this.part.assignments[0].helper_id
    },
    partCongregation () {
      return this.part && this.part.assignments && this.part.assignments[0] && this.part.assignments[0].speaker_congregation
    },
    partTitle () {
      if (this.chairman && this.hall === 1) return i18n.t('labels.meetings.chairman')
      else if (this.chairman && this.hall === 2) return i18n.t('labels.meetings.aux_counselor')
      else if (this.chairman && this.hall === 3) return i18n.t('labels.meetings.aux_counselor_2')
      else if (this.part.meeting === 'talk' && this.part.part === 'talk' && this.part.assignments[0]) return this.part.assignments[0].notes
      return this.part.title
    },
    endTime () {
      const seconds = (parseInt(this.part.time) * 60)
      return new Date(this.part.startTime.getTime() + (seconds * 1000))
    },
    timeThroughPart () {
      if (this.part.startTime) {
        if (this.now.getTime() >
          this.part.startTime.getTime() &&
          this.now.getTime() <
          this.endTime.getTime()) {
          const percentageComplete = (this.now.getTime() - this.part.startTime.getTime()) / (this.endTime.getTime() - this.part.startTime.getTime()) * 100
          return (Math.round(percentageComplete * 100) / 100)
        }
      }
      return 0
    }
  },
  methods: {
    partHasStudent (part) {
      if (part.meeting === 'ministry') {
        if (part.title.includes('Video')) return false
        if (part.title.includes('Apply Yourself')) return false
        if (part.title.includes('Talk')) return false
        if (part.title.includes('Discussion')) return false
        return true
      } else if (part.meeting === 'treasures' && part.part === 'reading') return true
      return false
    },
    insertPart (siblingPart) {
      this.resetData('resource')
      this.resource.meeting = siblingPart.meeting
      this.resource.order = siblingPart.order + 1
      this.popupMenu = false
      this.editPartActive = true
    },
    editPart () {
      this.resetData('resource')
      this.fillObject(this.resource, this.part)
      this.popupMenu = false
      this.editPartActive = true
    },
    submitPart () {
      if (this.resource.id) {
        this.put(['meetings', this.part.meeting_id, 'parts', this.resource.id], this.resource)
          .then(res => {
            if (res.data.data.meeting_id !== this.part.meeting_id) {
              this.$parent.$emit('reload')
            } else {
              this.merge('meetingPartsStore', res.data.data, 'original_id')
            }
            this.editPartActive = false
          })
      } else {
        this.post(['meetings', this.part.meeting_id, 'parts'], this.resource)
          .then(res => {
            if (res.data.data.meeting_id !== this.part.meeting_id) {
              this.$parent.$emit('reload')
            } else {
              this.assign('meetingPartsStore', res.data.data)
            }
            this.editPartActive = false
          })
      }
    },
    destroyPart () {
      this.resetData('resource')
      this.fillObject(this.resource, this.part)
      this.popupMenu = false
      this.deletePartActive = true
    },
    destruction () {
      this.destroy(['meetings', this.part.meeting_id, 'parts', this.part.id])
        .then(() => {
          this.forget('meetingPartsStore', this.part, 'id')
          this.deletePartActive = false
        })
    },
    assign (assignment, hall, type) {
      if (this.part.meeting === 'talk' && this.part.part === 'talk') return
      this.$emit('assign', this.part, hall || 1, assignment, type || 'main')
    }
  }
}
</script>
