<template>
  <div class="relative pt-1 w-100 bg-white rounded-md shadow-md max-w-screen-sm mx-auto mt-2" v-if="queue.message">
    <div class="flex items-center justify-between px-2 py-1">
      <div>
      <span class="text-xs font-semibold inline-block uppercase rounded-full text-teal-darkest">
        {{ queue.message }}
      </span>
      </div>
      <div class="text-right">
      <span class="text-xs font-semibold inline-block text-teal-darkest">
        <animated-number :value="state.completed" :formatValue="formatToPercent" :duration="400" />
      </span>
      </div>
    </div>
    <div class="h-2 text-xs flex bg-gray-200">
      <div :style="'width:' + state.completed + '%'" class="rounded-md bg-teal transition-all relative progress-glow"></div>
    </div>
  </div>
</template>

<script setup>
import { onMounted, reactive, computed, watch } from 'vue'
import { store } from '@/store'
import AnimatedNumber from 'animated-number-vue'

const props = defineProps({
  queue: Object
})

const state = reactive({
  completed: 0,
  avgTime: computed(() => calculateAvgTime(props.queue))
})

const axiosTiming = computed(() => store.state.tuxedo.axiosTiming)

watch(() => props.queue, (newQueue) => {
  state.avgTime = calculateAvgTime(newQueue)
})

onMounted(() => {
  const interval = 100
  const increment = 100 / (state.avgTime * 1000 / interval)
  const timer = setInterval(() => {
    state.completed += increment
    if (state.completed >= 100) {
      state.completed = 100
      clearInterval(timer)
    }
  }, interval)
})

const calculateAvgTime = (queue) => {
  if (queue && axiosTiming.value[queue.url]) {
    let time = 0
    axiosTiming.value[queue.url].forEach(value => {
      time += value
    })
    return time / axiosTiming.value[queue.url].length
  } else return 10
}

const formatToPercent = (value) => `${value.toFixed(0)}%`
</script>

<style>
.progress-glow:before {
  position: absolute;
  display: block;
  content: " ";
  right: 0;
  width: 100%;
  max-width: 50px;
  height: 8px;
  box-shadow: 5px 0 10px #21ced9, 10px 0 8px #ffffff;
  background: linear-gradient(to left, #a3e7ec 0%, transparent 100%);
}
</style>
