<template>
  <div>
    <div class="alert is-info m-0 mb-2">
      <div class="icon">
        <fa-icon :icon="['fal','exclamation']" class="fa" />
      </div>
      <div class="alert-title">{{ $t('notifications.cart_report.title') }}</div>
      <div class="alert-content">
        {{ $t('notifications.cart_report.description',
        {
          location: schedule.location,
          date: schedule.date,
          time: schedule.time
        }
      ) }}
      </div>
      <div class="alert-tools">
        <button class="button btn-sm btn-warning mr-1" @click="promptNoShow">
          <span>{{ $t('notifications.cart_report.cancel') }}</span>
        </button>
        <button class="button btn-sm btn-default" @click="submitCartReport">
          <span>{{ $t('notifications.cart_report.submit') }}</span>
        </button>
      </div>
    </div>

    <modal :header="$t('notifications.cart_report.title')" :active.sync="cartReportFormActive" @submit="confirmCartReport">
      <input-integer :label="$t('fields.placements.label')" :type="'number'" v-model="report.placements" />
      <input-integer :label="$t('fields.spiritual_conversations.label')" :type="'number'" v-model="report.conversations" />
      <input-slider :label="$t('fields.foot_traffic.label')" v-model="report.traffic" :options="traffic" />
      <input-message :label="$t('fields.comments.label')" v-model="report.comments" />
    </modal>

    <modal
      :header="$t('notifications.cart_report.cancel')"
      :active.sync="noShowActive"
      :message="$t('notifications.cart_report.cancel_confirm')"
      @submit="confirmNoShow"
    ></modal>

  </div>

</template>

<script setup>
import { ref } from 'vue'
import { put } from '@/composables/serverInterface'
import { clearData } from '@/helpers'
import { store } from '@/store'

const props = defineProps(['schedule'])

const report = ref({
  placements: null,
  conversations: null,
  traffic: 2,
  comments: ''
})
const traffic = ref({
  1: 'Low',
  2: 'Moderate',
  3: 'High'
})
const cartReportFormActive = ref(false)
const noShowActive = ref(false)

const promptNoShow = () => {
  this.noShowActive = true
}
const submitCartReport = () => {
  clearData(report.value)
  cartReportFormActive.value = true
}
const confirmCartReport = () => {
  put(['cart-schedules', props.schedule.id, 'report'], report.value).then(() => {
    store.dispatch('GET_ASSIGNMENTS')
    cartReportFormActive.value = false
  })
}
const confirmNoShow = () => {
  this.destroy(['cart-schedules', this.schedule.id]).then(() => {
    store.dispatch('GET_ASSIGNMENTS')
    noShowActive.value = false
  })
}

</script>
