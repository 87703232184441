import Vue from 'vue'
import { i18n } from './i18n'

Vue.filter('shortMonth', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  return i18n.t('time.shortMonth.' + dt.getMonth())
})

Vue.filter('jS', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  const date = dt.getDate()
  const dateSuffix = i18n.t('time.dateSuffix.' + dt.getDate())
  return `${date}${dateSuffix}`
})

Vue.filter('j', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  const date = dt.getDate()
  return `${date}`
})

Vue.filter('ljS', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  const day = i18n.t('time.day.' + dt.getDay())
  const date = dt.getDate()
  const dateSuffix = i18n.t('time.dateSuffix.' + dt.getDate())
  return `${day} ${date}${dateSuffix}`
})

Vue.filter('M_jS', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  const date = dt.getDate()
  const month = i18n.t('time.shortMonth.' + dt.getMonth())
  const dateSuffix = i18n.t('time.dateSuffix.' + dt.getDate())
  return `${month} ${date}${dateSuffix}`
})

Vue.filter('M_j', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  const date = dt.getDate()
  const month = i18n.t('time.shortMonth.' + dt.getMonth())
  return `${month} ${date}`
})

Vue.filter('M_jS_Y', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  const date = dt.getDate()
  const month = i18n.t('time.shortMonth.' + dt.getMonth())
  const dateSuffix = i18n.t('time.dateSuffix.' + dt.getDate())
  const year = dt.getFullYear()
  return `${month} ${date}${dateSuffix}, ${year}`
})

Vue.filter('Y', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  const year = dt.getFullYear()
  return `${year}`
})

Vue.filter('time', function (dt) {
  if (typeof dt === 'string') dt = new Date(dt)
  let hour = dt.getHours()
  let meridiem = 'am'
  if (hour > 12) {
    hour -= 12
    meridiem = 'pm'
  } else if (hour === 12) {
    meridiem = 'pm'
  }
  const minute = dt.getMinutes()
  return `${hour}:${String(minute).padStart(2, '0')}${meridiem}`
})
