import Vue from 'vue'

export const SET_FIELD_SERVICE_LOCATIONS = (state, data) => {
  if (!data.id) state.locations = data
  else {
    const key = state.locations.findIndex(row => row.id === data.id)
    if (key >= 0) Vue.set(state.locations, key, data)
    else state.locations.unshift(data)
  }
}
