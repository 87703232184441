<template>
  <div v-if="assignment">
    <section class="assignment">
      <card :start="start.time" :end="end.time" :date="assignment.multi_day ? 'range' : true" :time="'range'"
            :color="'convention'" :location="assignment.location" class="mb-5">
        <template v-slot:header>
          {{ $t('labels.events.convention') }}
        </template>
        <template v-slot:content>
          {{ assignment.label }}
        </template>
      </card>
    </section>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { Sec } from '@/mixins/sec'

const props = defineProps(['assignment'])

const start = computed(() => {
  return new Sec(props.assignment.start_date)
})
const end = computed(() => {
  return new Sec(props.assignment.end_date)
})

</script>
