<template>
  <div v-if="state">
    <svg class="checkmark" :class="checkmarkClass" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
      <circle class="checkmark__circle" :class="circleClass" cx="26" cy="26" r="25" fill="none"/>
      <path class="checkmark__check" :class="checkClass" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
    </svg>
  </div>
</template>

<script setup>
import { computed } from 'vue'

const props = defineProps(['state'])

const checkmarkClass = computed(() => {
  return props.state === 'complete' ? 'checkmark-stroke' : ''
})
const circleClass = computed(() => {
  if (props.state === 'processing') return 'circle-processing'
  if (props.state === 'complete') return 'circle-stroke'
  return null
})
const checkClass = computed(() => {
  return props.state === 'complete' ? 'check-stroke' : ''
})

</script>

<style>
.checkmark__circle{
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 4;
  stroke-miterlimit: 10;
  stroke: #16a34a;
  fill: none;
  transform-origin: center;
}
.circle-processing {
  animation: processing 0.6s cubic-bezier(0.65, 0, 0.45, 1) infinite forwards
}
.circle-stroke {
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards
}
.checkmark {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: block;
  stroke-width: 4;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0 0 0 #16a34a;
}
.checkmark-stroke {
  animation: fill .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both
}
.checkmark__check{
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
}
.check-stroke {
  animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards
}
@keyframes processing {
  0% {
    transform: rotateZ(0);
    stroke-dashoffset: 110;
  }
  50% {
    stroke-dashoffset: 130;
  }
  100% {
    transform: rotateZ(360deg);
    stroke-dashoffset: 110;
  }
}
@keyframes stroke{
  100% {stroke-dashoffset: 0 }
}
@keyframes scale{
  0%, 100%{transform: none}
  50%{transform: scale3d(1.1, 1.1, 1)}
}
@keyframes fill{
  100%{box-shadow: inset 0 0 0 30px #16a34a}
}
</style>
