import Vue from 'vue'
import VueI18n from 'vue-i18n'
import axios from 'axios'

const baseURL = process.env.VUE_APP_URL + '/'

Vue.use(VueI18n)

export const i18n = new VueI18n({
  locale: 'en',
  fallbackLocale: 'en'
})

const loadedLanguages = []

function setI18nLanguage (lang) {
  i18n.locale = lang
  axios.defaults.headers.common['Accept-Language'] = lang
  document.querySelector('html').setAttribute('lang', lang)
  return lang
}

export function requestLanguage (lang, set = true) {
  lang = lang.toLocaleLowerCase()
  return new Promise((resolve, reject) => {
    axios.get(baseURL + 'api/lang/' + lang)
      .then(res => {
        i18n.setLocaleMessage(lang, res.data)
        loadedLanguages.push(lang)
        if (set) setI18nLanguage(lang)
        resolve()
      })
  })
}
