<template>
  <modal :submitting="submitting" :header="'Print Attendants Schedule'" :active="active" @close="emit('close')" @submit="submit" :save="'Generate PDF'" :validated="true">
    <input-text :label="'Header'" :type="'text'" v-model="resource.print_header" :help="'This will be used as the heading of the print out. Eg: '+settings.cong.name+' Attendants Schedule'" />
    <input-message :label="'Notes'" v-model="resource.print_notes" :help="'These notes will appear at the bottom of the schedule printout and can include things like extra instructions.'" />
    <input-toggle :label="'Compact Print'" v-model="resource.compact" :help="'Generate a compact PDF printout, useful if you have included a lot of printable notes.'" />
  </modal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { download } from '@/composables/serverInterface'
import { store } from '@/store'

const props = defineProps(['active'])
const emit = defineEmits(['close'])

const settings = computed(() => store.state.settings)
const submitting = ref(false)
const resource = ref({
  print_header: false,
  print_notes: null,
  compact: null
})

watch(() => props.active, async (active) => {
  if (active) {
    const config = settings.value.cong.modules.attendants_schedule
    Object.keys(resource.value).forEach(key => {
      if (config[key]) resource.value[key] = config[key]
    })
  }
})

const submit = () => {
  submitting.value = true
  download(['generate-attendant-schedule'], { modules: { attendants_schedule: resource.value } })
    .then(() => {
      submitting.value = false
      emit('close')
    })
    .catch(() => {
      submitting.value = false
    })
}

</script>
