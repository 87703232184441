import Vue from 'vue'
import _ from 'lodash'

export const SET_PUBLIC_TALK_SUBJECTS = (state, data) => {
  state.public_talk_subjects = data
}

export const SET_PUBLIC_TALK_TITLES = (state, data) => {
  state.public_talks = data
}

export const ADD_PUBLIC_TALK_TITLE = (state, data) => {
  state.public_talks.push(data)
}

export const REMOVE_PUBLIC_TALK_TITLE = (state, data) => {
  const id = _.findIndex(state.public_talks, (talk) => {
    return talk.id === data.id
  })
  Vue.delete(state.public_talks, id)
}
