<template>

  <view-content :back="{ name:'Groups' }">

    <template v-slot:header>
      {{ group ? group.name : '' }}
    </template>

    <template v-slot:subtext>
      Overseer: {{ group && group.overseer_id ? find(users, 'id', group.overseer_id, 'full_name') : '' }}
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="permits['settings.groups.edit']" @click="addPub">
        Add Publisher
      </action>
    </template>

    <table class="table is-fullwidth align-middle">
      <tr v-for="(user, key) in assignedPubs" :key="'ap' + key">
        <td>
          <span class="last-name">{{ user.last_name }}, </span>
          <span class="first-name">{{ user.first_name }}</span>
          <span v-if="user.appointment === 'elder'"><i> (Elder)</i></span>
          <span v-if="user.appointment === 'servant'"><i> (Servant)</i></span>
        </td>
        <td class="tools t1" v-if="permits['settings.groups.edit']">
          <button @click="promptRemovePub(user)" class="btn btn-danger btn-round">
            <fa-icon :icon="['fal', 'trash']" />
          </button>
        </td>
      </tr>
    </table>

    <portal to="modals">
      <modal :submitting="submitting" :header="'Add Publisher'" :active.sync="assignFormActive" @submit="submitPub()" :validated="formValid">
        <div class="field">
          <label class="label">Publisher</label>
          <div class="control">
            <div class="select is-multiple">
              <select multiple size="6" v-model="form.users">
                <option v-for="user in optionalPubs" :key="'op' + user.id" :value="user.id" :selected="form.users && form.users.indexOf(parseInt(user.id)) != -1">{{ user.last_first }}</option>
              </select>
            </div>
          </div>
        </div>
      </modal>

      <delete
        :submitting="submitting"
        :header="'Remove Publisher'"
        :active.sync="activePromptRemovePub"
        :message="'Are you sure you wish to remove '+user.first_name+' '+user.last_name+' from this list?'"
        @submit="confirmRemovePub()"
      ></delete>
    </portal>

  </view-content>

</template>
<script setup>
import { ref, computed } from 'vue'
import { store } from '@/store'
import { orderBy } from 'lodash'
import { get } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'
import { useRoute } from 'vue-router/composables'

const route = useRoute()
const groupID = route.params.id
const form = ref({
  group: groupID,
  users: []
})
const user = ref({
  first_name: null,
  last_name: null
})
const submitting = ref(false)
const assignFormActive = ref(false)
const activePromptRemovePub = ref(false)

const users = computed(() => store.state.users)
const groups = computed(() => store.state.groups)
const permits = computed(() => store.state.core.permits)
const formValid = computed(() => !!form.value.users)
const group = computed(() => {
  const group = groups.value.filter(group => parseInt(group.id) === parseInt(groupID))
  return group.pop()
})
const optionalPubs = computed(() => {
  let us = orderBy(users.value, ['last_first'])
  us = us.filter(user => {
    if (user.group_id && user.group_id > 0) return false
    if (user.appointment === 'guest') return false
    return user
  })
  return us
})
const assignedPubs = computed(() => {
  let us = orderBy(users.value, ['last_first'])
  us = us.filter(user => parseInt(user.group_id) === parseInt(groupID))
  return us
})

const addPub = () => {
  assignFormActive.value = true
  form.value.users = []
}
const submitPub = () => {
  submitting.value = true
  store.dispatch('ASSIGN_TO_GROUP', {
    form: form.value
  }).then(() => {
    get('users', { force: true }).then(res => {
      assign('users', res)
      submitting.value = false
      assignFormActive.value = false
    })
  })
}
const promptRemovePub = (u) => {
  Object.keys(user.value).forEach(key => {
    if (u[key]) user.value[key] = u[key]
  })
  form.value.users = [u.id]
  activePromptRemovePub.value = true
}
const confirmRemovePub = () => {
  submitting.value = true
  store.dispatch('REMOVE_FROM_GROUP', {
    token: store.state.core.token,
    form: form.value
  }).then(() => {
    submitting.value = false
    activePromptRemovePub.value = false
  })
}

</script>
