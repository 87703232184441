<template>
  <div class="clam relative" v-if="startTime" :class="templateClass">

    <reset-schedule :schedule="schedule" @reload="$emit('reload')" />

    <meeting-part v-if="chairmanPart" :part="chairmanPart" :hall="1" :chairman="true" @assign="assign" />
    <meeting-part v-if="chairmanPart && activeHalls > 1" :part="chairmanPart" :hall="2" :chairman="true" @assign="assign" />
    <meeting-part v-if="chairmanPart && activeHalls > 2" :part="chairmanPart" :hall="3" :chairman="true" @assign="assign" />

    <meeting-part v-for="part in preludeParts" :key="'part' + part.id" :part="part" :edit="edit" @assign="assign" />

    <div class="treasures" v-if="treasureParts.length">
      <div class="heading mb-3 shadow-md">{{ $t('schedules.clm.treasures') }} <span class="clock">{{ treasureStart | time }}</span></div>
      <meeting-part v-for="part in treasureParts" :key="'part' + part.id" :part="part" :edit="edit" :halls="activeHalls" @assign="assign" />
    </div>

    <div class="ministry" v-if="ministryParts.length">
      <div class="heading mb-3 shadow-md">{{ $t('schedules.clm.ministry') }} <span class="clock">{{ ministryStart | time }}</span></div>
      <meeting-part v-for="part in ministryParts" :key="'part' + part.id" :part="part" :edit="edit" :halls="activeHalls" @assign="assign" />
    </div>

    <div class="living" v-if="livingParts.length">
      <div class="heading mb-3 shadow-md">{{ $t('schedules.clm.living') }} <span class="clock">{{ livingStart | time }}</span></div>
      <meeting-part v-for="part in livingParts" :key="'part' + part.id" :part="part" :edit="edit" @assign="assign" />
    </div>

    <attendance-form v-if="this.cong.modules.meeting_attendance.on && permits['settings.meetings.attendance']" :attendance="schedule.attendance" :meeting="schedule.id" />

    <meeting-assignment
      :part="part"
      :hall="assignmentHall"
      :assignment="assignment"
      :assignmentType="assignmentType"
      :active.sync="assignmentFormActive" />

  </div>
</template>

<script>
import { mapState } from 'vuex'
import ResetSchedule from './ResetSchedule'

export default {
  name: 'MeetingParts',
  components: {
    'reset-schedule': ResetSchedule
  },
  props: ['edit', 'halls', 'loading', 'schedule', 'meeting', 'day'],
  data () {
    return {
      loadDirection: 'forward',
      part: {},
      assignmentHall: 1,
      assignment: {},
      assignmentType: 'main',
      assignmentFormActive: false
    }
  },
  computed: {
    ...mapState({
      schedules: state => state.meetings,
      resources: state => state.meetingParts,
      cong: state => state.settings.cong,
      permits: state => state.core.permits
    }),
    templateClass () {
      if (!this.loading && this.meeting === 'p') return 'animate-backward-out'
      if (this.loading) return 'animate-' + this.loadDirection + '-out'
      else if (this.startTime) return 'animate-' + this.loadDirection + '-in'
      else return 'hidden'
    },
    parts () {
      return this._.orderBy(this.resources, ['order'], ['asc'])
    },
    activeHalls () {
      const halls = []
      if (this.chairmanPart && this.chairmanPart.assignments) {
        this.chairmanPart.assignments.forEach(a => {
          if (a.user_id || a.helper_id) halls.push(a.hall)
        })
      }
      if (this.treasureParts) {
        this.treasureParts.forEach(p => {
          if (p.assignments) {
            p.assignments.forEach(a => {
              if (a.user_id || a.helper_id) halls.push(a.hall)
            })
          }
        })
      }
      if (this.ministryParts) {
        this.ministryParts.forEach(p => {
          if (p.assignments) {
            p.assignments.forEach(a => {
              if (a.user_id || a.helper_id) halls.push(a.hall)
            })
          }
        })
      }
      const length = this._.uniq(halls).length
      if (this.halls > 1) return Math.min(length + 1, 3)
      else return length
    },
    startTime () {
      if (this.schedule) {
        const date = new Date(this.schedule.start_date)
        const currentDay = date.getDay()
        const distance = parseInt(this.cong.modules.meeting_schedule.clam_day) - currentDay
        date.setDate(date.getDate() + distance)
        const time = this.cong.modules.meeting_schedule.clam_start_time.split(':')
        date.setHours(time[0], time[1], 0)
        return date
      } return null
    },
    treasureStart () {
      let minutes = 0
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.preludeParts)) {
        minutes += parseInt(value.time)
      }
      return new Date(this.startTime.getTime() + (minutes * 60000))
    },
    ministryStart () {
      let minutes = 0
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.treasureParts)) {
        minutes += parseInt(value.time)
      }
      minutes += 1
      return new Date(this.treasureStart.getTime() + (minutes * 60000))
    },
    livingStart () {
      let minutes = 0
      // eslint-disable-next-line no-unused-vars
      for (const [key, value] of Object.entries(this.ministryParts)) {
        minutes += parseInt(value.time)
        if (this.partHasStudent(value)) minutes += 1
      }
      return new Date(this.ministryStart.getTime() + (minutes * 60000))
    },
    chairmanPart () {
      return this._.find(this.parts, r => { return r.meeting === 'prelude' && r.part === 'comments' })
    },
    preludeParts () {
      const parts = this._.filter(this.parts, r => { return r.meeting === 'prelude' })
      let minutes = 0
      parts.forEach(p => {
        p.startTime = new Date(this.startTime.getTime() + (minutes * 60000))
        minutes += parseInt(p.time)
      })
      return parts
    },
    treasureParts () {
      const parts = this._.filter(this.parts, r => { return r.meeting === 'treasures' })
      let minutes = 0
      parts.forEach(p => {
        p.startTime = new Date(this.treasureStart.getTime() + (minutes * 60000))
        minutes += parseInt(p.time)
      })
      return parts
    },
    ministryParts () {
      const parts = this._.filter(this.parts, r => { return r.meeting === 'ministry' })
      let minutes = 0
      parts.forEach(p => {
        p.startTime = new Date(this.ministryStart.getTime() + (minutes * 60000))
        minutes += parseInt(p.time)
        if (this.partHasStudent(p)) minutes += 1
      })
      return parts
    },
    livingParts () {
      const parts = this._.filter(this.parts, r => { return r.meeting === 'living' })
      let minutes = 0
      parts.forEach(p => {
        p.startTime = new Date(this.livingStart.getTime() + (minutes * 60000))
        minutes += parseInt(p.time)
      })
      return parts
    }
  },
  watch: {
    day: function (newVal, oldVal) {
      if (this.meeting === 'cl') {
        if (parseInt(newVal) > parseInt(oldVal)) this.loadDirection = 'backward'
        else this.loadDirection = 'forward'
      }
    },
    meeting: function (newVal, oldVal) {
      if (oldVal === 'p') this.loadDirection = 'forward'
      else this.loadDirection = 'backward'
    }
  },
  methods: {
    assign (part, hall, assignment, type) {
      console.log('trigger')
      this.part = part
      this.assignment = assignment
      this.assignmentType = type || 'main'
      this.assignmentHall = hall || 1
      this.assignmentFormActive = true
    },
    partHasStudent (part) {
      if (part.meeting === 'ministry') {
        if (part.title.includes('Video')) return false
        if (part.title.includes('Apply Yourself')) return false
        if (part.title.includes('Talk')) return false
        if (part.title.includes('Discussion')) return false
        if (part.references && part.references.includes('Discussion')) return false
        return true
      } else if (part.meeting === 'treasures' && part.part === 'reading') return true
      return false
    }
  }
}
</script>
