<template>
  <div class="notification is-default" style="padding-right: 10px;" v-if="profile && !profile.privacy_consent && !accepting">
    <div><strong>{{ $t('notifications.privacy_consent.title') }}</strong></div>
    <div v-html="$t('notifications.privacy_consent.statement')"></div>
    <div class="mt-3 display-flex justify-content-end">
      <button class="button is-success" @click="accept">{{ $t('notifications.privacy_consent.accept') }}</button>
      <button class="button is-text" @click="declineActive = true">{{ $t('notifications.privacy_consent.decline') }}</button>
    </div>

    <update
      :submitting="submitting"
      :header="$t('notifications.privacy_consent.decline')"
      :active.sync="declineActive"
      :message="$t('notifications.privacy_consent.decline_warn')"
      @submit="decline"
    ></update>

  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PrivacyPrompt',
  data () {
    return {
      accepting: false,
      submitting: false,
      declineActive: false
    }
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  methods: {
    accept () {
      this.accepting = true
      this.$store.dispatch('ACCEPT_PRIVACY_POLICY')
    },
    decline () {
      this.submitting = true
      this.$store.dispatch('DECLINE_PRIVACY_POLICY').then(() => {
        this.$parent.$parent.authenticate()
      })
    }
  }
}
</script>
