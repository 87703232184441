import Vue from 'vue'
import _ from 'lodash'

export const UPDATE_MAPS = (state, data) => {
  if (!data.id) {
    state.maps = data
  } else {
    const key = state.maps.findIndex(row => row.id === data.id)
    if (key >= 0) Vue.set(state.maps, key, data)
    else state.maps.unshift(data)
  }
}

export const UPDATE_MAP = (state, data) => {
  state.map = data
}

export const UPDATE_MAP_SUBURBS = (state, data) => {
  if (!data.id) {
    state.suburbs = data
  } else {
    const key = state.suburbs.findIndex(row => row.id === data.id)
    if (key >= 0) state.suburbs[key] = data
    else state.suburbs.unshift(data)
  }
}

export const UPDATE_MAP_STREETS = (state, data) => {
  if (!data.id) {
    state.streets = data
  } else {
    const key = state.streets.findIndex(row => row.id === data.id)
    if (key >= 0) state.streets[key] = data
    else state.streets.unshift(data)
  }
}

export const UPDATE_MAP_PRELOADED_TERRITORY = (state, data) => {
  _.forEach(data, (street) => {
    const key = state.streets.findIndex((s) => { return parseInt(s.id) === parseInt(street.id) })
    state.streets[key].preloaded = street.addresses
  })
}

export const UPDATE_MAP_FOREIGN_TERRITORY = (state, data) => {
  state.foreign = data
}

export const ADD_TO_PRELOADED_TERRITORY = (state, data) => {
  _.forEach(data, (add) => {
    const street = state.streets.find((s) => { return parseInt(s.id) === parseInt(add.street) })
    if (street) {
      const key = street.preloaded.findIndex((f) => {
        return parseInt(f.id) === parseInt(add.id)
      })
      if (key >= 0) {
        Vue.set(street.preloaded, key, add)
      } else {
        street.preloaded.push(add)
      }
    }
  })
}

export const UPDATE_PRELOADED_TERRITORY = (state, data) => {
  const street = state.streets.find((s) => { return parseInt(s.id) === parseInt(data.street) })
  if (street) {
    const key = street.preloaded.findIndex((f) => {
      return parseInt(f.id) === parseInt(data.id)
    })
    if (key >= 0) {
      Vue.set(street.preloaded, key, data)
    } else {
      street.preloaded.push(data)
    }
  }
}

export const ADD_TO_FOREIGN_TERRITORY = (state, data) => {
  _.forEach(data, (add) => {
    const street = state.streets.find((s) => { return parseInt(s.id) === parseInt(add.street) })
    const key = street.foreign.findIndex((f) => { return parseInt(f.id) === parseInt(add.id) })
    if (key >= 0) {
      Vue.set(street.foreign, key, add)
    } else {
      street.foreign.push(add)
    }
  })
}

export const DELETE_FROM_PRELOADED_TERRITORY = (state, data) => {
  const street = state.streets.find((s) => { return parseInt(s.id) === parseInt(data.street) })
  street.preloaded = _.remove(street.preloaded, (a) => { return a.id !== data.id })
}

export const DELETE_FROM_FOREIGN_ADDRESSES = (state, data) => {
  const street = state.streets.find((s) => { return parseInt(s.id) === parseInt(data.street) })
  street.foreign = _.remove(street.foreign, (a) => { return a.id !== data.id })
}

export const UPDATE_MAP_TYPES = (state, data) => {
  state.types = data
}
