<template>
  <div>
    <modal :header="'Assign'" @submit="submit" :active="active" @close="emit('update:active', false)">
      <div class="display-flex mb-2">
        <action :secondary="true" :small="true"
                @click="orderMenu = true"
                :id="'orderActionPart' + part.id"
                class="mr-2 mb-2" :icon="['fal', 'arrow-alt-down']">
          <span>Sort By</span>
        </action>
        <action :secondary="true" :small="true"
                @click="filterMenu = true"
                :id="'filterActionPart' + part.id"
                class="mr-2 mb-2" :icon="['fal', 'filter']">
          <span>Filter</span>
        </action>
        <tuxedo-secondary-menu v-if="orderMenu" @close="orderMenu = false" :target="'orderActionPart' + part.id">
          <ul class="text-lg m-4">
            <sm-item class="justify-content-center" @click="reorderPubs('last_name','asc')">Name (A-Z)</sm-item>
            <sm-item class="justify-content-center" @click="reorderPubs('last_name','desc')">Name (Z-A)</sm-item>
            <sm-item class="justify-content-center" @click="reorderPubs('part','desc')">Time since last giving this part</sm-item>
          </ul>
        </tuxedo-secondary-menu>
        <tuxedo-secondary-menu v-if="filterMenu" @close="filterMenu = false" :target="'filterActionPart' + part.id">
          <ul class="text-lg m-4">
            <sm-item class="justify-content-center" @click="filterPubs([1],[], [])">Show only Brothers</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([2],[], [])">Show only Sisters</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([1],['elder', 'servant'], [])">Show only Appointed Brothers</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([1],['student', 'unbaptised', 'publisher'], [])">Show only non-Appointed Brothers</sm-item>
            <sm-item class="justify-content-center" @click="filterPubs([],['student', 'unbaptised'], [])">Show only Students and Unbaptised Pubs</sm-item>
            <sm-item v-for="group in groups"
                      :key="group.id"
                     class="justify-content-center"
                     @click="filterPubs([],[], [group.id])">
              <div>Show only {{ group.name }} Pubs</div>
              <div class="text-xs">{{ find(users, ['id', group.overseer_id]).last_first }}</div>
            </sm-item>
          </ul>
        </tuxedo-secondary-menu>
      </div>
      <div class="field">
        <div class="detail-radio">
          <transition v-for="user in orderedPubs" :key="assignKey() + user.id" name="collapse">
            <label class="profile-card" :class="userClass(user)" v-show="showUser(user)">
              <input type="radio" @click="checkUser(user.id)" :value="user.id" :checked="selectedUser === user.id">
              <span class="card-content">
              <table class="table is-fullwidth no-border">
                <tbody>
                <tr>
                  <th colspan="2">{{ user.last_first }} <b v-if="user.away"> - AWAY</b><b v-if="userLastAssignment(user) === 0"> - HAS PART THIS WEEK</b></th>
                </tr>
                <tr v-if="userLastThisPart(user) !== false && cong.modules.meeting_schedule.history !== 'detailed'">
                  <td colspan="2">
                    {{
                      $tc('messages.since_last_assignment.script', userLastThisPart(user), {
                        weeks: userLastThisPart(user),
                        part: $t('messages.since_last_assignment.' + partRef(part, hall))
                      })
                    }}
                  </td>
                </tr>
                <tr v-if="userLastAssignment(user) !== false && cong.modules.meeting_schedule.history !== 'detailed'">
                  <td colspan="2" class="font-light">
                    {{ $tc('messages.since_last_part', userLastAssignment(user), { weeks: userLastAssignment(user) }) }}
                  </td>
                </tr>
                </tbody>
                <tbody v-if="cong.modules.meeting_schedule.history === 'detailed' && user.lastParts">
                <tr v-for="assignment in user.lastParts.slice(0,4)" :key="assignKey() + assignment.id">
                  <td colspan="2" class="font-light" :class="partRef(part, hall) === partRef(assignment.part, assignment.hall) ? 'font-bold' : ''">
                    {{
                      $tc('messages.since_last_assignment.script', userLastAssignment(user, assignment), {
                        weeks: userLastAssignment(user, assignment),
                        part: $t('messages.since_last_assignment.' + partRef(assignment.part, assignment.hall))
                      })
                    }}
                  </td>
                </tr>
                </tbody>
                <tbody>
                <tr v-if="user.partsInMonth">
                  <td colspan="2" class="font-light">
                    {{ $tc('messages.parts_scheduled', user.partsInMonth, { count: user.partsInMonth }) }}
                  </td>
                </tr>
                <tr v-if="user.utilisation && cong.modules.meeting_schedule.history === 'detailed'">
                  <td colspan="2" class="font-light">
                    {{ $t('messages.part_utilisation', { percent: user.utilisation }) }}
                  </td>
                </tr>
                </tbody>
              </table>
          </span>
            </label>
          </transition>
        </div>
      </div>
    </modal>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import { filter, find, sortBy, orderBy } from 'lodash'
import { store } from '@/store.js'
import { put, post } from '@/composables/serverInterface'
import { merge } from '@/composables/dataInterface'
import { assignKey } from '@/composables/assignKey'

const props = defineProps(['active', 'part', 'hall', 'assignment', 'assignmentType'])
const emit = defineEmits(['input'])

const sort = ref('part')
const direction = ref('asc')
const filterGender = ref([])
const filterAppointment = ref([])
const filterGroup = ref([])
const orderMenu = ref(false)
const filterMenu = ref(false)
const selectedUser = ref(false)
const refreshOrderedPubs = ref(0)

const users = store.state.users
const cong = store.state.settings.cong
const groups = store.state.groups

const approvedPubs = computed(() => {
  return filter(users, u => {
    return find(u.qualified_parts, q => {
      if (props.part.user_meeting_part_id === 2 && props.hall > 1) return q.id === 17
      else return q.id === (props.assignmentType === 'helper' ? props.part.helper_meeting_part_id : props.part.user_meeting_part_id)
    })
  })
})

const filteredPubs = computed(() => {
  return filter(approvedPubs.value, u => {
    if (filterGender.value && filterGender.value.length > 0) {
      if (!filterGender.value.includes(u.gender)) return false
    }
    if (filterAppointment.value && filterAppointment.value.length > 0) {
      if (!filterAppointment.value.includes(u.appointment)) return false
    }
    if (filterGroup.value && filterGroup.value.length > 0) {
      if (!filterGroup.value.includes(u.group_id)) return false
    }
    return true
  })
})

const orderedPubs = computed(() => {
  if (sort.value === 'part' && refreshOrderedPubs.value) {
    return sortBy(filteredPubs.value, p => {
      if (props.helper && p.lastAsHelper) return p.lastAsHelper
      if (p.lastOfKind) return p.lastOfKind
      else return '2019-01-01'
    })
  } else {
    return orderBy(filteredPubs.value, ['last_name'], [direction.value])
  }
})

const assignmentPart = computed(() => {
  return props.part.meeting + '.' + props.part.part
})

const assignmentStart = computed(() => {
  return props.part.startTime
})

watch(() => props.active, () => {
  if (props.active) {
    selectedUser.value = false
    filterPubs([], [])
    if (props.assignment) {
      selectedUser.value = props.assignmentType === 'main' ? props.assignment.user_id : props.assignment.helper_id
    }
    const ids = []
    approvedPubs.value.forEach(pub => {
      ids.push(pub.id)
    })

    const post = {
      ids: ids,
      meeting: props.part.meeting_id,
      part: assignmentPart.value,
      hall: props.hall
    }

    store.dispatch('GET_RECENT_ASSIGNMENTS', { post: post })
      .then(() => refreshOrderedPubs.value++)
  }
})

const partRef = (part, hall) => {
  const meeting = typeof part === 'object' ? part.meeting : part.split('.')[0]
  if (meeting === 'ministry' && props.assignmentType === 'helper') return 'ministry_helper'
  const partId = typeof part === 'object' ? part.part : part.split('.')[1]
  let helperType = null
  if (props.assignmentType === 'helper') {
    helperType = typeof part === 'object' ? part.helper_type : part.split('.')[2]
  }
  return meeting + '_' + partId + (helperType ? '_' + helperType : '') + (partId === 'comments' && hall === 1 ? 1 : '')
}

const showUser = (user) => {
  return selectedUser.value === user.id || !selectedUser.value || !find(approvedPubs.value, p => p.id === selectedUser.value)
}

const userClass = (user) => {
  if (user.away || userLastAssignment(user) === 0) return 'is-red'
  if (user.id === selectedUser.value) return 'is-orange'
  return 'is-blue'
}

const checkUser = (id) => {
  selectedUser.value = selectedUser.value === id ? false : id
}

const reorderPubs = (s, d) => {
  sort.value = s
  direction.value = d
  orderMenu.value = false
}

const filterPubs = (gender, appointment, group) => {
  filterGender.value = gender
  filterAppointment.value = appointment
  filterGroup.value = group
  filterMenu.value = false
}

const userLastAssignment = (user, assignment = null) => {
  if (assignmentStart.value) {
    const a = new Date(assignmentStart.value)
    const currentDay = a.getDay()
    a.setDate(a.getDate() + (1 - currentDay))
    a.setHours(10)
    a.setMinutes(0)
    if (assignment || (user.lastParts && user.lastParts.length > 0)) {
      let b = new Date()
      if (assignment) b = new Date(assignment.start_date)
      else if (user.lastParts.length > 0) b = new Date(user.lastParts[0].start_date)
      b.setHours(10)
      b.setMinutes(0)
      const diffTime = Math.abs(a - b)
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))
    }
  }
  return false
}

const userLastThisPart = (user) => {
  if (assignmentStart.value) {
    const a = new Date(assignmentStart.value)
    const currentDay = a.getDay()
    a.setDate(a.getDate() + (1 - currentDay))
    a.setHours(10)
    a.setMinutes(0)
    if (user.lastOfKind) {
      const b = new Date(user.lastOfKind)
      b.setHours(10)
      b.setMinutes(0)
      const diffTime = Math.abs(a - b)
      return Math.ceil(diffTime / (1000 * 60 * 60 * 24 * 7))
    }
  }
  return false
}

const submit = () => {
  const resource = {
    id: props.assignment ? props.assignment.id : null,
    user_id: props.assignmentType === 'main' ? selectedUser.value : props.assignment ? props.assignment.user_id : null,
    helper_id: props.assignmentType === 'helper' ? selectedUser.value : props.assignment ? props.assignment.helper_id : null,
    hall: props.hall
  }

  const uri = ['meetingParts', props.part.id, 'assignments']
  if (resource.id) {
    uri.push(resource.id)
    put(uri, resource).then(res => {
      merge('meetingParts', res.data.data, 'original_id')
      emit('update:active', false)
    })
  } else {
    post(uri, resource).then(res => {
      merge('meetingParts', res.data.data, 'original_id')
      emit('update:active', false)
    })
  }
}

</script>
