var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.alerts.group && _vm.alerts.group.location && _vm.nextGroupActive)?_c('div',{ref:"fieldGroupPrompt",staticClass:"notification is-group",class:_vm.collapse ? 'collapsing' : ''},[_c('button',{staticClass:"delete",on:{"click":function($event){return _vm.dismissAlert('nextGroupActive')}}}),(!_vm.alerts.group.online)?_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('notifications.next_group', {
              time: _vm.alerts.group.meeting_time,
              label: _vm.alerts.group.label,
              location: _vm.alerts.group.location
          }))}}):_vm._e(),(_vm.alerts.group.online)?_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('notifications.next_group_online', {
              time: _vm.alerts.group.meeting_time,
              label: _vm.alerts.group.label,
              url: _vm.alerts.group.location
          }))}}):_vm._e(),_c('div',{staticClass:"mt-3 display-flex justify-content-end",staticStyle:{"margin-right":"-25px"}},[_c('a',{staticClass:"button btn-sm btn-success",attrs:{"href":_vm.alerts.group.location,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t('labels.button.join')))])])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }