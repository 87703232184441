import axios from 'axios'

export const GET_MEETING_SCHEDULE_YEARS = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/meeting-schedule/years')
      .then(function (response) {
        commit('UPDATE_YEARS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MEETING_SCHEDULE_MONTHS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/meeting-schedule/' + payload.year + '/months')
      .then(function (response) {
        commit('UPDATE_MONTHS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MEETING_SCHEDULE_DAYS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/meeting-schedule/' + payload.year + '/' + payload.month)
      .then(function (response) {
        commit('UPDATE_DAYS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MEETING_SCHEDULE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/meeting-schedule/' + payload.day)
      .then(function (response) {
        commit('UPDATE_SCHEDULE', response.data.data)
        commit('UPDATE_PUBLIC_MEETING_ALERT', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_RECENT_ASSIGNMENTS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/meeting-schedule/pub-assignment-profile', payload.post)
      .then(function (response) {
        commit('UPDATE_PUB_PART_DATES', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_SCHEDULE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/meeting-schedule?api_token=' + payload.token, payload.schedule)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_ASSIGNMENT = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/meeting-schedule/assignment', payload.assignment)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
