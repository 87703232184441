<template>
  <div class="font-sans">
      <div v-if="user">
      <div v-if="!small" class="media align-items-center">
        <div class="media-left">
          <figure class="image is-48x48">
            <img v-if="false" class="border rounded-circle" :src="appURL + '/storage/' + avatar" :height="height || 30" />
            <div v-else-if="user" class="rounded-full h-full w-full border flex justify-content-center align-items-center"
                 style="background: #dee2e6; color: #6574cd"
                 :class="border">
              <span class="text-lg uppercase">{{ initials }}</span>
            </div>
            <span v-else class="d-block border rounded-circle text-center" :style="'width: ' + (height+2 || '32') + 'px; line-height: ' + (height || '30') + 'px;'">
                <fa-icon :icon="['fal', 'user']" />
            </span>
          </figure>
        </div>
        <div>
          <div class="mb-0 text-base">
            <span v-if="user.full_name">{{ user.full_name }}</span>
            <span v-else>{{ user.name }}</span>
            <div v-if="awards && user.awards" class="inline ml-2">
              <div v-if="user.awards.ideas_man" v-tooltip.top="'<b>' + $t('awards.ideas.label') + '</b><br>' + $t('awards.ideas.comment', { name: user.first_name })"
                class="text-sm inline-flex items-center justify-center px-2 py-1 mr-1 bg-yellow-100 border border-yellow-500 text-yellow-500 font-bold leading-none rounded-full">
                <fa-icon :icon="['fad', 'lightbulb-on']" />
              </div>
              <div v-if="user.awards.advocate" v-tooltip.top="'<b>' + $t('awards.advocate.label') + '</b><br>' + $t('awards.advocate.comment', { name: user.first_name })"
                class="text-sm inline-flex items-center justify-center px-2 py-1 mr-1 bg-red-100 border border-red-500 text-red-500 font-bold leading-none rounded-full">
                <fa-icon :icon="['fad', 'hands-heart']" />
              </div>
              <div v-if="user.awards.comments" v-tooltip.top="'<b>' + $t('awards.comments.label') + '</b><br>' + $t('awards.comments.comment', { name: user.first_name })"
                class="text-sm inline-flex items-center justify-center px-2 py-1 mr-1 bg-blue-100 border border-blue-500 text-blue-500 font-bold leading-none rounded-full">
                <fa-icon :icon="['fad', 'comments']" />
              </div>
            </div>
          </div>
          <p class="text-gray-500 mb-0">{{ user.congregation }} Congregation</p>
        </div>
      </div>
      <div v-else class="media align-items-center">
        <div class="media-left">
          <figure class="image is-32x32">
            <img v-if="false" class="border rounded-circle" :src="appURL + '/storage/' + avatar" :height="height || 30" />
            <div v-else-if="user" class="rounded-full h-full w-full border flex justify-content-center align-items-center"
                 style="background: #dee2e6; color: #6574cd"
                 :class="border">
              <span class="text-xs uppercase">{{ initials }}</span>
            </div>
            <span v-else class="d-block border rounded-circle text-center" :style="'width: ' + (height+2 || '32') + 'px; line-height: ' + (height || '30') + 'px;'">
                <fa-icon :icon="['fal', 'user']" />
            </span>
          </figure>
        </div>
        <div class="media-content">
          <p class="mb-0">
            <span v-if="user.full_name" class="is-size-6">{{ user.full_name }}</span>
            <span v-else class="is-size-6">{{ user.name }}</span>
            <span class="text-muted mb-0"> / {{ user.congregation }} Congregation</span>
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from 'vue'
const props = defineProps(['user', 'height', 'small'])

const appURL = ''
const awards = ref(true)

const initials = computed(() => {
  let matches = ''
  if (props.user.full_name) {
    matches = props.user.full_name.match(/\b(\w)/g)
  } else {
    matches = props.user.name.match(/\b(\w)/g)
  }
  return matches.slice(0, 2).join('')
})

const border = computed(() => {
  if (props.user.appointment === 'elder') return 'border-elder'
  else if (props.user.appointment === 'servant') return 'border-servant'
  return 'border-publisher'
})

</script>
