<template>

    <view-content :back="{name:'Settings'}">

      <template v-slot:header>
        Automatic Messaging Settings
      </template>

      <template v-slot:subtext>
        <p><strong class="text-gray-100">Messaging Costs (10c)</strong></p>
        <p class="mb-3">A third party service can be used to automate the sending of reminder messages if you wish. The cost to use
          this service is 10c (AUD) per 160 characters, and will be added as a charge to your congregation account at
          the end of the month.</p>
        <p>A cost estimate is provided for each notification type based upon your last months messaging.</p>
      </template>

      <div class="bg-white overflow-hidden">
      <div class="flex flex-col p-2">
        <input-toggle class="mb-3" v-if="false"
                     :label="'Publisher Invites'"
                     v-model="cong.modules.automatic_messaging.invites"
                     :help="invitesCost ? invitesCost : ''"
                     @input="confirmCongSettings()"
        />
        <input-toggle class="mb-3" :label="'Mass Texts'"
                     v-model="cong.modules.automatic_messaging.manual"
                     :help="manualCost ? manualCost : ''"
                     @input="confirmCongSettings()"
        />
        <input-toggle class="mb-3" :label="'Cart Notifications'"
                     v-model="cong.modules.automatic_messaging.carts"
                     :help="cartsCost ? cartsCost : ''"
                     @input="confirmCongSettings()"
        />
        <input-toggle class="mb-3" :label="'Map Notifications'"
                     v-model="cong.modules.automatic_messaging.maps"
                     :help="mapsCost ? mapsCost : ''"
                     @input="confirmCongSettings()"
        />
        <input-toggle class="mb-3" :label="'Group Notifications'"
                     v-model="cong.modules.automatic_messaging.groups"
                     :help="'Notifications and reminders for publishers looking after a field service group. '+ groupsCost ? groupsCost : ''"
                     @input="confirmCongSettings()"
        />
        <input-toggle class="mb-3" :label="'Field Service Report Notifications'"
                     v-model="cong.modules.automatic_messaging.reports"
                     :help="reportsCost ? reportsCost : ''"
                     @input="confirmCongSettings()"
        />
        <input-toggle class="mb-3" :label="'Attendant Responsibility Notification'"
                     :id="'attendant-toggle'"
                     v-model="cong.modules.automatic_messaging.attendants"
                     :help="attendantsCost ? attendantsCost : ''"
                     @input="confirmCongSettings()"
        />
        <input-slider class="mb-3" :label="''"
                     v-if="cong.modules.automatic_messaging.attendants"
                     v-model="cong.modules.attendants_schedule.reminder_timing"
                     :options="{1: 'Two Days Prior', 2: 'Half Day Prior', 3: 'Both'}"
                     @input="confirmCongSettings()"
        />

      </div>
      </div>

    </view-content>

</template>

<script setup>
import { computed, onMounted } from 'vue'
import { store } from '@/store'
import { post } from '@/composables/serverInterface'
import { find } from 'lodash'

const cong = computed(() => store.state.settings.cong)
const stats = computed(() => store.state.messaging.stats)

const invitesCost = computed(() => {
  const stat = find(stats.value, (s) => { return s.type === 'invites' })
  if (stat) {
    return 'Estimated cost: $' + (stat.count * 0.2).toFixed(2) + ' AUD'
  }
  return ''
})
const manualCost = computed(() => {
  const stat = find(stats.value, (s) => { return s.type === 'manual' })
  if (stat) {
    return 'Estimated cost: $' + (stat.count * 0.1).toFixed(2) + ' AUD'
  }
  return ''
})
const cartsCost = computed(() => {
  const stat = find(stats.value, (s) => { return s.type === 'carts' })
  if (stat) {
    return 'Estimated cost: $' + (stat.count * 0.1).toFixed(2) + ' AUD'
  }
  return ''
})
const mapsCost = computed(() => {
  const stat = find(stats.value, (s) => { return s.type === 'maps' })
  if (stat) {
    return 'Estimated cost: $' + (stat.count * 0.1).toFixed(2) + ' AUD'
  }
  return ''
})
const groupsCost = computed(() => {
  const stat = find(stats.value, (s) => { return s.type === 'groups' })
  if (stat) {
    return 'Estimated cost: $' + (stat.count * 0.1).toFixed(2) + ' AUD'
  }
  return ''
})
const reportsCost = computed(() => {
  const stat = find(stats.value, (s) => { return s.type === 'reports' })
  if (stat) {
    return 'Estimated cost: $' + (stat.count * 0.1).toFixed(2) + ' AUD'
  }
  return ''
})
const attendantsCost = computed(() => {
  return 'Estimated cost: $' + (cong.value.modules.attendants_schedule.reminder_timing === 3 ? '8' : '4') + '.00 AUD'
})

onMounted(() => {
})

const confirmCongSettings = () => {
  post('congregation', cong.value)
}

</script>
