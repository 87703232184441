<template>
  <div>
    <alert-box v-if="editedSchedule && permits['schedules.meetings.edit']">
      <div class="flex justify-content-between align-items-center">
        <div>{{ $t('messages.edited_schedule') }}</div>
        <action :secondary="true"
                v-if="permits['schedules.meetings.import']"
                :small="true"
                @click="resetMeetingScheduleActive = true">
          {{ label }}
        </action>
      </div>
    </alert-box>
    <modal :header="label"
           :submitting="submitting"
           :active.sync="resetMeetingScheduleActive"
           :save="$t('actions.reset')"
           @submit="submit">
      <p>{{ $t('prompts.meeting.reset_schedule') }}</p>
    </modal>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { get } from '@/mixins/api'

export default {
  name: 'ResetSchedule',
  props: ['schedule'],
  mixins: [get],
  data () {
    return {
      submitting: false,
      resetMeetingScheduleActive: false,
      label: this.$t('actions.action_resource', { action: this.$t('actions.reset'), resource: this.$tc('resources.schedule', 1) })
    }
  },
  computed: {
    ...mapState({
      permits: state => state.core.permits
    }),
    editedSchedule () {
      return this.schedule.id !== this.schedule.edited_id
    }
  },
  methods: {
    submit () {
      this.submitting = true
      this.get(['meeting-schedule', this.schedule.id, 'reset']).then(() => {
        this.submitting = true
        this.$emit('reload')
        this.resetMeetingScheduleActive = false
      })
    }
  }
}
</script>
