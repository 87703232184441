<template>
  <div>
    <badge class="cursor-pointer"
           @click="assign"
           :color="partHasAssignee ? part.meeting : 'gray-300'"
           v-html="partHasAssignee ? assigneeName : partTag">
    </badge>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'PartAssignee',
  props: {
    part: {
      required: false
    },
    hall: {
      required: false,
      default () { return 1 }
    }
  },
  data () {
    return {
      assignment: null,
      partHasAssignee: null,
      assigneeName: ''
    }
  },
  computed: {
    ...mapState({
      users: state => state.users,
      permits: state => state.core.permits
    }),
    partTag () {
      if (this.part.meeting === 'prelude' && this.part.part === 'comments') return 'Chairman'
      if (this.part.meeting === 'talk' && this.part.part === 'song') return 'Chairman'
      if (this.part.meeting === 'living' && this.part.part === 'study') return 'Conductor'
      return 'Assignee'
    }
  },
  created () {
    this.processPart()
  },
  watch: {
    part: {
      deep: true,
      handler () {
        this.processPart()
      }
    }
  },
  methods: {
    processPart () {
      this.assignment = null
      this.partHasAssignee = null
      let key = 0
      if (this.part && this.part.assignments) {
        key = this.part.assignments.findIndex(a => a.hall === this.hall)
        if (this.part.assignments[key]) {
          this.assignment = this.part.assignments[key]
          this.partHasAssignee = this.part.assignments[key].user_id
          if (this.part.assignments[key].speaker_name) {
            this.assigneeName = this.part.assignments[key].speaker_name
          } else {
            this.assigneeName = this.find(this.users, 'id', this.partHasAssignee, 'full_name')
          }
        }
      }
    },
    assign () {
      if (!this.permits['schedules.meetings.assign']) return
      if (this.part.meeting === 'talk' && this.part.part === 'talk') return
      this.$emit('assign', this.assignment, this.hall, 'main')
    }
  }
}
</script>
