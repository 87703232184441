<template>
  <div class="mb-4">
    <label v-if="label" :for="name" class="text-uppercase mb-0 font-semibold">{{ label }}</label>
    <DatePicker
      class="block w-full"
      :type="'time'"
      :format="format"
      :minute-step="minuteStep"
      :shortcuts="shortcuts"
      @closed="$emit('closed')"
      :range="range"
      :input-class="'block w-full mb-0 bg-gray-100 text-base leading-7 border-gray-300 border border-t-0 border-l-0 shadow-sm rounded-md py-1 px-3 outline-none focus:border-teal'"
      v-model="inputValue"></DatePicker>
    <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
    <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
  </div>

</template>

<script setup>
import { computed } from 'vue'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'

const props = defineProps({
  label: {
    type: String,
    required: false
  },
  name: {
    type: String,
    required: true
  },
  type: {
    type: String,
    required: false,
    default: 'date'
  },
  help: {
    type: String,
    required: false
  },
  error: {
    type: Object,
    required: false
  },
  value: {
    type: [String, Number, Date],
    required: false
  },
  insistFormat: {
    type: String,
    required: false,
    default: '24'
  },
  format: {
    type: String,
    required: false,
    default: 'h:mma'
  },
  minuteStep: {
    type: Number,
    required: false,
    default: 1
  },
  disabledDates: {
    type: Object,
    required: false,
    default () { return {} }
  },
  shortcuts: {
    type: Array,
    required: false,
    default () {
      return [
        {
          text: 'Now',
          onClick () {
            return new Date()
          }
        }
      ]
    }
  },
  range: {
    type: Boolean,
    required: false
  },
  inputClass: {
    type: String,
    required: false
  }
})

const emit = defineEmits(['input'])

const inputValue = computed({
  get () {
    if (props.range) {
      const value1 = new Date()
      const value2 = new Date()
      if (Array.isArray(props.value)) {
        const time1 = props.value[0].split(':')
        const time2 = props.value[1].split(':')
        value1.setHours(time1[0])
        value1.setMinutes(time1[1])
        value2.setHours(time2[0])
        value2.setMinutes(time2[1])
      }
      return [value1, value2]
    } else if (props.insistFormat === 'iso') {
      const value = Date.parse(props.value)
      return new Date(value)
    } else {
      const time = props.value ? props.value.split(':') : [0, 0]
      const value = new Date()
      value.setHours(time[0])
      value.setMinutes(time[1])
      return value
    }
  },
  set (val) {
    if (props.insistFormat && props.range) {
      val = [
        handleFormat(val[0], props.insistFormat),
        handleFormat(val[1], props.insistFormat)
      ]
    } else if (props.insistFormat) {
      val = handleFormat(val, props.insistFormat)
    }
    emit('input', val)
  }
})

const handleFormat = (dateObject, format) => {
  if (format === '24') {
    const time = new Intl.DateTimeFormat('en', { hour: '2-digit', hour12: false, minute: '2-digit' }).format(dateObject)
    return `${time}`
  } else if (format === 'iso') {
    return dateObject.toISOString()
  }
}

</script>
