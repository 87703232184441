<template>
  <div class="notification is-warning" v-if="alerts.request">
    <div v-html="
            $t('notifications.group_request.message', {
                full_name:alerts.request.overseer.full_name,
                first_name: alerts.request.overseer.first_name,
                date: alerts.request.date,
                time: alerts.request.time,
                location: alerts.request.location
            })"></div>
    <div class="mt-3 display-flex justify-content-end" style="margin-right:-25px">
      <button class="button btn-sm btn-danger mr-2" @click="!rejectSubmitting && !acceptSubmitting ? rejectRequest() : null">
        <fa-icon :icon="['fal', 'spinner-third']" spin v-if="rejectSubmitting" />
        <span v-if="!rejectSubmitting">{{ $t('notifications.group_request.reject') }}</span>
      </button>
      <button class="button btn-sm btn-success" @click="!rejectSubmitting && !acceptSubmitting ? acceptRequest() : null">
        <fa-icon :icon="['fal', 'spinner-third']" spin v-if="acceptSubmitting" />
        <span v-if="!acceptSubmitting">{{ $t('notifications.group_request.accept') }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { mapState } from 'vuex'

export default {
  name: 'PublicTalkPrompt',
  data () {
    return {
      rejectSubmitting: false,
      acceptSubmitting: false
    }
  },
  computed: {
    ...mapState({
      alerts: state => state.dashboard.alerts
    })
  },
  methods: {
    rejectRequest () {
      this.rejectSubmitting = true
      axios.post('api/special-service-group/request-reject', { id: this.alerts.request.id })
        .then(() => {
          this.alerts.request = null
        })
        .catch(e => {
          this.$emit('update:error', e)
        })
    },
    acceptRequest () {
      this.acceptSubmitting = true
      axios.post('api/special-service-group/request-accept', { id: this.alerts.request.id })
        .then(() => {
          this.alerts.request = null
        })
        .catch(e => {
          this.$emit('update:error', e)
        })
    }
  }
}
</script>
