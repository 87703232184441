<template>
  <modal :submitting="submitting" :header="'Banner Notification'" :active="active" @close="emit('close')" @submit="submit" :validated="true">
    <input-toggle class="mb-3" :label="'On/Off'" v-model="resource.on" />
    <input-text :label="'Title'" v-model="resource.title" />
    <input-message :label="'Notification'" v-model="resource.content" :help="'This message will appear as a notification on every publishers Service App at the top of the Maps view.'" />
  </modal>
</template>

<script setup>
import { computed, ref, watch } from 'vue'
import { post } from '@/composables/serverInterface'
import { assign } from '@/composables/dataInterface'
import { store } from '@/store'

const props = defineProps(['active'])
const emit = defineEmits(['close'])

const settings = computed(() => store.state.settings)
const submitting = ref(false)
const resource = ref({
  on: false,
  title: null,
  content: null
})

watch(() => props.active, async (active) => {
  if (active) {
    const banner = settings.value.cong.modules.banner_notification
    Object.keys(resource.value).forEach(key => {
      if (banner[key]) resource.value[key] = banner[key]
    })
  }
})

const submit = () => {
  submitting.value = true
  post(['congregation'], { modules: { banner_notification: resource.value } })
    .then(res => {
      const obj = { ...settings.value }
      obj.cong = res.data.data
      assign('settings', obj)
      submitting.value = false
      emit('close')
    })
    .catch(() => {
      submitting.value = false
    })
}

</script>
