<template>
  <div class="tuxedo-input-field-group">
    <label v-if="label" :for="id">
      <span>{{ label }}</span>
      <small v-if="sub">{{ sub }}</small>
    </label>
    <div class="input-group select-group" v-click-outside="closeSelector">
      <div class="tuxedo-input-wrapper clickable" :class="inputClass">
        <input type="text" :id="id" v-model="selectDisplay" @click="pickerActive = true" :name="id" :class="inputClass"
               autocomplete="off" autocorrect="off" autocapitalize="none" spellcheck="false" :readonly="inputReadOnly">
        <div class="tuxedo-input-action">
          <slot name="action">
            <svg xmlns="http://www.w3.org/2000/svg" width="16" fill="currentColor" viewBox="0 0 16 16" @click="pickerActive = true">
              <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
            </svg>
          </slot>
        </div>
      </div>
      <div class="select-picker" v-show="pickerActive">
        <div class="select-options">
          <div class="tuxedo-mobile-select-options">
            <div class="select-grow-2" v-if="multi">
              <div class="select-all-button" @click="selectAll">
                {{ insistSelectAll }}
              </div>
            </div>
            <div class="select-grow-1">
              <div class="select-close-icon" @click="pickerActive = false">
                <svg xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                  <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
                </svg>
              </div>
            </div>
            <div class="select-grow-2" v-if="multi">
              <div class="unselect-all-button" @click="unselectAll">
                {{ insistUnselectAll }}
              </div>
            </div>
          </div>
          <div class="options">
            <div class="option multi-select-options" v-if="multi">
              <div class="select-all-button" @click="selectAll">
                {{ insistSelectAll }}
              </div>
              <div class="unselect-all-button" @click="unselectAll">
                {{ insistUnselectAll }}
              </div>
            </div>
            <div class="option" :class="selected(option) ? 'active' : ''" v-for="(option, key) in filteredOptions" :key="name + 'Objects' + key" @click="select(option)">
              <div class="label">
                <slot name="label" v-bind:option="option">
                  <span>{{ resolve(insistLabel, option) }}</span>
                </slot>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width="24" height="16">
                  <!--! Font Awesome Pro 6.1.0 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
                  <path d="M0 256C0 114.6 114.6 0 256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256zM371.8 211.8C382.7 200.9 382.7 183.1 371.8 172.2C360.9 161.3 343.1 161.3 332.2 172.2L224 280.4L179.8 236.2C168.9 225.3 151.1 225.3 140.2 236.2C129.3 247.1 129.3 264.9 140.2 275.8L204.2 339.8C215.1 350.7 232.9 350.7 243.8 339.8L371.8 211.8z"/>
                </svg>
              </div>
              <slot name="content" class="content" v-bind:option="option">
              </slot>
            </div>
          </div>
          <div class="tuxedo-input-search">
            <input type="search" :name="id" v-model="searchPhrase" autocomplete="off" autocorrect="off" autocapitalize="off">
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="16" fill="currentColor" viewBox="0 0 16 16" class="search-icon">
              <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z"/>
            </svg>
          </div>
        </div>
      </div>
    </div>
    <small class="form-text text-danger" v-if="error && error[name]">{{ error[name][0] }}</small>
    <small class="form-text text-muted" v-else-if="help">{{ help }}</small>
  </div>
</template>

<script>
import vClickOutside from 'v-click-outside'
import { find } from 'lodash'

export default {
  name: 'InputSelect',
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    label: {
      type: String,
      required: false
    },
    sub: {
      type: String,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    id: {
      type: String,
      required: false,
      default () {
        let result = ''
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
        const charactersLength = characters.length
        for (let i = 0; i < 6; i++) {
          result += characters.charAt(Math.floor(Math.random() * charactersLength))
        }
        return this.name + '_' + result
      }
    },
    help: {
      type: String,
      required: false
    },
    value: {
      type: [String, Number, Object, Array],
      required: false
    },
    error: {
      type: Object,
      required: false
    },
    options: {
      type: [Array, Object],
      required: true
    },
    multi: {
      type: Boolean,
      default: false,
      required: false
    },
    autoSelectSingle: {
      type: Boolean,
      default: true,
      required: false
    },
    insistLabel: {
      type: String,
      default: 'label',
      required: false
    },
    insistDisplay: {
      type: String,
      required: false
    },
    insistSelectAll: {
      type: String,
      default: 'Select All',
      required: false
    },
    insistUnselectAll: {
      type: String,
      default: 'Unselect All',
      required: false
    },
    insistReturn: {
      type: String,
      default: 'object',
      required: false
    },
    default: {
      type: [String, Number, Array],
      required: false
    },
    loading: {
      type: Boolean,
      required: false
    },
    inputClass: {
      type: String,
      required: false
    }
  },
  data () {
    return {
      searchPhrase: null,
      pickerActive: false,
      objectsSelected: [],
      inputReadOnly: true,
      scrollEvent: null
    }
  },
  computed: {
    inputValue: {
      get () {
        if (this.objectsSelected[0]) {
          return this.resolve(this.insistLabel, this.objectsSelected[0])
        } else if (this.value) {
          if (this.insistReturn === 'object') return this.resolve(this.insistLabel, this.value[0])
          else {
            const obj = find(this.options, () => o => o[this.insistReturn] === this.value)
            // const obj = this.options.find()
            if (obj) {
              // eslint-disable-next-line vue/no-side-effects-in-computed-properties
              this.objectsSelected = [JSON.parse(JSON.stringify(obj))]
              return this.resolve(this.insistLabel, obj)
            }
          }
        }
        return ''
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    selectDisplay: {
      get () {
        if (this.pickerActive) {
          return this.searchPhrase
        } else if (this.insistDisplay) {
          return this.insistDisplay
        } else {
          if (this.multi) {
            const string = []
            let others = 0
            this.objectsSelected.forEach(o => {
              if (string.length < 3) string.push(this.resolve(this.insistLabel, o))
              else others += 1
            })
            if (others > 0) string.push('+' + others)
            return string.join(', ')
          }
          return this.inputValue
        }
      },
      set (val) {
        this.searchPhrase = val
      }
    },
    filteredOptions () {
      if (this.searchPhrase) {
        const arr = []
        this.options.forEach((o) => {
          Object.entries(o).some((p) => {
            const string = JSON.stringify(p[1]).toLowerCase()
            if (string.includes(this.searchPhrase.toLowerCase())) {
              arr.push(o)
              return true
            }
          })
        })
        this.resizeSelectionBox()
        // Remove reactivity since we don't want to accidentally mutate the origin
        return JSON.parse(JSON.stringify(arr))
      }
      this.resizeSelectionBox()
      // Remove reactivity since we don't want to accidentally mutate the origin
      return JSON.parse(JSON.stringify(this.options))
    }
  },
  mounted () {
    setTimeout(() => {
      this.assignCurrentSelection()
    }, 150)
  },
  created () {
    window.addEventListener('resize', this.resizeSelectionBox)
  },
  destroyed () {
    window.removeEventListener('resize', this.resizeSelectionBox)
  },
  watch: {
    pickerActive (val) {
      const body = document.getElementsByTagName('body')[0]
      if (window.innerWidth < 768) {
        if (val) body.style.overflow = 'hidden'
        else body.style.overflow = null
      } else {
        window.addEventListener('scroll', this.resizeSelectionBox)
      }
      if (!val) {
        window.removeEventListener('scroll', this.resizeSelectionBox, true)
      }
      this.resizeSelectionBox()
    },
    options () {
      this.assignCurrentSelection()
    }
  },
  methods: {
    assignCurrentSelection () {
      // Assign the currently selected options
      if (this.multi && this.value && this.value.length > 0) {
        console.log('first')
        let selection = []
        this.options.forEach(o => {
          if (this.insistReturn === 'object') {
            this.value.forEach(v => {
              if (JSON.stringify(v) === JSON.stringify(o)) {
                selection.push(JSON.parse(JSON.stringify(o)))
              }
            })
          } else {
            if (this.value.includes(o[this.insistReturn])) {
              selection.push(JSON.parse(JSON.stringify(o)))
            }
          }
        })
        // Make the selection unique
        selection = [...new Set(selection)]
        this.objectsSelected = selection
      } else if (this.default && !this.value) {
        this.objectsSelected = this.default
      } else if (this.autoSelectSingle && this.options && this.options.length === 1 && (!this.value || (this.value && this.value.length === 0))) {
        this.objectsSelected = this.options
      } else if (!this.value) {
        this.objectsSelected = []
      }

      this.$emit('input', this.determineValue())
    },
    selectAll () {
      this.objectsSelected = []
      this.filteredOptions.forEach(o => { this.objectsSelected.push(o) })

      // This determines if we are adding the entire object to our returned selection array, or just a property
      if (this.insistReturn === 'object') this.inputValue = this.objectsSelected
      else {
        const arr = []
        this.objectsSelected.forEach(os => { arr.push(os[this.insistReturn]) })
        this.inputValue = arr
      }
    },
    unselectAll () {
      this.objectsSelected = []
      this.inputValue = []
    },
    selected (o) {
      return this.objectsSelected && this.objectsSelected.findIndex(os => JSON.stringify(os) === JSON.stringify(o)) >= 0
    },
    select (o) {
      // If this is multiselect
      if (this.multi) {
        // this handles toggling the selection rather than just applying it
        const key = this.objectsSelected.findIndex(os => JSON.stringify(os) === JSON.stringify(o))

        // If we selected option isn't already in the list then add it
        if (key === -1) this.objectsSelected.push(o)

        // Otherwise, remove it
        else this.objectsSelected.splice(key, 1)
      } else {
        this.objectsSelected = [o]
        this.pickerActive = false
      }
      this.inputValue = this.determineValue()
    },
    determineValue () {
      if (this.multi) {
        if (this.insistReturn === 'object') return this.objectsSelected
        else {
          const arr = []
          this.objectsSelected.forEach(os => {
            arr.push(os[this.insistReturn])
          })
          return arr
        }
      } else if (this.objectsSelected[0]) {
        if (this.insistReturn === 'object') return this.objectsSelected[0]
        else return this.objectsSelected[0][this.insistReturn]
      }
    },
    closeSelector () {
      this.pickerActive = false
    },
    resizeSelectionBox (e) {
      setTimeout(() => {
        const windowHeight = window.innerHeight
        let maxHeight = windowHeight - 80
        const options = this.$el.getElementsByClassName('option')
        let optionsHeight = 30
        let singleHeight = 50
        let inputHeight = 38
        let spaceAbove = 100
        let spaceBelow = 200
        const target = document.getElementById(this.id)
        const element = target ? target.parentElement : undefined
        const container = this.$el.getElementsByClassName('select-options')[0]

        if (options[0]) {
          singleHeight = options[0].scrollHeight
        }
        for (const o of options) {
          optionsHeight += o.scrollHeight
        }

        if (window.innerWidth >= 768) {
          this.inputReadOnly = false
          if (element) {
            inputHeight = element.getBoundingClientRect().height
            spaceAbove = element.getBoundingClientRect().top
            const left = element.getBoundingClientRect().left
            const width = element.getBoundingClientRect().width
            spaceBelow = windowHeight - element.getBoundingClientRect().bottom
            container.style.left = left + 'px'
            container.style.width = width + 'px'
            maxHeight = Math.max(spaceAbove, spaceBelow)
            maxHeight = Math.min(maxHeight, 300)
          }
          singleHeight += 24
        } else {
          this.inputReadOnly = true
          optionsHeight += 115
          singleHeight += 105
          container.style.top = 'auto'
          container.style.left = '0'
          container.style.width = '100%'
          container.style.bottom = '0'
          container.style.height = 'auto'
          maxHeight = windowHeight - 80
        }

        optionsHeight = Math.max(optionsHeight, singleHeight)
        const height = Math.min(maxHeight, optionsHeight)
        container.style.height = height + 'px'

        if (window.innerWidth >= 768) {
          if (element) {
            if (spaceBelow < 300 && spaceAbove > spaceBelow) {
              container.style.top = 'auto'
              container.style.bottom = spaceBelow + inputHeight + 'px'
            } else {
              container.style.top = spaceAbove + inputHeight + 'px'
              container.style.bottom = 'auto'
            }
          }
        }
      }, 5)
    },
    resolve (path, obj) {
      const val = path.split('.').reduce(function (prev, curr) {
        return prev ? prev[curr] : null
      }, obj || self)
      return val && val.length > 0 ? val : '&nbsp;'
    }
  }
}
</script>
