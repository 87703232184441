<template>
    <div class="field">
        <label class="label">{{ label }}</label>
        <div class="control">
            <img v-bind:src="imagePreview" v-show="showPreview"/>
            <div class="file">
                <label class="file-label">
                    <input class="file-input" type="file" id="file" ref="file" accept="image/*,image/svg+xml" v-on:change="handleFileUpload()"/>
                    <span class="file-cta">
                        <span class="file-icon">
                            <fa-icon :icon="['fal', 'upload']" />
                        </span>
                        <span class="file-label">
                            Choose image
                        </span>
                    </span>
                </label>
            </div>
        </div>
    </div>
</template>

<script>
export default {
  props: ['label', 'value'],
  data () {
    return {
      file: '',
      showPreview: false,
      imagePreview: ''
    }
  },
  watch: {
    value: function (newVal) {
      if (newVal === null) {
        this.showPreview = false
      } if (newVal.indexOf('base64') !== -1) {
        this.showPreview = true
      } else {
        this.imagePreview = 'https://srvc.app/storage/assets/' + newVal
        this.showPreview = true
      }
    }
    //            imagePreview: function (newVal) {
    //                this.$emit('input', newVal);
    //            }
  },
  methods: {
    handleFileUpload () {
      /*
                  Set the local file variable to what the user has selected.
                */
      this.file = this.$refs.file.files[0]

      /*
                  Initialize a File Reader object
                */
      const reader = new FileReader()

      /*
                  Add an event listener to the reader that when the file
                  has been loaded, we flag the show preview as true and set the
                  image to be what was read from the reader.
                */
      reader.addEventListener('load', function () {
        this.showPreview = true
        this.imagePreview = reader.result
        this.$emit('input', reader.result)
      }.bind(this), false)

      /*
                  Check to see if the file is not empty.
                */
      if (this.file) {
        /*
                      Ensure the file is an image file.
                    */
        if (/\.(jpe?g|png|gif|svg)$/i.test(this.file.name)) {
          /*
                          Fire the readAsDataURL method which will read the file in and
                          upon completion fire a 'load' event which we will listen to and
                          display the image in the preview.
                        */
          reader.readAsDataURL(this.file)
        }
      }
    }
  }

}

</script>
