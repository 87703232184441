export const state = {
  maps: false,
  cart_reports: false,
  available_territory: false,
  available_territory_map: false,
  carts: false,
  special_groups: false,
  attendants: false,
  mapAddressOptions: [],
  mapOptions: [],
  meeting_parts: false
}
