export const pubsLocalUpdatedAt = (state) => {
  return state.pubsLastUpdated
}

export const localCache = (state) => {
  return state.localCache
}

export const remoteCache = (state) => {
  return state.remoteCache
}

export const TOKEN = (state) => {
  return state.token
}
