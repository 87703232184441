<template>
  <div>
    <span class="font-medium">{{ street.name }}</span>
    <small class="font-normal" v-if="suburb">
      <span> / {{ suburb.suburb }}</span>
      <span v-if="region" class="uppercase"> {{ region.code }}, {{ suburb.code }}</span>
    </small>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useCollectionsStore } from '@/stores/collections'
import { store } from '@/store'
import { find } from 'lodash'

const newStore = useCollectionsStore()

const props = defineProps(['street'])

const suburbs = computed(() => store.state.suburbs)
const regions = computed(() => newStore.all('regions'))

const suburb = computed(() => {
  return find(suburbs.value, s => s.id === props.street.suburb_id)
})

const region = computed(() => {
  if (suburb.value) {
    return find(regions.value, s => s.id === suburb.value.region_id)
  } else return null
})
</script>
