export const state = {
  years: {},
  months: {},
  days: {},
  schedule: {},
  activeYear: null,
  activeMonth: null,
  activeDay: null,
  activeMeeting: null,
  currentScheduleWeek: null,
  currentSchedule: {},
  pubOrder: {
    sort: 'part',
    direction: 'desc'
  }
}
