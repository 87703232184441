var render = function render(){var _vm=this,_c=_vm._self._c;return (_vm.alerts.request)?_c('div',{staticClass:"notification is-warning"},[_c('div',{domProps:{"innerHTML":_vm._s(
          _vm.$t('notifications.group_request.message', {
              full_name:_vm.alerts.request.overseer.full_name,
              first_name: _vm.alerts.request.overseer.first_name,
              date: _vm.alerts.request.date,
              time: _vm.alerts.request.time,
              location: _vm.alerts.request.location
          }))}}),_c('div',{staticClass:"mt-3 display-flex justify-content-end",staticStyle:{"margin-right":"-25px"}},[_c('button',{staticClass:"button btn-sm btn-danger mr-2",on:{"click":function($event){!_vm.rejectSubmitting && !_vm.acceptSubmitting ? _vm.rejectRequest() : null}}},[(_vm.rejectSubmitting)?_c('fa-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}}):_vm._e(),(!_vm.rejectSubmitting)?_c('span',[_vm._v(_vm._s(_vm.$t('notifications.group_request.reject')))]):_vm._e()],1),_c('button',{staticClass:"button btn-sm btn-success",on:{"click":function($event){!_vm.rejectSubmitting && !_vm.acceptSubmitting ? _vm.acceptRequest() : null}}},[(_vm.acceptSubmitting)?_c('fa-icon',{attrs:{"icon":['fal', 'spinner-third'],"spin":""}}):_vm._e(),(!_vm.acceptSubmitting)?_c('span',[_vm._v(_vm._s(_vm.$t('notifications.group_request.accept')))]):_vm._e()],1)])]):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }