import Vue from 'vue'

export const UPDATE_YEARS = (state, data) => {
  state.years = data
}

export const UPDATE_ACTIVE_YEAR = (state, data) => {
  state.activeYear = data
}

export const UPDATE_MONTHS = (state, data) => {
  state.months = data
}

export const UPDATE_WEEKS = (state, data) => {
  state.weeks = data
}

export const UPDATE_ACTIVE_MONTH = (state, data) => {
  state.activeMonth = data
}

export const UPDATE_DAYS = (state, data) => {
  state.days = data
}

export const UPDATE_ACTIVE_DAY = (state, data) => {
  state.activeDay = data
}

export const UPDATE_MEETING_ASSIGNMENT_DISPLAY_ORDER = (state, data) => {
  state.pubOrder = data
}

export const UPDATE_SCHEDULE_PART = (state, data) => {
  const parts = data.assignment.part.split('.')
  const type = data.type === 'assignee' ? 'assignee' : 'helper'
  if (!state.schedule[data.meeting].parts[parts[0]][parts[1]][type]) {
    Vue.set(state.schedule[data.meeting].parts[parts[0]][parts[1]], type, {})
  }
  Vue.set(state.schedule[data.meeting].parts[parts[0]][parts[1]][type], data.assignment.hall, {
    id: data.user.id,
    name: data.user.full_name
  })
}

export const UPDATE_SCHEDULE = (state, data) => {
  state.schedule = data
}

export const UPDATE_CURRENT_SCHEDULE = (state, data) => {
  state.currentSchedule = data
}

export const UPDATE_CURRENT_SCHEDULE_WEEK = (state, data) => {
  state.currentScheduleWeek = data
}

export const UPDATE_ACTIVE_MEETING = (state, data) => {
  state.activeMeeting = data
}
