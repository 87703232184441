import Assignment from './components/assignments/assignment'
import Tours from './components/tours/index'
import Progress from './components/general/progress'
import Avatar from './components/elements/Avatar'
import UserAvatar from './components/elements/UserAvatar'
import StoreLoader from './components/elements/StoreLoader'
import ViewContent from './components/elements/ViewContent'

import AwaySchedulerMeeting from './components/settings/public/away'

import Treeselect from '@riophae/vue-treeselect'
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import Modal from './components/general/form-modal'
import Update from './components/general/confirm-update'
import Wizard from './components/general/wizard'
import WizardStep from './components/general/wizard-step'
import Delete from './components/general/confirm-delete'
import InputText from './components/fields/InputText.vue'
import InputInteger from './components/fields/InputInteger.vue'
import InputEmail from './components/fields/InputEmail.vue'
import InputSlider from './components/fields/InputSlider.vue'
import InputToggle from './components/fields/InputToggle.vue'
import InputMessage from './components/fields/InputMessage.vue'
import InputSelect from './components/fields/InputSelect.vue'
import FilesField from './components/general/form-fields/fileupload'
import CheckboxField from './components/general/form-fields/checkbox'
import InputTime from './components/fields/InputTime'
import DateField from './components/general/form-fields/date'
import DateTimeField from './components/general/form-fields/datetime'
import CopyField from './components/general/form-fields/copy'
import ImageUpload from './components/general/form-fields/imageupload'
import TopSlidingNav from './components/general/top-sliding-nav'
import SortList from './components/general/sort-list'
import SortItem from './components/general/sort-item'
import Card from './components/elements/Card'
import CardSummary from './components/elements/CardSummary'
import Action from './components/elements/Action'
import UndoTimer from './components/elements/UndoTimer'
import SubMenu from './components/elements/SubMenu'
import SubMenuItem from './components/elements/SubMenuItem'
import Badge from './components/elements/Badge'
import DateTime from './components/elements/DateTime'
import TimeOnly from './components/elements/TimeOnly'
import DateOnly from './components/elements/DateOnly'
import AlertBox from './components/elements/AlertBox'
import WarningBox from './components/elements/WarningBox'
import ListItem from './components/elements/ListItem'
import SecondaryMenu from './components/elements/SecondaryMenu'

import MeetingPart from './components/schedules/meetings-2/part'
import CLM from './components/schedules/meetings-2/clm'
import Public from './components/schedules/meetings-2/public'
import PartAssignee from './components/schedules/meetings-2/assignee'
import PartHelper from './components/schedules/meetings-2/helper'
import MeetingAssignment from './components/schedules/meetings-2/assign'
import ScheduleEvents from './components/schedules/meetings-2/ScheduleEvents'
import AttendanceForm from './components/schedules/meetings/attendance'
import TopicCard from './components/forum/TopicCard'

import InstallPrompt from './components/dashboard/prompts/InstallPrompt'
import PublicTalkPrompt from './components/dashboard/prompts/PublicTalkPrompt'
import PrivacyPrompt from './components/dashboard/prompts/PrivacyPrompt'
import FieldGroupPrompt from './components/dashboard/prompts/FieldGroupPrompt'
import BannerPrompt from './components/dashboard/prompts/BannerPrompt'
import OnlineMeetingPrompt from './components/dashboard/prompts/OnlineMeetingPrompt'
import ReportPrompt from './components/dashboard/prompts/ReportPrompt'
import GroupChangePrompt from './components/dashboard/prompts/GroupChangePrompt'
import CartPrompt from './components/dashboard/prompts/CartPrompt'
import SubscribedTopicPrompt from './components/dashboard/prompts/SubscribedTopicPrompt'
import AppDown from './components/elements/AppDown'

// Mailables
import ClmToChairman from './components/settings/mailables/ClmToChairman'

import Vue from 'vue'
import { Datetime } from 'vue-datetime'

import {
  TuxedoDate, TuxedoRadio, TuxedoInput, TuxedoSelect, TuxedoSwitch, TuxedoSecondaryMenu, TuxedoText
} from 'tuxedo-elements'

const components = {
  TuxedoDate, TuxedoRadio, TuxedoInput, TuxedoSelect, TuxedoSwitch, TuxedoSecondaryMenu, TuxedoText
}

Object.entries(components).forEach(([name, component]) => Vue.component(name, component))

Vue.component('treeselect', Treeselect)

Vue.component('tours', Tours)
Vue.component('progress-modal', Progress)
Vue.component('view-content', ViewContent)

Vue.component('input-text', InputText)
Vue.component('input-integer', InputInteger)
Vue.component('input-email', InputEmail)
Vue.component('input-slider', InputSlider)
Vue.component('input-toggle', InputToggle)
Vue.component('input-message', InputMessage)
Vue.component('input-select', InputSelect)
Vue.component('modal', Modal)
Vue.component('update', Update)
Vue.component('wizard', Wizard)
Vue.component('wizard-step', WizardStep)
Vue.component('delete', Delete)
Vue.component('checkboxfield', CheckboxField)
Vue.component('input-time', InputTime)
Vue.component('datefield', DateField)
Vue.component('datetimefield', DateTimeField)
Vue.component('copyfield', CopyField)
Vue.component('imageupload', ImageUpload)
Vue.component('filesfield', FilesField)
Vue.component('datetime', Datetime)
Vue.component('time-only', TimeOnly)
Vue.component('date-only', DateOnly)
Vue.component('alert-box', AlertBox)
Vue.component('warning-box', WarningBox)
Vue.component('list-item', ListItem)
Vue.component('secondary-menu', SecondaryMenu)

Vue.component('top-sliding-nav', TopSlidingNav)
Vue.component('sort-list', SortList)
Vue.component('sort-item', SortItem)

Vue.component('assignment', Assignment)

Vue.component('meeting-part', MeetingPart)
Vue.component('clm', CLM)
Vue.component('public', Public)
Vue.component('assignee', PartAssignee)
Vue.component('helper', PartHelper)
Vue.component('attendance-form', AttendanceForm)
Vue.component('meeting-assignment', MeetingAssignment)
Vue.component('schedule-events', ScheduleEvents)
Vue.component('away-scheduler-meeting', AwaySchedulerMeeting)
Vue.component('topic-card', TopicCard)

Vue.component('prompt-install', InstallPrompt)
Vue.component('prompt-public-talk', PublicTalkPrompt)
Vue.component('prompt-privacy', PrivacyPrompt)
Vue.component('prompt-field-group', FieldGroupPrompt)
Vue.component('prompt-banner', BannerPrompt)
Vue.component('prompt-online-meeting', OnlineMeetingPrompt)
Vue.component('prompt-report', ReportPrompt)
Vue.component('prompt-group-change', GroupChangePrompt)
Vue.component('prompt-cart', CartPrompt)
Vue.component('prompt-subscribed-topic', SubscribedTopicPrompt)

Vue.component('avatar', Avatar)
Vue.component('user-avatar', UserAvatar)
Vue.component('queue', StoreLoader)
Vue.component('app-down', AppDown)
Vue.component('card', Card)
Vue.component('card-summary', CardSummary)
Vue.component('action', Action)
Vue.component('undo-timer', UndoTimer)
Vue.component('sub-menu', SubMenu)
Vue.component('sm-item', SubMenuItem)
Vue.component('badge', Badge)
Vue.component('date-time', DateTime)

// Mailables
Vue.component('mail-clm-to-chairman', ClmToChairman)
