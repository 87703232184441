<template>
  <div class="flex relative">
    <div :class="color ? 'bg-' + color : 'bg-gray-300'" class="w-12 h-12 rounded-l-lg z-10 text-white text-center font-sans flex items-center justify-center">
      <div v-if="rendering" class="leading-none pl-1">
        <fa-icon class="animate-spin" :icon="['fal', 'spinner-third']" />
      </div>
      <div v-else-if="date" class="leading-none pl-1">
          <small>{{ start | shortMonth }}</small><br>
          <strong class="text-white">{{ start | jS }}</strong>
      </div>
      <div v-else-if="dateYear" class="leading-none pl-1 text-right w-100 pr-1">
        <span class="text-white text-xs">{{ start | Y }}</span><br>
        <span class="text-xs uppercase opacity-75">{{ start | shortMonth }}</span>
        <span class="text-white text-xs font-bold">{{ start | j }}</span>
      </div>
      <div v-else-if="minutes" class="leading-none pl-1">
        <strong class="text-white">{{ minutes }}</strong><br>
        <small>{{ $tc('time.minutes_abbr', minutes) }}</small>
      </div>
      <div v-else-if="icon" class="leading-none pl-1">
        <fa-icon :icon="icon" />
      </div>
    </div>
    <div :class="color ? 'border-' + color : 'border-gray-300'" class="border-l-4 bg-white w-full rounded-bl-md rounded-r-md shadow-md relative">
      <div class="flex flex-col" :class="!actionSlot ? 'pb-2' : ''">
        <div class="text-left text-base font-sans font-bold px-2 pt-2">
          <slot name="header">
            <div class="bg-gray-200 h-5 w-1/2 rounded animate-pulse" />
          </slot>
        </div>
        <div class="text-left font-sans font-semibold px-2" v-if="hasBadgesSlot">
          <slot name="badges"></slot>
        </div>
        <div class="text-left font-sans font-semibold px-2" v-if="hasContentSlot">
          <slot name="content">
            <div class="bg-gray-200 h-4 w-1/3 mt-2 rounded animate-pulse" />
          </slot>
        </div>
        <div :class="summaryClass" v-if="hasSummarySlot">
          <card-summary v-if="rendering" class="col-span-4">
            <template v-slot:heading>
              <div class="bg-gray-200 h-3 w-1/4 rounded animate-pulse" />
            </template>
            <template v-slot:content>
              <div class="bg-gray-200 h-3 w-1/4 mt-1 rounded animate-pulse" />
            </template>
          </card-summary>
          <card-summary v-if="false && assistant" class="col-span-4">
            <template v-slot:heading>{{ $t('labels.assistant') }}</template>
            <template v-slot:content>{{ assistant.full_name }}</template>
          </card-summary>
          <card-summary v-if="time">
            <template v-slot:heading>{{ $t('time.time') }}</template>
            <template v-slot:content v-if="time === 'range'">{{ timeDisplay(start, timeFormat) }} - {{ timeDisplay(end, timeFormat) }}</template>
            <template v-slot:content v-else>{{ timeDisplay(start, timeFormat) }}</template>
          </card-summary>
          <card-summary v-if="congregation">
            <template v-slot:heading>{{ $tc('resources.congregation', 1) }}</template>
            <template v-slot:content>{{ congregation }}</template>
          </card-summary>
          <card-summary v-if="reference" class="col-span-2">
            <template v-slot:heading>{{ $t('labels.references.label') }}</template>
            <template v-slot:content><span v-html="reference"></span></template>
          </card-summary>
          <card-summary v-if="date === 'range'">
            <template v-slot:heading>{{ $t('time.date') }}</template>
            <template v-slot:content v-if="start.getMonth() !== end.getMonth()">{{ start | M_jS }} - {{ end | M_jS }}</template>
            <template v-slot:content v-else>{{ start | M_jS }} - {{ end | jS }}</template>
          </card-summary>
          <card-summary v-if="location" class="col-span-2">
            <template v-slot:heading>{{ $t('labels.location') }}</template>
            <template v-slot:content>
              <div v-if="typeof location === 'object'" class="break-words">
                {{ location.address }}, {{ location.suburb }} {{ location.postcode }}<br>
                {{ location.country }} {{ location.region }}
              </div>
              <div v-else>{{ location }}</div>
            </template>
          </card-summary>
          <card-summary v-if="publishers" class="col-span-2">
            <template v-slot:heading>{{ $tc('resources.publisher', 0) }}</template>
            <template v-slot:content>
              <div v-for="(p, key) in publishers" :key="'pub' + key">
                {{ p }}
              </div>
            </template>
          </card-summary>
          <card-summary v-if="online" class="col-span-3">
            <template v-slot:heading>{{ $t('labels.online') }}</template>
            <template v-slot:content><div class="break-all">{{ online }}</div></template>
          </card-summary>
        </div>
        <div class="bg-gray-50 border-t text-right p-2" v-if="actionSlot">
          <slot name="actions"></slot>
        </div>
      </div>
      <div v-if="loading" class="absolute bottom-0 h-1 overflow-hidden transition-all duration-1000" :class="'bg-' + color" :style="'width:' + loading + '%'"></div>
    </div>
    <div class="time-line bg-gray-200"></div>
  </div>
</template>
<script setup>
import { computed, useSlots } from 'vue'
import { store } from '@/store'
import { timeDisplay } from '@/helpers'

const props = defineProps([
  'start',
  'end',
  'color',
  'date',
  'dateYear',
  'time',
  'location',
  'publishers',
  'online',
  'assistant',
  'icon',
  'minutes',
  'reference',
  'loading',
  'rendering',
  'congregation',
  'actionSlot'
])

const slots = useSlots()

const summaryClass = computed(() => {
  let cl = 'grid gap-3 divide-x pt-3'
  let grid = 0
  if (props.time) grid++
  if (props.congregation) grid++
  if (props.reference) grid += 2
  if (props.date === 'range') grid++
  if (props.location) grid += 2
  if (props.publishers) grid += 2
  if (props.online) grid += 3
  cl += ' grid-cols-' + grid
  return cl
})

const timeFormat = computed(() => store.state.settings.cong.time_format)
const hasBadgesSlot = computed(() => !!slots.badges)
const hasContentSlot = computed(() => !!slots.content || props.rendering)
const hasSummarySlot = computed(() => props.assistant || props.time || props.location || props.online || props.rendering)

</script>

<style>
.time-line {
  position: absolute;
  left: 21px;
  width: 2px;
  top: 20px;
  z-index: -1;
  height: 100%;
}
</style>
