import axios from 'axios'
import { get } from '@/composables/serverInterface.js'
import { assign } from '@/composables/dataInterface.js'

export const GET_ALERTS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/alerts')
      .then(function (response) {
        commit('UPDATE_ALERTS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const HIDE_ALERT = ({ commit }, payload) => {
  commit('DISMISS_ALERT', payload.alert)
}

export const ACCEPT_PRIVACY_POLICY = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/privacy-policy/accept')
      .then(function (response) {
        get('profile').then(res => assign('profile', res))
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DECLINE_PRIVACY_POLICY = ({ dispatch }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/privacy-policy/decline')
      .then(function (response) {
        get('profile').then(response => {
          assign('profile', response)
          resolve(response)
        })
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
