<template>
  <portal to="menus">
    <div class="fixed inset-0 w-100 z-50">
      <div class="bg-gray-200 fixed inset-0 transition-opacity"
           :class="firstOpen ? 'opacity-0' : 'opacity-75 md:opacity-25'"
           @click="closeSelector"></div>
      <div class="absolute bg-white shadow opacity-0 transition-all inset-x-0 bottom-0 rounded-t-lg md:inset-auto md:rounded"
           ref="menu">
        <div class="absolute flex justify-center w-full" style="top: -45px">
          <div class="flex-grow flex justify-center">
            <div class="h-9 w-9 rounded-full bg-white md:hidden" @click="closeSelector"
                 :class="firstOpen ? 'opacity-0' : 'opacity-100'">
              <svg class="p-1.5 pt-2 fill-black" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z"/>
              </svg>
            </div>
          </div>
        </div>
        <div class="text-left py-4 md:py-2" style="max-height:70vh" ref="options">
          <slot></slot>
        </div>
      </div>
    </div>
  </portal>
</template>

<script setup>
import { ref, computed, onMounted } from 'vue'

const emit = defineEmits(['close'])
const props = defineProps({
  target: {
    type: String
  },
  breakpoint: {
    type: Number,
    default: 768
  }
})

const optionsHeight = ref(0)
const firstOpen = ref(true)
const menu = ref(null)

const el = computed(() => {
  if (props.target) {
    const el = document.getElementById(props.target)
    if (el) return el.getBoundingClientRect()
  }
  return null
})
const pixelsTop = computed(() => {
  if (el.value) {
    const { top } = el.value
    return top
  }
  return 1
})
const pixelsBottom = computed(() => {
  if (el.value) {
    const { top, height } = el.value
    return window.innerHeight - (top + height)
  }
  return 1
})
const pixelsLeft = computed(() => {
  if (el.value) {
    const { left } = el.value
    return left
  }
  return 1
})
const pixelsRight = computed(() => {
  if (el.value) {
    const { left, width } = el.value
    return window.innerWidth - (left + width)
  }
  return 1
})
const optionsWidth = computed(() => {
  return menu.value.clientWidth
})
const x = computed(() => {
  if (el.value) {
    if (pixelsLeft.value > optionsWidth.value) { // Display on left
      const { left } = el.value
      return left - optionsWidth.value
    } else if (pixelsRight.value > optionsWidth.value) { // Display on right
      const { right } = el.value
      return right
    }
  }
  return 0
})
const y = computed(() => {
  if (el.value) {
    if (pixelsBottom.value > optionsHeight.value) { // Display on top
      const { top } = el.value
      return top
    } else if (pixelsTop.value > optionsHeight.value) { // Display on bottom
      const { bottom } = el.value
      return bottom - optionsHeight.value
    }
  }
  return 0
})

const closeSelector = () => {
  firstOpen.value = true
  if (window.innerWidth < props.breakpoint) {
    menu.value.setAttribute(
      'style',
      'height:0px'
    )
  } else {
    menu.value.setAttribute(
      'style',
      'left: ' + x.value + 'px!important;top: ' + y.value + 'px!important;height:0px;overflow:hidden'
    )
  }
  setTimeout(() => {
    const body = document.getElementsByTagName('body')
    body[0].style.overflow = 'initial'
    emit('close')
  }, 200)
}

onMounted(() => {
  setTimeout(() => {
    optionsHeight.value = menu.value.clientHeight
    firstOpen.value = false
    menu.value.setAttribute(
      'style',
      'height:0px'
    )
    menu.value.classList.remove('opacity-0')
  }, 1)
  setTimeout(() => {
    menu.value.setAttribute(
      'style',
      'height: ' + optionsHeight.value + 'px'
    )
  }, 5)
  if (window.innerWidth < props.breakpoint) {
    const body = document.getElementsByTagName('body')
    body[0].style.overflow = 'hidden'
  } else {
    setTimeout(() => {
      menu.value.setAttribute(
        'style',
        'left: ' + x.value + 'px!important;top: ' + y.value + 'px!important;height: ' + optionsHeight.value + 'px'
      )
    }, 10)
  }
})

</script>
