<template>
  <div>
    <action :secondary="true" :small="true" @click="create">
      {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.address', 1) }) }}
    </action>
    <div class="w-100 mt-2">
      <div v-for="(s, key) in availableStreets" :key="'s1' + key">
        <div class="flex align-items-center justify-content-between py-1 border-t">
          <div>
            <street-label :street="s" />
            <div>
              <small>
                {{ $t('resources.count', {count: availableInStreet(s).length, resource: $tc('resources.address', availableInStreet(s).length) }) }}
              </small>
            </div>
          </div>
          <div>
            <action :default="true" :small="true" @click="$emit('expand', s.id)">
              <span v-if="expandedStreet === s.id">{{ $t('actions.collapse') }}</span>
              <span v-else>{{ $t('actions.expand') }}</span>
            </action>
          </div>
        </div>
        <div v-for="(address, akey) in availableInStreet(s)" :key="'sf' + akey">
          <address-manage v-if="expandedStreet === s.id" :address="address" />
        </div>
      </div>
    </div>

    <modal :header="$t('labels.foreign.add')" :active.sync="editActive" @submit="submit">
      <template v-slot:default="{ error }">
        <tuxedo-select name="street" :label="$t('fields.street.label')" v-model="resource.street" :error="error"
                       :options="sortedStreets" insistLabel="name" inputClass="bg-gray-100" insistReturn="id" />
        <tuxedo-input name="house" :label="$t('labels.house_number.label')" type="number" v-model="resource.house"
                      inputClass="bg-gray-100" :error="error" :help="$t('labels.house_number.tip')" />
        <tuxedo-input name="suffix" :label="$t('labels.house_suffix.label')" :error="error" v-model="resource.suffix"
                      :help="$t('labels.house_suffix.tip', { number: resource.house || 10, range: parseInt(resource.house || 10) + 2 })"
                      inputClass="bg-gray-100" />
        <tuxedo-input name="unit" :label="$t('labels.house_unit.label')" v-model="resource.unit" :error="error"
                      inputClass="bg-gray-100" :help="$t('labels.house_unit.tip', { number: resource.house || 10 })" />
      </template>
      <template #preview>
        <div v-if="resource.street" class="p-2 m-2 rounded bg-white border-2 border-green-600 animate__animated" id="addressPreview" :class="addressCheck ? '' : 'animate__tada'">
          <div class="grid grid-cols-4 items-center">
            <div class="col-span-3">
              <street-label :street="selectedStreet" />
              <address-label :address="resource" />
            </div>
            <div class="flex justify-end">
              <check-animation :state="addressCheck" />
            </div>
          </div>
        </div>
      </template>
    </modal>

  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { store } from '@/store'
import { filter, find, orderBy } from 'lodash'
import { post } from '@/composables/serverInterface'
import { push } from '@/composables/dataInterface'
import AddressLabel from '@/components/maps/AddressLabel'
import StreetLabel from '@/components/maps/StreetLabel'
import CheckAnimation from '@/components/animations/CheckAnimation'
import AddressManage from '@/components/maps/AddressManage'

const props = defineProps(['map', 'resources', 'expandedStreet'])

const streets = computed(() => store.state.streets)

const sortedStreets = computed(() => {
  return orderBy(streets.value, ['name'])
})
const availableStreets = computed(() => {
  return filter(sortedStreets.value, s => {
    return find(props.resources, f => f.street === s.id)
  })
})
const selectedStreet = computed(() => {
  return find(streets.value, s => s.id === resource.value.street)
})

const initialResource = {
  id: null,
  assigned_id: null,
  assigned_map_id: null,
  street: null,
  street_name: null,
  house: null,
  unit: null,
  lang: null,
  type: null
}

const resource = ref({
  street: null
})
const activeID = ref(null)
const editActive = ref(false)
const addressCheck = ref(null)

const availableInStreet = (s) => {
  const filtered = filter(props.resources, a => a.street === s.id)
  return orderBy(filtered, ['house', 'suffix', 'unit'])
}
const create = () => {
  activeID.value = null
  resource.value = { ...initialResource }
  editActive.value = true
}
const submit = () => {
  addressCheck.value = 'processing'
  post(['available-territory'], { ...resource.value, map: props.map })
    .then(res => {
      addressCheck.value = 'complete'
      setTimeout(() => {
        addressCheck.value = null
        if (resource.value.unit) resource.value.unit = null
        else if (resource.value.suffix) resource.value.suffix = null
        else if (resource.value.house) resource.value.house = null
      }, 3000)
      push('availableAddresses', res)
    })
    .catch(() => {
      addressCheck.value = null
    })
}
</script>
