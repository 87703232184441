import axios from 'axios'
import store from '../store'

const MAX_REQUESTS_COUNT = 3
const INTERVAL_MS = 10
let PENDING_REQUESTS = 0

// It's bad practice to include interceptors on every axios request, so we create an
// additional instance we can use when we want to invoke our interceptors
const queue = axios.create()
queue.defaults.baseURL = process.env.VUE_APP_URL

queue.interceptors.request.use(request => {
  return new Promise((resolve, reject) => {
    const interval = setInterval(() => {
      if (PENDING_REQUESTS < MAX_REQUESTS_COUNT) {
        PENDING_REQUESTS++
        clearInterval(interval)

        // Set the toast message
        const message = request.data && request.data.message ? request.data.message : null
        const icon = request.data && request.data.icon ? request.data.icon : null
        store.commit('updateQueue', { url: request.url, message: message, icon: icon })
        // updateQueue(state, { url: request.url, message: message, icon: icon })
        // Start the response timer
        request.headers['request-startTime'] = new Date().getTime()
        resolve(request)
      }
    }, INTERVAL_MS)
  })
})

queue.interceptors.response.use(response => {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)

  // Calculate response time in seconds
  const start = response.config.headers['request-startTime']
  const end = new Date().getTime()
  response.headers['request-duration'] = Math.abs((end - start) / 1000)

  // Store response time and clear this request from the queue
  store.commit('recordResponseTime', { url: response.config.url, seconds: response.headers['request-duration'] })
  store.commit('removeFromQueue', { url: response.config.url })

  // If pending requests is zero then clear the queue, just in case something got stuck
  if (PENDING_REQUESTS === 0) store.commit('removeFromQueue')

  return Promise.resolve(response)
}, error => {
  PENDING_REQUESTS = Math.max(0, PENDING_REQUESTS - 1)
  return Promise.reject(error)
})

export default queue
