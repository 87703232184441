import Vue from 'vue'
import _ from 'lodash'

export const UPDATE_PUBLIC_MEETINGS = (state, data) => {
  state.meetings = data
}

export const UPDATE_AWAY_TALKS = (state, data) => {
  state.away_talks = data
}

export const UPDATE_PUBLIC_SPEAKERS = (state, data) => {
  state.speakers = data
}

export const UPDATE_PTC = (state, data) => {
  state.ptc = data
}

export const UPDATE_PUBLIC_SPEAKER_SCHEDULE = (state, data) => {
  const id = _.findIndex(state.meetings, (meeting) => {
    return meeting.id === data.id
  })
  Vue.delete(state.meetings, id)
  state.meetings.push(data)
}
