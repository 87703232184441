import Vue from 'vue'

export const axiosError = (state, val) => {
  if (val && val.response && val.response.data) state.axiosError = val.response.data
  else state.axiosError = null
}

export const AUTHORISED = (state, data) => {
  state.online = true
  state.sid = data.sid
  state.group = data.group
  state.name = data.name
  state.email = data.email
}

export const UPDATE_TOKEN = (state, data) => {
  state.token = data
}

export const UPDATE_SOURCE = (state, data) => {
  state.source = data
}

export const ASSIGN_PERMITS = (state, permits) => {
  state.permits = permits
}

export const UNAUTHORISED = (state) => {
  state.online = false
  state.sid = null
}

export const BACKGROUND_LOADING = (state, data) => {
  state.backgroundLoading = data
}

export const AUTO_REFRESH = (state, data) => {
  state.autoRefresh = data
}

export const TEMP_TO = (state, data) => {
  state.tempTo = data
}

export const UPDATE_REMOTE_CACHE = (state, data) => {
  Object.keys(data).forEach(key => {
    Vue.set(state.remoteCache, key, data[key])
  })
}

export const UPDATE_LOCAL_CACHE = (state, data) => {
  Vue.set(state.localCache, data.key, data.value)
}

export const AXIOS_ERROR = (state, val) => {
  state.appDown = false
  if (val && val.response && val.response.data) {
    if (val.response.status === 503) state.appDown = true
    state.axiosError = val.response.data
  } else state.axiosError = null
}

export const SUBMIT_ERROR = (state, data) => {
  state.submitError = data || null
}

export const UPDATE_VERSION = (state, data) => {
  state.remoteVersion = data
}

export const UPDATE_CONGREGATIONS = (state, data) => {
  state.congs = data
}

export const UPDATE_CARTS = (state, data) => {
  state.cartLocations = data
}

export const PUSH_CONGREGATION = (state, data) => {
  state.congs.push(data)
}

export const RESET_LOAD_REQUESTS = (state) => {
  state.complete_load_requests = 0
}

export const UPDATE_LOAD_REQUESTS = (state) => {
  state.complete_load_requests += 1
}

export const UPDATE_LANGS = (state, data) => {
  state.langs = data
}
