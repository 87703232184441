<template>
  <nav class="fixed bottom-0 inset-x-0 bg-white z-40 py-2 border-t-2 border-gray-200" role="navigation" aria-label="main-navigation" id="nav-main">

    <div class="flex justify-around">
      <router-link :to="{ name: 'Dashboard'}" v-slot="{ href, navigate, isExactActive }" id="nav-maps">
        <a :href="href" @click="navigate">
          <nav-option :icon="'map'" :label="$t('nav.maps.label')" :active="isExactActive" />
        </a>
      </router-link>
      <router-link to="/assignments/" v-slot="{ href, navigate, isActive }" @click.native="callbackTour()" id="nav-assignments">
        <a :href="href" @click="navigate">
          <nav-option :icon="'list-alt'" :label="$t('nav.assignments.label')" :active="isActive" />
        </a>
      </router-link>
      <router-link to="/schedules/" v-slot="{ href, navigate, isActive }" @click.native="callbackTour()" id="nav-schedules">
        <a :href="href" @click="navigate">
          <nav-option :icon="'calendar-alt'" :label="$t('nav.schedules.label')" :active="isActive" />
        </a>
      </router-link>
      <router-link to="/settings/" v-slot="{ href, navigate, isActive }" @click.native="callbackTour()" id="nav-settings">
        <a :href="href" @click="navigate">
          <nav-option :icon="'cog'" :label="$t('nav.settings.label')" :active="isActive" />
        </a>
      </router-link>
    </div>
  </nav>
</template>

<script>
import NavOption from './elements/NavOption'

export default {
  name: 'Navigation',
  components: { NavOption },
  methods: {
    callbackTour () {
      if (this.$tours['welcome-tour'].currentStep === 4) {
        this.$tours['welcome-tour'].currentStep++
      }
      if (this.$tours['welcome-tour'].currentStep === 10) {
        this.$tours['welcome-tour'].currentStep++
      }
      if (this.$tours['welcome-tour'].currentStep === 13) {
        this.$tours['welcome-tour'].currentStep++
      }
      if (this.$tours['attendants-schedule-tour'].currentStep === 9) {
        this.$tours['attendants-schedule-tour'].currentStep++
      }
      if (this.$tours['attendants-schedule-tour'].currentStep === 16) {
        this.$tours['attendants-schedule-tour'].currentStep++
      }
      if (this.$tours['attendants-schedule-tour'].currentStep === 19) {
        this.$tours['attendants-schedule-tour'].currentStep++
      }
    }
  }
}
</script>
