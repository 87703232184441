<template>
  <div>
    <badge class="cursor-pointer"
           @click="assign"
           :color="partHasAssignee ? part.meeting + '-400' : 'gray-300'"
           v-html="partHasAssignee ? find(users, 'id', partHasAssignee, 'full_name') : partTag">
    </badge>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { put, post } from '../../../mixins/api'
import { merge } from '../../../mixins/storeMutations'

export default {
  name: 'PartHelper',
  props: {
    part: {
      required: false
    },
    hall: {
      required: false,
      default () { return 1 }
    }
  },
  mixins: [put, post, merge],
  data () {
    return {
      assignment: null,
      partHasAssignee: null
    }
  },
  computed: {
    ...mapState({
      users: state => state.users,
      permits: state => state.core.permits
    }),
    partTag () {
      if (this.part.meeting === 'living' && this.part.part === 'study') return 'Reader'
      if (this.part.meeting === 'watchtower' && this.part.part === 'discussion') return 'Reader'
      return 'Householder'
    }
  },
  created () {
    this.processPart()
  },
  watch: {
    part: {
      deep: true,
      handler () {
        this.processPart()
      }
    }
  },
  methods: {
    processPart () {
      this.assignment = null
      this.partHasAssignee = null
      if (this.part && this.part.assignments) {
        let key = 0
        key = this.part.assignments.findIndex(a => a.hall === this.hall)
        if (this.part.assignments[key]) {
          this.assignment = this.part.assignments[key]
          this.partHasAssignee = this.part.assignments[key].helper_id
        }
      }
    },
    assign () {
      if (!this.permits['schedules.meetings.assign']) return
      this.$emit('assign', this.assignment, this.hall, 'helper')
    }
  }
}
</script>
