import helpers from '../helpers'

export const find = {
  methods: {
    find (objects, match, value, key) {
      const obj = objects.find(o => o[match] === value)
      if (obj && key && obj[key]) return obj[key]
      if (obj) return obj
      return null
    }
  }
}

export const resourceValid = {
  data () {
    return {
      requiredResourceFields: []
    }
  },
  computed: {
    resourceValid () {
      return this.validate('resource')
    }
  },
  methods: {
    validate (resource) {
      let valid = true
      const rules = 'required' + helpers.firstToUpper(resource) + 'Fields'
      this[rules].forEach(f => {
        if (!this[resource][f]) valid = false
      })
      return valid
    }
  }
}

export const resetData = {
  mounted () {
    this.$data.mountedState = JSON.parse(JSON.stringify({ ...this.$data, mountedState: undefined }))
  },
  methods: {
    resetData (key) {
      this.$data[key] = JSON.parse(JSON.stringify(this.$data.mountedState[key]))
    }
  }
}

export const fillObject = {
  methods: {
    fillObject (target, source, timeout = 200) {
      return new Promise(resolve => {
        // This timeout just provides a short amount of time for dynamic fields to mount before setting the data we want
        setTimeout(() => {
          Object.keys(target).forEach(key => {
            if (source[key]) target[key] = source[key]
          })
          return resolve()
        }, timeout)
      })
    }
  }
}

export const labels = {
  data () {
    return {
      labels: {
        name: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 0),
        create: this.$t('actions.action_resource', { action: this.$t('actions.create'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        edit: this.$t('actions.action_resource', { action: this.$t('actions.edit'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        delete: this.$t('actions.action_resource', { action: this.$t('actions.delete'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        send: this.$t('actions.action_resource', { action: this.$t('actions.send'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        view: this.$t('actions.action_resource', { action: this.$t('actions.view'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        assign: this.$t('actions.action_resource', { action: this.$t('actions.assign'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        download: this.$t('actions.action_resource', { action: this.$t('actions.download'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        upload: this.$t('actions.action_resource', { action: this.$t('actions.upload'), resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) })
      },
      messages: {
        delete: this.$t('messages.delete_resource', { resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 1) }),
        loading: this.$t('messages.simple_loading', { resource: this.$tc('resources.' + helpers.camelToSnake(helpers.singular(this.$options.name)), 0) })
      }
    }
  }
}

export const watchWindowSize = {
  data () {
    return {
      mobileView: false
    }
  },
  created () {
    this.windowSize()
    window.addEventListener('resize', this.windowSize)
  },
  destroyed () {
    window.removeEventListener('resize', this.windowSize)
  },
  methods: {
    windowSize () {
      this.mobileView = window.innerWidth <= 512
    }
  }
}
