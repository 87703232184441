<template>
  <div></div>
</template>

<script setup>
import { onMounted } from 'vue'
import { useProfileStore } from '@/stores/profile'
const profile = useProfileStore()

onMounted(() => {
  profile.sync()
})

window.addEventListener('online', () => {
  profile.appOnline = true
  setTimeout(() => {
    if (profile.appOnline) {
      profile.sync()
    }
  }, 4000)
})

window.addEventListener('offline', () => {
  profile.appOnline = false
})
</script>
