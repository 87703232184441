import axios from 'axios'

export const GET_PUBLIC_TALK_SUBJECTS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('https://srvc.app/api/public-talk-subjects/?t=' + payload.token)
      .then(function (response) {
        commit('SET_PUBLIC_TALK_SUBJECTS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_PUBLIC_TALK_TITLES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/public-talk-titles', payload && payload.assignment ? payload.assignment : {})
      .then(function (response) {
        commit('SET_PUBLIC_TALK_TITLES', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const SUBMIT_PUBLIC_TALK_TITLE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('https://srvc.app/post/public-talk-title/?t=' + payload.token, JSON.stringify(payload.talk))
      .then(function (response) {
        commit('ADD_PUBLIC_TALK_TITLE', payload.talk)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_PUBLIC_TALK_TITLE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('https://srvc.app/post/public-talk-title/delete/?t=' + payload.token, JSON.stringify(payload.talk))
      .then(function (response) {
        commit('REMOVE_PUBLIC_TALK_TITLE', payload.talk)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
