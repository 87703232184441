export const UPDATE_ALERTS = (state, data) => {
  state.alerts = data
}

export const DISMISS_ALERT = (state, data) => {
  console.log(data)
  state[data] = false
}

export const SHOW_ALERTS = (state) => {
  state.nextGroupActive = true
  state.nextCartActive = true
  state.publicTalkActive = true
  state.bannerActive = true
  state.onlineMeetingActive = true
  state.subscribedTopicsActive = true
  state.subscribePromptActive = true
}

export const UPDATE_PUBLIC_MEETING_ALERT = (state, data) => {
  state.public_meeting = data
}
