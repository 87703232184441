<template>

    <div class="field">
        <label class="label">{{ label }}</label>
        <div class="control">
            <label v-for="option in options" :key="'cbo' + option.id" class="checkbox toggle-button">
                <input ref="options" type="checkbox" @change="updateValue()" :name="label" :value="option.id" v-model="inputChecked">
                <span class="toggle-contain">
                    <span class="toggle">
                        <span class="LED"></span>
                    </span>
                </span>
                <span class="toggle-label" v-html="option.label"></span>
            </label>
            <p class="help" v-if="help">{{ help }}</p>
        </div>
    </div>

</template>

<script setup>
import { ref, watch } from 'vue'

const emit = defineEmits(['input'])
const props = defineProps({
  label: String,
  help: String,
  value: Array,
  options: Array
})

const inputOptions = ref({})
const inputChecked = ref([])

watch(() => props.options, (newVal) => {
  inputOptions.value = newVal
})

watch(() => props.value, (newVal) => {
  inputChecked.value = newVal
})

const updateValue = () => {
  emit('input', inputChecked.value.filter(n => n))
}

</script>

<style>

    label.checkbox {
        display: block;
        line-height: 24px;
    }

</style>
