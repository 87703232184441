export function configureUri (uri) {
  if (Array.isArray(uri)) {
    let string = ''
    uri.forEach(p => { string += '/' + p })
    uri = string
  }
  if (uri.charAt(0) !== '/') uri = '/' + uri

  return uri
}
