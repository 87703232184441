<template>

  <div class="field">
    <label class="label">{{ label }}</label>
    <div class="control has-icons-right">
      <span class="icon is-small is-right cursor-pointer text-dark" style="z-index: 100" @click="inputValue = null">
        <fa-icon :icon="['fal', 'calendar-times']" />
      </span>
      <datetime :input-class="'input'" :value-zone="'local'" v-model="inputValue"></datetime>
      <p class="help" v-if="help" v-html="help"></p>
    </div>
  </div>

</template>

<script setup>
import 'vue-datetime/dist/vue-datetime.css'
import { computed, watch } from 'vue'

const emit = defineEmits(['input'])
const props = defineProps(['label', 'help', 'value'])

const inputValue = computed({
  get () {
    return props.value
  },
  set (val) {
    emit('input', val)
  }
})

watch(() => props.value, (newVal) => {
  inputValue.value = newVal
})

</script>
