<template>

  <view-content v-if="cart" :back="{name:'Schedules'}">

    <template v-slot:header>
      {{ cart.cart.location }}
    </template>

    <template v-slot:subtext>
      {{ $t('time.week_starting', { date: cart.cart.start_date }) }}
    </template>

    <div class="cart-location">
      <div class="bg-gray-300 flex justify-between p-2 items-center">
        <button class="flex items-center" @click="weekBack()">
          <fa-icon :icon="['fas', 'chevron-left']" class="h-5 mr-2" />
          <span>{{ $t('time.previous_week') }}</span>
        </button>
        <button @click="currentWeek()">{{ $t('time.current_week') }}</button>
        <button class="flex items-center" @click="weekOn()">
          <span>{{ $t('time.next_week') }}</span>
          <fa-icon :icon="['fas', 'chevron-right']" class="h-5 ml-2" />
        </button>
      </div>
      <table class="clickable-data-table cross-header align-middle" style="font-size:12px;">
        <thead class="calendar">
        <tr>
          <th></th>
          <th v-for="day in cart.appointments" :key="'day' + day.date">
            <span>{{ day.date }}</span>
            <span>{{ $t('time.shortDay.' + day.key) }}</span>
          </th>
        </tr>
        </thead>
        <tbody v-if="times.length > 0">
        <tr v-for="time in times" :key="'time' + time">
          <th style="text-align:center;padding:0" :style="mobileView ? 'width:30px' : 'width:50px'">
            <span v-if="time.substring(3,5) === '00'">
              {{ timeLegend(time) }}
            </span>
            <span v-else>.</span>
          </th>
          <td
            v-for="(day, key) in filterAppointments(time)"
            :key="'ca' + key"
            style="line-height:1.5em;"
            :class="day.slots[time].class"
            :rowspan="day.slots[time].hours*2"
            :data-date="day.date"
            :data-time="time"
            :data-key="key"
            @click="!day.slots[time].blocked ? openAppointmentBooker(day.key, time) : null"
          >
            <div :class="appointmentClass(day.slots[time])">{{ appointmentLabel(day.slots[time]) }}</div>
          </td>
        </tr>
        </tbody>
      </table>
    </div>

    <modal :header="$t('labels.new_appointment.label', { time: booking.readable_start_time })" :active.sync="appointmentActive" @submit="submitBooking()" :validated="appointmentValid">
      <template v-slot:default="{ error }">
      <tuxedo-select :label="$tc('resources.publisher', 0)" v-model="booking.publishers" :options="cartApprovedUsers" :multi="true"
                     :inputClass="'bg-gray-100'" :insistLabel="'last_first'" :name="'publishers'" :insistReturn="'id'"
      :error="error" />
      <input-slider :label="$tc('resources.hour', 0)" v-model="booking.minutes" :options="availableHours" />
      </template>
    </modal>

  </view-content>

</template>

<script>
import { mapState } from 'vuex'
import { get, post, put } from '../../mixins/api'
import { Sec } from '../../mixins/sec'
import { find, watchWindowSize } from '../../mixins/helpers'

export default {
  name: 'CartSchedule',
  mixins: [get, post, put, find, watchWindowSize],
  data () {
    return {
      cart: {
        appointments: [],
        cart: {
          blocks: [],
          end_time: '17:00'
        }
      },
      appointmentActive: false,
      booking: {
        id: null,
        cart_location_id: null,
        publishers: [],
        start_at: null,
        minutes: 60
      },
      minutes: {
        60: '1',
        90: '1½',
        120: '2',
        150: '2½',
        180: '3',
        210: '3½',
        240: '4'
      },
      times: [],
      offset: 0,
      error: false,
      loading: true
    }
  },
  computed: {
    ...mapState({
      permits: state => state.core.permits,
      users: state => state.users,
      carts: state => state.cartLocations,
      congregations: state => state.congregations,
      cong: state => state.settings.cong
    }),
    bookingAdvance () {
      return parseInt(this.cong.modules.cart_calendar.booking_advance)
    },
    appointmentValid () {
      return (this.booking.publishers && this.booking.publishers.length > 0) && this.booking.hours
    },
    cartApprovedUsers: function () {
      let users = this.users.filter(pub => pub.cart === 1)
      users = this._.sortBy(users, [function (user) {
        return user.last_name
      }])
      return users
    },
    availableHours () {
      let hours = {}
      const booking = this.booking
      const blocks = this.cart.cart.blocks
      const endTime = this.cart.cart.end_time.split(':')
      const bookingStartTime = new Sec(booking.start_at)
      const apts = this.cart.appointments[bookingStartTime.dayOfWeek()]
      const finalTimeSlot = new Sec(booking.start_at).hour(endTime[0]).minute(endTime[1])
      const bs = new Sec(booking.start_at)
      const be = new Sec(booking.start_at)
      hours = this._.mapKeys(this.minutes, function (hour, key) {
        if (bookingStartTime.isBefore(finalTimeSlot)) {
          let canBook = true
          if (blocks) {
            blocks.forEach(block => {
              if (bookingStartTime.dayOfWeek() === block.day) {
                bs.applyTimeString(block.start_time).subtract(30, 'm')
                be.dayOfWeek(block.day).applyTimeString(block.end_time)
                if (bookingStartTime.isBefore(bs) || bookingStartTime.isAfter(be) || bookingStartTime.isSame(be)) {
                  canBook = true
                } else canBook = false
              }
            })
          }
          if (apts && apts.slots) {
            parent._.mapKeys(apts.slots, function (slot, key) {
              if (slot.class.includes('active') && slot.id !== booking.id) {
                bs.applyTimeString(key).subtract(30, 'm')
                be.applyTimeString(key).add(slot.hours, 'h')
                if (bookingStartTime.isBefore(bs) || bookingStartTime.isAfter(be) || bookingStartTime.isSame(be)) {
                  canBook = canBook || false
                } else canBook = false
              }
            })
          }
          if (canBook) {
            bookingStartTime.add(30, 'm')
            return key
          }
        }
      })

      this.$delete(hours, 'undefined')
      return hours
    }
  },
  created () {
    this.cart.cart = this.find(this.carts, 'id', parseInt(this.$route.params.id))
    this.getAppointments()
  },
  methods: {
    filterAppointments (time) {
      return this._.filter(this.cart.appointments, a => a.slots[time])
    },
    timeLegend (time) {
      if (this.cong.time_format === 24) {
        return new Sec().applyTimeString(time).format('G')
      } else if (this.mobileView) {
        return new Sec().applyTimeString(time).format('ga').slice(0, -1)
      } else {
        return new Sec().applyTimeString(time).format('ga')
      }
    },
    appointmentLabel (app) {
      let label = ''
      if (app.congregation_id === this.cong.id) {
        if (app.publishers) {
          const pubs = []
          app.publishers.forEach(p => {
            pubs.push(this.find(this.users, 'id', p, 'first_name'))
          })
          label = pubs.join(', ')
        }
      } else {
        label = this.find(this.congregations, 'id', app.congregation_id, 'name')
      }
      return label
    },
    appointmentClass (app) {
      let string = ''
      if (app.congregation_id === this.cong.id) {
        string = 'bg-blue-400'
      } else if (app.congregation_id) {
        string = 'bg-gray-400'
      }
      return string
    },
    setTimes () {
      this.times = []
      const ct = new Sec().applyTimeString(this.cart.cart.start_time)
      const et = new Sec().applyTimeString(this.cart.cart.end_time)
      while (ct.isBefore(et)) {
        this.times.push(ct.format('H:i'))
        ct.add(30, 'm')
      }
      // One final push to get the last half hour of cart booking times
      this.times.push(ct.format('H:i'))
    },
    openAppointmentBooker (day, time) {
      if (!this.permits['schedules.carts.always_book']) {
        if (!this.cong.modules.cart_calendar.cart_booking) return
      }

      if (this.cart.appointments[day].slots[time].congregation_id && this.cart.appointments[day].slots[time].congregation_id !== this.cong.id) return

      this.booking.id = this.cart.appointments[day].slots[time].id
      this.booking.publishers = this.cart.appointments[day].slots[time].publishers
      this.booking.minutes = Math.max(parseInt(this.cart.appointments[day].slots[time].hours * 60), 2)
      this.booking.start_at = this.cart.appointments[day].slots[time].date
      this.booking.readable_start_time = this.cart.appointments[day].slots[time].start_time
      this.appointmentActive = true
    },
    getAppointments () {
      this.get(['cart-locations', this.$route.params.id], {
        resource: 'cart_booking',
        params: {
          offset: this.offset
        }
      }).then(res => {
        this.cart = res.data.data
        this.booking.cart_location_id = this.cart.cart.id
        this.setTimes()
      })
    },
    weekOn () {
      if (this.bookingAdvance === 0) this.offset = this.offset + 7
      else if ((this.bookingAdvance * 7) > this.offset) this.offset = this.offset + 7
      else if (this.permits['schedules.carts.always_book']) this.offset = this.offset + 7
      this.getAppointments()
    },
    weekBack () {
      this.offset = this.offset - 7
      this.getAppointments()
    },
    currentWeek () {
      this.offset = 0
      this.getAppointments()
    },
    submitBooking () {
      if (this.booking.id) {
        this.put(['cart-schedules', this.booking.id], this.booking).then(() => {
          this.appointmentActive = false
          this.getAppointments()
        })
      } else {
        this.post('cart-schedules', this.booking).then(() => {
          this.appointmentActive = false
          this.getAppointments()
        })
      }
    }
  }

}
</script>
