<template>
  <div ref="bannerPrompt" class="notification is-banner" :class="collapse ? 'collapsing' : ''" v-if="on">
    <button class="delete" @click="dismissAlert('bannerActive')"></button>
    <strong>{{ cong.modules.banner_notification.title }}</strong><br v-if="cong.modules.banner_notification.title">
    <div v-html="cong.modules.banner_notification.content"></div>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'BannerPrompt',
  data () {
    return {
      collapse: false,
      height: null
    }
  },
  mounted () {
    this.height = this.$refs.bannerPrompt ? this.$refs.bannerPrompt.clientHeight : 0
  },
  computed: {
    ...mapState({
      bannerActive: state => state.dashboard.bannerActive,
      modules: state => state.settings.cong.modules,
      cong: state => state.settings.cong
    }),
    on () {
      return this.bannerActive && this.modules && this.modules.banner_notification && this.modules.banner_notification.on
    }
  },
  methods: {
    dismissAlert (alert) {
      this.collapse = true
      this.$refs.bannerPrompt.style.height = this.height + 'px'
      setTimeout(() => {
        this.$refs.bannerPrompt.style.height = 0
        this.$refs.bannerPrompt.style.padding = 0
        this.$refs.bannerPrompt.style.margin = 0
        setTimeout(() => {
          this.collapse = false
          this.$store.dispatch('HIDE_ALERT', { alert: alert })
        }, 300)
      }, 500)
    }
  }
}
</script>
