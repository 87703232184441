import axios from 'axios'

export const GET_ASSIGNMENTS = ({ commit }) => {
  return new Promise((resolve, reject) => {
    axios.get('api/assignments')
      .then(function (response) {
        commit('UPDATE_ASSIGNMENTS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_ADDRESS_OPTIONS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/address-options')
      .then(function (response) {
        commit('UPDATE_ADDRESS_OPTIONS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_ADDRESSES = ({ dispatch, commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/request-territory', payload.mapAddressChoice)
      .then(function (response) {
        // TODO: Improve speed by only loading the newly requested addresses instead of all assignments
        dispatch('GET_ASSIGNMENTS').then(() => {
          resolve(response)
        })
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const CLEAR_ADDRESS_CONTACT = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/address/clear-contact', payload)
      .then(function (response) {
        commit('UPDATE_ADDRESS', payload)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const ASSIGN_ADDRESSES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/reassign-addresses', payload)
      .then(function (response) {
        commit('UPDATE_ADDRESSES', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const HAND_IN_ADDRESSES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/return-territory')
      .then(function (response) {
        commit('CLEAR_ADDRESSES')
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_MAP_OPTIONS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/map-options')
      .then(function (response) {
        commit('UPDATE_MAP_OPTIONS', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const REQUEST_MAP = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/map/request', payload)
      .then(function (response) {
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_CART_ASSIGNMENT = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/cart-cancel', payload)
      .then(function (response) {
        dispatch('GET_ASSIGNMENTS').then(() => {
          resolve(response)
        })
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const REQUEST_CHANGE_ASSIGNMENT = ({ dispatch }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/special-service-group/request-change', payload.assignment)
      .then(function (response) {
        dispatch('GET_ASSIGNMENTS').then(() => {
          resolve(response)
        })
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
