import { defineStore } from 'pinia'
import axios from 'axios'
import { camelToKebab } from '@/helpers'

function configureUri (uri) {
  if (Array.isArray(uri)) {
    let string = ''
    uri.forEach(p => { string += '/' + camelToKebab(p) })
    uri = string
  }
  if (uri.charAt(0) !== '/') uri = '/' + camelToKebab(uri)

  return uri
}

export const useAPIStore = defineStore({
  id: 'API',
  state: () => ({
    error: false,
    submitting: false,
    loading: false
  }),
  getters: {},
  actions: {
    setError (error) {
      this.error = error
    },
    setSubmitting (submitting) {
      this.submitting = submitting
    },
    setLoading (loading) {
      this.loading = loading
    },
    get (uri, config = {}) {
      uri = configureUri(uri)

      return new Promise(resolve => {
        if (!navigator.onLine) resolve()
        axios.get('/api' + uri, config)
          .then(response => {
            resolve(response)
          })
          // .catch(err => {
        // if (err.response.status === 401) {
        //   const profile = useProfileStore()
        //   profile.checkAuthentication()
        // }
          // })
      })
    }
  }
})
