export const state = {
  cong: {
    name: '',
    location: '',
    sms_user: '',
    sms_pass: '',
    device: null,
    assigned_map_cutoff: 0,
    expired_addresses: 0,
    email_addresses_to: null,
    country: null,
    timezone: null,
    circuit: null,
    cart_booking: 1,
    cart_booking_advance: 0,
    reporting: null,
    blocks_as_letters: 0,
    accounts_pub: null,
    maps_updated_at: null,
    pubs_updated_at: null,
    events_updated_at: null
  },
  timezones: []
}
