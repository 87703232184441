<template>
  <div>
  <modal :header="$t('labels.congregation.ptc')" :active="active" @submit="submit" @close="emit('close')">
    <template v-slot:default="{ error }">
      <input-text :name="'name'" :label="$t('fields.name.label')" :type="'text'" v-model="resource.full_name" :error="error" />
      <input-text :name="'email'" :label="$t('fields.email.label')" :type="'email'" v-model="resource.email" :error="error" />
      <input-text :name="'phone'" :label="$t('fields.mobile.label')" :type="'text'" v-model="resource.phone" :error="error" />
    </template>
  </modal>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { post } from '@/composables/serverInterface.js'

const props = defineProps(['congregation', 'ptc', 'active'])
const emit = defineEmits(['close'])

const initialResource = {
  congregation: null,
  full_name: null,
  email: null,
  phone: null
}
const resource = ref({ ...initialResource })

watch(() => props.active, async (active) => {
  if (active === true) {
    resource.value = { ...initialResource }
    if (props.ptc) {
      Object.keys(resource.value).forEach(key => {
        if (props.ptc[key]) resource.value[key] = props.ptc[key]
      })
    }
  }
})

const submit = () => {
  resource.value.congregation = props.congregation.id
  post(['congregation', props.congregation.id, 'ptc'], resource.value).then(res => {
    emit('saveResponse', res)
  })
}

</script>
