<template>
  <view-content :back="{name:'Translations'}">

    <template v-slot:header>
      {{ $t('languages.'+iso) }}
    </template>

    <sub-menu class="mt-3">
      <sm-item v-for="(data, label) in sections" :key="label">
        <router-link :to="'/settings/translations/' + iso + '/' + label">{{ label }}</router-link>
      </sm-item>
    </sub-menu>
  </view-content>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { useRoute } from 'vue-router/composables'
import { i18n, requestLanguage } from '@/i18n'

const route = useRoute()

const iso = computed(() => route.params.iso)
const sections = computed(() => i18n.getLocaleMessage('en'))

onMounted(() => {
  requestLanguage(iso.value, false)
})

</script>
