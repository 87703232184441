import Vue from 'vue'
import _ from 'lodash'

export const UPDATE_ASSIGNMENTS = (state, data) => {
  state.maps = data.maps
  state.cart_reports = data.cart_reports
  state.available_territory = data.available_territory
  state.available_territory_map = data.available_territory_map
  state.carts = data.carts
  state.special_groups = data.special_groups
  state.meeting_parts = data.meeting_parts
  state.attendants = data.attendants
}

export const UPDATE_ADDRESS_OPTIONS = (state, data) => {
  data = _.filter(data, (e) => e.assignment !== null)
  state.mapAddressOptions = _.orderBy(data, (e) => e.assignment.assigned_at)
}

export const UPDATE_ADDRESSES = (state, data) => {
  state.available_territory = data.available_territory
}

export const UPDATE_ADDRESS = (state, data) => {
  Object.keys(state.available_territory).forEach((key, index) => {
    state.available_territory[key].addresses.forEach((add, k) => {
      if (add.id === data.address.id) {
        add.public_name = null
        add.public_number = null
        Vue.set(state.available_territory[key], k, add)
      }
    })
  })
}

export const CLEAR_ADDRESSES = (state) => {
  state.available_territory = null
  state.available_territory_map = null
}

export const UPDATE_MAP_OPTIONS = (state, data) => {
  state.mapOptions = data
}
