import queue from '@/router/queue'
import submit from '@/router/submit'
import { assign } from '@/composables/dataInterface.js'
import { configureUri } from './configureUri.js'
import { store } from '@/store.js'
import { kebabToCamel, camelToSnake, snakeToCamel, singular } from '@/helpers.js'
import { i18n } from '@/i18n.js'
import Vue from 'vue'

function returnFromCache (resource) {
  // If the resource is in the local cache and the timestamps match, return the resource from the local cache
  resource = camelToSnake(resource)
  if (resource in store.state.core.remoteCache && resource in store.state.core.localCache) {
    if (store.state.core.remoteCache[resource] === store.state.core.localCache[resource]) {
      console.warn('Resource: ' + snakeToCamel(resource) + ' is up to date.')
      return true
    }
  }
  return false
}

function shouldUpdateCache (resource) {
  // If the resource is in the local cache and the timestamps don't match, we need to update the local cache
  resource = camelToSnake(resource)
  if (resource in store.state.core.remoteCache) {
    if (
      !store.state.core.localCache[resource] ||
      store.state.core.remoteCache[resource] !== store.state.core.localCache[resource]
    ) {
      console.warn('Resource: ' + snakeToCamel(resource) + ' requires updating from the server.')
      return true
    }
  }
  return false
}

/**
 * Returns a Promise that sends a GET request to a server at the provided URI.
 * @param uri The URI to send the GET request to.
 * @param args An object containing the following optional properties:
 *  resource: The resource to be retrieved. If not provided, the resource is assumed to be the last part of the URI.
 *  params: An object containing the parameters to be sent with the request.
 *  force: true|false Force function to bypass cache check
 * @returns A new Promise.
 */
export function get (uri, args) {
  const resource = kebabToCamel(args?.resource ?? (Array.isArray(uri) ? uri.at(-1) : uri))
  const params = args?.params || {}
  const reponseType = args?.responseType || 'json'
  const headers = args?.headers || {}

  uri = configureUri(uri)

  const config = {
    params: params,
    responseType: reponseType,
    headers: headers,
    data: {
      message: i18n.t('actions.action_resource', {
        action: i18n.t('actions.loading'),
        resource: i18n.tc('resources.' + singular(camelToSnake(resource)), 0)
      })
    }
  }

  return new Promise((resolve, reject) => {
    if (returnFromCache(resource) && !args?.force) resolve()
    else {
      queue.get('/api' + uri, config)
        .then(response => {
          if (shouldUpdateCache(resource)) {
            assign(resource, response)
            Vue.set(store.state.core.localCache, camelToSnake(resource), store.state.core.remoteCache[camelToSnake(resource)])
          }
          resolve(response)
        })
        .catch(error => {
          reject(error)
        })
    }
  })
}

export function post (uri, payload) {
  uri = configureUri(uri)

  return new Promise((resolve, reject) => {
    store.commit('submitting', true)
    submit.post('/api' + uri, payload)
      .then(response => {
        store.commit('submitting', false)
        resolve(response)
      })
      .catch(error => {
        store.commit('submitting', false)
        reject(error)
      })
  })
}

export function put (uri, payload) {
  uri = configureUri(uri)

  return new Promise(resolve => {
    store.commit('submitting', true)
    submit.put('/api' + uri, payload)
      .then(response => {
        store.commit('submitting', false)
        resolve(response)
      })
      .catch(() => { store.commit('submitting', false) })
  })
}

export function download (uri, payload = {}, accept = '*') {
  uri = configureUri(uri)

  return new Promise((resolve, reject) => {
    submit.post('/api' + uri, payload, {
      responseType: 'arraybuffer',
      headers: {
        Accept: accept
      }
    })
      .then((response) => {
        let filename = 'generated-file-download'
        if (response.headers['content-disposition']) {
          filename = response.headers['content-disposition'].split('=')
        } else if (response.headers['Content-Disposition']) {
          filename = response.headers['Content-Disposition'].split('=')
        }

        // It is necessary to create a new blob object with mime-type explicitly set
        // otherwise only Chrome works like it should
        const newBlob = new Blob([response.data], { type: payload.accept })

        // IE doesn't allow using a blob object directly as link href
        // instead it is necessary to use msSaveOrOpenBlob
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(newBlob)
          return
        }

        // For other browsers:
        // Create a link pointing to the ObjectURL containing the blob.
        const data = window.URL.createObjectURL(newBlob)
        const link = document.createElement('a')
        link.href = data
        link.download = filename[1]
        link.click()
        setTimeout(function () {
          // For Firefox it is necessary to delay revoking the ObjectURL
          window.URL.revokeObjectURL(data)
        }, 100)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export function destroy (uri, payload) {
  uri = configureUri(uri)

  return new Promise((resolve, reject) => {
    store.commit('submitting', true)
    submit.delete('/api' + uri, payload)
      .then(response => {
        store.commit('submitting', false)
        resolve(response)
      })
      .catch(error => {
        store.commit('submitting', false)
        reject(error)
      })
  })
}
