<template>
  <span>{{ display }}</span>
</template>

<script setup>
import { computed } from 'vue'
import { Sec } from '../../mixins/sec'

const props = defineProps({
  time: String
})

const display = computed(() => {
  const time = new Sec(props.time)
  return time.format('h:ia')
})
</script>
