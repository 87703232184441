<template>

  <view-content :back="{name:'SpeakerDirectory'}" :content-class="'max-w-5xl'">

    <template v-slot:header>
      {{ $t('labels.congregation.profile') }}
    </template>

    <div class="grid md:grid-cols-3 gap-4">
      <div class="flex flex-col gap-4">
        <ShadowCardRounded>
          <div class="text-xl font-bold">{{ profile.name }}</div>
          <div class="text-base">{{ $t('fields.circuit.label') }} {{ profile.circuit }}</div>
          <LabeledData :label="$t('fields.address.label')">
            {{ profile.location }}
          </LabeledData>
          <LabeledData :label="$t('labels.type.public')">
            {{ profile.public_meeting_time }}
          </LabeledData>
          <action :secondary="true" class="mt-4 text-center md:w-full" v-if="!profile.active || !profile.scheduling_active || cong.id === profile.id" @click="congFormActive = true">
            {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.congregation', 1) }) }}
          </action>
        </ShadowCardRounded>

        <ShadowCardRounded>
          <div v-if="profile.ptc">
            <div class="text-xl font-bold">{{ $t('labels.congregation.ptc') }}</div>
            <LabeledData :label="$t('fields.name.label')">
              <span v-if="profile.ptc">{{ profile.ptc.full_name }}</span>
            </LabeledData>
            <LabeledData :label="$t('fields.email.label')">
              {{ profile.ptc.email }}
            </LabeledData>
            <LabeledData :label="$t('fields.mobile.label')">
              {{ profile.ptc.phone }}
            </LabeledData>
          </div>
          <action :secondary="true" class="mt-4 text-center md:w-full" v-if="!profile.active" @click="ptcFormActive = true">
            {{ $t('actions.edit') }}
          </action>
          <action :secondary="true" class="mt-4 text-center md:w-full" v-else-if="cong.id === profile.id" @click="ptcChangeFormActive = true">
            {{ $t('actions.edit') }}
          </action>
          <action :primary="true" class="mt-4 text-center ml-2 md:w-full md:ml-0" @click="sendSpeakerListActive = true">
            {{ $t('actions.ar', { a: $t('actions.send'), r: 'Speaker List' }) }}
          </action>
        </ShadowCardRounded>
      </div>

      <div class="md:col-span-2">
        <div v-if="!profile.scheduling_active" class="mb-3 text-left">
          <action :primary="true" class="w-full md:w-auto text-center" @click="addSpeaker">
            {{ $t('labels.button.add_speaker') }}
          </action>
        </div>

        <div class="grid gap-4">
          <ShadowCardRounded v-for="speaker in speakers" :key="'sp' + speaker.id">
            <div class="relative grid md:grid-cols-5 gap-3">
              <div class="md:col-span-2">
                <div class="text-base font-bold">{{ speaker.last_first }}</div>
                <div class="text-base capitalize">{{ speaker.appointment }}</div>
                <LabeledData :label="$t('fields.email.label')">
                  {{ speaker.email }}
                </LabeledData>
                <LabeledData :label="$t('fields.mobile.label')">
                  {{ speaker.phone }}
                </LabeledData>
              </div>
              <div class="md:col-span-3 md:flex flex-col justify-between">
                <div>
                  <div class="font-bold mt-1">Confirmed Outlines</div>
                  <div v-for="outline in speaker.outlines" :key="'sp' + speaker.id + 'o' + outline.id">
                    <div class="flex">
                      <div class="w-8 flex-none">{{ outline.number }}</div>
                      <div>|</div>
                      <div class="pl-1">{{ outline.title }}</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="absolute right-0 top-0">
                <div class="px-3 bg-gray-200 py-1 text-gray-500 rounded-full shadow cursor-pointer"
                     @click="speakerActions = speaker.id" :id="'speakerMenu' + speaker.id"
                >
                  <fa-icon :icon="['fas', 'ellipsis-v']" />
                </div>
              </div>
            </div>
          </ShadowCardRounded>
        </div>
      </div>
    </div>

    <secondary-menu v-if="speakerActions" @close="speakerActions = false" :target="'speakerMenu' + speakerActions">
      <ul class="text-lg font-sans">
        <sm-item class="px-4" v-if="!profile.active || cong.id === profile.id"
                 @click="editSpeaker"
                 :icon="['fal', 'pencil']"
        >
          {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.speaker', 1) }) }}
        </sm-item>
        <sm-item class="px-4"
                 v-if="!profile.scheduling_active || cong.id === profile.id"
                 @click="editOutlines"
                 :icon="['fal', 'pencil']"
        >
          {{ $t('labels.button.edit_outlines') }}
        </sm-item>
        <sm-item class="px-4"
                 v-if="!profile.scheduling_active || cong.id === profile.id"
                 @click="addOutlines"
                 :icon="['fad', 'bolt']"
        >
          {{ $t('labels.speaker_directory.quick_outlines.title') }}
        </sm-item>
        <sm-item class="px-4 text-teal-400"
                 @click="bookSpeaker"
                 :icon="['fad', 'calendar-plus']"
        >
          {{ $t('actions.action_resource', { action: $t('actions.book'), resource: $tc('resources.speaker', 1) }) }}
        </sm-item>
        <sm-item v-if="!profile.scheduling_active || cong.id === profile.id" @click="promptDeleteSpeaker"
                 class="px-4 text-red-700 border-t mt-2"
                 :icon="['fal', 'trash']"
        >
          {{ $t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.speaker', 1) }) }}
        </sm-item>

      </ul>
    </secondary-menu>

    <portal to="modals">

      <CongregationProfileEdit
        :active="congFormActive"
        :congregation="profile"
        @close="congFormActive = false"
        @saveResponse="updateCong"
      />

      <CongregationProfilePTCEdit
        :active="ptcFormActive"
        :congregation="profile"
        :ptc="profile.ptc"
        @close="ptcFormActive = false"
        @saveResponse="updatePtc"
      />

      <CongregationProfilePTCChange
        :active="ptcChangeFormActive"
        :congregation="profile"
        :ptc="profile.ptc"
        @close="ptcChangeFormActive = false"
        @saveResponse="updatePtc"
      />

      <CongregationProfileSpeakerEdit
        :active="speakerFormActive"
        :congregation="profile"
        :speaker="speaker"
        @close="speakerFormActive = false"
        @saveResponse="updateSpeaker"
      />

      <CongregationProfileQuickOutlines
        :active="quickOutlineActive"
        :congregation="profile"
        :speaker="speakerId"
        @close="quickOutlineActive = false"
        @saveResponse="updateSpeaker"
      />

      <BookPublicTalkBySpeaker
        :active="bookSpeakerActive"
        :congregation="profile"
        :speaker="speaker"
        @close="bookSpeakerActive = false"
        @saveResponse="speakerBooked"
      />

      <SpeakerListEmail
        :active="sendSpeakerListActive"
        :congregation="profile"
        :speaker="speaker"
        @close="sendSpeakerListActive = false"
      />

      <modal :submitting="submitting" :header="$t('labels.profile.edit')" :active.sync="publicTalksFormActive" @submit="confirmOutlines">
        <tuxedo-select name="street" :label="$t('fields.outlines.label')" v-model="talkOutlines" :multi="true"
                       :options="talkOptions" insistLabel="name" inputClass="bg-gray-100" insistReturn="id" />
      </modal>

      <delete
        :submitting="submitting"
        :header="'Delete Speaker'"
        :active.sync="speakerDeleteActive"
        :message="deleteMessage"
        @submit="confirmDeleteSpeaker()"
      ></delete>
    </portal>

  </view-content>

</template>

<script setup>
import { ref, computed, onMounted } from 'vue'
import { useRoute } from 'vue-router/composables'
import { store } from '@/store.js'
import { orderBy, remove } from 'lodash'
import { replaceOrPush } from '@/helpers'
import { get, post, destroy } from '@/composables/serverInterface'
import CongregationProfilePTCEdit from '@/components/forms/CongregationProfilePTCEdit.vue'
import CongregationProfilePTCChange from '@/components/forms/CongregationProfilePTCChange.vue'
import CongregationProfileEdit from '@/components/forms/CongregationProfileEdit.vue'
import CongregationProfileSpeakerEdit from '@/components/forms/CongregationProfileSpeakerEdit.vue'
import CongregationProfileQuickOutlines from '@/components/forms/CongregationProfileQuickOutlines.vue'
import BookPublicTalkBySpeaker from '@/components/forms/BookPublicTalkBySpeaker.vue'
import SpeakerListEmail from '@/components/forms/SpeakerListEmail.vue'
import ShadowCardRounded from '@/components/elements/ShadowCardRounded.vue'
import LabeledData from '@/components/elements/LabeledData.vue'

const route = useRoute()

const profile = ref({})

const speaker = ref({})
const speakerActions = ref(null)
const speakerId = ref(null)
const talkOutlines = ref([])
const congFormActive = ref(false)
const ptcFormActive = ref(false)
const ptcChangeFormActive = ref(false)
const speakerFormActive = ref(false)
const speakerDeleteActive = ref(false)
const quickOutlineActive = ref(false)
const bookSpeakerActive = ref(false)
const sendSpeakerListActive = ref(false)
const canDelete = ref(false)
const publicTalksFormActive = ref(false)
const submitting = ref(false)

const cong = store.state.settings.cong
const publicTalks = store.state.publicTalkTitles

const talkOptions = computed(() => {
  const talks = []
  publicTalks.forEach((talk) => {
    talks.push({ id: talk.id, name: talk.number + '. ' + talk.title })
  })
  return talks
})

const deleteMessage = computed(() => {
  if (canDelete.value === 'checking') return 'Please wait... checking if this speaker can be deleted.'
  if (canDelete.value) return 'Are you sure you wish to delete ' + speaker.value.full_name + ' as a Speaker?'
  else if (!canDelete.value) return speaker.value.full_name + ' is assigned to give a talk in the future and therefore can not be deleted.'
  return ''
})

const speakers = computed(() => {
  return orderBy(profile.value.speakers, ['last_first'])
})

onMounted(() => {
  get(['congregation', route.params.congregation, 'profile'])
    .then((response) => {
      get(['public-talk-titles'])
      profile.value = response.data.data
    })
})

const addSpeaker = () => {
  speaker.value = {}
  speakerActions.value = false
  speakerFormActive.value = true
}

const editSpeaker = () => {
  speaker.value = profile.value.speakers.find((s) => s.id === speakerActions.value)
  speakerActions.value = false
  speakerFormActive.value = true
}

const promptDeleteSpeaker = () => {
  speaker.value = profile.value.speakers.find((s) => s.id === speakerActions.value)
  speakerActions.value = false
  speakerDeleteActive.value = true
  canDelete.value = 'checking'
  submitting.value = true
  get(['speaker', speaker.value.id, 'delete']).then((response) => {
    submitting.value = false
    canDelete.value = response.data
  })
}

const confirmDeleteSpeaker = () => {
  if (canDelete.value) {
    destroy(['congregations', profile.value.id, 'speakers', speaker.value.id])
      .then(() => {
        speakerDeleteActive.value = false
        profile.value.speakers = remove(profile.value.speakers, (s) => s.id !== speaker.value.id)
      })
  }
}

const editOutlines = () => {
  const speaker = profile.value.speakers.find((s) => s.id === speakerActions.value)
  speakerActions.value = false
  talkOutlines.value = []
  speaker.outlines.forEach((talk) => {
    talkOutlines.value.push(talk.id)
  })
  speakerId.value = speaker.id
  publicTalksFormActive.value = true
}

const addOutlines = () => {
  speakerId.value = speakerActions.value
  speakerActions.value = false
  quickOutlineActive.value = true
}

const bookSpeaker = () => {
  speaker.value = profile.value.speakers.find((s) => s.id === speakerActions.value)
  speakerActions.value = false
  bookSpeakerActive.value = true
}

const updateCong = (response) => {
  profile.value = response.data.data
  congFormActive.value = false
}

const updatePtc = (response) => {
  profile.value = response.data.data
  ptcFormActive.value = false
  ptcChangeFormActive.value = false
}

const updateSpeaker = (response) => {
  profile.value.speakers = replaceOrPush(profile.value.speakers, response.data.data)
  speakerFormActive.value = false
  quickOutlineActive.value = false
}

const speakerBooked = () => {
  bookSpeakerActive.value = false
}

const confirmOutlines = () => {
  post(['speaker-outlines'], { speaker: speakerId.value, outlines: talkOutlines.value })
    .then(() => {
      get(['congregation', route.params.congregation, 'profile']).then((response) => {
        profile.value = response.data.data
        publicTalksFormActive.value = false
      })
    })
}

</script>
