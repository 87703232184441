<template>

  <view-content :back="{name:'Settings'}">

    <template v-slot:header>
      {{ $tc('resources.cart_location', 0) }}
    </template>

    <template v-slot:actions>
      <action :primary="true" v-if="permits['settings.cart_locations.add']" @click="addCartLocation">
        {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.cart_location', 1) }) }}
      </action>
    </template>

    <list-item v-for="r in resources" :key="'cart' + r.id">
      <template v-slot:header>
        <div class="relative">
          <div>{{ r.location }}</div>
          <div class="absolute right-0 top-0">
            <div class="px-3 bg-gray-200 py-1 text-gray-500 rounded-full shadow cursor-pointer" @click="userActions = r.id" :id="'userMenu' + r.id">
              <fa-icon :icon="['fas', 'ellipsis-v']" />
            </div>
          </div>
        </div>
      </template>
      <div>
        <i>{{ r.start_time }}</i> - <i>{{ r.end_time }}</i>
      </div>
      <div v-if="r.congregations.length > 0">
        <div class="mt-3 font-semibold">Calendar Shared With</div>
        <div v-for="id in r.congregations" :key="'share' + r.id + id" class="mb-1">
          <div class="grid grid-cols-12">
            <div class="col-span-8 flex align-center">{{ pluck(congregations).using(id).display('name') }}</div>
            <div class="col-span-4 flex justify-end align-center">
              <button @click="deleteShare(r.id, id)" class="btn btn-danger btn-round"><fa-icon :icon="['fal', 'trash']" /></button>
            </div>
          </div>
        </div>
      </div>
      <div v-if="r.blocks.length > 0">
        <div class="mt-3 font-semibold">Blocked Times</div>
        <div v-for="(block, id) in r.blocks" :key="'cb' + r.id + id" class="mb-1">
          <div class="grid grid-cols-12">
            <div class="col-span-4">{{ days[block.day] }}</div>
            <div class="col-span-4"><i>{{ block.start_time }} - {{ block.end_time }}</i></div>
            <div class="col-span-4 flex justify-end align-center">
              <button @click="deleteCartBlock(block)" class="btn btn-danger btn-round"><fa-icon :icon="['fal', 'trash']" /></button>
            </div>
          </div>
        </div>
      </div>
    </list-item>

    <secondary-menu v-if="userActions" @close="userActions = false" :target="'userMenu' + userActions">
      <ul class="text-lg font-sans">
        <sm-item class="px-4" @click="shareLocation" :icon="['fal', 'handshake']">
          {{ $t('actions.action_resource', { action: $t('actions.share'), resource: $tc('resources.location', 1) }) }}
        </sm-item>
        <sm-item class="px-4" @click="addCartBlock" :icon="['fal', 'calendar-times']">
          {{ $t('actions.action_resource', { action: $t('actions.add'), resource: $tc('resources.cart_block', 1) }) }}
        </sm-item>
        <sm-item class="px-4 text-teal-400" @click="editCartLocation" :icon="['fal', 'pencil']">
          {{ $t('actions.action_resource', { action: $t('actions.edit'), resource: $tc('resources.location', 1) }) }}
        </sm-item>
        <sm-item @click="promptDeleteCartLocation" :icon="['fal', 'trash']" class="px-4 text-red-700 border-t mt-2">
          {{ $t('actions.action_resource', { action: $t('actions.delete'), resource: $tc('resources.location', 1) }) }}
        </sm-item>
      </ul>
    </secondary-menu>

    <portal to="modals">

      <modal :header="$tc('resources.cart_block', 1)" :active.sync="cartBlockFormActive" @submit="submitCartBlock()" :validated="blockValid">
        <input-slider :label="'Day of the Week'" v-model="block.day" :options="days" />
        <input-time :label="'From'" :name="'start_time'" v-model="block.start_time" :minuteStep="30" />
        <input-time :label="'To'" :name="'end_time'" v-model="block.end_time" :minuteStep="30" :help="'All half hour blocks between these two times will be blocked out.'" />
      </modal>

      <modal :header="'Public Witnessing Location Form'" :active.sync="cartLocationFormActive" @submit="submitCartLocation" :validated="resourceValid">
        <template v-slot:default="{ error }">
          <input-text :label="'Location'" :type="'text'" v-model="resource.location" :error="error"></input-text>
          <tuxedo-input :label="'Maximum Publishers'" :type="'number'" :name="'max_users'" v-model="resource.max_users" :inputClass="'bg-gray-100'" :error="error" />
          <input-time :label="'First Hour'" :name="'start_time'" v-model="resource.start_time" :minuteStep="30" :error="error" />
          <input-time :label="'Final Hour'" :name="'end_time'" v-model="resource.end_time" :minuteStep="30" :error="error" />
        </template>
      </modal>

      <modal :header="$t('actions.action_resource', { action: $t('actions.share'), resource: $tc('resources.location', 1) })" :active.sync="cartShareFormActive" @submit="submitShareLocation" :validated="shareValid">
        <template v-slot:default="{ error }">
          <input-select
            :label="$tc('resources.congregation', 1)"
            name="congregation_id"
            :options="filteredCongregations"
            v-model="share.congregation_id"
            insistLabel="name"
            inputClass="bg-gray-100"
            insistReturn="id"
            :error="error"
          />
        </template>
      </modal>

      <delete
        :header="'Delete Cart'"
        :active.sync="cartDeleteActive"
        :message="'Are you sure you wish to delete this cart location?'"
        @submit="confirmDeleteCartLocation()"
      ></delete>

    </portal>

  </view-content>

</template>

<script setup>
import { computed, ref, onMounted } from 'vue'
import { store } from '@/store'
import { get, put, post, destroy } from '@/composables/serverInterface'
import { assign, push, merge, forget } from '@/composables/dataInterface'
import { filter, find } from 'lodash'
import { fillObject, validate, pluck } from '@/helpers'

const initialResource = {
  id: null,
  location: null,
  max_users: 3,
  start_time: null,
  end_time: null
}
const initialBlock = {
  cart_location_id: null,
  day: '0',
  start_time: null,
  end_time: null
}
const requiredBlockFields = ['day', 'start_time', 'end_time']
const requiredResourceFields = ['location', 'max_users', 'start_time', 'end_time']
const requiredShareFields = ['id', 'congregation_id']

const resource = ref({ ...initialResource })
const block = ref({ ...initialBlock })
const share = ref({ id: null, congregation_id: null })
const cartBlockFormActive = ref(false)
const cartLocationFormActive = ref(false)
const cartShareFormActive = ref(false)
const cartDeleteActive = ref(false)
const userActions = ref(false)

const resources = computed(() => store.state.cartLocations)
const congregations = computed(() => store.state.congregations)
const cartBlocks = computed(() => store.state.cartBlocks)
const permits = computed(() => store.state.core.permits)
const days = computed(() => store.state.core.days)
const cong = computed(() => store.state.settings.cong)

const filteredCongregations = computed(() => {
  return filter(congregations.value, c => parseInt(c.region_id) === parseInt(cong.value.region_id))
})

const blockValid = computed(() => {
  return validate(block.value, requiredBlockFields)
})
const resourceValid = computed(() => {
  return validate(resource.value, requiredResourceFields)
})
const shareValid = computed(() => {
  return validate(share.value, requiredShareFields)
})

onMounted(() => {
  get('cart-locations').then(res => {
    assign('cartLocations', res)
  })
  get('cart-blocks').then(res => {
    assign('cartBlocks', res)
  })
})

const mergeCartBlocks = () => {
  resources.value.forEach(c => {
    c.blocks = filter(cartBlocks.value, b => b.cart_location_id === c.id)
    merge('cartLocations', c)
  })
}

const addCartBlock = (cart) => {
  block.value = { ...initialBlock }
  block.value.cart_location_id = userActions.value
  userActions.value = null
  cartBlockFormActive.value = true
}

const submitCartBlock = () => {
  post('cart-blocks', block.value).then(res => {
    push('cartBlocks', res)
    mergeCartBlocks()
    cartBlockFormActive.value = false
  })
}

const deleteCartBlock = (b) => {
  destroy(['cart-blocks', b.id]).then(() => {
    forget('cartBlocks', b)
    mergeCartBlocks()
  })
}

const addCartLocation = () => {
  resource.value = { ...initialResource }
  resource.value.start_time = '6:00'
  resource.value.end_time = '17:00'
  cartLocationFormActive.value = true
}

const editCartLocation = () => {
  resource.value = { ...initialResource }
  fillObject(resource.value, find(resources.value, { id: userActions.value }))
  userActions.value = null
  cartLocationFormActive.value = true
}

const shareLocation = () => {
  share.value.id = userActions.value
  userActions.value = null
  cartShareFormActive.value = true
}

const submitShareLocation = () => {
  get(['cart-locations', share.value.id, 'congregations', share.value.congregation_id, 'attach'])
    .then(res => {
      merge('cartLocations', res.data.data)
      mergeCartBlocks()
      cartShareFormActive.value = false
    })
}

const deleteShare = (cartLocationId, congregationId) => {
  get(['cart-locations', cartLocationId, 'congregations', congregationId, 'detach'])
    .then(res => {
      merge('cartLocations', res.data.data)
      mergeCartBlocks()
      cartShareFormActive.value = false
    })
}

const submitCartLocation = () => {
  const uri = ['cart-locations']
  if (resource.value.id) {
    uri.push(resource.value.id)
    put(uri, resource.value).then(res => {
      merge('cartLocations', res.data.data)
      mergeCartBlocks()
      cartLocationFormActive.value = false
    })
  } else {
    post(uri, resource.value).then(res => {
      push('cartLocations', res)
      cartLocationFormActive.value = false
    })
  }
}

const promptDeleteCartLocation = () => {
  fillObject(resource.value, find(resources.value, { id: userActions.value }))
  userActions.value = null
  cartDeleteActive.value = true
}

const confirmDeleteCartLocation = () => {
  destroy(['cart-locations', resource.value.id]).then(() => {
    forget('cartLocations', resource.value)
    cartDeleteActive.value = false
  })
}

</script>
