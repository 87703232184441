import axios from 'axios'

export const GET_CARTS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('https://srvc.app/api/schedules/carts/?t=' + payload.token)
      .then(function (response) {
        commit('UPDATE_CARTS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_FIELD_MEETINGS = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/schedules/service')
      .then(function (response) {
        commit('UPDATE_FIELD_MEETINGS', response.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_CART_CALENDAR = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.post('api/cart-schedule', payload)
      .then(function (response) {
        commit('UPDATE_CART_SCHEDULE', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const GET_NOTICEBOARD_FILES = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.get('api/noticeboard-files')
      .then(function (response) {
        commit('UPDATE_NOTICEBOARD_FILES', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}

export const DELETE_NOTICEBOARD_FILE = ({ commit }, payload) => {
  return new Promise((resolve, reject) => {
    axios.delete('api/noticeboard-file/' + payload.id)
      .then(function (response) {
        commit('UPDATE_NOTICEBOARD_FILES', response.data.data)
        resolve(response)
      })
      .catch(function (error) {
        reject(error)
      })
  })
}
